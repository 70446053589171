.sierra-part-library-panel {
  box-shadow: 0 4px 12px 4px rgba(0, 0, 0, 0.15);
}

.part-library-content {
  width: 100%;
  padding: 10px 12px;
  min-width: 300px;
}

.part-library-upload-content {
  /* width: 100%; */
  /*   display: flex; */
  /*  justify-content: space-between; */
  /*   justify-content: flex-end; */
  float: right;
  /*   height: 28px; */
  /*  margin: 8px 0px; */
  margin-right: 10px;
  cursor: pointer;
  width: 22px;
  height: 22px;
  text-align: center;
}

.part-library-upload-button,
.part-library-add-button,
.part-library-check-button {
  /*  color: #1890ff; */
  padding: 0px 8px;
}

.part-library-content .aurora-model-name-error-msg,
.part-library-content .aurora-success-msg {
  margin-bottom: 6px;
}

.part-repeater-pin-library-value {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  position: relative;
  width: 100%;
}

.part-library-dialog-search {
  margin-bottom: 8px;
}

.part-library-dialog-search .aurora-input {
  width: 100%;
}

.sierra-part-library-check-file-icon {
  color: #1890ff;
  cursor: pointer;
  margin-left: 10px;
  vertical-align: text-bottom;
}

.sierra-part-library-help-icon,
.sierra-part-library-delete-icon,
.sierra-part-library-position-add-icon {
  color: #1890ff;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  right: 2px;
  top: 6px;
}

.sierra-part-library-table-part-text .sierra-part-library-delete-icon {
  color: #ccc;
  font-size: 14px;
  display: none;
  top: 7px;
}

.sierra-part-library-table-part-text:hover .sierra-part-library-delete-icon {
  color: #1890ff;
  display: block;
}

.sierra-part-library-table-part-text .sierra-part-library-position-add-icon {
  color: #ccc;
  font-size: 14px;
  display: none;
  top: 4px;
  /*   right: 22px; */
}

.sierra-part-library-table-part-text:hover .sierra-part-library-position-add-icon {
  color: #1890ff;
  display: block;
}

.sierra-part-library-table-text,
.sierra-part-library-table-part-text {
  width: 100%;
  position: relative;
  min-height: 22px;
}

.sierra-part-library-table-text>span:not(.anticon),
.sierra-part-library-table-part-text>span:not(.anticon) {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sierra-part-library-table-part-text:hover>span:not(.anticon) {
  padding-right: 17px;
}

.sierra-part-library-add-icon {
  cursor: pointer;
  color: #1890ff;
  font-size: 16px;
  vertical-align: middle;
  margin-left: 8px;
}

.sierra-part-repeater-part-title {
  vertical-align: middle;
}

/* check file panel */

.part-library-check-files-content {
  padding: 8px 10px;
  font-weight: 600;
  background: #f0f0f0;
  border: 1px dashed #ccc;
  border-radius: 4px;
}

.part-library-check-files-content .part-library-check-files-no-data {
  color: #ccc;
  font-weight: normal;
  text-align: center;
}

/* repeater connections panel */
.sierra-repeater-connections-table tbody>tr>td:last-child {
  position: relative;
}

.sierra-repeater-connections-table .delete-icon {
  position: absolute;
  right: 2px;
  top: calc(50% - 6px);
}

.sierra-repeater-connections-table .delete-icon:hover {
  color: #1890ff;
}

.sierra-repeater-connections-table tbody>tr>td:last-child .editable-cell-value-wrap {
  width: calc(100% - 6px);
}

.sierra-repeater-connections-content {
  padding: 10px 14px;
}

.sierra-repeater-connections-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.sierra-repeater-connections-add-button {
  height: 24px;
  font-size: 13px;
}

.repeater-model-pin-input,
.repeater-model-pin-output {
  display: flex;
  width: calc(50% - 30px);
}

.repeater-model-pin-input {
  margin-right: 10px;
  justify-content: right;
}

.repeater-model-pin-output {
  margin-left: 10px;
  justify-content: left;
}

.repeater-model-pin-content {
  margin: 0;
  padding: 0;
  border: 1px dashed #ccc;
  background: #f0f0f0;
  border-radius: 4px;
  margin: 5px 5px;
}

.sierra-repeater-connections-content .spice-model-single-select .spice-netlist-model-select {
  width: 100%;
  padding: 5px 5px;
  padding-right: 20px;
}

.repeater-model-pin-item {
  display: flex;
  justify-content: center;
  height: 28px;
  line-height: 28px;
  margin: 10px 0px;
  align-items: center;
}

.repeater-model-pin-title {
  height: 28px;
  width: fit-content;
  max-width: 80px;
  min-width: 20px;
  margin: 0px 10px;
  text-align: center;
}

/* .repeater-model-pin-input .repeater-model-pin-title {
  text-align: left;
}

.repeater-model-pin-output .repeater-model-pin-title {
  text-align: right;
} */

.repeater-model-pin-item-title .repeater-model-pin-input {
  margin-right: 30px;
}

.repeater-model-pin-item-title .repeater-model-pin-output {
  margin-left: 30px;
}

.repeater-model-pin-content .spice-pin-line {
  width: 40px;
}


.repeater-model-pin-content .repeater-spice-pin-input {
  min-width: 80px;
  font-size: 12px;
}

.repeater-model-pin-content .repeater-spice-pin-input .ant-input {
  text-align: center;
}

.repeater-model-pin-content .repeater-spice-pin-input:hover .ant-input-clear-icon {
  visibility: visible;
}

.repeater-model-pin-content .repeater-spice-pin-input .ant-input-clear-icon {
  visibility: hidden;
}

/* .node-list-ul>.node-list-ul {
  height: fit-content;
  width: 100%;
  list-style: none;
  min-width: 20px;
  max-height: 100px;
  border-radius: unset;
}

.node-list-ul>.node-list-ul>li {
  padding-left: 18px;
}

.node-list-ul>div {
  height: 24px;
  line-height: 24px;
  padding: 0px 6px;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
  font-weight: 600;
  background-color: #f0f0f0;
}
 */
.sierra-repeater-connections-content .spice-model-multi-select>*:first-child {
  padding-left: 5px;
}

.repeater-model-pin-item .spice-pin-input .ant-input {
  height: 28px;
  line-height: 28px;
  padding: 4px 6px;
}

.repeater-model-pin-item .spice-pin-input .ant-input-suffix {
  right: 6px;
}

.repeater-model-pin-item .spice-pin-input .ant-input-suffix {
  display: none;
}

.repeater-model-pin-item .spice-pin-input:hover .ant-input-suffix {
  display: flex;
}

.repeater-model-pin-copy-content {
  padding-left: 12px;
  display: none;
}

.repeater-model-pin-copy-icon {
  color: #1890ff;
  cursor: pointer;
}

.repeater-model-pin-item:hover .repeater-model-pin-copy-content {
  display: block;
}

.part-library-menu-list {
  margin-bottom: 8px;
}

.part-library-menu-list .ant-divider-vertical {
  margin: 0px 4px;
}