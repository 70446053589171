/* === package model panel === */
.pkg-new-panel .package-model-select {
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 4px 10px;
}

.pkg-new-panel .package-model-select.package-die-model-select {
  font-weight: 400;
}

.pkg-new-panel .package-model-selection {
  width: calc(100% - 100px);
  height: 24px;
}

.pkg-new-panel .package-model-selection .ant-select-arrow {
  right: 11px !important;
}

.pkg-new-panel .package-model-select-dropdown {
  z-index: 10000000;
}

.pkg-new-panel .package-model-select>*:first-child {
  width: 100px;
  height: 24px;
  line-height: 22px;
}

.pkg-new-panel .package-model-title {
  font-size: 14px;
  font-weight: 500;
}

.pkg-new-panel .package-model-select .package-model-selection {
  height: 24px;
  line-height: 22px;
  font-weight: 500;
  font-size: 13px;
  margin-left: 0px;
  width: calc(100% - 111px);
  flex: 1;
}

.spice-model-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.spice-model-panel .panel-body {
  position: relative;
}

.spice-model-panel .spice-model-panel-title {
  font-size: 14px;
  font-weight: 500;
}

.pkg-new-panel .spice-model-content>div {
  min-width: 650px;
  /* width: fit-content; */
}

.pkg-new-panel .spice-pin-list-ul {
  padding: 3px 10px;
}

.pkg-new-panel .spice-model-single-select .pkg-model-select.pkg-model-select-exist {
  width: calc(100% - 10px);
}

.pkg-new-panel .pkg-model-select {
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 4px 10px;
}

.pkg-new-panel .pkg-model-select>*:first-child {
  width: 100px;
  height: 24px;
  line-height: 24px;
  display: inline-block;
}

.pkg-new-panel .pkg-model-select>.pkg-model-selection-div {
  width: calc(100% - 130px);
  display: inline-block;
}

.pkg-new-panel .pkg-model-select-width>.pkg-model-selection-div {
  width: calc(100% - 100px);
}

.pkg-new-panel .pkg-model-select .pkg-model-selection-div .pkg-model-selection,
.pkg-new-panel .pkg-model-select .pkg-model-selection-div .pkg-model-selection .ant-select-selection {
  height: 24px;
  line-height: 22px;
}

.pkg-new-panel .pkg-model-select .pkg-model-selection-div .pkg-model-selection {
  width: 100%;
}

.pkg-new-panel .pkg-model-select .ant-select-selector,
.pkg-new-panel .pkg-model-select .spice-netlist-model-selection-item,
.pkg-new-panel .pkg-model-select .ant-select-selection,
.pkg-new-panel .spice-netlist-model-selection {
  line-height: 22px;
  font-weight: 500;
  font-size: 13px;
}

.pkg-new-panel .spice-netlist-model-selection {
  height: 24px;
}

.pkg-new-panel .pkg-model-select .Subckt {
  width: 0px;
}

.pkg-new-panel .pkg-model-select .Subckt_select {
  width: 100%;
}

.pkg-new-panel .spice-model-multi-select .pkg-model-select {
  padding-left: 5px;
}

.pkg-new-panel .pkg-model-select .ant-switch {
  width: unset;
  display: inline-block;
}

.pkg-new-panel .spice-box {
  cursor: pointer;
}

.pkg-new-panel .spice-model-single-select .ant-select-disabled .ant-select-selection {
  background: #ffffff;
  cursor: text;
  color: rgba(0, 0, 0, 0.65);
}

.pkg-new-panel .spice-model-single-select .ant-select-arrow .ant-select-suffix {
  display: none;
}

.pkg-new-panel .pkg-pin-node-input {
  flex: 0 0 auto;
  text-align: center;
  max-width: 80px;
  width: 80px;
  min-width: 50px;
  display: inline-block;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  position: relative;
  vertical-align: top;
}

.pkg-new-panel .pkg-pin-node-input .ant-input:not(:last-child) {
  padding-right: 11px;
}

.pkg-new-panel .pkg-pin-node-input:hover span {
  max-width: 65px;
}

.pkg-new-panel .pkg-pin-node-input span {
  display: inline-block;
  max-width: 80px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pkg-new-panel .pkg-pin-node-input:hover .spice-pin-node-delete-icon {
  display: inline-block;
}

.spice-model-single-select:hover .pkg-model-delete-file-icon,
.spice-model-multi-select:hover .pkg-model-delete-file-icon {
  visibility: visible;
}

.pkg-model-delete-file-icon {
  color: #3da4fa;
  font-size: 13px;
  visibility: hidden;
  position: absolute;
  right: 4px;
  top: 11px;
}

.pkg-new-panel .pkg-model-selection-div .spice-netlist-model-selection {
  width: 100%;
  height: 24px;
  line-height: 22px;
}

.pkg-new-panel .spice-box-signal {
  cursor: pointer;
  padding: 0px 4px;
  text-align: center;
}

.pkg-new-panel .apply-all-main {
  margin-left: 12px;
  margin-top: 12px;
}

.pkg-new-panel .component-apply-all-title {
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
}

.pkg-new-panel .component-apply-all-checkbox {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  height: 24px;
}

.pkg-new-panel .component-apply-all-checkbox .ant-checkbox {
  height: 16px;
  display: inline-block;
}

.spice-netlist-model-title-div .anticon.anticon-menu.ant-dropdown-trigger {
  cursor: pointer;
  color: #0d87f7;
  font-size: 15px;
  margin-left: 10px;
  vertical-align: text-bottom;
}

.circle-blank-box {
  width: 18px;
  padding: 0px 5px;
  margin-left: 4px;
  margin-right: 8px;
}

.spice-netlist-model-title>.spice-netlist-model-title-new {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spice-netlist-model-title .anticon.anticon-ellipsis.ant-dropdown-trigger {
  font-size: 17px;
  margin-left: 10px;
}

.spice-netlist-model-title .spice-netList-model-width-div {
  width: 200px;
  margin-right: 157px;
  position: relative;
  display: flex;
  justify-content: center;
}

.spice-netlist-model-title .spice-netList-model-width-div.spice-netlist-model-title-div .spice-netlist-model-title-pcb-add-icon {
  right: -168px;
}

.add-model-box {
  width: 200px;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #a8c4e6;
  border-radius: 4px;
  cursor: pointer;
  vertical-align: middle;
  margin: 34px 0 6px;
  z-index: 10;
  margin-left: 10px;
}

.add-model-box>div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.add-model-box>div .plus-icon {
  font-size: 48px;
  color: #a8c4e6;
}

.spice-netlist-model-title-div .iconfont.icon-xinpian_chip {
  font-size: 15px;
  margin-left: 10px;
  cursor: pointer;
}

.spice-netlist-model-title .port-icon-box {
  width: 200px;
  text-align: center;
  padding-left: 10px;
  color: #005a9e;
  cursor: pointer;
  line-height: 32px;
  height: 32px;
}

.pkg-model-disable-select .pkg-model-selection .ant-select-selector {
  color: #f30518;
}

.pkg-model-disable-select .anticon.anticon-question-circle.aurora-file-check-icon {
  font-size: 16px;
  top: 18px;
  right: 30px;
}

.pkg-model-disable-panel-select .anticon.anticon-question-circle.aurora-file-check-icon {
  right: 59px;
}

.spice-model-single-select .pkg-model-select.pkg-model-disable-select .pkg-model-selection-div:hover .aurora-file-check-icon {
  right: 50px;
}

.spice-model-single-select .pkg-model-select.pkg-model-disable-panel-select .pkg-model-selection-div:hover .aurora-file-check-icon {
  right: 79px;
}

.spice-model-single-select.spice-subckt-model-single-select {
  display: flex;
}

.spice-subckt-model-single-select .pkg-model-select>*:first-child {
  width: 0;
}

.spice-subckt-model-single-select .pkg-model-select>.pkg-model-selection-div {
  width: 100%;
}

.spice-pin-list-ul .spice-pin-connect-node-title {
  width: 100%;
  text-align: center;
  color: #005a9e;
  height: 32px;
  font-size: 16px;
  line-height: 32px;
  padding-left: 8px;
  position: relative;
}

.spice-pin-list-ul .spice-pin-connect-node-title .icon-xinpian_chip {
  cursor: pointer;
}

.spice-pin-list-ul .spice-pin-connect-node-title .anticon-delete {
  margin-left: 8px;
  position: absolute;
  color: #537fa8;
  right: 10px;
  top: 7px;
}

.pkg-new-panel .spice-box.spice-box-disable {
  cursor: default;
}

/* .spice-subckt-model-single-select-width {
  width: 100%;
} */

.pkg-new-panel .spice-subckt-model-single-select-width .pkg-model-select {
  width: 100%;
}

.pkg-panel .find-package-layout-errorMsg-box {
  margin: 4px 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 7px;
  max-height: 130px;
  overflow: auto;
  font-size: 14px;
}

.pkg-panel .find-package-layout-errorMsg-box .errorMsg-box-div {
  padding-left: 5px;
}

.pkg-panel .find-package-layout-errorMsg-box .errorMsg-box-span {
  padding-left: 15px;
}

.spice-pin-list-li-left,
.spice-pin-list-li-right {
  width: min-content;
}

.spice-netlist-model-collapse {
  margin: 4px 10px;
}

.spice-netlist-model-collapse>.ant-collapse-item:last-child .ant-collapse-header {
  padding: 6px 6px;
  padding-left: 6px;
}

.spice-netlist-model-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  left: 8px;
}

.spice-netlist-model-collapse>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
  padding: 0px 8px 8px 8px;
}

.rocky-pkg-panel .spice-pin-list-ul .spice-pin-connect-node-item-center {
  justify-content: center;
  position: unset;
}

.spice-pin-line-die {
  margin-left: 8px;
}

.andes-die-model-DIE {
  position: absolute;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

.andes-die-model-termination {
  position: absolute;
  right: 20px;
}

.spice-netlist-model-title {
  position: relative;
  height: 32px;
}

.spice-netlist-model-title .spice-netList-model-width-div-termination {
  margin-right: 0px;
}

.spice-netList-model-width-div-die .anticon-delete {
  position: absolute;
  right: -20px;
  top: 7px;
  color: #537fa8;
  font-size: 16px;
}

.spice-netList-model-termination-box {
  position: relative;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
}

.pkg-new-panel .pkg-model-select.pkg-die-model-select {
  font-weight: 400;
}

.model-row-item {
  display: flex;
  padding: 4px 10px;
  height: 32px;
}

.model-row-item>label {
  width: 130px;
}

.model-row-item>.model-title {
  font-weight: 500;
  font-size: 14px;
}

.model-row-item>div {
  flex: 1;
}

.spice-netList-model-termination-box {
  position: relative;
}

.spice-netList-model-termination-box>.model-termination-input-box {
  position: absolute;
  width: 80px;
  left: 50%;
  top: -8px;
  transform: translateX(-50%);
}