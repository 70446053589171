li {
  list-style: none;
}

.clear {
  zoom: 1;
}

.clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.my-ccc-monitor {
  overflow: auto;
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  top: 44px;
  left: 102px;
  right: 14px;
  bottom: 14px;
}

.my-ccc-backend-log {
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  top: 44px;
  left: 102px;
  right: 14px;
  bottom: 14px;
}

.ccc-simulation-holygrail {
  position: absolute;
  right: 100px;
  left: 0;
  box-sizing: border-box;
}

.ccc-simulation-title {
  width: 100%;
  position: relative;
  height: 44px;
}

.ccc-debug-button {
  float: right;
  line-height: 44px;
  height: 44px;
  font-size: 18px;
  color: #1890ff;
  position: relative;
  z-index: 20;
  cursor: pointer;
  box-sizing: border-box;
  margin-right: 16px;
}

.ccc-button-icon {
  font-size: 22px;
}

.ccc_monitor {
  padding: 14px 20px;
  margin: 0;
  overflow: unset;
}

.ccc-task-list {
  display: inline-block;
  line-height: 44px;
  margin-left: 10px;
  height: 42px;
}

.ccc-status-list {
  display: inline-block;
  line-height: 44px;
  margin-left: 10px;
  height: 42px;
}

.ccc-server-task-span {
  display: inline-block;
  margin-right: 10px;
  line-height: 44px;
}

.ccc-server-task-span-popover {
  display: inline-block;
  margin-right: 10px;
  line-height: 36px;
  width: 45px;
}

.ccc-server-task-title {
  display: inline-block;
  margin-left: 16px;
  line-height: 44px;
  font-weight: 600;
  cursor: pointer;
}

.ccc-server-task-title-popover {
  display: inline-block;
  margin-left: 16px;
  line-height: 44px;
  font-weight: 600;
  cursor: pointer;
}

.ccc-server-task-title-popover:hover {
  color: #1890ff;
}

.ccc-server-select {
  width: 230px;
}

.ccc-page-server-select {
  min-width: 340px;
}

.ccc-task-select {
  width: 100px;
}

.ccc-task-select ., .ccc-server-select ., .ccc-page-server-select . {
  height: 28px;
  line-height: 28px !important;
  box-sizing: border-box;
}

.ccc-task-select . .ant-select-selector, .ccc-server-select . .ant-select-selector, .ccc-page-server-select . .ant-select-selector {
  height: 26px;
  line-height: 26px !important;
  box-sizing: border-box;
}

.ccc-task-kill-popover-button {
  width: 70px;
  height: 28px;
  line-height: 27px;
  padding: 0 8px;
  float: right;
  margin-top: 7px;
}

.ccc-task-kill-button {
  width: 70px;
  height: 28px;
  line-height: 27px;
  padding: 0 8px;
}

.ccc-task-kill-div {
  line-height: 44px;
  display: inline-block;
  margin-left: 10px;
}

.ccc-task-list-popover {
  line-height: 36px;
  margin: 0 10px;
  height: 36px;
}

.ccc-server-select-popover . {
  height: 28px;
  line-height: 28px !important;
  box-sizing: border-box;
}

.ccc-server-select-popover . .ant-select-selector {
  height: 26px;
  line-height: 26px !important;
  box-sizing: border-box;
}

.ccc-server-select-popover {
  width: 180px;
}

.ccc-popover .ant-popover-title {
  display: none;
}

.ccc-popover .ant-popover-inner-content {
  padding: 8px;
  height: 126px;
}

.ccc-debug-button {
  margin-right: 16px;
}

.ccc-debug-log-item {
  display: inline-block;
  box-sizing: border-box;
  border-right: 2px solid #e2dfdf;
  border-left: 2px solid #e2dfdf;
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
}

.ccc-log-ul {
  margin: 0;
  overflow: unset;
  padding: 16px;
  padding-top: 10px;
  white-space: nowrap;
}

.ccc-type-span {
  font-size: 15px;
  font-weight: 600;
  display: inline-block;
}

.ccc-type-daemon-span {
  font-size: 15px;
  font-weight: 600;
  display: block;
  padding-bottom: 10px;
}

.ccc-type-close-button {
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 22px;
  position: absolute;
  right: 0px;
  top: 4px;
  z-index: 20;
  background: #4e4e4e;
  z-index: 20;
  opacity: 0.5;
  border-radius: 4px 0 0 4px;
}

.ccc-type-close-button .ccc-close-icon {
  font-size: 14px;
  color: #fff;
  line-height: 22px;
  cursor: pointer;
  opacity: 1;
}

.ccc-type-close-button:hover {
  transition: 0.6s;
  opacity: 0.8;
}

.ccc-daemon-title {
  margin: 0;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
  padding: 10px 16px
}

.ccc-worker-select {
  width: 112px;
}

.ccc-daemon-list {
  display: inline-block;
  line-height: 30px;
  margin: 6px 10px;
  height: 30px;
}

.ccc-daemon-list-switch {
  display: inline-block;
  line-height: 24px;
  height: 30px;
  vertical-align: middle;
  margin: 6px 10px;
}

.ccc-daemon-span {
  display: inline-block;
  line-height: 30px;
  height: 30px;
  width: 57px;
}

.ccc-worker-select . {
  height: 26px;
  line-height: 26px !important;
  box-sizing: border-box;
}

.ccc-worker-select . .ant-select-selector {
  height: 24px;
  line-height: 24px !important;
  box-sizing: border-box;
}

.ccc-log-div {
  overflow: auto;
  bottom: 0;
  top: 32px;
  position: absolute;
  left: 0;
  right: 0;
  border: 2px solid #e4e4e4;
  border-radius: 4px;
  color: #000000cc;
}

.ccc-daemon-box {
  height: 100%;
  margin-left: 10px;
  position: relative;
}

.ccc-daemon-div {
  overflow: auto;
  /* position: absolute; */
  bottom: 0;
  top: 72px;
  left: 0;
  right: 0;
  border: 2px solid #e4e4e4;
  border-radius: 4px;
  background: #ffffff;
  color: #000000cc;
  height: 100%;
}

.ccc-daemon-msg {
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
}

.ccc-daemon-switch {
  vertical-align: text-top;
}

.task-table-status {
  display: inline-block;
  width: 100%;
  position: relative;
}

.task-table-status .icon-cancel9 {
  font-size: 18px;
  color: #1890ff;
  cursor: pointer;
  position: absolute;
  top: -3px;
  left: 40px;
}

.ccc-delete-pop-confirm .task-table-status-remove {
  display: block;
  height: 20px;
  float: right;
  text-align: center;
  line-height: 16px;
  font-size: 12px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 6px;
}


.ccc-delete-title {
  display: block;
  font-size: 14px;
  color: #f12727;
}

.ccc-delete-confirm {
  color: #1890ff;
}

.ccc-delete-confirm:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.ccc-delete-pop-confirm .ant-popover-buttons {
  display: none;
}

.ccc-delete-pop-confirm .ant-popover-inner-content {
  padding: 8px 16px;
}

.ccc-delete-pop-confirm .ant-popover-message {
  padding-bottom: 0;
}

.ccc-delete-pop-confirm .ant-popover-message>.ccc-confirm-icon {
  display: none;
}

.ccc-delete-pop-confirm .ant-popover-message>.ccc-confirm-icon {
  display: none;
}

.ccc-delete-pop-confirm .ant-popover-message-title {
  padding-left: 0;
}

.table-status-remove-content {
  width: 221px;
}