.decap-radio-upload {
  float: left;
  line-height: 20px;
  width: 50%;
  margin: 0 !important;
}

.decap-radio-edit {
  float: left;
  line-height: 20px;
  width: 50%;
  margin: 0 !important;
}

.decap-radio-touchstone {
  float: left;
  line-height: 20px;
  width: 50%;
  margin: 0 !important;
}

.decap-radio-group {
  display: block;
  margin: 0 16px;
  height: 40px;
  margin-top: 16px;
}

.VRM-table {
  margin: 8px 20px;
}

.model-name {
  display: inline-block;
  width: 200px;
  margin-left: 10px;
  font-size: 16px;
  margin-right: 10px;
}

.SPIM-input {
  width: 89%;
  margin: 16px;
  margin-bottom: 0px;
}

.SPIM-upload-icon {
  font-size: 18px;
  color: #1890ff;
  cursor: pointer;
}

.SPIM-del-icon {
  margin-left: 10px;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
}

.unit-select {
  float: right;
}

.unit-span {
  line-height: 32px;
}

.decap-table {
  position: absolute;
  width: 90%;
  left: 42px;
  top: 160px;
}

.disabled {
  opacity: 0.5 !important;
}

.under-line {
  width: 94%;
  height: 1px;
  background: #cdcfd2;
  margin: 0 10px;
}

#decap-file-view,
#powerdomain-file-view {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  border: 1px solid #cdcfd2;
}

.decap-file-panel .panel-body {
  position: relative;
  min-height: 156px;
}

.decap-file-panel .dividingLine {
  top: 0px;
}

.decap-file-content {
  background: #fafafa;
  padding: 16px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  height: 400px;
  margin-bottom: 0;
}

.decap-response-icon {
  color: #1890ff;
  font-size: 18px;
  margin-left: 10px;
  vertical-align: middle;
}

.decap-res-progress-bar {
  width: 100%;
  max-width: 500px;
  margin-left: 20px;
}

.decap-graph-subckts-list {
  float: right;
  width: 26%;
  height: 100%;
  background: #f9f9f9;
  position: relative;
  z-index: 10;
  border-left: 1px solid #e2dfdf;
}

.decap-graph-right-content {
  height: 100%;
}

.decap-graph-subckts-list .decap-subckt-name {
  display: inline-block;
  max-width: 160px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.decap-graph-file-name {
  transition: all 0.3s;
  font-weight: bold;
  margin-top: 5px;
  cursor: pointer;
  line-height: 24px;
}

.decap-graph-file-name .ant-checkbox-group {
  width: 100%;
}

.decap-graph-file-name:hover {
  background-color: #e0e0e0;
  transition: all 0.3s;
}

.decap-graph-checkbox-group>.ant-checkbox {
  top: 0px;
  left: 4px;
}

.decap-graph-checkbox-group-name {
  display: inline-block;
  line-height: 20px;
}

.decap-graph-checkbox-group .ant-checkbox+span {
  height: 26px;
  display: inline-block;
  padding-left: 6px;
  padding-right: 4px;
}

.decap-graph-checkbox-group {
  width: 100%;
}

.decap-graph-curve-current {
  display: inline-block;
  margin-left: 2px;
  vertical-align: middle;
  font-weight: 400;
}

.decap-graph-color-select {
  padding: 0px;
  margin: 0px;
  background-color: #f9f9f9;
  border: 0px;
  width: 19px;
  margin-right: 2px;
  cursor: pointer;
  vertical-align: middle;
}

.decap-graph-file-name:hover .decap-graph-color-select {
  background-color: #e0e0e0;
}

.decap-graph-right,
.decap-graph-right-100 {
  float: right;
  height: 100%;
  width: 70%;
  position: relative;
}

.decap-graph-right-100 {
  width: 100%;
}

.decap-file-svg {
  position: absolute;
  left: 6px;
  top: 0;
  bottom: 0;
  right: 26%;
}

.decap-graph-svg-content {
  width: 100%;
  height: 100%;
}

.decap-graph-setting-box {
  width: 100%;
  height: auto;
}

.decap-graph-setting-box .ant-row-flex-middle {
  margin: 2px 0;
}

.decap-graph-select {
  z-index: 1000000;
}

.decap-graph-selector-slide {
  cursor: move;
  fill: #337ab7;
}

.selector-traveller {
  cursor: col-resize;
  fill: #444;
}

.decap-graph-curve-mark {
  fill: #ff0000;
}

.decap-graph-mark-text {
  font-size: 10px;
  text-anchor: middle;
}

.decap-file-display {
  float: left;
}

.decap-graph-setting-input {
  width: 80px;
  margin-left: 0px;
  height: 28px;
}

.decap-graph-setting-input input {
  height: 28px;
}

.decap-graph-setting {
  padding-top: 8px;
  overflow: auto;
}

.decap-graph-setting-title {
  cursor: pointer;
  padding-right: 14px;
}

.decap-graph-file-list {
  overflow: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.decap-graph-setting-content {
  min-width: 240px;
}

.decap-graph-setting {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 50%;
}

.decap-characterize-invisible {
  position: absolute;
  top: 16px;
  left: 30%;
  padding-left: 16px;
  z-index: 1;
}

.decap-characterize-visible {
  position: absolute;
  top: 18px;
  right: 40px;
  z-index: 1;
  font-size: 16px;
}

.netListFold {
  position: absolute;
  left: 6px;
  top: 30px;
  width: 24px;
  height: 24px;
  line-height: 27px;
  border-radius: 5px;
  color: #0d87f7;
  font-size: 16px;
  font-weight: bold;
  transition: 0.3s;
  box-shadow: #ffffff 0px 0px 0px 0px;
  background: #ffffff;
  z-index: 1;
  border: 1px solid#dedede;
  justify-content: center;
}

.show-hide-plot {
  position: absolute;
  left: 6px;
  top: 60px;
  width: 24px;
  height: 24px;
  line-height: 27px;
  border-radius: 5px;
  color: #0d87f7;
  font-size: 16px;
  font-weight: bold;
  transition: 0.3s;
  box-shadow: #ffffff 0px 0px 0px 0px;
  background: #ffffff;
  z-index: 1;
  border: 1px solid#dedede;
  justify-content: center;
}

.netListFold:hover,
.show-hide-plot:hover {
  color: #379af5;
  box-shadow: rgba(168, 204, 241, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.18) 0px 0px 5px 2px;
  transition: 0.3s;
  background: #fafafa;
}

.decap-graph-maximize {
  color: #1890ff;
  font-size: 18px;
  margin-right: 26px;
  float: right;
  margin-top: 2px;
  cursor: pointer;
}

.decap-graph-setting-box .ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 0%;
}

#decap-file-view .panel-ant-modal-title,
#powerdomain-file-view .panel-ant-modal-title {
  line-height: 18px;
}

.sp-setting-select {
  height: 28px;
  line-height: 28px;
  width: 100px;
}

.sp-setting-select div {
  height: 28px;
}

.sp-setting-select .ant-select-selection-selected-value {
  line-height: 26px;
  padding-right: 14px;
}

.sp-setting-select .ant-select-selector {
  line-height: 26px;
}

.sp-setting-select .ant-select-arrow {
  right: 6px;
}

.sp-setting-select-value {
  width: 102px;
}

.PDN-show-models .panel-ant-modal-body.panel-body {
  padding: 16px !important;
}

.show-model-content {
  background: #f5f5f5;
  max-height: 300px;
  overflow: auto;
  padding: 12px 16px;
  margin: 0;
  border: 1px dashed #d9d9d9;
}

.pdn-show-log-content {
  background: #f5f5f5;
  max-height: 800px;
  overflow: auto;
  padding: 12px 16px;
  margin: 0;
  border: 1px dashed #d9d9d9;
}