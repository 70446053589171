.andes-v2-hspice-simulation-container {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 10px;
  font-weight: 500;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 14px;
  color: black;
}

.andes-v2-hspice-simulation-input,
.andes-v2-hspice-simulation-rx,
.andes-v2-hspice-simulation-tx-net,
.andes-v2-hspice-simulation-tx-node,
.andes-v2-hspice-simulation-ctle {
  position: absolute;
  cursor: pointer;
}

.andes-v2-hspice-simulation-canvas-box {
  padding: 10px;
  position: relative;
}

.andes-v2-hspice-simulation-input .z0-empty-input {
  border: 1px solid #cccccc;
  border-radius: 4px;
  color: #afafaf;
  width: 60px;
}

/* canvas ctle */
.andes-v2-hspice-simulation-ctle-item {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 200px;
  padding: 10px;
}

.andes-v2-hspice-simulation-ctle-item-zIndex {
  z-index: 100;
}

.andes-v2-hspice-simulation-ctle-item>.ant-input,
.andes-v2-hspice-simulation-canvas-input {
  width: 60px;
  padding: 4px;
  text-align: center;
}

.andes-v2-hspice-simulation-canvas-div,
.andes-v2-hspice-simulation-canvas-div-net,
.andes-v2-hspice-simulation-canvas-input.ant-input,
.andes-v2-hspice-simulation-canvas-signal-name {
  position: absolute;
  z-index: 100;
}

.andes-v2-hspice-simulation-canvas-div,
.andes-v2-hspice-simulation-canvas-div-net {
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.andes-v2-hspice-simulation-canvas-div-net {
  width: 130px;
  cursor: text;
  text-align: center;
}

.andes-v2-hspice-simulation-canvas-signal-name {
  left: 320px;
  width: 150px;
  text-align: center;
}

.andes-v2-hspice-simulation-model-content {
  margin: 10px;
  overflow: hidden;
  font-size: 14px;
  padding: 0px 10px;
  font-weight: 500;
}

.andes-v2-hspice-simulation-model-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
}

.andes-v2-hspice-simulation-model-row-item {
  flex: 1;
}

.andes-v2-hspice-simulation-model-row>.andes-v2-hspice-simulation-model-row-item:nth-of-type(1) {
  padding-right: 24px;
}

.andes-v2-hspice-simulation-model-row>span,
.andes-v2-hspice-simulation-model-row-item>span,
.andes-v2-hspice-simulation-title {
  display: inline-block;
  width: 100px;
  height: 24px;
  line-height: 24px;
  font-weight: 500;
}

.andes-v2-hspice-simulation-model-select,
.andes-v2-hspice-simulation-model-row-item>span.andes-v2-hspice-simulation-model-select,
.andes-v2-hspice-simulation-model-input,
.andes-v2-hspice-simulation-right-default-width {
  width: calc(100% - 100px);
}

.andes-v2-hspice-simulation-model-row-item>.andes-v2-hspice-simulation-model-subckt-label {
  width: 80px;
}

.andes-v2-hspice-simulation-model-subckt-select {
  width: calc(100% - 80px);
}

.andes-v2-hspice-simulation-node-input {
  width: 100%;
  text-align: center;
}

.andes-v2-hspice-simulation-z0-input {
  width: 80px;
}

.andes-v2-hspice-simulation-component-select {
  margin-left: 10px;
  min-width: 80px;
  max-width: 100px;
}

.andes-v2-hspice-simulation-model-select .ant-select-selection--single,
.andes-v2-hspice-simulation-model-select .ant-select-selection__rendered,
.andes-v2-hspice-simulation-model-select,
.andes-v2-hspice-simulation-model-input,
.andes-v2-hspice-simulation-component-select,
.andes-v2-hspice-simulation-component-select .ant-select-selection--single,
.andes-v2-hspice-simulation-component-select .ant-select-selection__rendered,
.andes-v2-hspice-simulation-rx-model-node-select,
.andes-v2-hspice-simulation-node-input,
.andes-v2-hspice-simulation-z0-input>.ant-input {
  height: 24px;
  line-height: 22px;
  /* font-weight: 500; */
  font-size: 14px;
}

.andes-v2-hspice-simulation-icon {
  color: rgba(0, 0, 0, 0.55);
  font-size: 13px;
  margin-left: 10px;
  cursor: pointer;
  vertical-align: text-top;
}

.andes-v2-hspice-simulation-rx-add-icon {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  color: #0d87f7;
}

.andes-v2-hspice-simulation-title {
  display: flex;
  align-items: center;
}

.andes-v2-hspice-simulation-title-box {
  margin: 10px 0px;
  display: flex;
}

.andes-v2-hspice-simulation-title-box>div {
  white-space: nowrap;
}

/* Tx */
.andes-v2-hspice-simulation-title-box>div:nth-of-type(1) {
  margin-left: 55px;
  width: 455px;
}

/* Rx */
.andes-v2-hspice-simulation-title-box>div:nth-of-type(2) {
  width: 190px;
}

.andes-v2-hspice-simulation-title-box>div:nth-of-type(2)>.andes-v2-hspice-simulation-add-icon {
  margin-left: 85px;
}

/* RxCpad */
/* .andes-v2-hspice-simulation-title-box>div:nth-of-type(3) {
  margin-left: 50px;
} */

/* termination */
.andes-v2-hspice-simulation-title-box>div:nth-of-type(4) {
  margin-left: 20px;
}

/* ctle */
.andes-v2-hspice-simulation-title-box>div:nth-of-type(5) {
  margin-left: 100px;
}

.andes-v2-hspice-simulation-title-box>div:nth-of-type(3)>.andes-v2-hspice-simulation-add-icon {
  margin-left: 40px;
}

.andes-v2-hspice-simulation-title:hover .andes-v2-hspice-simulation-icon,
.andes-v2-hspice-simulation-model-row:hover .andes-v2-hspice-simulation-icon {
  color: #0d87f7;
}

/* Tx model canvas position */
.andes-v2-hspice-simulation-tx-model-canvas-box {
  position: relative;
  margin-top: 20px;
}

.andes-v2-hspice-simulation-tx-model-canvas-box>.andes-v2-hspice-simulation-tx-model-node,
.andes-v2-hspice-simulation-rxCapd-model-canvas-box>.andes-v2-hspice-simulation-rxCapd-model-node,
.andes-v2-hspice-simulation-tx-model-canvas-box>span {
  position: absolute;
}

.andes-v2-hspice-simulation-tx-model-canvas-box>.andes-v2-hspice-simulation-tx-model-node {
  left: 60px;
}

.andes-v2-hspice-simulation-tx-model-canvas-box>.andes-v2-hspice-simulation-tx-model-node:nth-child(1) {
  top: 23px;
}

.andes-v2-hspice-simulation-tx-model-canvas-box>.andes-v2-hspice-simulation-tx-model-node:nth-child(2) {
  top: 78px;
}

.andes-v2-hspice-simulation-tx-model-canvas-box>.andes-v2-hspice-simulation-tx-model-node:nth-child(3) {
  top: 134px;
}

.andes-v2-hspice-simulation-tx-model-canvas-box>span {
  left: 50%;
  transform: translate(-50%, 0);
}

.andes-v2-hspice-simulation-tx-model-canvas-box>span:nth-of-type(1) {
  top: 12px;
}

.andes-v2-hspice-simulation-tx-model-canvas-box>span:nth-of-type(2) {
  top: 68px;
}

.andes-v2-hspice-simulation-tx-model-canvas-box>span:nth-of-type(3) {
  top: 124px;
}

.andes-v2-hspice-simulation-tx-model-canvas-box>.andes-v2-hspice-simulation-tx-model-signal-name {
  left: 490px;
  top: 78px;
  width: 140px;
  text-align: center;
}

/* Rx Capd model canvas position */
.andes-v2-hspice-simulation-rxCapd-model-canvas-box {
  position: relative;
}

.andes-v2-hspice-simulation-rxCapd-model-canvas-box>.andes-v2-hspice-simulation-rxCapd-model-node {
  left: 370px;
}

.andes-v2-hspice-simulation-rxCapd-model-canvas-box>.andes-v2-hspice-simulation-rxCapd-model-node:nth-child(1) {
  top: 40px;
}

.andes-v2-hspice-simulation-rxCapd-model-canvas-box>.andes-v2-hspice-simulation-rxCapd-model-node:nth-child(2) {
  top: 120px;
}

.andes-v2-hspice-simulation-rxCapd-model-canvas-box>.andes-v2-hspice-simulation-rxCapd-model-node:nth-child(3) {
  top: 200px;
}

.andes-v2-hspice-simulation-rxCapd-model-canvas-box>.andes-v2-hspice-simulation-rxCapd-model-signal-name {
  position: absolute;
  left: 0px;
  top: 94px;
  width: 142px;
  text-align: center;
}

.andes-v2-hspice-simulation-rx-model {
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
}

/* ctle model */
.andes-v2-hspice-simulation-ctle-model {
  position: relative;
  background-color: #fff;
  border-radius: 2px;
  /* padding: 10px; */
  margin: auto;
  margin-top: 10px;
  width: 200px;
  height: 240px;
  border: 2px solid black;
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;
}

.andes-v2-hspice-simulation-ctle-model>span {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.andes-v2-hspice-simulation-ctle-model-item>hr {
  position: absolute;
  width: 300px;
  z-index: -1;
  left: -50px;
  height: 5px;
  background: #0e63b8;
  transform: scaleY(0.2);
}

.andes-v2-hspice-simulation-rx-model-item,
.andes-v2-hspice-simulation-rx-model-title-item,
.andes-v2-hspice-simulation-ctle-model-item {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.andes-v2-hspice-simulation-rx-model-title-item {
  margin-bottom: 10px;
}

/* .andes-v2-hspice-simulation-ctle-model>.andes-v2-hspice-simulation-ctle-model-item:nth-of-type(2), */
.andes-v2-hspice-simulation-rx-model>.andes-v2-hspice-simulation-rx-model-item:nth-of-type(3) {
  padding: 10px 0px;
}

.andes-v2-hspice-simulation-rx-model-item>hr,
.andes-v2-hspice-simulation-rx-model-title-item>hr {
  flex: 1;
  margin: auto 17px;
}

.andes-v2-hspice-simulation-rx-model-item>.andes-v2-hspice-simulation-rx-model-pin,
.andes-v2-hspice-simulation-rx-model-title-item>.andes-v2-hspice-simulation-rx-model-pin {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #fff;
  padding: 0px 12px;
}

.andes-v2-hspice-simulation-rx-model-title-item>span,
.andes-v2-hspice-simulation-rx-model-title-item>.andes-v2-hspice-simulation-rx-model-node-select,
.andes-v2-hspice-simulation-rx-model-item>span {
  display: inline-block;
  width: 100px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.andes-v2-hspice-simulation-rx-model-node-select {
  width: 100px;
}

.position-relative {
  position: relative;
}

.andes-v2-hspice-simulation-flex {
  display: flex;
  padding: 5px 0px;
}

/* node popover */
.andes-v2-hspice-simulation-node-box {
  width: 60px;
  text-align: center;
  cursor: pointer;
  color: #232323;
  background-color: #fff;
}

.andes-v2-hspice-simulation-rxCpad-node-box {
  background-color: transparent;
}

.andes-v2-hspice-simulation-node-popover-content {
  max-width: 300px;
  min-width: 200px;
  padding: 5px;
}

.andes-v2-hspice-simulation-node-select {
  width: 100%;
}

.andes-v2-hspice-simulation-node-span {
  display: inline-block;
  width: 100%;
}

.andes-v2-hspice-simulation-node-icon {
  position: relative;
  top: -5px;
  color: rgba(0, 0, 0, .5);
  font-size: 12px;
  margin-left: 5px;
  display: none;
}

.andes-v2-hspice-simulation-node-box:hover .andes-v2-hspice-simulation-node-span {
  width: 40px;
}

.andes-v2-hspice-simulation-node-box:hover .andes-v2-hspice-simulation-node-icon {
  display: inline-block;
}

/* Rx Model*/
.andes-v2-hspice-simulation-model-name-select-list {
  padding: 6px;
  overflow: auto;
  background-color: #eee;
  border-radius: 6px;
}

.andes-v2-hspice-simulation-model-name-select-list>div {
  display: block;
  width: 100%;
  height: 24px;
  line-height: 24px;
  padding: 0 6px;
  border-radius: 3px;
  white-space: nowrap;
  cursor: pointer;
}

.andes-v2-hspice-simulation-model-name-select-list>.andes-v2-hspice-simulation-model-name-selected {
  background-color: #b9ecff;
}

.andes-v2-hspice-simulation-rx-tooltip {
  display: inline-block;
  margin: 10px 0px;
  font-style: italic;
  font-weight: 400;
}

.andes-v2-hspice-no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-popover-hidden.spice-node-select-Popover {
  display: none;
}

.andes-v2-hspice-simulation-setting-button {
  display: flex;
  justify-content: center;
}

.andes-v2-hspice-simulation-setting-button>button {
  font-size: 12px;
  color: #1890ff;
  background-color: #fff;
  border-color: #1890ff;
  height: 24px;
  line-height: 23px;
  padding: 0px 12px;
}

.andes-v2-hspice-simulation-model-select-dropdown ul.ant-select-dropdown-menu-item-group-list {
  padding-left: 10px;
}

.andes-v2-hspice-simulation-file-not-exist {
  color: red;
}

.andes-v2-hspice-simulation-file-not-exist-icon {
  position: absolute;
  right: 5px;
  top: 6px;
  color: red;
}

.rx-model-ibis-table .ant-table-small>.ant-table-content>.ant-table-body>table>.ant-table-tbody>tr>td {
  width: 50%;
}

.rx-model-ibis-table .ant-table-small>.ant-table-content>.ant-table-body>table>.ant-table-tbody>tr>td:last-child {
  padding: 4px 8px;
}

.rx-model-ibis-table .ant-table>.ant-table-content .ant-table-body .ant-table-row td {
  position: relative;
  font-size: 14px;
}

.rx-model-ibis-table .ant-table-body table {
  table-layout: fixed;
}

.andes-v2-hspice-simulation-canvas-click-box {
  position: absolute;
  border-radius: 2px;
  cursor: pointer;
}

.andes-v2-hspice-simulation-canvas-tx-box {
  width: 160px;
  height: 200px;
}

.andes-v2-hspice-simulation-canvas-rx-box {
  width: 160px;
  height: 285px;
}

.andes-v2-hspice-simulation-canvas-rxCpad-box {
  width: 55px;
  height: 285px;
}

.andes-v2-hspice-simulation-canvas-ctle-box {
  width: 200px;
  height: 240px;
  border: 2px solid #404040;
}

.andes-v2-hspice-simulation-canvas-click-box:hover {
  border: 2px solid #404040;
}

.flex-box {
  display: flex;
}

.andes-v2-hspice-simulation-auto-match>span {
  cursor: pointer;
}

.andes-v2-hspice-simulation-auto-match {
  display: flex;
  justify-content: center;
  color: #005a9e;
}

.andes-v2-hspice-simulation-model-radio-group {
  display: flex;
  flex-wrap: wrap;
  column-gap: 50px;
}

.andes-v2-hspice-simulation-model-radio-group>.ant-radio-wrapper {
  position: relative;
  flex: 0 1 calc(33.33% - 50px);
  height: 90px;
}

.andes-v2-hspice-simulation-model-radio-group>.ant-radio-wrapper.radio-text {
  height: auto;
}

.position-absolute-left-10-top-0 {
  position: absolute;
  top: 0px;
  left: 10px
}

.andes-v2-hspice-simulation-vLine {
  width: 0px;
  height: 84px;
  border-left: 1px solid black;
}

.andes-v2-hspice-simulation-vLine-right,
.andes-v2-hspice-simulation-vLine-left {
  width: 10px;
  word-wrap: break-word;
  white-space: normal;
  margin: 0 15px;
}

.andes-v2-hspice-simulation-setting-model-content {
  margin: 10px;
  overflow: hidden;
  font-size: 14px;
  padding: 0px 10px;
}

.andes-v2-hspice-simulation-setting-model-content label {
  font-weight: 500;
}

.andes-v2-hspice-simulation-model-row>.andes-v2-hspice-simulation-icon {
  width: 6px;
}