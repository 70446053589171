.sierra-warning-title {
  display: block;
  margin-top: 10px;
  margin-left: 10px;
  color: #ff4848;
  font-weight: bold;
}

.error-font-bold {
  font-weight: bold;
}

.sierra-warning-dialog {
  padding: 10px 20px;
  color: #ff4848;
  overflow: unset;
}