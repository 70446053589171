.specify-service-content {
  position: absolute;
  top: 44px;
  left: 102px;
  right: 14px;
  bottom: 14px;
  padding: 10px 20px;
  background: #ffffff;
  overflow: auto;
}

.specify-service-main {
  width: 100%;
  max-width: 1000px;
  min-width: 350px;
}

.specify-service-title {
  width: 100%;
  text-align: center;
  line-height: 32px;
  font-weight: 600;
}

.specify-service-project-title {
  height: 44px;
  width: 100%;
}

.specify-service-project-title .span-msg-icon-div {
  padding-left: 12px;
  margin-left: 4px;
  width: 100%;
  margin-top: 12px;
}

.specify-service-project-title .span-msg-icon-div .span-msg-icon {
  font-size: 18px;
  color: #1890ff;
}

.specify-service-content .specify-service-item {
  width: 100%;
  margin: 5px 0px;
}

.specify-service-content .specify-service-item .aurora-select {
  width: calc(100% - 92px);
  vertical-align: middle;
}

.specify-service-content .specify-service-item .aurora-select,
.specify-service-content .specify-service-item .aurora-select .ant-select-selector {
  height: auto;
}

.specify-service-content .specify-service-item>span {
  display: inline-block;
  width: 92px;
  vertical-align: middle;
}

.specify-service-content .specify-service-button {
  width: 120px;
  height: 28px;
  line-height: 27px;
  padding: 0 8px;
}

.specify-service-content .specify-service-button-content {
  margin-top: 10px;
  height: 28px;
  text-align: center;
}

.specify-service-content .specify-service-task-content {
  position: relative;
}

.specify-service-content .ant-divider {
  margin: 10px 0px;
}

.specify-service-content .specify-service-task-content:hover .specify-service-delete-task-icon {
  color: #3da4fa;
}

.specify-service-content .specify-service-add-task-icon {
  margin-left: 8px;
  color: #3da4fa;
  font-size: 14px;
}

.specify-service-content .specify-service-delete-task-icon {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.45);
  position: absolute;
  right: -15px;
  top: 8px;
}

.specify-service-content .specify-service-message-success-content {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}

.specify-service-content .specify-service-message-failed-content {
  border: 1px solid #ffa39e;
  background-color: #fff1f0;
}

.specify-service-content .specify-service-message-content {
  border-radius: 4px;
  padding: 4px 8px;
}

.specify-service-content .specify-service-task-content .specify-service-item>.server-title {
  width: 100%;
  font-weight: 600;
}

.specify-service-content .specify-service-task-content .specify-service-sub-item {
  padding-left: 20px;
}