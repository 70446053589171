body {
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.canvas {
  width: 100%;
  /*  height: 100%; */
  height: 98%;
  background-color: #000000;
  padding-bottom: 28px;
  stroke-linecap: round;
  stroke-linejoin: round;
  transform: translateZ(0);
  will-change: transform;
}

.lay_geom:hover {
  stroke: red;
}

svg {
  stroke-linecap: round;
  stroke-linejoin: round;
}

polygon:hover,
cicle:hover,
path:hover,
polyline:hover {
  stroke: red;
}

[fill="#333333"] .pad,
[fill="#333333"] .barrel {
  fill: #333333;
}

.pad {
  fill: #e1e1e1;
  opacity: 0.7;
}

.barrel {
  fill: #555555;
}

.selected-via .pad{
  fill: #bfad6f;
}

.selected-via .barrel{
  fill: #f2c056;
}

.antipad {
  fill: #000;
}

[layer="selection"] .antipad {
  opacity: 0;
}

.layer-tab {
  height: 28px;
  position: absolute;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  line-height: 28px;
}

.layer-tab .ant-tabs-nav-container {
  line-height: 28px;
}

.layer-tab .ant-select>.ant-select-selector {
  background-color: #1677ff;
  color: #fff;
}

.aurora-content .layer-tab {
  background-color: #1e1e1c;
  border-top: 1px solid #a0a0a0;
}

.aurora-content .layer-tab .ant-select>.ant-select-selector {
  border: none;
}

.aurora-content .layer-tab .ant-select>.ant-select-selector {
  line-height: 24px;
}

.serdes-select-version .ant-select-arrow svg {
  color: #fff;
}

.canvas-coord {
  position: absolute;
  left: 5px;
  bottom: 40px;
  color: #fff;
  float: right;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-group-vertical {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 0;
  left: 0;
}

.layBtn {
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 1px 0;
  width: 100%;
  margin: 10px 5px;
  display: block;
  position: relative;
  font-size: 24px;
  color: #aaa;
}

.layBtn:hover {
  background-color: #dddddd;
  color: #333;
  cursor: pointer;
}

.layBtn .pintopin-bars {
  font-size: 28px;
}

.global-search {
  position: absolute;
  top: 5px;
  right: 5px;
  overflow: hidden;
}

.location-mark {
  display: none;
  position: absolute;
  height: 24px;
  width: 24px;
  fill: red;
  left: 50%;
  top: 50%;
  margin-top: -36px;
  margin-left: -12px;
  pointer-events: none;
}

.panel {
  position: absolute !important;
  top: 50px;
  width: 900px;
  left: 50%;
  margin-left: -450px;
}

.panel .panel-body {
  height: 450px;
  overflow-y: auto;
}

.result-panel .panel-body {
  height: 450px;
}

.layer-button {
  display: inline-block;
}

.coloredby .ant-tooltip-inner {
  color: #1890ff;
  background-color: #fff;
}

.coloredby .ant-tooltip-arrow::before {
  background-color: #fff;
}

.verify-content>.ant-spin-nested-loading,
.verify-content>.ant-spin-nested-loading>.ant-spin-container {
  width: 100%;
  height: 100%;
}

.change-opacity {
  opacity: 0.5 !important;
}

.aurora-content .ant-tabs-tab-prev-icon,
.aurora-content .ant-tabs-tab-next-icon {
  color: #fff;
}

.aurora-content .layer-tab {
  height: 30px;
}

.pcb-explorer {
  height: 100%;
}

.pcb-explorer .ant-spin-nested-loading {
  height: 100%;
}

.pcb-explorer .ant-spin-nested-loading .ant-spin-container {
  height: 100%;
}

.pcb-explorer-layer-tab-col {
  overflow: hidden;
  padding: 0 2px
}

.pcb-explorer-layer-tab-content {
  display: flex;
  justify-content: right;
}

.layer-tab .pcb-explorer-layer-tab-col .ant-tabs-nav-container {
  float: right;
}