.monitor-error-msg {
  color: red;
}

.monitor-warning-msg {
  color: orange;
}

.monitor-error-main {
  margin-top: 10px;
}

.monitor-error-title {
  font-weight: bold;
  color: red;
}

.monitor-error-sub-content {
  margin-left: 12px;
}

.cascade-simulation-title .aurora-simulation-holygrail {
  display: flex;
}

.cascade-message-title {
  padding-left: 10px;
}

.cascade-monitor-title-box {
  display: flex;
  padding-right: 10px;
}

.cascade-simulation-title .span-msg-icon-div {
  margin-right: 10px;
  margin-top: 13px;
}

.cascade-simulation-title .aurora-simulation-progress {
  line-height: 42px
}

.cascade-simulation-title .aurora-simulation-span {
  line-height: 42px;
}

.cascade-monitor-title-box .aurora-simulation-span .aurora-span-msg {
  white-space: pre;
}