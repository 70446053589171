.tree-node .ant-btn-disabled,
.tree-node .ant-btn.disabled,
.tree-node .ant-btn[disabled],
.tree-node .ant-btn-disabled:hover,
.tree-node .ant-btn.disabled:hover,
.tree-node .ant-btn[disabled]:hover,
.tree-node .ant-btn-disabled:focus,
.tree-node .ant-btn.disabled:focus,
.tree-node .ant-btn[disabled]:focus,
.tree-node .ant-btn-disabled:active,
.tree-node .ant-btn.disabled:active,
.tree-node .ant-btn[disabled]:active,
.tree-node .ant-btn-disabled.active,
.tree-node .ant-btn.disabled.active,
.tree-node .ant-btn[disabled].active {
  color: #a7a7a7;
  border-color: #a9a9a9;
  cursor: not-allowed;
  background-color: #fff;
}

.aurora-tooltip .ant-tooltip-arrow .ant-tooltip-arrow-content {
  --antd-arrow-background-color: #fff;
}

.aurora-tooltip .ant-tooltip-inner {
  color: #1890ff;
  background-color: #fff;
  padding: 5px;
}

.aurora-tooltip .ant-tooltip-arrow::before {
  background-color: #fff;
}

.aurora-tooltip,
.aurora-warning-tooltip,
.aurora-warning-tooltip {
  z-index: 10000000;
  max-width: 400px;
}

.aurora-warning-tooltip .ant-tooltip-inner {
  color: #fa8c16;
  background-color: #fff;
  padding: 5px;
}

.aurora-error-tooltip .ant-tooltip-inner {
  color: #ff0000;
  background-color: #fff;
  padding: 5px;
}

.aurora-tree-add-icon,
.aurora-tree-edit-icon,
.aurora-tree-del-icon,
.aurora-tree-download-icon,
.aurora-tree-rename-icon,
.aurora-tree-copy-icon,
.aurora-tree-project-rename-icon,
.aurora-tree-set-icon,
.aurora-tree-simulate-icon,
.aurora-tree-more-icon,
.aurora-tree-info-icon,
.aurora-tree-menu-icon,
.aurora-tree-sweep-rename-icon,
.aurora-tree-pcb-setting-icon,
.aurora-tree-batch-delete-icon,
.aurora-tree-combine-interface-icon,
.aurora-tree-upload-icon,
.aurora-tree-refresh-icon,
.aurora-tree-project-save-icon,
.aurora-tree-project-edit-icon,
.aurora-tree-multi-interface-setup-icon,
.aurora-tree-file-text-icon,
.aurora-tree-layout-check-icon,
.aurora-tree-layout-check-loading-icon {
  text-align: center;
  line-height: 24px;
  font-size: 15px;
  color: #1890ff;
  display: inline-block;
  padding-top: 2px;
}

.aurora-tree-more-icon {
  line-height: 26px;
}

.copy-loading-icon-visible {
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  color: #1890ff;
  display: inline-block;
  visibility: visible !important;
  right: 12px !important;
  padding-top: 2px;
}

.cascade-sider-menu-tree .ant-tree-title:hover .copy-loading-icon-visible {
  margin-right: 24px !important;
}

.aurora-menu-item-icon {
  float: none;
  line-height: 22px;
}

.aurora-tree-refresh-icon {
  margin-right: 10px;
  font-size: 14px;
}

.aurora-tree-warning-icon {
  text-align: center;
  line-height: 36px;
  font-size: 15px;
  color: rgb(255, 0, 0);
  float: right;
}

.aurora-tree-simulate-icon {
  position: absolute;
  right: 14px;
}

.aurora-tree-edit-icon {
  margin-right: 8px;
}

.aurora-tree-copy-icon {
  margin-right: 24px;
  font-size: 14px;
}

.aurora-tree-project-rename-icon,
.aurora-tree-project-edit-icon {
  margin-right: 48px;
  font-size: 14px;
  cursor: pointer;
}

.aurora-tree-sweep-rename-icon {
  margin-right: 22px;
  font-size: 14px;
  cursor: pointer;
}

.aurora-tree-rename-icon {
  margin-right: 24px;
  font-size: 14px;
  cursor: pointer;
}

.aurora-tree-create-icon {
  display: block;
  float: right;
  text-align: center;
  line-height: 24px;
  height: 22px;
  color: #1890ff;
}

.aurora-status-span {
  font-size: 15px;
  font-weight: 700;
  width: 16px;
  text-align: center;
  position: absolute;
  right: 14px;
}

.aurora-status-icon {
  font-size: 14px;
  font-weight: 500;
  float: right;
  width: 16px;
  text-align: center;
  line-height: 24px;
  cursor: pointer;
}

.aurora-replace-icon {
  font-size: 13px;
  font-weight: 500;
  float: right;
  margin-right: -7px;
  width: 16px;
  text-align: center;
  line-height: 28px;
  color: #1890ff;
  cursor: pointer;
}

.icon-import-export {
  font-size: 18px;
  font-weight: 500;
  float: right;
  margin-right: -7px;
  width: 16px;
  text-align: center;
  line-height: 28px;
  cursor: pointer;
}

.tree-node .ant-tree-title .aurora-tree-loading-icon {
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  right: 14px;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  margin-top: 4px;
}

.icon-loading3:before {
  display: inline-block;
  animation: loading 1s linear infinite;
}

@keyframes loading {
  from {
    transform: rotate(0deg) translateZ(0);
  }

  to {
    transform: rotate(360deg) translateZ(0);
  }
}

.aurora-status-waiting {
  font-size: 13px;
}

.aurora-error-msg-tooltip .ant-tooltip-inner {
  color: #cb2431;
  background-color: #ffdce0;
  padding: 5px;
}

.aurora-error-msg-tooltip .ant-tooltip-arrow::before {
  background-color: #ffdce0;
}

.aurora-error-msg-tooltip {
  z-index: 3000;
  max-width: 320px;
}

.aurora-error-color {
  color: #cb2431;
}

/* .aurora-error-tooltip {
  width: auto;
} */

/* simulation button */

.simulation-btn {
  line-height: 18px;
  width: 68px;
  height: 20px;
  padding: 0 6px;
  margin-top: 6px;
  border: 1px solid #1890ff;
  color: #1890ff;
  position: absolute;
  right: 14px;
}

.simulation-btn-disable {
  position: absolute;
  right: 14px;
}

.simulation-btn-disable {
  line-height: 18px;
  width: 68px;
  height: 20px;
  padding: 0 6px;
  margin-top: 6px;
  border: 1px solid #1890ff;
  color: #1890ff;
}

.aurora-content {
  background-color: #ffffff;
  position: absolute;
  top: 0px;
  left: 240px;
  right: 0px;
  bottom: 0px;
}

#aurora-switch-icon-selected {
  color: #0d87f7;
}

.aurora-layout-button-1 {
  display: inline-grid;
  width: 20px;
  height: 18px;
  border: 1px #a5a5a5 solid;
  margin-right: 8px;
  grid-template-columns: 50% 50%;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 2px;
  margin-left: 7px;
  margin-top: 5px;
}

.aurora-layout-button-left {
  background: #fff;
  text-align: center;
  line-height: 36px;
  font-size: 10px;
}

.aurora-layout-button-right {
  background: #b5b5b5;
  text-align: center;
  line-height: 36px;
  font-size: 10px;
}

.aurora-layout-button-2 {
  display: inline-grid;
  width: 20px;
  height: 18px;
  border: 1px #a5a5a5 solid;
  grid-template-rows: 50% 50%;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 8px;
  border-radius: 2px;
  margin-left: 7px;
  margin-top: 5px;
}

.aurora-layout-button-top {
  background: #b5b5b5;
  text-align: center;
  line-height: 18px;
  font-size: 10px;
}

.aurora-layout-button-bottom {
  background: #fff;
  text-align: center;
  line-height: 18px;
  font-size: 10px;
}

.aurora-layout-selected {
  border-color: #63aef3;
  border-style: solid;
}

.aurora-layout-selected .aurora-layout-button-right,
.aurora-layout-selected .aurora-layout-button-top {
  background: #85bcff;
}

.aurora-layout-selected-3 {
  border-color: #63aef3;
  border-style: solid;
  background: #c0def9;
}

.layout-icon-disabled .aurora-layout-button-right,
.layout-icon-disabled .aurora-layout-button-top {
  background: #c5c5c5;
}

.sider-switch-icon {
  display: inline-block;
  vertical-align: middle;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 6px;
  color: #737373;
}

.sider-switch-vertical-icon {
  padding: 0 17px;
  padding-bottom: 4px;
}

.sider-switch-vertical-icon .pcb-icon {
  margin-left: -3px;
}

.sider-layout-div {
  display: inline-block;
}

.sider-layout-vertical-div {
  padding: 0 10px;
  padding-bottom: 4px;
}

.sider-layout-vertical-div .aurora-layout-button-1 {
  margin-bottom: 9px;
  margin-top: 3px;
}

.sider-layout-vertical-div .aurora-layout-button-2 {
  margin-bottom: 4px;
}

.aurora-icon-color:hover {
  color: #1890ff;
}

.aurora-icon-hover-color {
  color: #1890ff;
}

.aurora-sider-menu-tree .ant-tree-title .anticon {
  position: absolute;
  right: 14px;
  top: 0px;
  visibility: hidden;
}

.aurora-sider-menu-tree .ant-tree-title:hover .anticon {
  visibility: visible;
}

.aurora-sider-menu-tree .ant-tree-title .aurora-tree-checked-box-span {
  margin-left: 20px;
}

.aurora-sider-menu-tree .aurora-project-failed .ant-tree-title .aurora-tree-checked-box-span {
  margin-left: 0px;
}

.simulate-icon-disabled {
  color: #a0a0a0;
  cursor: not-allowed;
}

.aurora-copy-popover {
  width: 300px;
}

.aurora-copy-popover .ant-popover-inner-content {
  padding: 8px;
}

.aurora-sider-menu-tree .download-progress-bar {
  height: 36px;
  width: 100%;
  float: left;
  overflow: visible;
  position: relative;
}

.aurora-sider-menu-tree .ant-tree-title .download-progress-bar .anticon {
  right: 9px;
}

.aurora-sider-menu-tree .tree-library-file .ant-tree-title .download-progress-bar .tree-library-content {
  padding-right: 40px;
}

.aurora-sider-menu-tree .tree-library-file .ant-tree-title:hover .download-progress-bar .tree-library-content {
  padding-right: 20px;
}

.aurora-sider-menu-tree .download-progress-bar::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #2486ff;
  text-align: right;
  white-space: nowrap;
  opacity: 0.4;
  overflow: hidden;
  color: #fff;
  counter-reset: progress var(--percent);
  content: "";
  transition: 0.6s;
  width: calc(1%*var(--percent));
}

.aurora-sider-menu-tree .tree-library-file .download-progress-bar-success::before {
  content: counter(progress) '%\2002';
}

.aurora-sider-menu-tree .ant-tree-title:hover .aurora-interface-type-tag,
.aurora-sider-menu-tree .ant-tree-title:hover .aurora-library-type-tag {
  display: none;
}

.aurora-interface-type-tag {
  position: absolute;
  right: 30px;
  line-height: 16px;
  height: 18px;
  font-size: 10px;
  z-index: 20;
  margin-top: 2px;
  text-align: center;
}

.aurora-library-type-tag {
  position: absolute;
  right: 5px;
  line-height: 16px;
  height: 18px;
  font-size: 10px;
  z-index: 20;
  margin-top: 6px;
  text-align: center;
}

.aurora-tag-gold {
  color: #03a341;
  background: #e8fff1;
  border-color: #009f3d;
}

/* .aurora-tag-gold {
  color: #da9100;
  background: #fff9d6;
  border-color: #da9100;
} */

.aurora-tag-green {
  color: #2f8804;
  background: #f2fde6;
  border-color: #79ab52;
}

.aurora-tag-cyan {
  color: #07a9a9;
  background: #e6fffb;
  border-color: #6ecec4;
}

.aurora-tag-brown {
  color: #9f6804;
  background-color: #fdf7e7 !important;
  border-color: #c28311;
}

.aurora-tag-purple {
  color: #9d00ff;
  /* background-color: #d380ff29 !important; */
  border-color: #7923d570;
}

.aurora-tree-verification-span {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.aurora-sider-menu-tree .ant-tree-title .aurora-icon-disabled {
  color: #a0a0a0;
  cursor: not-allowed;
}

.tree-node .ant-tree-title>span.aurora-add-interface-action {
  color: #ff610b;
  position: relative;
  cursor: pointer;
  line-height: 24px;
  height: 24px;
  border: 1px solid #ff610b;
  text-align: center;
  border-radius: 4px;
}

.tree-node .ant-tree-title>.aurora-add-interface-action:hover {
  color: #f87e3b;
  border: 1px solid #f87e3b;
}

.aurora-sider-menu-tree .aurora-add-interface-action>.aurora-add-interface-icon {
  position: static;
  visibility: visible;
  font-size: 11px;
  color: #ff610b;
  vertical-align: top;
  height: 22px;
  line-height: 22px;
}

.aurora-sider-menu-tree .aurora-add-interface-action>.aurora-tree-verification-span {
  width: auto;
  font-size: 12px;
  line-height: normal;
  max-width: calc(100% - 20px);
  height: 24px;
  line-height: 22px;
}

.ant-tree .aurora-tree-add-action .ant-tree-node-content-wrapper:hover {
  background-color: #f9f9f9;
}

.aurora-sider-menu-tree .aurora-tree-add-action .ant-tree-node-content-wrapper {
  margin-left: -11px;
}

.aurora-default-tree-title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.aurora-default-tree-title:hover {
  width: 80%;
}

span.anticon.anticon-exclamation-circle.debug-design-icon {
  visibility: initial;
  font-size: 15px;
  line-height: 28px;
  color: #ff0000;
  display: inline-block;
}

span.anticon.anticon-exclamation-circle.identify-failed-design-icon {
  color: #fa8c16;
  visibility: visible;
  font-size: 15px;
  line-height: 36px;
  display: inline-block;
}

span.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal:hover .debug-design-icon,
span.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal:hover .identify-failed-design-icon {
  right: 40px;
}

span.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal:hover .rocky-card .debug-design-icon,
span.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal:hover .rocky-card .identify-failed-design-icon {
  right: 64px;
}