.andes-v2-table .editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.andes-v2-content-setting {
  padding: 20px;
  background: #ffffff;
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  bottom: 0;
}

.andes-v2-prelayout-setting {
  overflow: auto;
}

.andes-v2-content-setting .andes-v2-content-setting-box {
  min-width: 1350px;
}

.andes-v2-content-left {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.andes-v2-content-bottom {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
}

.andes-v2-select-dropdown-menu {
  z-index: 10000000000;
}

.andes-customized-title .design-name {
  color: rgba(0, 0, 0, 0.85);
  display: inline-block;
}

.andes-customized-title .component-pin-name {
  color: #3b70a7;
  display: inline-block;
}

.andes-customized-title .signal-name {
  color: #2f7b35;
}

.andes-customized-title .port-mode {
  color: rgba(0, 0, 0, 0.65);
}

.andes-v2-result-content .andes-result-mode-tab .ant-tabs-card-bar .ant-tabs-tab-active {
  color: #005a9e;
  background: #c7e0f4;
  border-radius: 0px;
  border-color: #c7e0f4;
  height: 30px;
}

.andes-v2-result-content .andes-result-mode-tab .ant-tabs-card-bar .ant-tabs-nav-container {
  height: 30px;
}

.andes-v2-result-content .andes-result-mode-tab .ant-tabs-bar {
  margin: 0px;
}

.andes-v2-result-content .andes-result-mode-tab .ant-tabs-card-bar .ant-tabs-tab {
  line-height: 28px;
  border-radius: 0px;
  height: 30px;
}

.andes-v2-result-content .andes-result-mode-tab .ant-tabs-card-bar .ant-tabs-tab:first-child {
  border-left: 0px;
}

.andes-v2-channel-setup {
  overflow: visible;
}

#andes-v2-content-main.andes-v2-lr-content-main,
#andes-v2-content-main.andes-v2-tb-content-main {
  top: 45px;
}

#andes-v2-content-main.andes-v2-lr-result-main {
  top: 0px;
}

.andes-v2-content-bottom .andes-v2-content-setting {
  top: 45px;
}

.pcb-channel-main {
  top: 0px !important;
}

.andes-v2-setup-normal {
  top: 45px;
}

.andes-v2-result-normal {
  top: 0px;
}

.andes-v2-result-content-channel,
.andes-v2-result-content-waveform {
  overflow: hidden;
}

.andes-v2-content-setting.pcb-channel-main .ant-spin-container>.space-10:last-child {
  margin-bottom: 22px;
  padding-bottom: 20px;
}

.andes-v2-prelayout-setting .pkg-new-panel .package-model-select .package-model-selection {
  margin-left: 0px;
  width: calc(100% - 131px);
}

.andes-v2-result-content-waveform .andes-v2-result-content-waveform-radio {
  padding-left: 16px;
  height: 40px;
  line-height: 40px;
}

.andes-customized-title .driver-or-receiver {
  display: inline-block;
  font-weight: 700;
  width: 80px;
}

.andes-content .aurora-select.ant-select-multiple .ant-select-selector::after,
.andes-content .aurora-select.ant-select-multiple .ant-select-selection-wrap::after {
  line-height: 20px;
}

.andes-content .aurora-select.ant-select-multiple .ant-select-selection-overflow-item::after,
.andes-content .aurora-select.ant-select-multiple .ant-select-selector>.ant-select-selection-wrap .ant-select-selection-overflow-item {
  height: 20px;
}

.andes-content .aurora-select.ant-select-multiple .ant-select-selection-overflow-item-suffix {
  min-height: 20px;
}

.andes-content .aurora-select.ant-select-multiple .ant-select-selector>.ant-select-selection-wrap .ant-select-selection-overflow-item>.ant-select-selection-item {
  box-sizing: border-box;
  height: 18px;
  line-height: 16px;
}