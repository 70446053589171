.cascade-result-IR {
  height: 100%;
  position: 'relative'
}

.IR-Result {
  height: 100%;
}

.IR-Result-Menu {
  width: 100%;
  background-color: #fff;
  position: absolute;
  top: 45px;
  left: 0;
  z-index: 300;
}

.ir-table-content {
  padding: 15px 20px;
  position: absolute;
  top: 90px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.I-select-box {
  display: inline-block;
}

.result-filter-divider {
  margin: 2px 0;
}

.result-filter-checkout-box {
  padding: 5px 12px;
}

.result-filter-box-footer {
  padding: 5px 16px;
}

.ir-table-content th.ant-table-column-has-actions.ant-table-column-has-filters.ant-table-column-has-sorters.ant-table-row-cell-break-word {
  position: relative;
}

.ir-table-content .ant-spin-nested-loading .virtual-table-without-scroll-Y .ant-spin-container{
  padding-bottom: 20px;
}