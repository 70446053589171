.pre-layout-setting {
  padding: 10px 14px;
}

.pre-layout-extraction {
  min-width: 708px;
}

.andes-v2-pre-layout-panel {
  top: 130px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

/* Component table */

.andes-v2-content-setting .pre-layout-component-table .ant-table-thead>tr>th .ant-table-header-column,
.andes-v2-content-setting .pre-layout-signals-table .ant-table-thead>tr>th .ant-table-header-column {
  display: block !important;
}

.andes-v2-content-setting .pre-layout-component-table .ant-table-thead>tr>th:nth-child(2) .ant-table-header-column .ant-table-column-title,
.andes-v2-content-setting .pre-layout-component-table .ant-table-thead>tr>th:nth-child(3) .ant-table-header-column .ant-table-column-title {
  padding-left: 4px
}

.andes-v2-content-setting .pre-layout-component-table .editable-cell-value-wrap,
.andes-v2-content-setting .pre-layout-signals-table .editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.andes-v2-content-setting .pre-layout-component-table {
  background: #ffffff;
}

.pre-layout-select,
.pre-layout-input {
  width: calc(100% - 180px);
}

.pre-layout-span {
  display: inline-block;
  width: 180px;
  height: 28px;
  line-height: 28px;
}

.pre-layout-pcie-select {
  display: flex;
  width: 100%;
  line-height: 34px;
  height: 34px;
}

.pre-layout-spacing .pre-layout-span {
  width: 190px;
}

.pre-layout-spacing,
.pre-layout-unit {
  padding-left: 30px;
}

.pre-layout-unit .pre-layout-span {
  width: 38px;
}

/* interface type */

.pre-layout-serdes-type-content {
  width: calc(100% - 180px);
  display: inline-block;
  position: relative;
  line-height: 28px;
}

#pre-layout-serdes-type-width-input-id {
  height: 28px;
}

.pre-layout-serdes-type-width-input {
  width: 100%;
  font-size: 13px;
  height: 28px;
}

.pre-layout-serdes-type-width-select {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  height: 0px;
  border: none;
  box-shadow: 0px 0px 0px;
}

.pre-layout-serdes-type-width-select .ant-select-selector {
  visibility: hidden;
}

.pre-layout-serdes-type-width-select .ant-select-selection:focus,
.pre-layout-serdes-type-width-select .ant-select-selection:active,
.pre-layout-serdes-type-width-select .ant-select-selection {
  border: none;
  height: 0px;
  box-shadow: 0px 0px 0px;
}

.pre-layout-signal-delete-icon {
  position: absolute;
  right: -5px;
  top: 50%;
  margin-top: -7px;
  color: #ccc;
  display: none;
}

.pre-layout-signals-table .aurora-table-body>tr.ant-table-row-selected td {
  background-color: inherit;
}

.pre-layout-signals-table .pre-layout-section-template {
  display: block;
  min-width: 72px;
}

.pre-layout-signals-table .pre-layout-section-length {
  display: block;
  min-width: 64px;
}

.pre-layout-signals-table .ant-table>.ant-table-content .aurora-table-body .ant-table-row td {
  position: relative;
}

.pre-layout-signals-table .ant-table-thead>tr>th,
.pre-layout-signals-table .aurora-table-body>tr>td {
  text-align: center;
}

.pre-layout-signals-table .ant-table>.ant-table-content .aurora-table-body .ant-table-row td .editable-cell-value-wrap {
  display: grid;
}

.andes-pre-layout-help {
  position: absolute;
  right: 10px;
}

.pre-layout-unit-main {
  position: absolute;
  right: 300px;
  top: 7px;
}

.pre-layout-unit-main .aurora-select {
  width: 80px;
}

.pre-layout-unit-main .pre-layout-span {
  width: 36px;
}

.andes-v2-content-setting .pre-layout-fold {
  cursor: pointer;
  display: inline-block;
  margin-left: 0px;
}

.andes-v2-content-setting .pre-layout-fold-icon {
  margin-top: 0px;
  vertical-align: middle;
}

.andes-v2-content-setting .pre-layout-fold>span {
  vertical-align: middle;
}

.andes-v2-pre-layout-panel .pre-layout-setting .sub-extraction-input .ant-input-disabled {
  color: rgba(0, 0, 0, 0.65);
  background-color: #f8f8f8;
  cursor: not-allowed;
}

.andes-v2-pre-layout-panel .pre-layout-pcie-select .pre-layout-span {
  width: 180px;
}

.andes-v2-pre-layout-panel .pre-layout-pcie-select .pre-layout-select,
.andes-v2-pre-layout-panel .pre-layout-pcie-select .pre-layout-input {
  width: calc(100% - 180px);
}

.pre-layout-lanes-number-select {
  width: 100%;
}

.andes-v2-pre-layout-panel .aurora-select .ant-select-selector>.ant-select-selection-wrap .ant-select-selection-overflow-item {
  height: 24px;
  align-self: flex-start;
}

.andes-v2-pre-layout-panel .aurora-select .ant-select-selector>.ant-select-selection-wrap .ant-select-selection-overflow-item>.ant-select-selection-item {
  box-sizing: border-box;
  height: 20px;
  line-height: 18px;
}