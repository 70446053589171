.andes_v2_result {
  padding-left: 22px;
}

.andes-v2-sider-menu-tree .my-tree .tree-node-pcb-name .ant-tree-node-content-wrapper .ant-tree-title>span>.tree-node-pcb-name {
  width: 100%;
}

.andes-v2-sider-menu-tree .my-tree .tree-node-pcb-name .ant-tree-node-content-wrapper .ant-tree-title>span>.andes-v2-tootip-failed {
  width: calc(100% - 36px);
}

.andes-v2-sider-menu-tree .my-tree .tree-node-pcb-name .ant-tree-node-content-wrapper .ant-tree-title:hover>span>.tree-node-pcb-name {
  width: calc(100% - 54px);
}

.andes-v2-sider-menu-tree .my-tree .tree-node-interface-name .ant-tree-node-content-wrapper .ant-tree-title:hover>span>.tree-node-interface-name,
.andes-v2-sider-menu-tree .my-tree .andes-pcb-channel-node-name .ant-tree-node-content-wrapper .ant-tree-title>span>.andes-pcb-channel-node-name {
  padding-right: 64px;
}

.andes-v2-sider-menu-tree .my-tree .tree-node-pcb-name .ant-tree-node-content-wrapper .ant-tree-title:hover>.aurora-project-create-name {
  padding-right: 0px;
}

.andes-v2-sider-menu-tree .my-tree .andes-pcb-channel-node-name .ant-tree-node-content-wrapper .ant-tree-title:hover>span>.andes-pcb-channel-node-name .aurora-tree-verification-span {
  width: 100% !important;
}

.andes-v2-sider-menu-tree .my-tree .andes-pcb-channel-node-name .ant-tree-node-content-wrapper .ant-tree-title:hover>span>.andes-pcb-channel-node-name {
  padding-right: 114px;
}

.andes-v2-sider-menu-tree .my-tree .tree-node-interface-name .ant-tree-node-content-wrapper .ant-tree-title>span>.tree-node-interface-name {
  padding-right: 58px;
}

.andes-v2-sider-menu-tree .my-tree .ant-tree-node-content-wrapper .ant-tree-title .andes-v2-tooltip-span {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.andes-v2-tootip-failed {
  color: #ff0000;
}

.anticon.anticon-exclamation-circle.design-failed-icon {
  visibility: visible;
  visibility: initial;
  font-size: 15px;
  line-height: 36px;
  color: #ff0000;
}

.andes-v2-sider-menu-tree span.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal:hover .design-failed-icon {
  right: 38px;
}

.andes-v2-sider-menu-tree .ant-tree-title .aurora-tree-simulate-icon {
  visibility: visible;
}

.andes-v2-sider-menu-tree .ant-tree-title:hover .aurora-tree-simulate-icon {
  right: 38px;
}

.andes-v2-sider-menu-tree .ant-tree-title .andes-v2-status-check-icon {
  line-height: 28px;
  display: inline-block;
}

.andes-v2-sider-menu-tree .ant-tree-title .aurora-status-icon {
  visibility: visible;
  position: absolute;
  right: 14px;
  top: 0px;
  font-size: 17px;
}

.andes-v2-sider-menu-tree .ant-tree-title:hover .aurora-status-icon {
  right: 86px;
}

.andes-v2-sider-menu-tree .ant-tree-title:hover .aurora-not-prelayout-status-icon {
  right: 38px;
}

.andes-v2-sider-menu-tree .ant-tree-title:hover .aurora-experiment-status-icon {
  right: 64px;
}

.andes-v2-sider-menu-tree .tree-node-interface-name .ant-tree-title:hover .aurora-status-icon {
  right: 86px;
}

.andes-v2-sider-menu-tree .ant-tree-title .aurora-replace-icon {
  visibility: hidden;
  position: absolute;
  right: -22px;
  margin-right: 0px;
}

.andes-v2-sider-menu-tree .ant-tree-title .aurora-PCB-replace>span {
  visibility: hidden;
  display: inline-block;
  height: 32px;
  width: 16px;
  position: absolute;
  right: 60px;
}

.andes-v2-sider-menu-tree .ant-tree-title:hover .aurora-replace-icon {
  visibility: visible;
}

.andes-v2-sider-menu-tree .ant-tree-title .aurora-project-name-input .aurora-tree-create-icon {
  visibility: visible;
  position: static;
  line-height: 24px;
  height: 22px;
}

.andes-v2-sider-menu-tree .ant-tree-title .tree-node-pcb-add-icon {
  position: absolute;
  right: 38px;
}

.andes-v2-sider-menu-tree .tree-node-project-name .aurora-tree-project-rename-icon {
  margin-right: 48px;
}

.andes-v2-sider-menu-tree .tree-node-pcb-name .aurora-tree-prelayout-rename-icon,
.andes-v2-sider-menu-tree .tree-node-project-name .aurora-tree-sweep-rename-icon {
  margin-right: 24px;
}

.andes-v2-sider-menu-tree .tree-library-file .ant-tree-title:hover .aurora-tooltip-span {
  width: calc(100% - 52px);
}

.andes-v2-sider-menu-tree .tree-library-folder-file .ant-tree-title:hover .aurora-tooltip-span {
  width: 100%;
}

.andes-v2-sider-menu-tree .tree-node-project-name .ant-tree-title:hover>span>.tree-node-project-name {
  padding-right: 70px;
}

.andes-v2-sider-menu-tree .aurora-copy-loading-tree {
  padding-right: 24px;
}

.andes-v2-sider-menu-tree .ant-tree-title:hover .aurora-tree-loading-icon,
.andes-v2-sider-menu-tree .tree-node-pcb-name .ant-tree-title .aurora-tree-loading-icon,
.andes-v2-sider-menu-tree .tree-node-interface-name .ant-tree-title .aurora-tree-loading-icon,
.andes-v2-sider-menu-tree .tree-node-project-name .ant-tree-title:hover .aurora-tree-loading-icon {
  right: 36px;
}

.andes-v2-sider-menu-tree .tree-node-project-name .ant-tree-title .aurora-tree-loading-icon,
.andes-v2-sider-menu-tree .tree-node-interface-name .ant-tree-title .aurora-tree-loading-icon,
.andes-v2-sider-menu-tree .andes-pcb-channel-node-name .ant-tree-title .aurora-tree-loading-icon {
  right: 14px;
}

.andes-v2-sider-menu-tree .ant-tree-title:hover .design-failed-icon {
  right: 38px;
}

.andes-v2-sider-menu-tree .my-tree .tree-node .ant-tree-node-content-wrapper .ant-tree-title .andes-end-to-end-channel-children,
.andes-v2-sider-menu-tree .my-tree .tree-node .ant-tree-node-content-wrapper .ant-tree-title:hover .andes-end-to-end-channel-children {
  margin-left: 0px;
  width: calc(100% - 30px);
}

.andes-v2-sider-menu-tree .my-tree div.andes-end-to-end-channel-children .ant-tree-node-content-wrapper .ant-tree-title:hover>span {
  padding-right: 0px;
}

.andes-v2-sider-menu-tree .my-tree div.andes-end-to-end-channel-children .ant-tree-title:hover .aurora-status-icon {
  right: 14px;
}

.andes-tree-menu .ant-dropdown-menu-item>.anticon:first-child {
  margin-right: 0px;
}

.andes-tree-menu .ant-dropdown-menu-item {
  padding: 5px 10px;
}

.andes-v2-sider-menu-tree .ant-tree-title .aurora-tree-import-export-library-icon,
.andes-v2-sider-menu-tree .ant-tree-title .aurora-tree-import-export-project-icon {
  position: absolute;
  right: 21px;
}

.andes-v2-sider-menu-tree .ant-tree-title:hover .aurora-tree-import-export-project-icon {
  right: 48px;
}

.andes-v2-sider-menu-tree .ant-tree-title .aurora-tree-import-export-loading-icon {
  right: 14px;
  visibility: visible;
  text-align: center;
  line-height: 30px;
  font-size: 15px;
  color: #1890ff;
  float: right;
}

.andes-v2-sider-menu-tree .ant-tree-title .aurora-tree-import-export-library-loading,
.andes-v2-sider-menu-tree .ant-tree-title .aurora-tree-import-export-project-loading {
  position: absolute;
  right: 14px;
  margin-top: calc(5% - 9px);
}

.andes-v2-sider-menu-tree .ant-tree-title:hover .aurora-tree-import-export-project-loading {
  right: 42px;
}

.andes-v2-sider-menu-tree .my-tree div.andes-v2-sweep-li .ant-tree-switcher.ant-tree-switcher-noop {
  padding-left: 25px;
}

.andes-v2-sider-menu-tree .my-tree div.andes-v2-sweep-li .ant-tree-node-content-wrapper {
  margin-left: 20px;
}

.andes-v2-sider-menu-tree .my-tree span.andes-v2-sweep-li .anticon-experiment.my-tree-switch-icon {
  color: rgb(151, 151, 151);
}

.andes-v2-sider-menu-tree .my-tree span.andes-v2-sweep-li.ant-tree-treenode-selected .anticon-experiment.my-tree-switch-icon {
  color: #3c5fa5;
}

.andes-v2-sider-menu-tree .my-tree .my-tree-switch-icon {
  margin-top: -5px;
}

.andes-v2-sider-menu-tree .my-tree .aurora-interface-type-tag {
  height: 19px;
}