.stackup-table .ant-table-footer {
  padding: 8px 0;
}

.stackup-table .ant-table-footer div {
  margin-left: 76px;
  font-weight: bold;
}

.stackup-table .ant-table-container .ant-table-header>table>.ant-table-thead>tr>th {
  background: #fafafa;
}

.stackup-input-group {
  width: 160px;
  float: left;
}

.stackup-input-group .ant-select-selection-selected-value {
  padding-right: 0px;
}

.stackup-thickness-name {
  float: left;
  margin-left: 0px;
  margin-right: 8px;
}

.stackup-input-col,
.stackup-input-row {
  line-height: 32px;
}

.stackup-input-col {
  float: left;
}

.stackup-set-name {
  margin: 0px 8px 0 16px;
}

.stackup-footer-value-setting {
  height: 32px;
  line-height: 32px;
  width: 100%;
  text-align: left;
  margin: 0px;
}

.stackup-set-button {
  margin-left: 8px;
  margin-right: 8px;
}

.stackup-clear {
  zoom: 1
}

.stackup-clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.stackup-dropdown-button .ant-dropdown-menu-item button {
  width: 140px;
  padding-left: 15px;
  text-align: left;
  border: 0px;
}

.stackup-menu-button {
  padding: 0px;
  vertical-align: middle;
}

.stackup-dropdown-button .ant-dropdown-menu-item {
  padding: 0px;
}

.stackup-dropdown-button .ant-dropdown-menu-item button:hover {
  background-color: #e6f7ff
}