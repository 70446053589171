.result-right-menu {
  background-color: #fafafa;
  border-left-color: #e8e8e8;
  border-left-width: 1px;
  border-left-style: solid;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.result-right-menu .result-file-list {
  background-color: #fafafa;
  overflow-x: hidden;
  overflow-y: auto;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 318px;
  margin: 0 5px;
  position: relative;
}

.result-right-menu .result-file-list .ant-checkbox-wrapper+.ant-checkbox-wrapper {
  margin-left: 0px;
}

.result-right-menu .result-file-list .ant-divider-inner-text {
  padding-left: 2px !important;
  padding-right: 30px !important;
}

.result-right-menu .result-file-list .ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 0%;
}

.result-right-menu .result-setting {
  position: absolute;
  left: 0;
  right: 0;
  max-height: 50%;
  bottom: 0;
  z-index: 1000;
}