.tree-select-dropdown {
  padding: 5px;
  overflow: auto;
  max-height: 400px;
}

.tree-select-file-folder,
.tree-select-folder {
  height: 24px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
  padding: 2px 0px;
  cursor: pointer;
}

.tree-select-folder-icon {
  margin-right: 5px;
  color: rgba(0, 0, 0, 0.45);
}

.tree-select-file {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 2px 0px;
  padding-left: 8px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}

.tree-select-file:hover,
.tree-select-file-ul li:hover {
  background: #d5ecff;
}

.tree-select-file-ul {
  margin: 0px;
  padding-left: 0px;
}

.tree-select-file-ul li {
  background-color: #f4f4f4;
  padding-left: 17px;
  line-height: 22px;
  font-size: 13px;
  cursor: pointer;
}

.tree-select-file-folder-icon {
  font-size: 13px;
}

.tree-selected-file {
  background: #b9ecff;
}

.tree-select-file-background {
  background-color: #f4f4f4;
}