.profile-table {
  min-width: 1000px;
}

.aurora-profile-monitor {
  overflow: auto;
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  top: 44px;
  left: 102px;
  right: 14px;
  bottom: 14px;
}