.sweep-config-content {
  padding: 10px 16px;
}

.sweep-config-title {
  font-size: 16px;
  font-weight: 500;
}

.sweep-config-radio {
  display: block;
  height: 30px;
  line-height: 30px;
}

.sweep-config-item {
  width: 100%;
  margin: 5px 0;
  min-height: 28px;
  line-height: 28px;
}

.sweep-config-item>span {
  display: inline-block;
  width: 120px;
  vertical-align: middle;
  /* font-size: 13px; */
}

.sweep-config-item>.aurora-select,
.sweep-config-item>.aurora-input {
  width: calc(100% - 130px);
  vertical-align: middle;
}

.andes-v2-sweep-add-icon {
  vertical-align: middle;
  color: #0d87f7;
  font-size: 16px;
  margin-left: 14px;
  cursor: pointer;
}

.andes-v2-sweep-dropdown-icon {
  vertical-align: middle;
  color: #0d87f7;
  font-size: 12px;
  margin-right: 5px;
  cursor: pointer;
  margin-top: -2px;
}

.andes-v2-sweep-table .ant-table-thead>tr>th,
.andes-v2-sweep-table .aurora-table-body>tr>td {
  text-align: center;
}

/* .andes-v2-sweep-title-box {
  position: relative;
}

.andes-v2-sweep-title-box>.andes-v2-sweep-title-name {
  position: absolute;
} */

.andes-v2-sweep-title-checkbox {
  position: absolute;
  max-height: 200px;
  background-color: #fff;
  top: 90px;
  left: calc(50% - 60px);
  margin-top: 8px;
  border-radius: 0 0 2px 2px;
  z-index: 999;
  overflow: auto;
  box-shadow: 1px 4px 6px rgb(0 0 0 / 31%);
}

.andes-v2-sweep-title-checkbox ul {
  margin: 0;
  padding: 0;
}

.andes-v2-sweep-title-checkbox ul li {
  width: 100%;
  text-align: left;
  padding: 4px 23px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sweep-setting-panel {
  max-height: 600px;
}

.andes-v2-sweeping-column-name-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 16px;
}

.andes-v2-sweeping-column-name {
  line-height: 24px;
  vertical-align: middle;
  position: relative;
}

.andes-v2-sweeping-column-name>span {
  color: #1890ff;
  position: absolute;
  visibility: hidden;
  top: 0px;
  right: 5px;
}

.andes-v2-sweeping-column-name:hover>span {
  visibility: visible;
}

.andes-v2-sweep-variable-setting-icon {
  color: #005a9e;
  cursor: pointer;
  margin-left: 10px;
  font-size: 16px;
  position: relative;
  top: 1px;
}

.andes-v2-sweep-apply-all-checkbox {
  padding: 4px 8px;
}

.andes-v2-sweep-select-dropdown-menu {
  text-align: left;
}

.andes-v2-sweep-select-dropdown-divider {
  margin: 3px 0px;
}

/* .andes-v2-content-setting {
  min-width: 1350px;
} */

.andes-v2-sweep-not-exist-net {
  color: red;
}

.andes-v2-sweep-icon-info-circle {
  position: relative;
  top: 2px;
  width: 24px;
  font-size: 16px;
}

.andes-v2-s-parameter-download-panel .aurora-debug-download-content {
  min-width: 260px;
}

.andes-v2-s-parameter-download-panel .aurora-debug-download-content .aurora-debug-item-content {
  width: 100%;
}

.sweep-config-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.sweep-config-panel .sweep-config-panel-content {
  padding: 8px 14px;
}

.andes-v2-sweep-value-text {
  height: 28px;
  text-align: center;
}

.ant-select.ant-select-sm.sweep-trace-width-select {
  position: absolute;
  left: 0px;
  bottom: 0px;
  right: 0px;
  height: 0px;
  border: none;
  box-shadow: 0px 0px 0px;
}

.ant-select.ant-select-sm.sweep-trace-width-select .ant-select-selector {
  visibility: hidden;
}

.sweep-trace-width-select .ant-select-selection:focus,
.sweep-trace-width-select .ant-select-selection:active,
.sweep-trace-width-select .ant-select-selection {
  border: none;
  height: 0px;
  box-shadow: 0px 0px 0px;
}

.sweep-trace-width-inputGroup {
  display: inline-block;
  white-space: nowrap;
  width: 100%;
}

.sweep-trace-width-inputGroup .ant-input {
  min-width: 50px;
  text-align: center;
}

.andes-v2-sweep-table,
.andes-v2-sweep-table .aurora-table-body {
  overflow-x: hidden !important;
}

.andes-v2-sweep-table-tdClass {
  white-space: nowrap;
}