/* === Select Model panel === */
.clear {
  zoom: 1;
}

.clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.IC-select-model-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.IC-select-model-title {
  font-size: 14px;
  font-weight: 500;
}

.IC-select-model-panel .IC-select-model-content {
  padding: 10px 14px;
  overflow: auto;
  font-size: 13px;
  height: 100%;
}

.IC-select-model-select-dropdown {
  z-index: 10000000;
}

.IC-select-model-select-dropdown .ant-select-dropdown-menu-item {
  font-size: 13px;
  padding: 2px 12px;
}

.IC-model-type-select.ant-select {
  float: left;
  width: calc(100% - 100px);
  font-size: 13px;
}

.IC-select-model-input {
  float: left;
  width: 161px;
}

.IC-select-model-input-odt {
  width: 161px;
  margin-left: 10px;
}

.IC-select-model-input .ant-input {
  font-size: 13px;
}

.IC-select-dialog {
  padding: 5px 10px;
  width: 50%;
  float: left;
  line-height: 24px;
  position: relative;
  overflow: hidden;
}

.IC-select-dialog-ami-model {
  width: 100% !important;
}

.IC-select-dialog:after,
.IC-select-model-content-main:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.IC-multi-spice-select-dialog {
  width: 100%;
  float: none;
}

.IC-multi-spice-select-dialog>.IC-select-model-title-item {
  width: 100px;
}

/* .IC-multi-spice-select-dialog>.IC-model-type-select {
  width: calc(100% - 100px);
  height: 24px;
  line-height: 26px;
} */

/* .IC-multi-spice-select-dialog>.IC-model-type-select . {
  height: 28px;
}

.IC-multi-spice-select-dialog>.IC-model-type-select .ant-select-selector {
  line-height: 26px;
} */

.IC-select-model-title-item,
.IC-spice-model-title,
.IC-apply-model-title,
.IC-apply-memory-model-title {
  float: left;
  line-height: 24px;
  font-weight: 500;
  width: 100px;
}

.IC-buffer-template-info {
  font-weight: 400;
  cursor: pointer;
  margin-left: 36px;
  font-size: 16px;
  color: #1890ff;
}

.IC-apply-dialog {
  padding: 5px 10px;
  width: 100%;
  line-height: 24px;
  float: left;
}

.IC-apply-model-title {
  width: 220px;
}

.IC-apply-signal-title {
  width: 340px;
}

.IC-apply-model-component-title {
  width: 330px;
}

.IC-apply-model-types-title {
  width: 190px;
}

.IC-apply-memory-model-title {
  width: 382px;
  font-size: 13px;
}

.IC-apply-interface-title {
  display: inline-block;
}

.IC-apply-interface-title-radio {
  line-height: 22px;
  font-size: 13px;
  display: block;
}

.IC-apply-interface-title-radio>span {
  vertical-align: middle;
}

.IC-model-apply-checkbox {
  margin-left: 15px;
  margin-right: 40px;
}

.IC-select-dialog .IC-model-apply-checkbox-pre {
  margin-right: 8px;
  margin-left: 0px;
}

.IC-select-model-name-box {
  padding: 6px;
  overflow: auto;
  background-color: #eee;
  border-radius: 6px;
  margin: 10px 10px 8px 8px;
}

.IC-select-model-input-after .ant-select-selection .ant-select-selector {
  margin: 0px 6px;
}

.IC-select-model-input-after .ant-select-selection .ant-select-arrow {
  right: 5px;
}

.IC-select-model-input-after .ant-select-selection .ant-select-selection-selected-value {
  padding-right: 15px;
}

.IC-select-model-input-after {
  font-size: 13px;
}

.IC-select-model-dropdown {
  z-index: 10000000;
}

.IC-select-model-dropdown .ant-select-dropdown-menu-item {
  font-size: 13px;
  padding: 0px 12px;
}

.IC-model-select-ul {
  padding: 0px;
  margin: 0px;
  max-height: 200px;
  overflow: auto;
  width: 100%;
  font-weight: 500;
}

.IC-model-select-li {
  white-space: nowrap;
  display: block;
  height: 22px;
  line-height: 22px;
  cursor: pointer;
  padding: 0px 6px;
  width: fit-content;
  font-size: 12px;
}


.IC-model-select-li:hover {
  background-color: #d5ecff;
}

.IC-model-select-li-selected {
  /* background-color: #abd7ff; */
  background-color: #b9ecff;
}

.IC-model-title-item {
  float: left;
  line-height: 24px;
  font-weight: 500;
  width: 100px;
  font-size: 13px;
}

.IC-select-model-name-input {
  float: left;
  width: calc(100% - 100px);
  height: 24px;
  font-size: 13px;
  line-height: 24px;
}

.IC-select-power-dialog {
  padding: 5px 9px;
  line-height: 24px;
  float: left;
}

.IC-select-power-dialog .IC-model-title-item {
  width: 80px;
  text-align: center;
}

.IC-power-off-switch::after {
  /* width: 9px;
  height: 9px; */
}

.IC-power-off-switch .IC-advanced-off-switch {
  min-width: 22px;
  height: 13px;
  line-height: 10px;
  margin-bottom: 3px;
  margin-left: 5px;
}

.IC-file-panel .panel-body {
  background: #f1f1f1;
}

.IC-file-panel .panel-body {
  position: relative;
}

.IC-file-content {
  background: #fafafa;
  padding: 16px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  height: 400px;
}

.IC-file-download {
  margin-left: 20px;
  font-size: 16px;
  color: #1890ff;
}

.IC-icon-tooltip {
  z-index: 2010;
}

.IC-icon-tooltip .ant-tooltip-inner {
  color: #1890ff;
  background-color: #fff;
}

.IC-icon-tooltip .ant-tooltip-arrow::before {
  background-color: #fffdfd;
}

#template-file-view {
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  border: 1px solid #cdcfd2;
}

.IC-model-type-select .ant-select-arrow:hover {
  color: #1890ff;
  text-transform: none;
}

.IC-model-type-select .ant-select-arrow {
  text-transform: none !important;
  transform: none !important;
  transition: none !important;
}

.IC-apply-sub-dialog {
  padding: 5px 10px;
  padding-left: 24px;
  width: 100%;
  line-height: 24px;
  float: left;
}

.IC-sub-apply-box {
  margin-right: 6px;
  width: 8px;
  height: 8px;
  background: cornflowerblue;
  border-radius: 4px;
  margin-top: 8px;
  float: left;
}

.IC-select-model-panel .IC-select-model-content .ant-spin-nested-loading {
  float: left;
  width: 100%;
}

.IC-model-label {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
}

.IC-model-checkbox {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  transform: translateY(-1px);
}

.IC-model-checkbox-selected {
  background-color: #b9ecff;
}

.IC-model-search-input {
  display: block;
  border: none;
  outline: none;
  min-width: 500px;
}

.IC-model-select-li.IC-model-select-li-selected-disable {
  background-color: #d9d9d9;
  cursor: not-allowed;
}

.IC-model-select-li.IC-model-select-li-selected-disable .IC-model-label {
  cursor: not-allowed;
}

.ic-model-divider {
  margin: 2px 0px;
}

.IC-select-model-content .IC-model-type-tree-select .aurora-select {
  height: 24px;
  line-height: 24px;
}

.IC-select-model-content .IC-model-type-tree-select .ant-select-selector {
  height: 22px;
  line-height: 22px;
}

.IC-select-model-content .IC-model-type-tree-select .ant-select-selector .ant-select-selection-selected-value {
  font-size: 13px;
  height: 22px;
  line-height: 22px;
}

.odt-value-validate {
  display: flex;
  padding: 6px 10px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 8px;
}

.IC-ami-model-select-tooltip {
  z-index: 9999;
}

.IC-select-dialog-ami-ignore-bits {
  display: inline-flex;
  align-items: center;
}

.IC-select-dialog-ami-ignore-bits-switch {
  margin-left: 10px;
}

.IC-select-ami-model-instantiation {
  width: 160px;
}

.IC-ami-model-instantiation-select.ant-select {
  width: calc(100% - 160px);
}

.IC-model-select-advanced {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding-bottom: 0;
}

.IC-model-select-advanced-title {
  font-weight: 500;
  width: 120px;
}

.IC-power-off-switch .IC-advanced-off-switch {
  margin-left: 5px;
}

.IC-model-select-advanced-item-title {
  width: calc(100% - 340px);
  font-weight: 500;
  padding-left: 15px;
}

.IC-model-select-advanced-item-input {
  width: 210px;
  padding-right: 10px;
  margin-left: 20px;
}

.IC-model-select-advanced-item-title i {
  font-size: 10px;
  margin-right: 4px;
  vertical-align: middle;
  margin-top: -4px;
}

.IC-model-select-name {
  font-weight: 500;
  margin-right: 25px;
  width: 240px;
  font-size: 13px;
  line-height: 28px;
  margin-left: 13px;
}

.IC-model-select-advanced-options {
  padding-left: 10px;
}

.IC-model-select-advanced-item {
  display: inline-flex;
  width: 100%;
  line-height: 28px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding-top: 2px;
}

.IC-adv-option-input-after {
  font-size: 13px;
  width: 56px;
}

.IC-adv-option-input-after-dropdown {
  z-index: 10000000;
}

.IC-advanced-wrap {
  margin-bottom: 15px;
  position: relative;
  float: left;
  width: 100%;
}

.IC-ami-model-collapse {
  border: none;
  background-color: #ffffff;
}

.IC-ami-model-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 6px 10px 6px 20px;
  width: fit-content;
}

.IC-ami-model-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  left: 0;
}

.IC-ami-model-collapse-title {
  font-size: 14px;
  font-weight: 500;
  height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
}

.IC-ami-parameters-table .ant-table-body table {
  table-layout: fixed;
}

.ami-parameters-title {
  cursor: pointer;
}

.ami-parameters-title:hover {
  color: #696969;
}

.IC-ami-parameters-value {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.IC-model-type-select.ant-select-single.ant-select-sm .ant-select-selector {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.85);
}