.himalayas-edit-library-content {
  width: 100%;
  padding: 16px;
}

.himalayas-edit-library-option-dropdown {
  z-index: 9999;
}

.himalayas-edit-library-item {
  width: 100%;
  display: flex;
}

.himalayas-edit-library-item>label {
  width: 100px;
  height: 28px;
  line-height: 28px;
}

.himalayas-edit-library-item>.aurora-select {
  width: calc(100% - 100px);
}

.himalayas-library-edit-panel-title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}