.himalayas-spice-card-template-content {
  padding: 20px 20px;
  min-width: fit-content;
  /*  width: 100%; */
}

.himalayas-spice-card-template-download-icon {
  color: #0d76e4;
  cursor: pointer;
  margin-left: 12px;
}

.himalayas-spice-card-file-box {
  float: left;
  padding-left: 20px;
  width: fit-content;
  color: #003b91;
  line-height: 32px;
}

/* Table */
.himalayas-spice-card-table-content .himalayas-spice-card-table {
  margin-top: 10px;
}

.himalayas-spice-card-table-content .himalayas-spice-card-table .ant-table-small .ant-table-content .ant-table-thead>tr>th {
  color: #003b91;
}

.himalayas-spice-card-table-content .himalayas-spice-card-table .ant-table-small .ant-table-content .ant-table-thead>tr>th:last-child {
  border-right: none;
}

.himalayas-spice-card-table-content .himalayas-spice-card-table .ant-table-small .ant-table-content .aurora-table-body .himalayas-spice-card-split-line-div,
.himalayas-spice-card-table-content .himalayas-spice-card-table .ant-table-small .ant-table-content .ant-table-thead>tr>.himalayas-spice-card-split-line-div {
  border-right: 2px solid #c0d9f2;
}

.himalayas-spice-card-table-content .himalayas-spice-card-table .ant-table-small .ant-table-content .aurora-table-body .himalayas-spice-card-table-name-td,
.himalayas-spice-card-table-content .himalayas-spice-card-table .ant-table-small .ant-table-content .ant-table-thead>tr>.himalayas-spice-card-table-name-td {
  border-right: 1px solid #c0d9f2 !important;
}

.himalayas-spice-card-title-span {
  display: inline-block;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.himalayas-spice-card-table .ant-table-header-column,
.himalayas-spice-card-table .ant-table-column-title {
  width: 100%;
}

.himalayas-spice-card-title-span>span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: max-content;
  max-width: calc(100% - 20px);
  vertical-align: middle;
  display: inline-block;
}

.himalayas-spice-card-fold-icon {
  margin-right: 5px;
  font-size: 10px;
  vertical-align: middle;
}

.himalayas-spice-card-title-span:hover .himalayas-spice-card-fold-icon {
  color: #1890ff;
}

.himalayas-spice-card-fold-icon-transform svg {
  transform: rotate(270deg);
}

.himalayas-spice-card-table .ant-table-small {
  border-radius: 0px 4px 4px 0px;
}

.himalayas-spice-card-table .ant-table-small tbody>tr>td {
  position: relative;
}

.himalayas-spice-card-table .aurora-table-row-disabled {
  background-color: #dcdcdc;
}

.himalayas-spice-card-table .spice-card-name-title {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  padding: 8px;
}

.himalayas-spice-card-table .spice-card-table-td {
  min-height: 22px;
}

.himalayas-spice-card-tabs .ant-tabs-tab-prev-icon,
.himalayas-spice-card-tabs .ant-tabs-tab-next-icon {
  color: #777
}

.himalayas-spice-card-tabs .ant-tabs-bar {
  margin-bottom: 0px;
}