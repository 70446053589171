.my-aurora-content {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
}

.my-aurora-content>.ant-spin-nested-loading,
.my-aurora-content>.ant-spin-nested-loading>.ant-spin-container {
  width: 100%;
  height: 100%;
}

.aurora-footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: #f9f9f9;
  border-top: 1px solid #e2dfdf;
  width: 100%;
  z-index: 20;
  box-sizing: border-box;
}