.dc-result-current-row-red {
  color: #ff600c;
}

.dc-result-table-title-content {
  position: absolute;
  right: 30px;
  display: inline-block;
}

.result-tooltip-overlay {
  display: none;
}

.dc-result-table-title-content.ant-table-filter-icon.ant-dropdown-trigger:hover {
  background-color: #fff !important;
}

.dc-result-table-title-content.ant-table-filter-icon.ant-dropdown-trigger {
  background-color: #fff;
  width: 50px;
  height: 34px;
  font-size: 16px;
  line-height: 39px;
  /* cursor: default; */
}

.dc-result-table .ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-filters .ant-table-filter-icon.dc-result-table-title-content {
  background-color: #fff;
}

.dc-result-table .ant-table-thead>tr>th.ant-table-column-has-actions.ant-table-column-has-filters .ant-table-filter-icon.ant-table-filter-open {
  background-color: #fff;
}

.dc-result-table th.ant-table-column-has-actions.ant-table-column-has-filters.ant-table-column-has-sorters.ant-table-row-cell-break-word:hover .ant-table-filter-icon.dc-result-table-title-content {
  background-color: #f5f5f5;
}

.dc-result-table-title-content .dc-result-table-title-img {
  height: 22px;
}

.dc-result-chart-plot .plot .domain {
  display: unset;
}

.dc-result-chart-body {
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.dc-result-chart-body .dc-result-chart-item {
  flex: auto;
  border: 1px solid #a8c4e6;
  border-radius: 3px;
  margin-top: 20px;
}

.dc-result-chart-body-unset .dc-result-chart-item {
  max-width: unset;
}

.dc-result-chart-body .dc-result-chart-item-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
}

.dc-result-chart-content-table {
  float: left;
  text-align: center;
  max-width: 1100px;
  width: 60%;
  min-width: 687px;
  padding: 0 20px 20px;
}

.dc-result-chart-plot .plot .domain {
  display: unset;
}

.dc-result-chart-plot {
  float: left;
  min-width: 678px;
  max-width: 1100px;
  width: 40%;
  padding: 0px 20px;
  margin: 15px 0;
}

.dc-result-chart-box-border {
  border: 1px solid #e8e8e8;
}

.dc-result-chart-body .ant-table-small {
  border-radius: 0px 0px 4px 4px;
}

.dc-result-chart-body .rocky-result-title {
  position: absolute;
  top: 0px;
  width: 100%;
  text-align: center;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: 600;
}

.dc-result-chart-content-table .overflew-box {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.dc-result-chart-item-flex .dc-result-chart-title {
  /* position: absolute;
  top: 0;
  width: 100%;
  text-align: center; */
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  font-weight: 600;
}

.dc-result-chart-item-flex .dc-result-chart-table-title {
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  font-weight: 600;
}

.chart-content-tooltip .ant-tooltip-inner {
  width: 400px;
  overflow: auto;
  max-height: 500px;
}

/* 1024x768, 1280x720, 1366x768, 1440x900, 1680x1050, 1920x1080 1920x1200, 2560x1440 */

@media (max-width: 1840px) {
  .dc-result-chart-body .dc-result-chart-item-flex {
    flex-direction: column;
  }

  .dc-result-chart-body-unset .dc-result-chart-item-flex {
    flex-direction: unset;
  }

  .dc-result-chart-body .dc-result-chart-plot,
  .dc-result-chart-body .dc-result-chart-content-table {
    width: 100%;
    margin: 0;
  }
}

@media (max-width:2560px) {
  .dc-result-chart-body .dc-result-chart-item-flex {
    flex-direction: column;
  }

  .dc-result-chart-body-unset .dc-result-chart-item-flex {
    flex-direction: unset;
  }

  .dc-result-chart-body .dc-result-chart-plot,
  .dc-result-chart-body .dc-result-chart-content-table {
    width: 100%;
    margin: 0;
  }
}