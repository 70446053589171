 .aurora-tags-input-content {
   display: inline-block;
   width: calc(100% - 120px);
   border: 1px solid #d9d9d9;
   border-radius: 4px;
   vertical-align: middle;
   padding: 0px 3px;
   cursor: pointer;
   background-color: #ffffff;
   position: relative;
   box-sizing: border-box;
   min-height: 28px;
 }

 .aurora-tags-input-content .tags-input-list-ul {
   overflow: hidden;
   padding: 0;
   margin: 0;
   min-height: 26px;
   line-height: 26px;
 }

 .aurora-tags-input-content .tags-input-tag {
   cursor: pointer;
   margin-top: 1.5px;
   float: left;
   line-height: 21px;
 }

 .aurora-tags-input-content .tags-input-tag .ant-tag .anticon-close {
   font-size: 11px;
 }

 .aurora-tags-input-content .tags-input-tag-input-li {
   width: auto;
   float: left;
   height: 26px;
 }

 .aurora-tags-input-content .tags-input-tag-input {
   border: none;
   height: 26px;
   position: static;
   padding: 0;
   width: .75em;
   max-width: 100%;
   float: left;
 }

 .aurora-tags-input-content .tags-input-tag-input .ant-input-group,
 .aurora-tags-input-content .tags-input-tag-input .ant-input-group .ant-input {
   height: 23px;
   font-size: 12px;
   margin-top: 1px;
 }

 .tags-input-tag-input:hover,
 .tags-input-tag-input:focus {
   border-right-width: 0px !important;
   border-width: 0px;
   box-shadow: 0px 0px 0px #ffffff;
   border-color: #fff;
 }

 .tags-input-list-ul>span:not(.anticon) {
   position: absolute;
   left: 11px;
   right: 11px;
   width: calc(100% - 22px);
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   font-size: 12px;
 }

 .tab-input-disabled,
 .tab-input-disabled .tags-input-tag-input {
   cursor: not-allowed;
   background-color: #f5f5f5;
   color: rgba(0, 0, 0, 0.25);
 }

 .aurora-tag-input-after-unit-content {
   display: inline-block;
   width: 46px;
   border: 1px solid #d9d9d9;
   border-radius: 0px 4px 4px 0px;
   vertical-align: middle;
   border-left: none;
   padding: 0px 4px;
   cursor: pointer;
   background-color: #fafafa;
   position: relative;
   box-sizing: border-box;
   min-height: 28px;
   text-align: center;
   line-height: 26px;
   font-size: 12px;
 }

 .aurora-tags-input-unit-content {
   border-radius: 4px 0px 0px 4px;
 }

 .tags-input-list-ul>.tags-input-list-placeholder {
   color: rgba(0, 0, 0, 0.25);
   font-weight: 500;
   font-size: 14px;
 }