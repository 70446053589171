.comp-extraction-option-content {
  padding: 0 10px;
  margin-bottom: 6px;
}

.comp-extraction-option-content-body {
  font-size: 14px;
  vertical-align: middle;
  display: inline-block;
  width: 45%;
  line-height: 28px;
}

.comp-extraction-option-sub-span {
  padding-left: 20px;
}

.comp-extraction-option-content .comp-extraction-option-content-input {
  display: inline-block;
  width: 55%;
  vertical-align: middle;
  height: 28px;
  line-height: 28px;
}

.comp-extraction-option-content-input {
  font-size: 13px;
}

.comp-extraction-option-content-input .ant-input {
  height: 28px;
  line-height: 28px;
  font-size: 13px;
}

.comp-extraction-option-content-input .ant-input-group-addon,
.comp-extraction-option-content-input .ant-input-group-addon .ant-select-selection-selected-value {
  font-size: 13px;
}

.comp-extraction-option-content-core-input.comp-extraction-option-content-input {
  width: calc(27.5% - 27px);
}

.comp-extraction-option-input-or-span {
  display: inline-block;
  margin: 0 20px;
}

.comp-extraction-option-input-addonAfter .ant-input-group-addon {
  width: 65px;
}

.comp-extraction-option-content-body .span-msg-icon {
  color: #1890ff;
  margin-left: 5px;
}

.comp-extraction-option-next-sub-span {
  padding-left: 40px;
}

.comp-extraction-option-distance-switch {
  margin-right: 40px;
}

.comp-extraction-option-distance-input {
  width: 25% !important;
  margin-right: 15px;
}

.comp-extraction-option-multi-zone-button {
  height: 28px;
  width: 80px;
  float: right;
}

.comp-extraction-option-multi-zone-button:last-child {
  margin-right: 10px;
}