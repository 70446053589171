.material-definition-panel {
  margin-top: 100px;
  min-width: 370px;
  overflow: auto;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.material-definition-panel .panel-body {
  min-width: 370px;
}

.material-definition-panel .panel-modal-header {
  padding-left: 14px !important;
}

.material-definition-title {
  font-size: 16px;
  font-weight: 500;
  width: calc(100% - 20px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.material-definition-panel .material-definition-content {
  padding: 10px 16px;
  min-width: 370px;
}

.material-definition-panel .material-definition-item {
  margin: 6px 0px;
  line-height: 28px;
  height: 28px;
}

.material-definition-content .material-edit-value-label {
  display: inline-block;
  min-width: 168px;
  margin-right: 10px;
  vertical-align: middle;
  /*  text-align: end; */
}

.material-definition-panel .material-select,
.material-definition-panel .material-input {
  width: calc(100% - 178px);
  font-size: 13px;
  vertical-align: middle;
}

.material-definition-content .material-dielectric-item .material-edit-value-label {
  min-width: 168px;
}

.material-definition-content .material-metal-item .material-edit-value-label {
  width: 98px;
}

.material-definition-content .material-metal-item .material-select,
.material-definition-content .material-metal-item .material-input {
  width: calc(100% - 178px);
  font-size: 13px;
}

.material-definition-panel .material-definition-content .material-definition-item-half {
  width: calc(100% - 208px);
  display: inline-block;
}

.material-definition-panel .material-definition-content .material-definition-item-checkbox {
  width: 30px;
  vertical-align: middle;
}

.material-definition-panel .material-definition-content .aurora-model-name-error-msg {
  margin: 8px 0px;
}

.material-definition-panel .material-definition-content .material-freq-dep-option {
  margin-left: 16px;
}

.material-definition-panel .material-definition-content .material-edit-value-label-long {
  width: calc(100% - 30px);
  margin-right: 0px;
}

.material-definition-panel .material-definition-content .material-divider {
  margin-top: 12px;
  margin-bottom: 2px;
}

.material-definition-panel .material-definition-content .material-definition-item-apply {
  margin: 0px 0px;
}

.material-input .ant-input[disabled],
.material-input[disabled],
.material-metal-item .ant-select-disabled {
  color: rgba(0, 0, 0, 0.6);
}

.material-freq-dep-option:first-of-type .material-input .ant-input-wrapper {
  margin-top: 1px;
}