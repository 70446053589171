.end-to-end-channel-connection-table .editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.end-to-end-channel-connection-table .ant-table-small .ant-table-container .aurora-table-body {
  margin: 0px;
}

.end-to-end-channel-connection-table .ant-table-small .ant-table-container .ant-table-body .aurora-table-body {
  padding-right: 22px;
}

.end-to-end-channel-connection-table tbody>tr>td:last-child {
  position: relative;
}

.end-to-end-channel-connection-table .ant-table-small>.ant-table-content .connection-delete {
  position: absolute;
  right: -19px;
  top: 50%;
  margin-top: -7px;
  color: #ccc;
}

.end-to-end-channel-connection-table .ant-table-small .ant-table-container .aurora-table-body .ant-table-row:hover .connection-delete {
  color: #1890ff;
}

.end-to-end-channel-connection-table .ant-table .ant-table-container .aurora-table-body .ant-table-row td {
  position: relative;
}

.end-to-end-channel-connection-table tbody>tr .connection-signal-content {
  position: relative;
}

.end-to-end-channel-connection-table tbody>tr .connection-signal-content .editable-cell-value-wrap {
  width: calc(100% - 14px);
}

.pcb-connection-table-title {
  min-width: 131px;
  display: inline;
}

.pcb-connection-table-title>span {
  vertical-align: middle;
}

.pcb-connection-table-title .pcb-connection-add-section {
  margin-left: 6px;
  color: #3da4fa;
  cursor: pointer;
  font-size: 15px;
  vertical-align: middle;
}

.pcb-connection-table-title .pcb-connection-delete-section {
  color: #ccc;
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  right: 6px;
  top: calc(50% - 7px);
}

.end-to-end-channel-connection-table .ant-table-row-cell-break-word:hover .pcb-connection-table-title .pcb-connection-delete-section {
  color: #3da4fa;
}

.end-to-end-channel-connection-table .ant-table-row-cell-break-word {
  position: relative;
}

.pcb-connections-schematic-main {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.pcb-connections-schematic-item {
  padding: 10px 0px;
  display: flex;
  align-items: center;
}

.pcb-connections-schematic-item .pcb-connections-schematic-pcb {
  padding: 0px 10px;
  font-size: 14px;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  width: 100px;
}

.pcb-connections-schematic-item .pcb-connections-schematic-pcb .icon-tool-xinpian- {
  font-size: 23px;
  vertical-align: middle;
  margin-right: 6px;
  color: #2e85d7;
}

.pcb-connections-schematic-item .connection-pcb-signal1-line,
.pcb-connections-schematic-item .connection-pcb-signal2-line,
.pcb-connections-schematic-item .connection-pcb-signals-line {
  width: 80px;
  height: 20px;
  flex: 0 0 auto;
  font-size: 12px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pcb-connections-schematic-item .connection-pcb-signals-line {
  font-size: 20px;
  line-height: 10px;
  color: #6095f3;
  margin: 0 auto;
}

.pcb-connections-schematic-item .connection-pcb-signal1-line {
  border-bottom: 1px solid #6095f3;
}

.pcb-connections-schematic-item .connection-pcb-signal2-line {
  border-top: 1px solid #6095f3;
}

.pcb-connections-schematic-main .pcb-connection-schematic-title-high-light .pcb-connections-schematic-pcb {
  background-color: #d3f1ff;
  border-color: #7ac4fb;
}

.pcb-connections-schematic-main .pcb-connections-schematic-signal-high-light .connection-pcb-signal1-line,
.pcb-connections-schematic-main .pcb-connections-schematic-signal-high-light .connection-pcb-signal2-line {
  border-width: 2px;
}

.end-to-end-channel-connection-table .ant-table-small .ant-table-container .ant-table-header {
  padding-right: 22px;
}