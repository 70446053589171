.result-top-menu {
  height: 45px;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  background: #f3f2f1;
  z-index: 1000;
}

.result-top-menu .result-top-menu-content {
  padding-left: 10px;
  line-height: 44px;
  height: 45px;
  background: #f3f2f1;
  width: 100%;
}

.result-top-menu .result-top-menu-item {
  text-align: center;
  cursor: pointer;
  float: left;
  margin-right: 15px;
}

.result-top-menu .result-top-menu-item .menu-item-title,
.result-top-menu .result-top-menu-item .menu-item-title-disable {
  font-size: 14px;
  color: #005a9e;
  height: 30px;
  display: inline-block;
  line-height: 30px;
  padding: 0 15px;
  background-color: #f3f2f1;
  transition: all 0.3s;
}

.result-top-menu .result-top-menu-item .menu-item-title:hover {
  background-color: #edebe9;
  transition: all 0.3s;
}

.result-top-menu .result-top-menu-item .menu-item-title-select,
.result-top-menu .result-top-menu-item .menu-item-title-select:hover {
  background-color: #c7e0f4;
}

.menu-item-title-disable {
  cursor: not-allowed;
}

.menu-item-title-icon {
  margin-right: 8px;
  font-size: 16px;
  line-height: 26px;
}

.result-top-menu .result-top-menu-item .menu-item-back-setup {
  color: #ff610b;
  border: 1px solid #ff610b;
  border-radius: 4px;
  line-height: 28px;
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
}

.result-top-menu .result-top-menu-item .menu-item-back-setup:hover {
  color: #f87e3b;
  border: 1px solid #f87e3b;
  background-color: #f3f2f1;
}

.result-top-menu .result-top-menu-setup .aurora-top-bar-vertical-line {
  margin-left: 28px;
}

.result-top-menu .result-top-menu-setup {
  margin-right: 0px;
  display: flex;
  justify-content: space-between;
  line-height: 44px;
  height: 44px;
  align-items: center;
}

.result-top-menu .result-top-menu-list {
  overflow: hidden;
  height: 45px;
  line-height: 44px;
  float: left;
}

.result-top-menu .result-save-div .aurora-top-bar-vertical-line {
  vertical-align: text-top;
}

.result-top-menu-list .result-top-menu-item.result-download-content:hover {
  color: #1890ff;
}

.result-top-menu-list .result-top-menu-item.result-download-content .aurora-top-bar-vertical-line {
  vertical-align: text-top;
}

.result-export-button {
  height: 26px;
  padding: 0 8px;
}

.save-result-history-error {
  display: block;
  padding: 4px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 10px;
  font-size: 14px;
  padding-left: 10px;
}

.result-zoom-menu {
  width: 28px;
  height: 118px;
  position: absolute;
  top: 2px;
  right: 8px;
  visibility: visible;
  text-align: center;
  border-right: none;
}

.result-zoom-menu .ant-menu-item {
  padding: 0px;
  margin-right: 0px;
  border-radius: 50%;
  cursor: pointer;
  width: 28px;
  height: 28px;
  text-align: center;
  transition: all 0.3s;
  line-height: 28px;
}

.result-zoom-menu .ant-menu-item .layBtn {
  margin: 0px;
  color: #ffffff;
  font-size: 17px;
  background-color: #9191918c;
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.14), 1px 1px 3px 0 rgba(0, 0, 0, 0.21), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.result-zoom-menu .ant-menu-item .layBtn:hover {
  background-color: #6e6e6e8c;
  transition: all 0.3s;
}

.result-zoom-menu .ant-menu-item-selected {
  background-color: none !important;
}

.rocky-top-bar-vertical-line {
  height: 16px;
  background-color: #bebebe;
  width: 1px;
  display: block;
}

.result-top-menu-item-line {
  padding: 12px 0px;
  margin: 2px 0px;
  height: 40px;
}

.result-top-menu-select.ant-select .ant-select-selection-item {
  flex: unset
}