.connection-model-assign-content {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  position: relative;
}

.connection-left-channel {
  justify-items: flex-start;
  width: 36%;
  min-width: 300px;
  min-height: 100px;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  position: relative;
}

.connection-right-channel {
  justify-items: flex-end;
  width: 36%;
  min-width: 300px;
  min-height: 100px;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  position: relative;
}

.connection-model-assign-content .connection-component-name {
  font-size: 14px;
  font-weight: 600;
  max-width: 100%;
  padding: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

.connection-model-assign-content .connection-pin-left-ul {
  position: absolute;
  /* right: -106px; */
  right: -75px;
  top: 100px;
}

.connection-model-assign-content .connection-pin-right-ul {
  position: absolute;
  /* left: -86px; */
  left: 0px;
  top: 100px;
}

.connection-component-select-content {
  width: calc(100% - 14px);
  height: 28px;
  margin: 6px 14px;
  position: relative;
  line-height: 28px;
  margin-left: 18px;
}

.connection-component-select-content>.connection-component-select-title {
  width: 140px;
  display: inline-block;
  color: #005a9e;
}

.connection-component-select-content .connection-single-select {
  width: calc(100% - 146px);
}

.connection-cable-model-content .connection-component-select-content>.connection-component-select-title {
  width: 120px;
}

.connection-cable-model-content .connection-component-select-content .connection-single-select {
  width: calc(100% - 95px);
}

.connection-cable-model-content {
  background: #eee;
  border: 1px solid #ccc;
  width: calc(28% - 20px);
  margin: 0px 10px;
  border-radius: 4px;
}

.connection-cable-model-none-content {
  background: #ffffff !important;
  border: none !important;
}

.connection-file-error .connection-single-select {
  color: #f30518 !important;
}

.connection-file-error {
  position: relative;
}

.connection-file-error .aurora-file-check-icon {
  right: 25px;
}

.connector-select-dropdown-menu {
  z-index: 10000000000;
}

.connector-model-add-file-icon {
  margin-left: 10px;
  color: #3da4fa;
  font-size: 14px;
}

.connection-component-select-main {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 38px;
}

.connector-model-close-file-icon {
  color: #3da4fa;
  font-size: 14px;
  position: absolute;
  right: -12px;
  top: 7px;
  display: none;
}

.connection-component-select-content:hover .connector-model-close-file-icon {
  display: inline-block;
}

.connection-model-assign-content .aurora-model-name-error-msg {
  margin: 0px 14px;
  margin-right: 24px;
  padding: 4px 10px;
}

.connection-model-assign-content .connector-model-box-left,
.connection-model-assign-content .connector-model-box-right {
  background: #ffffff;
  z-index: 10;
  width: 160px;
}

.connection-model-assign-content .connector-model-first-box {
  background: #ffffff;
  z-index: 40;
  width: 160px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  position: absolute;
  bottom: 20px;
  min-height: 135px;
  padding: 0px 5px;
}

.connector-model-first-box .connection-component-select-content {
  margin: 6px 4px;
  height: auto;
  font-size: 12px;
}

.connector-model-first-box .ant-select-sm.aurora-select.connection-single-select.connection-component-left-select.ant-select.ant-select-enabled.ant-select-allow-clear {
  width: 100%;
}

.connector-model-first-box .connection-component-select-content .connection-single-select {
  width: 100%;
}

.connector-model-first-box .connection-component-select-main {
  position: unset;
}

.connector-model-first-box .aurora-select .ant-select-selector {
  font-size: 12px;
}

.connection-model-assign-content .connection-cable-model-content .connection-component-select-content>.connection-component-select-title {
  font-size: 12px;
  width: 90px;
}

.connection-model-assign-content .connection-cable-model-content .connection-component-select-content .connection-single-select {
  font-size: 12px;
}

.connector-model-first-box>div {
  height: 98%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.connector-model-pinName-box {
  height: 26px;
  line-height: 26px;
  position: relative;
}

.connector-model-pinName-box .connection-pin-l-box {
  display: inline-block;
  margin-right: 10px;
}

.connector-model-pinName-box .connection-pin-r-box {
  display: inline-block;
  margin-left: 10px;
}

.connection-model-assign-content .connector-model-box-left {
  display: inline-block;
  margin: 0 10px;
}

.connection-model-assign-content .connector-model-box-right {
  display: inline-block;
  margin: 0 10px;
}

.connection-pin-l-value-box .connection-pin-net-box {
  text-align: left;
}