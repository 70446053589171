.component-CMC-model-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.component-CMC-model-panel .component-CMC-model-content {
  padding: 8px 14px;
}

.component-cmc-model-pin {
  width: 100%;
  height: 100%;
}

.component-cmc-model-port-select-dropdown {
  z-index: 30000;
}

.component-CMC-model-file-item {
  width: 100%;
  height: 28px;
  line-height: 28px;
  display: flex;
  margin-bottom: 8px;
}

.component-CMC-model-file-item>span {
  width: 120px;
}

.component-CMC-model-file-item>.aurora-select {
  width: calc(100% - 120px);
}

.component-CMC-model-content .component-CMC-model-file-item>.component-cmc-file-select {
  width: calc(100% - 140px);
}

.component-cmc-file-content-icon {
  width: 24px;
  color: #1ca4f3;
  line-height: 34px;
  font-size: 16px;
  height: 28px;
}