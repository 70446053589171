.andes-ads-model-setup-main {
  padding: 8px 14px;
  min-height: 200px;
  min-width: 700px;
  font-size: 12px;
}

.ami-model-select-item {
  height: 28px;
  line-height: 28px;
  display: flex;
  margin: 6px 0px;
  position: relative;
}

.ami-model-select-item>span {
  width: 100px;
}

.ami-model-select-item>.aurora-select {
  width: calc(100% - 100px);
}

.ami-model-select-checkbox {
  width: 50px;
}

.ami-select-model-pins-title,
.ads-select-model-pins-title {
  width: 60%;
  height: 56px;
  line-height: 56px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  justify-content: space-around;
}

.ami-select-model-pins-title>.ami-select-model-pin-item-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 28px;
}

.ami-select-model-signal-title {
  width: 70%;
  height: 28px;
  line-height: 28px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ami-select-model-pin-title {
  width: 30%;
  min-width: 80px;
  height: 28px;
  line-height: 28px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ami-model-jitters-add-icon {
  margin-left: 20px;
  color: #2a99f3;
  cursor: pointer;
  vertical-align: middle;
}

.ami-model-corner-content>span {
  width: 70px;
}

.ami-model-jitters-table tbody>tr>td:last-child,
.ami-model-jitters-table tbody>tr>td:first-child .editable-cell-value-wrap {
  position: relative;
}

.ami-model-jitters-table .ami-model-jitter-table-delete-icon {
  position: absolute;
  right: -18px;
  top: 50%;
  margin-top: -7px;
  color: #ccc;
}

.ami-model-jitters-table .ant-table-row:hover .ami-model-jitter-table-delete-icon {
  color: #1890ff;
}

.ami-model-jitters-table .ant-table-small .ant-table-container .aurora-table-body {
  padding-right: 14px;
}

.ami-model-jitters-table .ant-table-small .ant-table-container .ant-table-body .aurora-table-body {
  padding-right: 22px;
}

.ami-model-corner-content .aurora-select.ant-select-disabled .ant-select-selection {
  background: #f7f7f7;
}

.ami-model-corner-content>.aurora-select {
  width: calc(100% - 70px);
}

.andes-ami-parameters-value {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.ami-model-parameters-table .aurora-table-body table {
  table-layout: fixed;
}

.ami-parameters-range-span {
  color: #8d8d8d;
  text-indent: 20px;
}

.ami-parameters-title {
  cursor: pointer;
}

.ami-parameters-title:hover {
  color: #696969;
}

.ami-model-collapse-content .aurora-model-name-error-msg {
  margin-bottom: 6px;
}