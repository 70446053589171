.pdn-result-file-name {
  transition: all 0.3s;
  margin-top: 5px;
  padding-left: 10px;
  cursor: pointer;
  line-height: 24px;
}

.pdn-result-file-name:hover {
  background-color: #e0e0e0;
  transition: all 0.3s;
}

.file-close {
  width: 12px;
  height: 12px;
  display: inline-block;
}

.file-close:hover {
  cursor: pointer;
}

.pdn-result-checkbox-group {
  width: 100%;
  padding-left: 10px;
}

.file-expand-icon {
  padding-right: 4px;
  float: left;
  line-height: 28px;
  font-size: 10px;
  display: block;
  height: 24px;
  width: 24px;
}

.title-expand-icon {
  padding-right: 10px;
  float: left;
  color: rgba(0, 0, 0, 0.65);
  font-size: 10px;
  line-height: 18px;
}

.setting-box-sparam {
  width: 220px;
  height: auto;
}

.sp-axis-title {
  cursor: pointer;
}

.sp-axis-content>div {
  margin: 3px 0;
}

.sp-axis-content>div>div {
  display: flex;
}

.sp-axis-content>div>div .ant-select {
  width: 75px;
  height: 28px;
}

.sp-axis-content>div>div .sp-setting-select {
  width: 120px;
}

.sp-setting-input {
  width: 102px;
  margin-left: 0px;
  height: 28px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  vertical-align: top;
}

.sp-setting-input input {
  height: 28px;
}

.padding-left-42 {
  padding-left: 42px;
}

.parameter-setting-box {
  width: 100%;
  height: auto;
  padding-bottom: 2px;
}

.parameter-setting-box .ant-row-flex-middle {
  margin: 2px 0;
}

.clear-all {
  margin-top: 10px;
  width: 32px;
  height: 32px;
  color: #ffffff;
  border-color: #40a9ff;
  font-size: 18px;
  padding: 0px;
  line-height: 32px;
}

.pdn-result-parameter-file-list {
  background-color: #fafafa;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 318px;
  padding: 12px 0 12px 12px;
}

.pdn-result-parameter-right {
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  bottom: 20px;
  left: 0px;
  margin-left: 8px;
}

.pdn-result-parameter-left {
  background-color: #fff;
  border-left-color: #dcdcdc;
  border-left-width: 1px;
  border-left-style: solid;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 22%;
  overflow-y: auto;
  overflow-x: hidden;
}

.tool-left-1 .tool-upload-btn {
  color: #40a9ff;
  border-color: #40a9ff;
  font-weight: bold;
  height: 32px;
  padding: 0px;
  line-height: 32px;
  width: 130px;
  margin-left: 10px;
  margin-right: 10px;
}

.font-bold {
  font-weight: bold;
}

.sp-select-row {
  line-height: 28px;
  flex-wrap: nowrap;
}

.sp-select-col {
  width: 54px;
}

.sp-select-box {
  overflow: auto;
}

.sp-file-select {
  width: 200px;
}

.pdn-result-checkbox-group>.ant-checkbox {
  top: 0px;
}

.pdn-result-parameter-file-list .pdn-result-file-name .span-file-name,
.pdn-result-parameter-file-list .pdn-result-file-name .span-import-file-name,
.pdn-result-parameter-file-list .pdn-result-file-name .span-history-file-name,
.pdn-result-parameter-file-list .pdn-result-file-name .span-file-name-opt {
  float: left;
  max-width: calc(100% - 90px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pdn-result-parameter-file-list .pdn-result-file-name .span-file-name {
  max-width: calc(100% - 60px);
}

.pdn-result-parameter-file-list .pdn-result-file-name .span-file-name-opt {
  font-weight: bold;
}

.pdn-result-parameter-file-list .pdn-result-file-name .span-history-file-name {
  max-width: 90%;
}

.pdn-result-main,
.pdn-result-main .ant-spin-nested-loading,
.pdn-result-main .ant-spin-container {
  height: 100%;
}

.pdn-result-main {
  padding-top: 44px;
}

.pdn-result-main .pdn-result-setting {
  position: absolute;
  left: 0;
  right: 0;
  max-height: 50%;
  bottom: 0;
}

.sp-unit-select {
  vertical-align: top;
  width: 70px;
}

.sp-unit-select .ant-select-selection {
  position: relative;
  padding: 0 10px;
  padding-left: 6px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 28px;
  border-left: none;
}

.sp-unit-select .ant-select-arrow {
  right: 4px;
}

.sp-unit-select .ant-select-selector {
  margin-right: 0;
  margin-left: 0;
  line-height: 28px;
}

.sp-unit-select .ant-select-selector .ant-select-selection-selected-value {
  padding-right: 14px;
}

.pdn-result-curve-current {
  margin-left: 10px;
  vertical-align: middle;
  font-weight: bold;
}

.pdn-result-checkbox-group-name {
  display: inline-block;
  line-height: 20px;
}

.pdn-result-checkbox-group-name .pdn-result-checkbox-label {
  color: #000;
  vertical-align: middle;
  margin-left: 4px;
}

.pdn-result-checkbox-group .ant-checkbox+span {
  height: 26px;
  display: inline-block;
  padding-left: 3px;
}

.pdn-result-parameter-file-list .ant-checkbox-wrapper+.ant-checkbox-wrapper {
  margin-left: 0px;
}

.pdn-result-ports-list {
  padding-left: 10px;
}

.pdn-result-selector-slide {
  cursor: move;
  fill: #337ab7;
}

.pdn-result-curve-mark {
  fill: #ff0000;
}

.pdn-result-mark-text {
  font-size: 10px;
  text-anchor: middle;
}

.pdn-result-main .file-delete-icon {
  position: absolute;
  right: 8px;
  color: #40a9ff;
  margin-top: 4px;
  display: none;
  font-size: 16px;
}

.pdn-result-file-name:hover .file-download-icon,
.pdn-result-file-name:hover .import-file-download-icon,
.pdn-result-file-name:hover .file-delete-icon {
  display: inline-block;
}

.pdn-result-upload {
  position: relative;
  margin-top: 10px;
}

.result-upload-file-button {
  margin-left: 9px;
  margin-right: 12px;
  height: 26px;
  line-height: 23px;
  width: 84px;
  text-align: left;
  padding: 0 5px;
  color: #40a9ff;
  font-size: 17px;
}

/* .pdn-result-file-content {
  overflow-y: auto;
}

.pdn-result-upload-files {
  overflow-y: auto;
} */

.result-progress-bar {
  display: inline-block;
  width: 100%;
  padding-left: 10px;
  vertical-align: top;
}

.result-progress-bar .ant-progress-inner {
  background: #e0e0e0;
}

.result-import-title {
  cursor: pointer;
  padding-right: 1px;
}

.result-setting-expand-icon {
  font-size: 10px;
  padding-right: 10px;
  padding-left: 22px;
}

.pdn-result-target-switch {
  height: 18px;
  line-height: 16px;
  min-width: 34px;
}

.pdn-result-target-switch.ant-switch::after {
  width: 14px;
  height: 14px;
}

.pdn-result-parameter-file-list .ant-divider-inner-text {
  padding-left: 0px !important;
  padding-right: 23px !important;
}

.pdn-result-upload .ant-divider-inner-text {
  padding-left: 0 !important;
  padding-right: 6px !important;
}

.parameter-setting-box .ant-divider-inner-text {
  padding-left: 0px !important;
  padding-right: 30px !important;
}

.pdn-result-save-icon {
  margin-left: 9px;
  margin-right: 12px;
  padding: 0 5px;
  color: #40a9ff;
  font-size: 17px;
}

.pdn-result-save-icon-disabled {
  cursor: not-allowed !important;
  color: #909294;
  margin-left: 9px;
  margin-right: 12px;
  padding: 0 5px;
  font-size: 17px;
}

.pdn-result-show-icon {
  margin-left: 10px;
  margin-right: 14px;
  color: #40a9ff;
  font-size: 17px;
  margin-top: 2px;
}

.pdn-result-history-select-box {
  margin: 6px 6px;
}

.pdn-result-history-select {
  width: 50%;
  margin-left: 10px;
}

.pdn-result-history-panel .panel-body {
  min-height: 157px;
  overflow: auto;
  position: relative;
}

.pdn-result-history-panel {
  box-shadow: 1px 1px 8px 6px rgba(0, 0, 0, 0.15);
}

.pdn-result-history-table {
  margin: 6px 5px;
  background-color: #fff;
}

.pdn-result-component-part {
  width: 100%;
  overflow: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pdn-result-history-select-dropdown,
.pdn-result-history-dropdown {
  z-index: 100000000;
}

.pdn-result-view-icon {
  margin-left: 10px;
  margin-right: 14px;
  color: #40a9ff;
  font-size: 17px;
  vertical-align: text-bottom;
  cursor: pointer;
  margin-top: 2px;
}

.pdn-result-popover .ant-popover-inner-content {
  padding: 8px;
}

.pdn-result-name .ant-input-group-addon {
  width: 56px;
  padding: 0 8px;
}

.pdn-result-name {
  width: 200px;
}

.pdn-result-history-span {
  font-weight: bold;
  display: inline-block;
  margin-left: 6px;
}

.pdn-result-history-title {
  transition: all 0.3s;
  margin-top: 5px;
  padding-left: 10px;
  cursor: pointer;
  line-height: 24px;
}

.pdn-result-history-title:hover {
  background-color: #e0e0e0;
  transition: all 0.3s;
}

.pdn-result-history-title:hover .file-delete-icon,
.pdn-result-history-title:hover .import-file-download-icon {
  display: inline-block;
}

.pdn-result-history-files {
  padding-left: 8px;
}

.pdn-result-history-model {
  display: inline-block;
  width: 100%;
  overflow: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pdn-result-parameter-file-list .ant-divider-horizontal.ant-divider-with-text-left::before,
.parameter-setting-box .ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 0%;
}

.pdn-history-panel-content-basic,
.pdn-history-panel-content-compare {
  padding: 0 10px 10px;
  float: left;
  width: 50%;
}

.pdn-result-history-panel-main {
  width: 100%;
  background: rgb(220, 241, 253);
  height: 100%;
}

.pdn-result-menu-title {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-weight: bold;
}

.pdn-result-history-error {
  display: block;
  padding: 4px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 10px;
  font-size: 14px;
  padding-left: 10px;
  width: 200px;
}

.photo-box {
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background-color: #9191918c;
  position: absolute;
  top: 6px;
  right: 14px;
  cursor: pointer;
  border-radius: 50%;
  visibility: visible;
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); */
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.14), 1px 1px 3px 0 rgba(0, 0, 0, 0.21), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  text-align: center;
  line-height: 27px;
  transition: all 0.3s;
}

.photo-box:hover {
  background-color: #6e6e6e8c;
  transition: all 0.3s;
}

.fastpi-photo {
  width: 19px;
  margin-left: 1px;
}

.pdn-result-history-panel-main .ant-table-footer {
  padding: 8px;
}

.pdn-result-opt-icon {
  margin-left: 10px;
  color: #40a9ff;
  font-size: 16px;
  margin-top: 5px;
}

.span-history-file-name-opt {
  font-weight: bold;
}

.pdn-result-ports-list .ant-checkbox-group {
  width: 100%;
}

.pdn-port-name {
  height: 24px;
  line-height: 24px;
  margin-left: 6px;
  padding-left: 6px;
  cursor: pointer;
  transition: all 0.3s;
}

.pdn-port-name:hover {
  background: #efefef;
  transition: all 0.3s;
}

.pdn-file-expand-icon {
  padding-right: 4px;
  font-size: 10px;
  vertical-align: baseline;
}

.pdn-opt-sum-title {
  margin-left: 10px;
}

.curve-table-box,
.curve-clear-box {
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background-color: #9191918c;
  position: absolute;
  top: 44px;
  right: 14px;
  cursor: pointer;
  border-radius: 50%;
  visibility: visible;
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.14), 1px 1px 3px 0 rgba(0, 0, 0, 0.21), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  text-align: center;
  line-height: 30px;
  transition: all 0.3s;
}

.curve-clear-box {
  top: 83px;
}

.curve-table-box:hover,
.curve-clear-box:hover {
  background-color: #6e6e6e8c;
  transition: all 0.3s;
}

.fastpi-curve-table-icon {
  font-size: 17px;
  color: #fff;
}

.curve-mark-rect,
.curve-mark-hover-rect {
  width: 30px;
  height: 20px;
  display: inline-block;
  background: #d9d9d9;
}

.curve-box-close,
.curve-mark-close {
  cursor: pointer;
  font-size: 12px;
  font-weight: 100;
  color: #666;
}

.pdn-result-main .file-download-icon,
.pdn-result-main .import-file-download-icon {
  margin-left: 10px;
  color: #40a9ff;
  line-height: 20px;
  display: none;
  font-size: 17px;
  position: static;
  margin-top: 1px;
}

.pdn-result-components-table tbody>tr>td>span,
.pdn-result-components-table tbody>tr>td>.pdn-component-td>span {
  display: inline-block;
}

.pdn-result-components-table .editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.pdn-result-components-table .ant-table-column-title {
  padding-left: 4px;
}

.pdn-result-components-table .select-width .ant-select-selection {
  margin: -4px 0px;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.pdn-result-components-table .editable-row:hover .editable-cell-value-wrap {
  border-color: #d9d9d9;
  background: #e6f7ff;
}

.pdn-result-components-table tbody>tr>td .ant-select-selection--multiple {
  padding-bottom: 3px;
}

.pdn-result-components-table tbody>tr>td .ant-select-selection--multiple .ant-select-selector>ul>li {
  height: 24px;
  line-height: 24px;
}


.pdn-result-components-table .editable-cell-value-wrap .pdn-components-ignore-name {
  padding-left: 0px;
}


.pdn-result-components-table .ant-table>.ant-table-content>.aurora-table-body>table>.aurora-table-body>tr>td {
  word-break: break-word;
}

.pdn-result-components-table td {
  position: relative;
}

.pdn-result-panel-maximize {
  color: #1890ff;
  font-size: 18px;
  margin-right: 26px;
  float: right;
  margin-top: 2px;
  cursor: pointer;
}

.pdn-result-verticalAlign-middle-span {
  display: inline-block;
  width: 1px;
  vertical-align: middle;
}

.pdn-result-verticalAlign-middle-components-span {
  width: calc(100% - 2px);
  vertical-align: middle;
}

.pdn-cacl-table-edit {
  background: #ffffff;
  margin: 6px 0px;
}

.pdn-result-parameter-right .result-zoom-menu {
  top: 76px;
  width: 45px;
  right: -3px;
}

.pdn-result-parameter-right .result-zoom-menu.ant-menu-vertical .ant-menu-item {
  margin-bottom: 10px;
}