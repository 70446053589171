.waveform {
  width: 100%;
  height: 100%;
}

.waveform-clear {
  zoom: 1;
}

.waveform-clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.andes-result-main {
  position: relative;
}

.andes-waveform-svg .domain {
  display: none;
}

.andes-result-main .ant-spin-nested-loading,
.andes-result-main .ant-spin-container,
.andes-result-main {
  height: 100%;
  box-sizing: border-box;
}

.andes-waveform-component-name,
.andes-waveform-signal-name,
.andes-waveform-check-lable,
.andes-waveform-comp-name {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  float: left;
}

.andes-waveform-signal-name {
  margin-left: 4px;
}

.andes-waveform-comp-name {
  max-width: 62px;
}

.andes-waveform-checkboxgroup {
  padding-left: 28px;
  padding-top: 10px;
  width: 100%;
  max-width: 450px;
  /*   height: 56%; */
  overflow-y: auto;
  background-color: #fafafa;
}

.andes-waveform-checkbox:hover .color-select {
  background-color: #dbdbdb;
}

.andes-waveform-checkbox-lable {
  width: 100%;
  height: 20px;
  display: block;
}

.andes-waveform-tooltip {
  width: 100%;
  height: 20px;
  display: block;
}

.andes-waveform-current {
  float: left;
  margin-left: 10px;
}

.andes-waveform-left {
  height: 100%;
  float: left;
  width: 100%;
}

.andes-waveform-setting {
  border-left-color: #dcdcdc;
  border-left-width: 1px;
  border-left-style: solid;
  background-color: #ffffff;
  height: 100%;
}

.andes-waveform-left .andes-waveform-svg {
  height: 100%;
  width: 100%;
}

.andes-waveform-checkbox .ant-checkbox {
  float: left;
  margin-top: 4px;
  margin-right: 5px;
  margin-left: 5px;
}

.andes-waveform-checkbox span {
  padding: 0px;
}

.andes-waveform-checkbox:hover {
  background: #dbdbdb;
}

.andes-waveform-check-lable {
  margin-left: 4px;
  margin-right: 2px;
}

.andes-setting-input {
  width: 60%;
}

.andes-waveform-axis-title,
.andes-waveform-setting-input-lable,
.andes-waveform-display-mode-title {
  font-weight: bold;
  margin-right: 8px;
}

.andes-waveform-setting .ant-checkbox-group>.ant-checkbox-wrapper {
  margin-left: 0px;
  display: inline-block;
  width: 100%;
}

.andes-waveform-setting-box {
  padding-left: 10px;
  padding-top: 10px;
  max-width: 450px;
  overflow: auto;
}

.andes-result-driver-title {
  cursor: pointer;
  padding-right: 1px;
}

.andes-waveform-setting .dividingLine {
  height: 100%;
}

.andes-result-delay-title {
  font-weight: bold;
  margin: 10px;
}

.andes-result-main-title {
  margin-right: 84px !important;
}

.andes-result-save-div {
  position: absolute;
  top: 12px;
  right: 26px;
}

.andes-result-save-icon {
  font-size: 19px;
  color: #40a9ff;
  cursor: pointer;
}

.andes-delay-table {
  margin: 10px 20px;
  margin-bottom: 30px;
}

.andes-result-delay-select {
  width: 30%;
  margin-bottom: 10px;
}

.andes-delay-result-select-dropdown {
  z-index: 100000000;
}

.andes-waveform-setting .ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 0%;
}

.andes-waveform-setting-box .ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 5%;
}


.andes-waveform-setting .andes-result-driver .ant-divider-inner-text {
  padding-left: 6px !important;
  padding-right: 28px !important;
}


.andes-result-history-title {
  transition: all 0.3s;
  margin-top: 5px;
  padding-left: 10px;
  cursor: pointer;
  line-height: 24px;
  position: relative;
}

.andes-result-history-title:hover {
  background-color: #e0e0e0;
  transition: all 0.3s;
}

.andes-result-waveform-selection {
  overflow: auto;
  height: calc(100% - 176px);
  padding-top: 10px;
}

.andes-result-popover {
  height: 100px;
}

.andes-result-popover .ant-popover-inner-content {
  padding: 8px;
}

.andes-result-history-error {
  display: block;
  padding: 4px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 10px;
  font-size: 14px;
  padding-left: 10px;
}

.andes-result-name .ant-input-group-addon {
  width: 56px;
  padding: 0 8px;
}

.andes-result-name {
  width: 200px;
}

.andes-file-delete-icon {
  position: absolute;
  right: 8px;
  font-size: 17px;
  color: #40a9ff;
  margin-top: 3px;
  display: none;
}

.andes-result-history-title:hover .andes-file-delete-icon {
  display: inline-block;
}

.andes-result-content {
  position: relative;
}