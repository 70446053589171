.andes-ads-component-content {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  /*   height: 30px;
  line-height: 30px; */
}

.andes-ami-model-main {
  padding: 10px 14px;
  min-width: 600px;
}

.andes-ads-component-content>.andes-ads-component-content-device>span {
  padding-left: 16px;
}

.andes-ami-model-signals-content,
.andes-ads-component-content {
  display: flex;
  justify-content: space-between;
  margin: 6px 0px;
  height: 90px;
}

.andes-ami-model-signals-content-cphy {
  height: 100px;
}

.andes-ads-component-content {
  height: 28px;
}

.andes-ami-model-signals-content .ami-model-pins,
.andes-ads-component-content .ami-model-pins {
  display: flex;
  justify-content: center;
  padding: 0px 4px;
}

.andes-ads-component-content .ami-model-pins {
  height: 28px;
}

.andes-ami-model-signals-content .ami-model-signal-main,
.andes-ads-component-content .ami-model-signal-main {
  width: 160px;
  text-align: center;
  color: #005a9e;
  background: #d5d5d5;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}

.andes-ads-component-content .ami-model-signal-main {
  height: 28px;
  background: #ffffff;
  line-height: 28px;
}

.andes-ads-component-content .andes-ads-component-content-controller,
.andes-ads-component-content .andes-ads-component-content-device {
  width: 120px;
}

.andes-ads-component-content .andes-ads-component-content-controller .aurora-select {
  width: 94px;
}

.andes-ads-component-content .andes-ads-component-content-device .aurora-select {
  width: 94px;
  float: right;
}

.andes-ads-component-content .andes-ads-component-content-controller .end-to-end-ads-comp-select,
.andes-ads-component-content .andes-ads-component-content-device .end-to-end-ads-comp-select {
  width: 120px;
}

.andes-ads-component-content .ami-model-setup-content {
  height: 28px;
  line-height: 28px;
  text-align: center;
  color: #005a9e;
  display: flex;
}

.andes-ads-component-content .ami-model-setup-title-left-content {
  justify-content: left;
}

.andes-ads-component-content .ami-model-setup-title-right-content {
  justify-content: right;
}

.andes-ads-component-content .ami-model-signal-aggressors {
  height: 28px;
  line-height: 28px;
  text-align: center;
  color: #005a9e;
}

.andes-ads-component-content .ami-model-setup-content>span:not(.anticon) {
  width: 80px;
}

.andes-ads-component-content .ami-model-setup-content>.aurora-select {
  width: 60px;
}

.andes-ads-component-content .ami-model-signal-aggressors>span {
  width: 160px;
  text-align: center;
  display: block;
}

.andes-ami-model-signals-content .ami-model-signal-main .ami-model-signal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 30px;
  text-align: center;
  line-height: 30px;
}

.ami-model-pin-list {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 120px;
}

.ami-model-pin-item {
  display: flex;
  justify-content: space-between;
  height: 30px;
  width: 120px;
}

.ami-model-pin-item .ami-model-pin-content {
  width: 100px;
  height: 30px;
  background: #eee;
  position: relative;
  z-index: 1;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
}

.ami-model-pin-item .ami-model-pin-title {
  width: 70px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 28px;
  text-align: center;
  line-height: 28px;
  color: #005a9e;
  display: inline-block;
  background: #eee;
  z-index: 1;
  position: relative;
  vertical-align: middle;
  font-size: 12px;
}

.andes-ami-model-signals-content-cphy .ami-model-pin-item .ami-model-pin-title {
  width: 100%;
  text-align: center;
}

.ami-model-pin-item .ami-model-pin-type-title {
  width: 20px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  color: #005a9e;
  margin-right: 4px;
  font-size: 18px;
}

.ami-model-pin-item .ami-model-pin-type-device-title {
  margin-left: 4px;
  margin-right: 0px;
}

.ami-model-setup-content {
  display: flex;
  justify-content: space-between;
  /* height: 90px;
  line-height: 90px; */
  min-width: 206px;
}

.ami-model-direction-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 3px;
}

.ami-model-title-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* height: 90px; */
  width: 150px;
}

.ami-model-title {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  height: 28px;
  line-height: 26px;
  width: 80px;
  cursor: pointer;
  width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0px 4px;
  font-size: 12px;
}

.ami-model-file-error-title {
  color: #f30518;
  position: relative;
  padding-right: 24px;
}

.ami-model-file-error-title .aurora-file-check-icon {
  right: 4px;
}

.ami-model-title-disabled {
  cursor: not-allowed;
  background-color: #f5f5f5;
}

.ami-pin-long-line {
  height: 5px;
  background-color: #a6a6a6;
  margin-top: 14.5px;
  width: 30px;
  transform: scaleY(0.2);
}

.andes-ads-component-content .ami-model-setup-content .ads-model-type-select {
  width: 150px;
  color: #005a9e;
}

.andes-ads-component-content .ami-model-setup-content .ads-model-type-select .ant-select-selection-item {
  color: #005a9e;
}

.ami-advanced-select-item {
  min-height: 28px;
  line-height: 28px;
  display: flex;
  margin: 6px 0px;
  position: relative;
}

.ami-advanced-select-item>span {
  width: 220px;
}

.ami-advanced-select-item>.aurora-select,
.ami-advanced-select-item>.aurora-input {
  width: calc(100% - 220px);
}

.andes-ami-type-title {
  color: #3b70a7;
}

.ami-setup-footer-options {
  height: 30px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
}

.ami-setup-footer-options>div .ami-setup-left-options .anticon {
  font-size: 13px;
  vertical-align: middle;
}

.ami-setup-footer-options>div>div {
  cursor: pointer;
  color: #036fd2;
  font-size: 13px;
}

.ami-setup-footer-options>div>div:hover,
.ami-setup-footer-options>.ami-setup-middle-options:hover {
  color: #1890ff;
}

.ami-setup-footer-options>div {
  width: 33.33%;
}

.ami-setup-footer-options>.ami-setup-middle-options {
  text-align: center;
  color: #036fd2;
}

.ami-setup-footer-options>div .anticon {
  font-size: 13px;
  vertical-align: middle;
}

.ami-setup-footer-options>div>.ami-setup-left-options .anticon {
  margin-right: 4px;
}

.ami-setup-footer-options>div>.ami-setup-right-options .anticon {
  margin-left: 4px;
}

.ami-setup-footer-options>div>div span {
  vertical-align: middle;
}

.ami-setup-footer-options>div .ant-btn {
  font-size: 12px;
  color: #1890ff;
  background-color: #fff;
  border-color: #1890ff;
  height: 24px;
  line-height: 23px;
  padding: 0px 12px;
}

.ami-setup-footer-options>div .ant-btn:hover {
  color: #217ed4;
  border-color: #91d5ff;
  background: #e6f7ff;
}

.ami-setup-footer-options>div>.ami-setup-left-options {
  float: left;
}

.ami-setup-footer-options>div>.ami-setup-right-options {
  float: right;
}

.andes-ami-signal-aggressors-content {
  margin-top: 10px;
  border: none;
  background-color: #ffffff;
}

.andes-ami-signal-aggressors-content .ant-collapse-content-box {
  padding: 8px;
}

.andes-ami-signal-aggressors-content .ami-model-parameters-title {
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.andes-ami-signal-aggressors-content>.ant-collapse-item {
  border-bottom: none;
}

.andes-ami-signal-aggressors-content>.ant-collapse-item>.ant-collapse-header {
  padding: 6px 10px;
  padding-left: 20px;
  width: fit-content;
}

.andes-ami-signal-aggressors-content>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  left: 0px;
}

.andes-ami-signal-aggressors-content .ant-collapse-content {
  border-top: none;
}

.ami-advanced-sub-item {
  height: 28px;
  line-height: 28px;
  display: flex;
  margin: 6px 0px;
  position: relative;
  margin-left: 220px;
}

.ami-advanced-sub-item>span {
  width: 120px;
}

.ami-advanced-sub-item .aurora-select,
.ami-advanced-sub-item .aurora-input {
  width: calc(100% - 120px);
}

.ami-advanced-select-item .ami-model-select-checkbox {
  width: 160px;
  text-align: right;
}

.ami-advanced-select-item .ami-model-select-checkbox .ant-checkbox {
  margin-right: 26px;
}

.ami-advanced-select-item>.ami-advanced-checkbox-span {
  width: calc(100% - 160px);
}

/* .ami-model-signal-main ant-select-selector {
  margin-top: 2px;
  max-height: 56px;
  overflow: auto;
  width: 100%;
}

.ami-model-signal-main .aurora-select {
  width: 100%;
} */

.ami-model-signal-aggressors .aurora-select {
  width: 100%;
  max-height: 90px;
  min-height: 28px;
  height: auto;
}

.ami-model-signal-aggressors {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 160px;
  height: 90px;
  margin-left: 4px;
}

.ami-model-signal-aggressors .aurora-select.ant-select-multiple .ant-select-selector::after,
.ami-model-signal-aggressors .aurora-select.ant-select-multiple .ant-select-selection-wrap::after {
  line-height: 20px;
}

.ami-model-signal-aggressors .aurora-select.ant-select-multiple .ant-select-selection-overflow-item::after,
.ami-model-signal-aggressors .aurora-select.ant-select-multiple .ant-select-selector>.ant-select-selection-wrap .ant-select-selection-overflow-item {
  height: 20px;
}

.ami-model-signal-aggressors .aurora-select.ant-select-multiple .ant-select-selection-overflow-item-suffix {
  min-height: 20px;
}

.ami-model-signal-aggressors .aurora-select.ant-select-multiple .ant-select-selector>.ant-select-selection-wrap .ant-select-selection-overflow-item>.ant-select-selection-item {
  box-sizing: border-box;
  height: 18px;
  line-height: 16px;
}

.ami-model-collapse-content {
  border: none;
  background-color: #ffffff;
}

.ami-model-collapse-content .ant-collapse-content .ant-collapse-content-box {
  padding: 8px;
  padding-left: 20px;
  width: fit-content;
}

.andes-ads-model-setup-main .ami-model-collapse-content .ant-collapse-content-box,
.andes-ads-model-setup-main .ami-model-collapse-content .ant-collapse-content {
  width: 100%;
}

.andes-ads-model-setup-main .ami-model-collapse-content .ant-collapse-content-box {
  background-color: #f5f5f5;
  border-radius: 4px;
}

.andes-ads-model-setup-main .jitter-collapse-content .ant-collapse-content-box .ant-table-small,
.andes-ads-model-setup-main .ami-model-parameters-table .ant-table-small {
  background-color: #ffffff;
}

.andes-ads-model-setup-main .ads-model-collapse-main .ant-collapse-content-box {
  background-color: #eee;
}

.ami-model-collapse-content .ami-model-collapse-title {
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ami-model-collapse-content>.ant-collapse-item {
  border-bottom: none;
}

.ami-model-collapse-content>.ant-collapse-item>.ant-collapse-header {
  padding: 5px;
  width: fit-content;
}

.ami-model-collapse-content>.ant-collapse-item>.ant-collapse-header .ant-collapse-expand-icon {
  padding-inline-end: 5px;
}

.ami-model-collapse-content .ant-collapse-content {
  border-top: none;
  width: fit-content;
  margin: 0 auto;
}

.ami-model-simulation-content .ant-collapse-content,
.ami-model-simulation-content .ant-collapse-content .ant-collapse-content-box {
  width: 100%;
}

.ami-model-signal-aggressors ant-select-selector>ul>.ant-select-search,
.ami-model-signal-aggressors ant-select-selector .ant-select-selector>ul>.ant-select-search {
  width: 4px;
}

.ami-model-signal-aggressors ant-select-selector .ant-select-selection__choice {
  max-width: 98%;
  margin-right: 2px;
}

.andes-ami-model-main .ami-advanced-select-item .ant-input-wrapper .ant-input[disabled] {
  color: rgba(0, 0, 0, 0.55);
}

.andes-ads-model-setup-panel .andes-ami-model-main .aurora-select,
.andes-ads-model-setup-panel .andes-ami-model-main .aurora-input {
  font-size: 13px;
}

.andes-ads-model-setup-panel .andes-ami-model-main .aurora-select .ant-select-selector {
  font-size: 13px;
}

.andes-ads-model-setup-panel .andes-ami-model-main .aurora-select .ant-select-arrow,
.andes-ads-model-setup-panel .andes-ami-model-main .aurora-select .ant-select-clear {
  right: 4px;
}

/****  Model panel *****/
.andes-ads-model-setup-panel {
  top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.andes-ads-model-setup-panel .panel-body {
  overflow: auto;
}

.andes-ads-model-setup-main {
  padding: 8px 14px;
  min-height: 200px;
  min-width: 700px;
  font-size: 14px;
}

.ads-model-select-item {
  height: 28px;
  line-height: 28px;
  display: flex;
  margin: 6px 0px;
  position: relative;
}

.ads-model-select-item>span:not(.anticon) {
  width: 100px;
}

.ads-model-select-item>.aurora-select {
  width: calc(100% - 100px);
}

.andes-ads-model-setup-main .aurora-select,
.andes-ads-model-setup-main .aurora-input,
.andes-ads-model-setup-main .ami-model-select-item,
.andes-ads-model-setup-main .ami-advanced-sub-item>span {
  font-size: 14px;
}

.andes-ads-model-setup-main .ami-model-prbs-content .ami-model-select-item>span {
  width: 100px;
}

.andes-ads-model-setup-main .ami-model-prbs-content .ami-model-select-item .aurora-select,
.andes-ads-model-setup-main .ami-model-prbs-content .ami-model-select-item .aurora-input {
  width: calc(100% - 100px);
}

.andes-ads-model-setup-main .ami-advanced-sub-item {
  margin-left: 100px;
}

.ami-model-prbs-content .ant-collapse-content-box {
  padding-left: 20px;
}

.ami-model-jitter-title {
  font-size: 14px;
  font-weight: 500;
  height: 22px;
}

.andes-ads-model-setup-main .ads-model-select-item>.ads-model-file-select {
  width: calc(100% - 124px);
}

.ads-model-file-content-icon {
  width: 24px;
  color: #1ca4f3;
  line-height: 24px;
  font-size: 16px;
  height: 24px;
  margin-left: 5px;
}

.ads-model-apply-all-content {
  margin-top: 10px;
  width: 100%;
}

.ads-model-apply-all-content>span {
  display: inline-block;
  margin-right: 15px;
  font-size: 13px;
}

.ads-select-model-content {
  width: 100%;
  padding: 4px 10px;
  background-color: #eee;
  border-radius: 4px
}

.ads-select-model-content .ads-select-model-header {
  border-bottom: 1px dashed #8b8b8b;
  margin-bottom: 10px;
  cursor: text;
  margin: 0px;
  padding-left: 34px;
}

.ads-select-model-content .ads-select-model-selected,
.ads-select-model-content .ads-select-model-selected:hover {
  background-color: #c6e4fc;
  border-radius: 4px;
}

.ads-select-model-content .ads-select-model-header:hover {
  background-color: #eee;
}

.ads-select-model-content .ads-select-model-header .ads-select-model-pins-title,
.ads-select-model-content .ads-select-model-header .ibis-select-model-pins-title {
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 0px;
}

.ads-select-model-item {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 0px 8px;
  margin: 4px 0px;
}

.ads-select-model-item:hover {
  background-color: #ccc;
  border-radius: 4px;
}

.ads-select-model-item-title {
  width: 40%;
  height: 56px;
  line-height: 56px;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ads-select-model-name-title {
  width: 40%;
  height: 28px;
  line-height: 28px;
  font-size: 14px;
}

.ads-model-jitters-table .ant-table-small>.ant-table-content>.aurora-table-body>table>.aurora-table-body>tr>td {
  width: 50%;
}

.ads-model-jitters-table .ant-table-small>.ant-table-content>.aurora-table-body>table>.aurora-table-body>tr>td:last-child {
  padding: 4px 8px;
}

.ads-model-jitter-table-value-content {
  width: 100%;
}

.ads-model-jitter-table-value {
  width: 70%;
  display: inline-block;
}

.ads-model-jitter-input .ant-input-wrapper .ant-input-group-addon,
.ads-model-jitter-input .ant-input-wrapper .ant-input-group-addon .ant-select .ant-select-selection {
  width: 70px;
  font-size: 12px;
}

.ads-model-jitter-input .ant-input-wrapper .ant-input-group-addon .ant-select-selector {
  margin-right: 16px;
}

.ads-model-jitter-table-unit {
  width: 30%;
  padding-left: 10px;
  display: inline-block;
}

.ads-model-jitter-input {
  width: 100%;
}

.ads-model-jitter-title {
  font-size: 14px;
  font-weight: 500;
  height: 22px;
}

.ads-model-jitter-title>span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
}

.ads-model-collapse-main .ant-collapse-content .ant-collapse-content-box {
  padding-top: 0px;
}

.ads-model-jitters-table .ant-table>.ant-table-content .aurora-table-body .ant-table-row td {
  position: relative;
  font-size: 13px;
}

.ads-model-jitters-table .aurora-table-body table {
  table-layout: fixed;
}

.ads-model-checkbox {
  padding-right: 12px;
  line-height: 56px;
}

.ads-model-checkbox .ant-checkbox-inner {
  border-radius: 50%;
}

.ami-model-collapse-content .ant-collapse-content-box .ant-divider-horizontal {
  margin: 10px 0px;
  background: #ccc;
}

.ibis-model-eq-tags-input {
  margin-left: 10px;
  min-height: 28px;
}

.ibis-eq-dfe-setup-item .ibis-model-eq-tags-input {
  margin-left: 0px;
}

.ibis-eq-setup-item {
  margin-left: 26px !important;
}

.ibis-model-eq-tags {
  margin-left: 10px;
  display: flex;
}

.ibis-model-eq-tags>span {
  width: 82px;
  text-align: right;
}

.ibis-eq-dfe-setup-item.ibis-model-eq-tags>span {
  text-align: left;
}

.ami-advanced-select-item .ami-advanced-title-deepen-color {
  font-weight: 500;
  color: #000000d9;
}

.ami-advanced-input-combination-item>.aurora-input {
  margin-right: 30px;
}

/* 
.ami-advanced-input-combination-item .ami-advanced-input-combination-group>label {
  margin-right: 0;
  width: 33%;
}

.ami-advanced-input-combination-item .ami-advanced-input-combination-group>label:last-child {
  width: 67%;
}

.ami-advanced-input-combination-item .ami-advanced-input-combination-group>label:last-child span.ant-radio+* {
  padding-right: 0;
}

.ami-advanced-input-combination-item .ami-advanced-input-combination-group .ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
}

.ami-advanced-input-combination-item .ami-advanced-input-combination-group>label>span>div.aurora-select {
  margin-left: 5px;
  width: calc(100% - 136px);
}

.ami-advanced-input-combination-item .ami-advanced-input-combination-group>label>span>.aurora-input {
  margin-left: 5px;
  width: calc(100% - 85px);
} */

.ibis-model-eq-tags.ibis-model-eq-input-hide-tags {
  margin-left: 0;
}

.ami-model-collapse-content .ibis-advanced-select-item .ibis-model-eq-input-hide-tags .aurora-tags-input-content {
  margin-left: 0;
}

.ami-advanced-input-combination-item .ami-advanced-input-combination-group .ami-combination-group-input-disable.ant-input[disabled] {
  background-color: #fff;
  color: #000000a6;
}

.andes-ads-component-content .ami-model-setup-circuit-content,
.andes-ami-model-signals-content .ami-model-setup-circuit-content {
  min-width: 160px;
  justify-content: center;
  padding: 0px 5px;
}

.andes-ami-model-circuit-delete-icon {
  position: absolute;
  right: 8px;
  top: 50%;
  margin-top: -7px;
  font-size: 14px;
  display: none;
}

.andes-ami-model-circuit-delete-icon:hover {
  color: #1890ff;
}

.ami-model-setup-circuit-content .ami-model-title-content:hover .aurora-file-check-icon {
  right: 20px;
}

.ami-model-setup-circuit-content:hover .andes-ami-model-circuit-delete-icon {
  display: block;
}

.ami-model-setup-circuit-content:hover .ami-model-title-content .ami-model-title {
  padding-right: 22px;
}

.ami-model-setup-circuit-content {
  position: relative;
}

.andes-ami-model-circuit-add-icon {
  font-size: 18px;
  color: #1890ff;
  cursor: pointer;
}

.andes-ami-model-main .andes-ads-component-content .ami-model-setup-circuit-disabled-content,
.andes-ami-model-main .andes-ami-model-signals-content .ami-model-setup-circuit-disabled-content {
  min-width: 26px;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 0px;
}

.andes-ads-eye-probe-content .ami-advanced-select-item {
  margin-left: 20px;
}

.andes-ads-eye-probe-content>.andes-ads-eye-probe {
  margin-left: 0px;
}

.ami-advanced-select-item .aurora-file-check-icon {
  font-size: 13px;
  top: 16px;
  right: 20px;
}

.ami-info-circle-icon {
  position: relative;
  left: 6px;
  top: 1px;
  text-align: center;
  line-height: 36px;
}

.ami-info-circle-icon:hover {
  color: rgb(24, 144, 255);
}

.ami-model-direction-dropdown {
  min-width: 260px !important;
}

.ami-advanced-select-item>.setup-question-circle-icon {
  width: 16px;
}