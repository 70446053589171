.cascade-sider-menu-tree .ant-tree-title .aurora-project-name-input .aurora-tree-create-icon {
  visibility: visible;
  position: static;
  line-height: 24px;
  height: 22px;
}


.cascade-tootip-failed {
  color: red;
}

.cascade-default-tree-title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.ant-tree-title:hover .cascade-default-tree-title {
  width: 80%;
}

.cascade-tree-pcb-name,
.cascade-tree-channel-name,
.cascade-tree-sign-off-title-name {
  display: inline-block;
  width: calc(100% - 30px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-tree-title:hover .cascade-tree-pcb-name {
  width: calc(100% - 60px);
}

.ant-tree-title:hover .cascade-tree-channel-name {
  width: calc(100% - 90px);
}

.cascade-tree-sign-off-name {
  width: calc(100% - 26px);
}

.ant-tree-title:hover .cascade-tree-sign-off-running-name {
  width: calc(100% - 66px) !important;
}

.ant-tree-title:hover .cascade-library-title {
  width: 75%
}

.cascade-library-default-decap-title {
  width: 70%;
}

.ant-tree-title:hover .cascade-library-default-decap-title {
  width: 75%
}

.cascade-rename-icon {
  margin-right: 22px !important;
}

.cascade-sign-off-download-icon {
  margin-right: 22px;
}

.cascade-rename-icon-hasCopy {
  margin-right: 44px !important;
}

.cascade-copy-icon {
  right: 12px !important;
}

.cascade-copy-name .ant-input-group-addon {
  width: 56px;
  padding: 0 8px;
}

.cascade-copy-name {
  width: 283px;
}

.cascade-pcb-setting-icon {
  margin-top: -2px;
}

.cascade-sider-menu-tree .ant-tree-title .cascade-pcb-setting-icon {
  position: absolute;
  right: 32px;
  visibility: hidden;
}

.cascade-sider-menu-tree .ant-tree-title:hover .cascade-pcb-setting-icon {
  visibility: visible;
}

.cascade-tree-icon-location-0 {
  margin-right: 0px;
}

.cascade-tree-icon-location-1 {
  margin-right: 20px;
}

#cascade-pin-map-dialog .ant-select-dropdown {
  z-index: 2001 !important;
}

.cascade-pin-map-content {
  margin-top: 10px
}

.cascade-pin-map-add-row-icon {
  color: #0d87f7;
  font-size: 16px;
  margin-left: 14px;
  cursor: pointer;
  vertical-align: middle;
}


.cascade-pin-map-content-table .ant-table-small>.ant-table-content>.aurora-table-body .editable-cell-value-wrap {
  text-overflow: unset;
  overflow: visible;
  white-space: unset;
  line-height: auto;
  height: auto;
  min-height: 28px;
  position: relative;
}


.cascade-pin-map-content-delete-icon {
  position: absolute;
  right: -30px;
  margin-top: -5px;
  top: 48%;
  color: #ccc;
  cursor: pointer;
}

.cascade-pin-map-content-delete-icon:hover {
  color: #3da4fa;
}

.cascade-sider-menu-tree .ant-tree-title .aurora-tree-import-export-library-icon,
.cascade-sider-menu-tree .ant-tree-title .aurora-tree-import-export-project-icon {
  position: absolute;
  right: 21px;
}

.cascade-sider-menu-tree .ant-tree-title:hover .aurora-tree-import-export-project-icon {
  right: 48px;
}

.cascade-copy-error {
  display: block;
  padding: 4px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 10px;
  font-size: 14px;
  padding-left: 10px;
}

.cascade-sider-menu-tree .ant-tree-title .aurora-tree-layout-check-loading-icon {
  right: 14px;
  visibility: visible;
}

.cascade-sider-menu-tree .ant-tree-title:hover .aurora-tree-layout-check-loading-icon {
  right: 52px;
}

.cascade-sider-menu-tree .ant-tree-title .aurora-tree-import-export-loading-icon {
  right: 14px;
  visibility: visible;
  text-align: center;
  line-height: 30px;
  font-size: 15px;
  color: #1890ff;
  float: right;
}

.cascade-sider-menu-tree .ant-tree-title .aurora-tree-import-export-library-loading,
.cascade-sider-menu-tree .ant-tree-title .aurora-tree-import-export-project-loading {
  position: absolute;
  right: 14px;
  margin-top: calc(5% - 9px);
}

.cascade-sider-menu-tree .ant-tree-title:hover .aurora-tree-import-export-project-loading {
  right: 42px;
}


.cascade-tree-tag {
  position: absolute;
  top: 1px;
  right: 4px;
  border-radius: 4px;
  font-size: 12px;
  transform: scale(0.9, 0.9);
}

.cascade-sider-menu-tree .ant-tree-title:hover .cascade-tree-tag {
  display: none;
}

.cascade-sider-menu-tree .my-tree .my-tree-switch-icon {
  margin-top: -5px;
}