.pcb-upload-progress-bar {
  width: 100%;
  padding: 10px 0px;
}

.pcb-upload-progress-bar .ant-progress-outer {
  padding-right: 150px;
}

.pcb-upload-progress-bar .ant-progress-text {
  position: absolute;
  right: 0px;
  width: 140px;
  height: 22px;
  line-height: 22px;
  font-weight: bold;
}

.upload-PCB-content {
  padding: 10px 16px;
  min-height: 100px;
  height: fit-content;
  max-width: 800px;
  max-height: 600px;
}

.upload-pcb-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  margin: 0 auto;
}

.upload-pcb-success-icon {
  color: #52c419;
  font-size: 20px !important;
}

.pcb-name-main {
  width: calc(100% - 26px);
}

.upload-pcb-vendor-item {
  width: 100%;
  height: 28px;
  line-height: 28px;
  margin: 6 0px;
  display: flex;
  justify-content: center;
}

.upload-pcb-vendor-item>span {
  width: 116px;
  height: 28px;
  line-height: 28px;
}

.upload-pcb-vendor-item>.aurora-select {
  width: calc(100% - 116px);
  line-height: 28px;
}

.upload-pcb-list {
  display: block;
  width: 100%;
  height: 120%;
  max-height: 320px;
  min-height: 10px;
  margin: 4px;
  background: #f9f9fa;
  padding: 0;
  padding-top: 10px;
  overflow-y: auto;
}

.upload-pcb-item {
  width: calc(100% - 12px);
  height: 60px;
  background: #fff;
  border-radius: 4px;
  margin: 6px;
  box-shadow: 0 0 8px 0 rgba(12, 12, 13, 0.1);
  border: 0 solid #dae1e7;
  display: flex;
}

.pcb-name-span {
  display: inline-block;
  margin-left: 4px;
  line-height: 60px;
  font-size: 16px;
  font-weight: bold;
  opacity: 0.85;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 38px);
  color: #000000a6;
}

.upload-pcb-del-icon {
  float: right;
  font-size: 16px;
  font-weight: bold;
  line-height: 62px;
  margin-right: 8px;
  margin-left: 2px;
  cursor: pointer;
}

.upload-pcb-del-icon:hover {
  opacity: 0.75;
}

.upload-pcb-file-icon {
  float: left;
  margin-left: 10px;
  line-height: 60px;
  font-size: 24px;
  font-weight: bold;
  margin-top: 18px;
}

.pcb-file-name-content {
  cursor: pointer;
  color: #40a9ff;
}

.pcb-file-name-input {
  height: 26px;
  width: 72%;
}

.upload-pcb-box {
  display: block;
  margin: 0px;
  margin-top: 10px;
}

.upload-pcb-box .ant-upload.ant-upload-drag p.ant-upload-text {
  font-size: 14px;
}

.upload-pcb-file-button {
  margin: 6px;
}

.upload-pcb-button {
  width: 100%;
}

.pcb-file-name-error-content {
  color: #cb2431;
}

.upload-pcb-sub-option {
  margin-left: 10px;
}