.cascade-top-template-create-panel {
  margin-top: 60px;
  box-shadow: rgb(76 77 78 / 14%) 0px -2px 7px 8px, rgb(0 0 0 / 10%) 0px 0px 10px 3px;
}

.cascade-top-template-create-panel-name {
  font-size: 16px;
  font-weight: 500;
}

.cascade-top-template-create-panel-main {
  margin: 15px 20px;
}

.template-create-item,
.template-button-item {
  font-size: 14px;
  line-height: 28px;
  margin: 5px 0px;
  display: flex;
}

.template-create-item>span {
  width: 116px;
}

.template-create-item>.aurora-select {
  margin-left: 10px;
  width: calc(100% - 90px)
}

.template-button-item {
  justify-content: right;
}

.template-button-item .ant-btn {
  color: #2596f2;
  background-color: #fff;
  border-color: #2596f2;
}

.template-button-item .ant-btn:hover,
.template-button-item .ant-btn:focus {
  color: #40a9ff;
  border-color: #40a9ff;
}