.sierra-comp-pin-map-content {
  padding: 10px 14px;
}

.sierra-comp-pin-map-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.component-pmic-pin-library-cell .component-pin-map-value {
  width: 100%;
}

.sierra-components-pin-map-columns {
  width: 100%;
  height: 100%;
}

.sierra-components-pin-map-columns-select-dropdown {
  z-index: 30000;
}

.sierra-comp-pin-map-table tbody>tr>td:last-child {
  position: relative;
}

.sierra-comp-pin-map-table .delete-icon {
  position: absolute;
  right: 2px;
  top: calc(50% - 6px);
  display: none;
}

.sierra-component-pcb-repeater-table .component-pmic-pin-part-cell>.add-icon,
.sierra-component-pcb-repeater-table .component-pmic-pin-part-cell>.delete-icon {
  display: none;
}

.sierra-component-pcb-repeater-table .component-pmic-pin-part-cell>div {
  margin-right: 16px;
}

.sierra-component-pcb-repeater-table tbody>tr:hover .component-pmic-pin-part-cell>.delete-icon,
.sierra-component-pcb-repeater-table tbody>tr:hover .component-pmic-pin-part-cell>.add-icon,
.sierra-comp-pin-map-table tbody>tr:hover .delete-icon {
  display: block;
}

.sierra-comp-pin-map-table .delete-icon:hover {
  color: #1890ff;
}

.sierra-comp-pin-map-table tbody>tr>td:last-child .editable-cell-value-wrap {
  width: calc(100% - 6px);
}

.sierra-component-pcb-repeater-table tbody>tr>td:nth-child(3) {
  max-height: 80px;
  overflow: auto;
}

.sierra-comp-pin-map-add-button {
  height: 24px;
  font-size: 13px;
  /*  margin: 8px 0px; */
}

.sierra-component-pcb-repeater-table {
  border: 1px solid #a8c4e6;
  padding: 10px;
  border-radius: 3px;
  position: relative;
}

.sierra-component-pcb-repeater-table tbody>tr>td {
  position: relative;
}

.sierra-comp-repeater-part-switch-icon {
  color: #3da4fa;
  margin-left: 10px;
  font-size: 15px;
  vertical-align: middle;
}

.sierra-comp-repeater-part-title {
  vertical-align: middle;
}