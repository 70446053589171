.pdn-top-option-panel {
  margin-top: 60px;
}

.pdn-top-option-panel-name {
  font-size: 16px;
  font-weight: bold;
}

.pdn-top-option-panel-main {
  margin: 20px;
}

.pdn-top-extraction-panel-main {
  min-width: 650px !important;
  min-height: 440px;
}

.pdn-top-vrm-panel-main {
  min-width: 520px;
}

.pdn-top-opt-panel-main {
  min-width: 567px;
}

.pdn-top-option-panel-main .ant-divider-horizontal {
  margin: 10px 0px;
}

.pdn-top-option-panel-title {
  padding-left: 4px;
  font-weight: 600;
  margin-bottom: 4px;
}

.pdn-top-option-panel-content {
  padding: 0 10px;
  margin-bottom: 6px;
}

.pdn-top-option-panel-content-body,
.pdn-top-option-panel-content .comp-extraction-option-content-body {
  font-size: 14px;
  vertical-align: middle;
  display: inline-block;
  width: 65%;
  line-height: 28px;
}

.pdn-top-option-panel-content .comp-extraction-option-content-body,
.pdn-top-option-panel-content-extraction-body {
  width: 55%;
}

.pdn-top-option-panel-content-sub-body {
  margin-left: 10px
}

.pdn-top-option-panel-content-title {
  font-weight: bold;
}

.pdn-top-option-panel-content.comp-extraction-option-content .comp-extraction-option-content-input,
.pdn-top-option-panel-content .pdn-top-option-panel-content-input.pdn-top-option-panel-content-extraction-input {
  width: 45%;
}

.pdn-top-option-panel-content .pdn-top-option-panel-content-input,
.pdn-top-option-panel-content .comp-extraction-option-content-input {
  display: inline-block;
  width: 35%;
  vertical-align: middle;
  height: 28px;
  line-height: 28px;
}

.pdn-top-option-panel-content-input {
  font-size: 13px;
}

.pdn-top-option-panel-content-input .ant-input {
  height: 28px;
  line-height: 28px;
  font-size: 13px;
}

.pdn-top-option-panel-content-input .ant-input-group-addon,
.pdn-top-option-panel-content-input .ant-input-group-addon .ant-select-selection-selected-value {
  font-size: 13px;
}

.pdn-top-option-panel-content .comp-extraction-option-content-core-input.comp-extraction-option-content-input {
  width: calc(22% - 23px);
}

.pdn-top-option-panel-error-msg {
  display: block;
  padding: 8px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 10px;
}

.pdn-top-option-panel-sub-box {
  display: inline-block;
  margin-left: 20px;
  margin-right: 6px;
  width: 8px;
  height: 8px;
  background: cornflowerblue;
  border-radius: 4px;
}

.pdn-top-option-panel-sub-content-body {
  font-size: 14px;
  vertical-align: middle;
  display: inline-block;
  width: 65%;
  text-align: 20px;
  font-style: italic;
}

.pdn-top-option-panel-sub-content-extraction-body {
  width: 55%
}

.PDN-top-sus-report-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.PDN-top-sus-report-panel .top-sus-report-content {
  padding: 10px 16px;
  border-radius: 6px 6px 0 0;
  height: 100%;
  min-width: 500px;
}

.PDN-top-sus-report-panel button.panel-modal-minimize-button {
  right: 34px;
}

label.PDN-report-item-title {
  font-weight: 500;
  line-height: 28px;
  width: 84px;
  display: inline-block;
  font-size: 13px;
  position: relative;
}

.PDN-report-select {
  width: calc(100% - 84px);
  font-size: 13px;
  margin: 6px 0;
}

.PDN-report-select-dropdown {
  z-index: 1000000;
}

.panel-ant-modal-header.panel-modal-header {
  padding-left: 14px !important;
}

.PDN-report-select-dropdown .ant-select-dropdown-menu-item {
  padding: 4px 12px;
  font-size: 13px;
}

.PDN-thumbnail {
  width: calc(100% - 92px);
  display: inline-block;
  vertical-align: middle;
  margin: 6px 0;
  margin-left: 10px;
}

.PDN-top-report-message-main {
  margin-top: 17px;
  position: relative;
  min-height: 27px;
}

.PDN-report-download-button {
  position: absolute;
  bottom: 2px;
  right: 0px;
}

.PDN-report-download-btn {
  width: 80px;
  height: 26px;
  padding: 0px;
  font-size: 13px;
  line-height: 24px;
}

.PDN-top-report-message-main .PDN-report-download-message.download-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}

.PDN-top-report-message-main .PDN-report-download-message.download-error {
  border: 1px solid #ffa39e;
  background-color: #fff1f0;
}

.PDN-report-download-message {
  line-height: 28px;
  text-indent: 10px;
  border-radius: 4px;
  font-size: 14px;
  max-width: calc(100% - 90px);
  margin-bottom: 10px;
  padding: 0 8px;
}

.PDN-top-sus-item {
  margin: 6px 6px 6px 0px;
  float: left;
  text-align: center;
  cursor: pointer;
  background-color: #f3f2f1;
  transition: all 0.3s;
  color: #005a9e;
}

.PDN-top-sus-item:hover {
  background-color: #e0e0e0;
  transition: all 0.3s;
}

.PDN-top-sus-report-item {
  position: relative;
  margin-right: 6px;
}

.PDN-top-sus-report-item::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  -webkit-animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  content: '';
}

.PDN-top-sus-report-progress {
  width: 0px;
  height: 100%;
  background-color: #82c2fd;
  float: left;
  position: absolute;
  overflow: visible;
  top: 0;
  left: 0;
  z-index: 1;
}

.PDN-report-progress-span {
  height: 33px;
  display: block;
  float: left;
  line-height: 33px;
}

.PDN-top-sus-report-icon {
  z-index: 2;
}

.pdn-top-bar-item-hide {
  z-index: 2;
}

.simulate-content-select {
  width: 35%;
}

.simulate-content-select {
  height: 28px;
}

.simulate-content-select .ant-select-selector {
  line-height: 26px;
}

.PDN-simulate-select-dropdown-menu {
  z-index: 10000;
}

.fastpi-pdn-option-input-addonAfter .ant-input-group-addon {
  width: 65px;
}

.fastpi-simulate-select-dropdown .ant-select-dropdown-menu-item-selected {
  font-weight: 500;
}

.design-failed-Option {
  color: #ff0000 !important;
}

.pdn-sim-dropdown {
  padding: 0 0;
}

.pdn-sim-dropdown-checkbox {
  margin-right: 18px;
  margin-top: -5px;
}

.ant-dropdown .pdn-sim-dropdown-run.ant-dropdown-menu-item {
  text-align: center;
  background: #e6f7ff;
  color: #1890ff;
  margin-top: 5px;
  cursor: pointer;
}

.ant-dropdown .pdn-sim-dropdown-run.ant-dropdown-menu-item>span {
  justify-content: center;
}

.ant-dropdown .ant-dropdown-menu-item.pdn-sim-dropdown-run:hover {
  background: #1890ff;
  color: #ffffff !important;
}

.ant-dropdown .pdn-sim-dropdown-run-disabled.ant-dropdown-menu-item,
.ant-dropdown .ant-dropdown-menu-item.pdn-sim-dropdown-run-disabled:hover {
  background: #eeeeee;
  color: #555555 !important;
  cursor: not-allowed;
}

.pdn-extraction-panel-sub-span {
  padding-left: 20px;
}

.fastpi-extraction-option-panel {
  box-shadow: 0 -2px 7px 8px rgb(76 77 78 / 14%), 0 0 10px 3px rgb(0 0 0 / 10%);
}

.pdn-top-option-panel-content .fastpi-extraction-option-clipping-input {
  width: calc(45% - 134px);
}

.fastpi-extraction-option-panel .preview-button,
.fastpi-extraction-option-panel .preview-button-focus {
  height: 28px;
  float: right;
  width: 80px;
  margin-right: 0px;
}

.fastpi-extraction-option-panel .fastpi-clipping-btn {
  margin-right: 16px;
}

.pdn-top-option-panel-engine-option {
  width: 55%;
}

.fastpi-top-option-panel-advanced-body {
  color: #8c8c8c;
  cursor: pointer;
}

.fastpi-top-option-panel-advanced-body .advanced-arrow-icon {
  font-size: 10px;
  padding-right: 5px;
}

.fastpi-extraction-panel-next-sub-span {
  padding-left: 40px;
}

.pdn-top-option-panel-content .fastpi-option-advanced-mix-input {
  width: calc(45% - 52px) !important;
  margin-left: 24px;
}