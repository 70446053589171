li {
  list-style: none;
}

.clear {
  zoom: 1;
}

.clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.andes-footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: #f9f9f9;
  border-top: 1px solid #e2dfdf;
  width: 100%;
  z-index: 20;
  box-sizing: border-box;
}

/* my andes monitor */

.my-andes-monitor {
  overflow: auto;
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  top: 44px;
  left: 102px;
  right: 14px;
  bottom: 14px;
}

.my-andes-monitor-ccc {
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  top: 44px;
  left: 102px;
  right: 14px;
  bottom: 14px;
}

.my-andes-monitor-title {
  font-weight: bold;
  line-height: 44px;
  padding-left: 20px;
  float: left;
}

.my-andes-middle {
  position: relative;
  height: 44px;
  overflow: hidden;
  padding-right: 20px;
}

.my-andes-monitor-progress {
  position: absolute;
  top: 0px;
  height: 44px;
  line-height: 44px;
  width: 100%;
}

.monitor-upload-progress-bar {
  width: 100%;
}

.monitor-upload-progress-bar .ant-progress-text {
  font-weight: bold;
}

.my-andes-download-btn {
  position: relative;
  z-index: 20;
}

.my-andes-debug-btn {
  position: relative;
  z-index: 20;
}

.design-msg-style {
  color: #f5222d;
  margin: 0;
  text-align: center;
}

.andes-simulation-holygrail {
  position: absolute;
  right: 100px;
  left: 0;
  box-sizing: border-box;
}

.andes-simulation-title {
  width: 100%;
  position: relative;
  height: 44px;
}

.andes-simulation-btn {
  position: relative;
  float: right;
  height: 44px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  width: 80px;
  margin-right: 12px;
}

.andes-simulation-msg {
  position: relative;
  float: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  min-width: 160px;
  text-align: center;
  margin-left: 16px;
  height: 44px;
}

.andes-simulation-span {
  position: relative;
  float: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  min-width: 80px;
}

.andes-workflow-msg {
  position: relative;
  float: left;
  height: 44px;
  line-height: 42px;
  text-align: center;
  min-width: 80px;
  margin-left: 20px;
}

.workflow-msg {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 28px;
  font-size: 16px;
}

.andes-simulation-project {
  margin-right: 10px;
}

.span-msg, .span-msg-project {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 40px;
}

.andes-simulation-middle {
  position: relative;
  float: left;
  height: 44px;
  overflow: hidden;
  padding-right: 20px;
  box-sizing: border-box;
  float: right;
}

.andes-simulation-progress {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 40px;
  width: 100%;
  line-height: 44px;
  box-sizing: border-box;
}

.simulation-progress-bar {
  width: 100%;
}

.andes-simulation-button, .andes-upload-button, .andes-cancel-button, .andes-kill-button {
  float: right;
  line-height: 44px;
  height: 44px;
  font-size: 18px;
  color: #1890ff;
  position: relative;
  z-index: 20;
  cursor: pointer;
  margin-left: 16px;
  box-sizing: border-box;
}

.andes-cancel-button {
  line-height: 43px;
}

.andes-kill-button {
  line-height: 45px;
  font-size: 19px;
  margin-left: 18px;
}

.andes-simulation-progress .ant-progress-outer {
  width: 94%;
}

.andes-simulation-progress .ant-progress-outer .ant-progress-inner {
  background-color: #fff;
}

.icon-tooltip .ant-tooltip-inner {
  color: #1890ff;
  background-color: #fff;
}

.icon-tooltip .ant-tooltip-arrow::before {
  background-color: #fffdfd;
}

.span-msg {
  text-indent: 8px;
}

.andes-monitor-ul {
  margin: 0;
  padding: 0;
}

.andes-button {
  font-size: 18px;
}

.andes-button-cancel {
  transform: rotate(90deg);
}

.debug-mes-span {
  display: block;
}

.closed-message {
  display: block;
}

.andes-simulation-btn .icon-cancel9 {
  font-size: 20px;
  line-height: 44px;
  color: #1890ff;
}

.andes-button-icon {
  font-size: 22px;
}

.andes-simulation-title .span-msg-icon {
  font-size: 18px;
  color: #1890ff;
}

.andes-simulation-title .span-msg-icon-div {
  padding-left: 12px;
  margin-left: 4px;
  width: 100%;
  margin-top: 12px;
}

.andes-span-msg-tooltip {
  display: block;
  text-indent: 8px;
}

.andes-update-pcb-box {
  padding: 10px 20px;
  font-size: 14px;
  line-height: 22px;
}

.andes-update-pcb-span {
  padding-left: 10px;
  display: block;
}