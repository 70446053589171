.designInfo .ant-tabs-bar {
  margin: 0px;
}

.properties-title {
  background: #1890ff;
  color: #ffffff;
}

.properties-table .ant-table>.ant-table-content>.aurora-table-body {
  margin: 0px;
}

.properties-table .aurora-table-body>tr:hover>td {
  background: rgba(230, 247, 255, 0.784) ! important;
}

.properties-table .aurora-table-body>tr:hover .properties-title {
  background: #1890ff ! important;
}

.designInfo {
  height: 300px;
}

.detail-info {
  padding: 5px 10px
}

.detail-info div {
  line-height: 21px
}

.aurora-menu-panel-net,
.aurora-menu-panel-component,
.aurora-menu-panel-padstack,
.aurora-menu-panel-layout-errors {
  min-width: 256px;
}

.aurora-menu-panel-net .panel-modal-header,
.aurora-menu-panel-component .panel-modal-header {
  border-bottom: none;
}

.net-virtual-header .ant-table-bordered {
  border-right: 0px;
}

.net-virtual-header .ant-table-small {
  border-radius: 0px;
}

.net-virtual-body {
  margin-top: -5px;
}

.net-virtual-body .ant-table-small {
  border-radius: 0px;
}

.component-virtual-header .ant-table-bordered {
  border-right: 0px;
}

.component-virtual-header .ant-table-small {
  border-radius: 0px;
}

.component-virtual-body {
  margin-top: -5px;
}

.component-virtual-body .ant-table-small {
  border-radius: 0px;
}

.components-name {
  cursor: pointer;
}

.nets-name {
  cursor: pointer;
}

.layout-padstack-content {
  margin: 15px 10px;
}

.layout-padstack-content-right {
  text-align: right;
}

.layout-padstack-content .plating-thickness-title {
  width: 190px;
  display: inline-block;
  text-align: left;
  line-height: 24px;
}

.layout-padstack-content .plating-thickness-input {
  width: calc(22% - 5px);
  line-height: 24px;
}

.layout-padstack-content-error {
  width: 100%;
  padding-left: 10px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  line-height: 28px;
}

.layout-padstack-via-name {
  cursor: pointer;
}

.layout-padstack-panel .panel-body {
  overflow: auto !important;
}

.pad-stack-hole-size-unit {
  z-index: 9999;
}

.pad-stack-hole-size-body {
  margin: 10px;
}

.pad-stack-hole-size-content:not(:last-child) {
  margin-bottom: 10px;
}

.pad-stack-hole-size-content>.pad-stack-hole-size-title {
  display: inline-block;
  width: 90px;
  line-height: 24px;
}

.pad-stack-hole-size-content>.pad-stack-hole-size-input {
  width: calc(100% - 100px);
}

.layout-padstack-via-selected {
  background-color: rgb(176, 226, 255);
}

#pad-stack-dialog>div {
  z-index: 2001;
}

.layout-display-item-check-box {
  height: 16px;
  line-height: 16px;
}