#himalayas-top-bar {
  height: 45px;
  padding-left: 10px;
  background-color: #f3f2f1;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 300;
}

.himalayas-top-bar-left-item {
  display: flex;
  align-items: center;
}

.himalayas-top-bar-right-item {
  position: absolute;
  right: 0;
  display: flex;
}

.himalayas-top-bar-item {
  margin: 6px 6px 6px 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: #005a9e;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 33px;
}

.himalayas-top-bar-item:hover {
  background-color: #e0e0e0;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.himalayas-top-bar-item .anticon-play-circle,
.himalayas-top-bar-item .anticon-loading,
.himalayas-top-bar-setting {
  margin-right: 8px;
  font-size: 16px;
  margin-top: -2px;
}

.himalayas-top-bar-item-disabled {
  color: #797979 !important;
  cursor: not-allowed !important;
}

.himalayas-top-bar-setup {
  display: flex;
  align-items: center;
  margin: 6px 0;
  padding: 0 14px;
}

.himalayas-top-bar-setup i.iconfont.icon-Debug {
  margin-right: 8px;
}

#himalayas-top-bar.hide .himalayas-top-bar-item-hide {
  display: none;
}

#himalayas-top-bar.hide .himalayas-top-bar-icon,
#himalayas-top-bar.hide .top-bar-report-icon {
  margin-right: 0px;
}

#himalayas-top-bar.hide .himalayas-top-bar-result-icon {
  margin-left: 0px;
}

.himalayas-top-bar-result-item {
  margin: 6px 6px 6px 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 33px;
}

.himalayas-top-bar-result-vertical-line {
  height: 16px;
  background-color: #c7c7c7;
  width: 1px;
  margin-right: 28px;
}

.himalayas-top-bar-item-disabled .himalayas-top-bar-result-title,
.himalayas-top-bar-item-disabled .himalayas-top-bar-result-title:hover {
  color: #797979 !important;
  border: 1px solid #797979;
}

.himalayas-top-bar-result-title {
  color: #ff610b;
  border: 1px solid #ff610b;
  border-radius: 4px;
  padding: 2px 10px;
  display: inline-block;
}

.himalayas-top-bar-result-title .himalayas-top-bar-result-icon {
  margin-left: 8px;
  font-size: 15px;
}

.himalayas-top-bar-item-disabled {
  color: #797979 !important;
  cursor: not-allowed !important;
}

.himalayas-top-bar-item-disabled .himalayas-top-bar-result-title,
.himalayas-top-bar-item-disabled .himalayas-top-bar-result-title:hover {
  color: #797979 !important;
  border: 1px solid #797979;
}

/* debug panel */
.himalayas-debug-panel .aurora-debug-download-content .aurora-debug-item-content {
  width: 100%;
}

/* run menu */
.-sim-dropdown {
  padding: 0 0;
}

.himalayas-run-dropdown-checkbox {
  margin-right: 18px;
}

.himalayas-run-menu-content {
 padding-bottom: 0px;
}

.himalayas-run-menu-content .himalayas-run-dropdown-run {
  text-align: center;
  background: #e6f7ff;
  color: #1890ff;
  margin-top: 5px;
  cursor: pointer;
  height: 32px;
  line-height: 32px;
}

.himalayas-run-menu-content .himalayas-run-dropdown-run:hover {
  background: #1890ff;
  color: #ffffff !important;
}

.himalayas-run-menu-content .himalayas-run-dropdown-run-disabled,
.himalayas-run-menu-content .himalayas-run-dropdown-run-disabled:hover {
  background: #eeeeee !important;
  color: #555555 !important;
  cursor: not-allowed;
}

#himalayas-run-dropdown {
  box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  padding: 0
}

/* option panel **/
.himalayas-top-option-panel {
  margin-top: 60px;
  box-shadow: rgb(76 77 78 / 14%) 0px -2px 7px 8px, rgb(0 0 0 / 10%) 0px 0px 10px 3px;
}

.himalayas-top-option-panel-name {
  font-size: 16px;
  font-weight: 500;
}

.himalayas-top-option-panel-title {
  padding-left: 4px;
  font-weight: 500;
  margin-bottom: 4px;
}

.himalayas-top-option-panel-content {
  padding: 0 10px;
  margin-bottom: 6px;
}

.himalayas-top-option-panel-main {
  margin: 20px;
}

.himalayas-top-option-panel-main .ant-divider-horizontal {
  margin: 10px 0px;
}

.himalayas-top-option-panel-content-body {
  font-size: 14px;
  vertical-align: middle;
  display: inline-block;
  width: 45%;
  line-height: 28px;
}

.himalayas-top-option-panel-sub-span {
  padding-left: 20px;
}

.himalayas-top-option-panel-error-msg {
  display: block;
  padding: 8px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 10px;
}

.himalayas-top-option-panel-content .himalayas-top-option-panel-content-input {
  display: inline-block;
  width: 55%;
  vertical-align: middle;
  height: 28px;
  line-height: 28px;
}

.himalayas-top-option-panel-content-input {
  font-size: 13px;
}

.himalayas-top-option-panel-content-input .ant-input {
  height: 28px;
  line-height: 28px;
  font-size: 13px;
}

.himalayas-top-option-panel-content-input .ant-input-group-addon,
.himalayas-top-option-panel-content-input .ant-input-group-addon .ant-select-selection-selected-value {
  font-size: 13px;
}

.himalayas-top-option-panel-apply {
  text-align: left;
  padding-left: 15px;
}

.himalayas-top-option-panel-apply-title {
  padding-right: 10px;
}