.material-menu-panel {
  min-width: 200px;
  overflow: auto;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.material-menu-panel .panel-modal-header {
  padding-left: 14px !important;
}

.material-menu-panel .material-menu-content {
  padding: 10px;
  padding-top: 2px;
}

.material-menu-content .material-menu-item {
  height: 28px;
  line-height: 28px;
  cursor: pointer;
  padding: 0px 8px;
  margin: 2px 0px;
  position: relative;
}

.material-menu-content .material-menu-item>span:not(.anticon) {
  width: calc(100% - 10px);
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.material-menu-content .material-menu-item:hover {
  color: #1890ff;
  background-color: #e6f7ff;
}

.material-menu-content .material-menu-item-selected,
.material-menu-content .material-menu-item-selected:hover {
  color: #1890ff;
  background-color: #bae7ff;
}

.material-menu-content .material-menu-type-title {
  height: 28px;
  line-height: 28px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.85);
}

.material-menu-content .material-menu-type-title .anticon {
  margin-right: 10px;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.85);
  vertical-align: middle;
}

.material-menu-content .material-menu-type-title>span {
  vertical-align: middle;
}

.material-menu-content .material-menu-type-content {
  margin-left: 10px;
}

.material-menu-item-delete-icon {
  position: absolute;
  right: 3px;
  top: 7px;
  font-size: 13px;
}

.material-menu-content .material-menu-item:hover .material-menu-item-delete-icon {
  color: #1890ff;
}

.material-add {
  color: #1890ff;
  margin-left: 13px;
  font-size: 15px;
}