.result-generate-btn {
  border: 1px solid #1890ff;
  color: #1890ff;
  width: 100%;
  padding: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.result-generate-btn-progress {
  background: #82c2fd;
  position: absolute;
  left: 0px;
  bottom: 0px;
  top: 0px;
  right: 100%;
  transition: 0.3s;
}

.result-generate-btn-span {
  position: relative;
  line-height: 30px;
}