/* === debug panel === */
.aurora-debug-panel {
  margin-top: 100px;
  overflow: hidden;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.aurora-debug-panel .panel-modal-header {
  padding-left: 14px !important;
}

.aurora-debug-title {
  font-size: 16px;
  font-weight: 500;
}

.aurora-debug-panel .aurora-debug-content {
  margin: 10px;
  position: relative;
  border: 1px solid #f3f2f1;
  border-radius: 6px 6px 0px 0px;
  min-height: min-content;
  min-width: 740px;
}

.aurora-debug-menu {
  height: 32px;
  background: #f3f2f1;
}

.aurora-debug-menu-item,
.aurora-debug-menu-item-selected {
  float: left;
  line-height: 23px;
  padding: 4px 16px;
  cursor: pointer;
  border-radius: 6px 6px 0px 0px;
}

.aurora-debug-menu-item:hover {
  background-color: #edebe9;
}

.aurora-debug-menu-item-selected {
  background-color: #c7e0f4;
}

.aurora-debug-menu-item .debug-menu-item-span,
.aurora-debug-menu-item-selected .debug-menu-item-span {
  font-size: 14px;
  color: #005a9e;
}

.aurora-debug-select {
  font-size: 13px;
  height: 24px;
  display: inline-block;
  margin-left: 10px;
  color: #005a9e;
}

.aurora-debug-select-simulation-width {
  max-width: 160px;
}

.aurora-debug-select-sub-simulation-width {
  max-width: 106px;
}

.aurora-debug-select {
  height: 24px;
}

.aurora-debug-select .ant-select-selector {
  line-height: 22px;
}

.aurora-debug-select .ant-select-arrow {
  right: 6px;
  color: #005a9e;
}

.aurora-debug-select-down-drop {
  z-index: 10000000;
  max-width: 350px;
}

.aurora-debug-select-down-drop .ant-select-dropdown-menu-item {
  font-size: 13px;
  color: #005a9e;
}

.aurora-debug-select-down-drop .ant-select-dropdown-menu-item-selected {
  background-color: #e6f7ff;
}

.aurora-debug-list-content {
  margin-top: 6px;
  float: left;
  width: 100%;
}

.aurora-debug-list-content .debug-download-checkbox-group-box,
.aurora-debug-list-content .debug-download-checkbox-box {
  display: flex;
  width: fit-content;
  flex-direction: column;
}

.aurora-debug-list-content .debug-download-checkbox-group {
  padding: 5px 10px;
}

.aurora-debug-list-content .debug-download-checkbox-group .debug-download-checkbox-span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 644px;
  display: inline-block;
  vertical-align: middle;
}

.aurora-debug-list-content .debug-download-checkbox-group-content {
  margin-left: 12px;
}

.aurora-debug-list-content .debug-download-checkbox-group-content .debug-download-checkbox-group {
  padding-left: 10px;
}

.aurora-debug-list-content .debug-download-checkbox-group-content .anticon {
  font-size: 10px;
}

.aurora-debug-list-content .padding-left-34 {
  padding-left: 34px;
}

.aurora-debug-list-content .debug-download-checkbox-children-group {
  padding: 5px 10px;
  padding-left: 54px;
}

.aurora-debug-list-content .debug-select-list-item {
  padding: 5px 10px;
  margin-left: 4px;
  width: fit-content;
}

.aurora-debug-list-content .ant-checkbox-wrapper+.ant-checkbox-wrapper {
  margin-left: 0px;
}

.aurora-debug-list-content .debug-download-checkbox-group .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ffffff;
}

.aurora-debug-list-content .debug-download-checkbox-group:hover .ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #ffffff;
  border-top: 0;
  border-left: 0;
}

.aurora-debug-list-content .debug-download-checkbox-group .ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #1890ff;
  border-top: 0;
  border-left: 0;
}

.aurora-debug-list-content .debug-download-checkbox-group .ant-checkbox+span {
  padding-left: 16px;
}

.aurora-debug-list-content .aurora-debug-download-message {
  position: absolute;
  left: 16px;
  right: 120px;
  bottom: 8px;
  height: 30px;
  line-height: 28px;
  text-indent: 10px;
  border-radius: 4px;
  font-size: 14px;
}

.aurora-debug-list-content .aurora-debug-progress {
  padding: 2px 16px 2px 10px;
  margin: 10px 0px;
}

.aurora-debug-list-content .aurora-debug-progress .ant-progress-inner {
  background-color: #c7e0f4;
}

.aurora-debug-list-content .aurora-debug-progress .ant-progress-text {
  vertical-align: unset;
}

.aurora-debug-list-content .aurora-debug-progress .ant-progress-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  -webkit-animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  content: '';
}

.aurora-debug-list-content .debug-download-btn {
  float: right;
  width: 80px;
  height: 26px;
  padding: 0px;
  font-size: 13px;
  line-height: 24px;
}

.aurora-debug-list-content .debug-download-button {
  margin: 10px 10px;
  float: right;
}

.aurora-debug-list-content .download-error {
  border: 1px solid #ffa39e;
  background-color: #fff1f0;
}

.aurora-debug-list-content .download-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}