.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  border: 1px solid #fff;
  border-radius: 4px;
  cursor: pointer;
  height: 30px;
  line-height: 28px;
  margin: -4px;
  padding: 0 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  width: 100%;
}

.editTable-select.ant-select-multiple .ant-select-selector {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.aurora-table .ant-table-row:hover>td,
.aurora-table .ant-table-row:hover>td>.editable-cell-value-wrap {
  background-color: #e6f7ff;
}

.aurora-table .ant-table-row:hover>td>.editable-cell-value-wrap,
.aurora-table .ant-table-row:hover>td>.editable-cell-value-wrap.ant-table-cell {
  border-color: #d9d9d9
}

.aurora-table table td .ant-form-item .ant-form-item-control-input {
  min-height: 20px;
}

.edit-form-item {
  margin: 0 !important;
}

.edit-form-item .ant-form-item-control {
  line-height: 1;
}

.select-width {
  width: 100%;
}

.select-width .ant-select-selection {
  height: 30px;
  line-height: 28px;
  margin: -4px;
  padding: 0 4px;
  box-sizing: border-box;
}

.select-width .ant-select-selection .ant-select-selector {
  line-height: 28px;
  margin: 0px;
}

.editable-input {
  width: 100%;
}

.editTable-select.ant-select-multiple .ant-select-selector {
  min-height: 30px;
  height: auto;
}

.editTable-select.ant-select-multiple .ant-select-selector li {
  height: 22px;
  line-height: 20px;
}

.aurora-option-label-icon {
  color: #a0a0a0;
  cursor: pointer;
  margin-right: 0px;
  margin-left: 7px;
  font-size: 10px;
}

.input-select-after {
  min-width: 58px;
  z-index: 2000;
}

.aurora-table-tree-select .aurora-tree-select {
  min-height: 28px;
  height: auto;
  width: 100%;
}

.aurora-table-select-selected {
  background: rgb(231, 231, 231);
}

.aurora-table .ant-table {
  border: 1px solid #e8e8e8;
  border-right: 0px;
}

.aurora-padding-table .ant-table {
  border-right: 1px solid #e8e8e8;
}

.aurora-padding-table .ant-table.ant-table.ant-table-bordered .ant-table-header {
  margin: 0px 20px;
}

.aurora-padding-table .ant-table.ant-table.ant-table-bordered .ant-table-thead .ant-table-cell-scrollbar {
  border-right: 0px;
}

.aurora-padding-table .ant-table.ant-table.ant-table-bordered .aurora-table-body {
  margin: 0px 20px;
}

.aurora-table .ant-table.ant-table-bordered>.ant-table-container {
  border: 0px;
}

.aurora-table .ant-table.ant-table.ant-table-bordered .aurora-table-body .ant-table-thead>tr>th {
  border-bottom: 1px solid #e8e8e8;
  background-color: initial;
}

.aurora-table .ant-table.ant-table.ant-table-bordered .aurora-table-body .ant-table-thead>tr>th:not(:last-child)::before {
  content: none;
}

.aurora-table .ant-table.ant-table.ant-table-bordered .aurora-table-body .ant-table-tbody>tr:last-child:td {
  border-bottom: 0px;
}

.aurora-table .ant-table.ant-table.ant-table-bordered .aurora-table-body .ant-table-thead>tr>th,
.aurora-table .ant-table.ant-table.ant-table-bordered .aurora-table-body .ant-table-tbody>tr>td {
  border-right: 1px solid #e8e8e8;
}

.aurora-padding-table .ant-table.ant-table.ant-table-bordered .aurora-table-body .ant-table-thead>tr>th:last-child,
.aurora-padding-table .ant-table.ant-table.ant-table-bordered .aurora-table-body .ant-table-tbody>tr>td:last-child {
  border-right: 0px;
}

.aurora-table .aurora-table-body .ant-table-row>td>.editable-cell-value-wrap {
  padding: 0px 5px;
}

.aurora-table .aurora-table-body .ant-table-row>td {
  position: relative;
}

.aurora-table .ant-table-content>.aurora-table-body .editable-cell-value-wrap {
  text-overflow: unset;
  overflow-y: auto;
  white-space: unset;
  min-height: 30px;
  position: unset;
  height: auto;
}

.aurora-table .ant-table-content>.aurora-table-body .ant-table-cell-ellipsis.editable-cell-value-wrap,
.aurora-table .ant-table-content>.aurora-table-body .ant-table-cell-ellipsis.editable-cell-value-wrap>div {
  height: auto;
  min-height: 28px;
}

.aurora-expanded-table .ant-table {
  margin-block: 0px !important;
}

.editTable-auto-complete {
  width: 100%;
}

.editTable-auto-complete-dropdown {
  z-index: 1000000;
}

.aurora-table .input-with-select .ant-select .ant-select-selector .ant-select-selection-item {
  padding-inline-end: 2px;
}