.load {
  position: absolute;
  top: 54px;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  min-width: 560px;
  overflow: auto;
}

.download-content {
  position: relative;
  width: 832px;
  margin: 15px auto;
}

.download-content .installer {
  margin-top: 20px;
}

.download-content .service h2,
.installer h2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.title-remark {
  font-size: 12px;
  margin-left: 5px;
  color: #1282d7;
}

.download-content .download-content-main {
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.download-content>h2 {
  position: absolute;
  top: -25px;
  left: 200px;
}

.load .download-window,
.load .download-linux {
  overflow: hidden;
  position: relative;
  width: 366px;
  height: 180px;
  line-height: 15px;
  margin-bottom: 18px;
  color: #000000;
  border-radius: 10px;
  background: #e6e6e6;
}

.load .download-window {
  margin-right: 50px;
}

.load .download-linux {
  margin-left: 50px;
}

.load .download-guide {
  width: 100%;
  height: 50px;
  line-height: 15px;
  color: #000000;
  border-radius: 10px;
  background: #e6e6e6;
  margin-top: 20px;
  background: transparent;
}

.load .download-header {
  position: relative;
  height: 140px;
}

.load .download-content img {
  position: absolute;
  top: 4px;
  left: 50%;
  margin-left: -38px;
  width: 76px;
  font-size: 40px;
  height: 76px;
  color: #1282d7;
}

.load .version-note-list {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.load .show-old-version:last-child .show-note-list div {
  border-bottom: none;
}

.load .show-note-list ul {
  max-width: 750px;
  margin-left: 60px;
}

.load .show-note-list ul li {
  padding-top: 3px;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 5px;
  list-style-type: disc !important;
}

.load .download-guide h2 {
  margin-left: 10px;
  margin-left: 0;
  font-size: 20px;
  color: #1282d7;
}

.load .download-guide .download-box {
  position: relative;
  top: 5px;
  display: flex;
  margin-left: 0;
  left: 0
}

.load .download-guide .download-box button {
  margin: 0 0 0 -14px;
}

.download-box {
  position: absolute;
  width: 270px;
  height: 42px;
  left: 50%;
  top: 76px;
  margin-left: -135px;
  border-radius: 4px;
}

.aurora-download-button {
  display: block;
  font-size: 16px;
  border: none;
  margin: 6px auto 0;
  color: #1282d7;
  background-color: transparent;
}

.a-link {
  color: #1282d7;
}

.aurora-download-button:hover {
  color: #23527c;
  background-color: transparent;
}

.aurora-download-button h2:hover {
  color: #23527c;
  background-color: transparent;
}

.aurora-download-button:active {
  color: #1282d7;
  background-color: transparent;
}

.aurora-download-button:focus {
  color: #1282d7;
  background-color: transparent;
}

.download-box-guide-icon {
  height: 32px;
  line-height: 32px;
  color: #1282d7;
  font-size: 18px;
  margin: -2px 0 0 25px;
}

.download-box-guide-icon:hover {
  color: #23527c;
}

button.ant-btn.aurora-download-button.aurora-download-version-button.ant-btn-primary {
  height: 52px;
}

.aurora-download-version-button-single {
  height: 32px;
  display: block;
  font-size: 16px;
  border: none;
  margin: 6px auto 0;
  color: #1282d7;
  background-color: transparent;
}
.aurora-download-version-button-second{
  height: 26px;
  display: block;
  font-size: 16px;
  border: none;
  margin: 6px auto 0;
  color: #1282d7;
  background-color: transparent;
}

.aurora-download-version-button:hover {
  background-color: #bae4f1;
}

.aurora-download-version-button-single:hover {
  background-color: #bae4f1;
}
.aurora-download-version-button-second:hover {
  background-color: #bae4f1;
}

button.ant-btn.aurora-download-button.aurora-download-version-button.ant-btn-primary>div {
  font-size: 14px;
}

.load .download-guide .download-box .aurora-download-icon {
  margin-top: 2px;
}

.note {
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}

.load .download-content .show-note-list {
  padding: 0 0 30px;
}

.show-old-version {
  margin-bottom: 40px;
}

.show-old-version .title {
  display: flex;
  justify-content: flex-start;
  font-size: 23px;
  font-weight: bold;
}

.show-old-version .old-version h2 {
  display: inline-block;
  margin-right: 3px;
}

.show-old-version .old-version {
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
}

.show-old-version .old-version .documentation {
  font-size: 18px;
  color: #1282d7;
  cursor: pointer;
}

.show-old-version .old-version .documentation:hover {
  color: #23527c;
}

.show-old-version .installer-service-select {
  font-size: 12px;
}

.show-old-version .old-version span {
  margin-left: 5px;
  font-size: 17px;
}

.show-old-version .old-version .span-content {
  color: #1282d7;
}

.show-old-version .old-version .span-content:hover {
  color: #23527c;
  cursor: pointer;
}

@media (max-width:947px) {
  .download-content {
    width: 100%;
  }

  .load .download-window {
    margin-left: 50px;
  }

  .load .download-linux {
    margin-right: 50px;
  }

  .load .download-guide .download-box {
    width: 500px;
  }
}

@media (max-width:657px) {
  .load {
    padding-bottom: 40px;
  }

  .load .show-note-list ul {
    margin-left: 10px;
    width: 570px;
  }

  .load .download-content {
    overflow-x: scroll;
  }
}