.eye-mask-panel-section {
  display: flex;
  margin-left: 25px;
  margin-bottom: 12px;
}

.eye-mask-panel-name-input {
  width: calc(100% - 76px);
  margin-left: 30px;
  display: inline;
}

.aurora-input-disable.eye-mask-panel-section[disabled] {
  color: rgba(0, 0, 0, 0.25);
}

.eye-mask-time-voltage-plot {
  position: relative;
  border-left: 1px solid #e8e8e8;
  overflow: hidden;
  min-width: 400px;
  min-height: 400px;
}

.mask-panel .eye-mask-content-box {
  padding: 10px;
  height: 100%;
}

.mask-panel .eye-mask-content-box .eye-mask-panel-section {
  margin-left: 10px;
}

.mask-panel .eye-mask-content-box .eye-mask-panel-section:last-child {
  height: calc(100% - 40px);
}

.eye-mask-content-box .file-content {
  margin: 23px 20px 0 0;
  overflow: auto;
  max-height: calc(100% - 50px);
}

.eye-mask-table-box.aurora-setup-border {
  margin-right: 12px;
}

.eye-mask-data-table {
  margin-top: 10px;
  position: relative;
  margin-bottom: 16px;
}

.eye-mask-data-table .ant-table .aurora-table-body {
  overflow-x: clip;
}

.eye-mask-data-table .ant-table .aurora-table-body .editable-row td {
  height: 32px;
  position: relative;
}

.eye-mask-data-table .anticon.anticon-plus-circle {
  position: absolute;
  bottom: -19px;
  right: 4px;
}

.eye-mask-error-msg {
  display: block;
  padding: 10px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin: 12px;
}

.eye-mask-delete-icon {
  color: #ccc;
  cursor: pointer;
  position: absolute;
  right: -16px;
  top: 12px;
}

.eye-mask-table-box .ant-table-fixed-header .ant-table-container .ant-table-header {
  padding-right: 10px;
}

.eye-mask-table-input-box span.ant-input-group-addon {
  width: 60px;
  padding: 0px;
}

.eye-mask-table-name-box {
  position: relative;
  display: inline-block;
  width: 100%;
}

.eye-mask-table-name-box>span {
  padding-right: 5px;
}

.eye-mask-table-name-box>.eye-mask-table-info-circle-icon {
  position: absolute;
  bottom: 0px;
}

.eye-mask-panel-left {
  width: 400px;
}

.eye-mask-panel-right {
  flex: 1;
  width: calc(100% - 400px);
  line-height: 28px;
}

.eye-mask-panel-right>label {
  margin-left: 8px;
}

.svg-text {
  position: absolute;
  color: #000;
  font-size: 13px;
  cursor: pointer;
}

.svg-text:hover {
  color: #1890ff;
}

.svg-tooltip {
  z-index: 2001;
}

.eye-mask-panel-error {
  color: red;
  font-size: 13px;
  font-weight: bold;
}