.sierra-connections-main {
  padding: 10px 14px;
}

.sierra-connections-buttons {
  padding-bottom: 6px;
  overflow: hidden;
  /*   display: flex;
  justify-content: left; */
}

.sierra-connections-buttons .sierra-connection-download-button,
.sierra-connections-buttons .sierra-connection-upload-button,
.sierra-connections-buttons .sierra-connection-add-button {
  /* margin-left: 10px; */
  padding: 0px 8px;
}

.sierra-connections-buttons .ant-divider-vertical {
  margin: 0px 4px;
}

.sierra-connections-footer-content {
  display: flex;
  justify-content: center;
  border-radius: 0 0 4px 4px;
}

.sierra-connections-footer-content .ant-btn {
  height: 28px;
  width: 75px;
}

.sierra-connections-buttons .ant-btn {
  height: 28px;
}

.connections-board-title {
  width: 100%;
  /*  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
}

.connections-board-title>span:not(.anticon) {
  display: block;
  width: calc(100% - 20px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sierra-connections-main .aurora-model-name-error-msg,
.sierra-connections-main .aurora-success-msg {
  margin: 6px 8px;
  margin-top: 0px;
}

.connections-table-main {
  padding: 6px;
  border: 1px solid #a8c4e6;
  border-radius: 4px;
}

.connections-table-main>label {
  color: #0b3694;
}

.connections-table-main>.sierra-connections-table {
  margin-top: 6px;
}

.connections-project-table-main {
  margin-top: 6px;
}

.sierra-connections-options-content>span {
  margin: 0px 8px;
  display: inline-block;
  height: 28px;
  line-height: 28px;
}

.connection-delete-icon {
  position: absolute;
  right: -17px;
  top: 50%;
  margin-top: -7px;
  color: #ccc;
}

.sierra-connections-table .ant-table-small .ant-table-content .ant-table-body tr:hover .connection-delete-icon {
  color: #1890ff;
}

.sierra-connections-table .ant-table-small .ant-table-content .ant-table-body tr {
  position: relative;
}

.sierra-connections-panel {
  top: 80px;
}