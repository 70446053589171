.advance-mask-inputGroup {
  display: flex;
  align-items: center;
  margin: 5px;
  width: 535px;
}

.advance-mask-add-icon {
  margin: 8px 16px;
  color: #1ca4f3;
}

.advance-mask-add-icon:hover {
  color: #1ca4f3;
}

.advance-mask-dataRate {
  display: flex;
  align-items: center;
  margin: 10px 0px;
  padding-left: 46px;
}

.advance-mask-dataRate-select {
  width: 420px;
}

.advance-mask-dataRate-divider {
  margin: 0px;
}

.mask-min-inputGroup>span {
  padding-left: 5px;
  padding-right: 5px;
}

.mask-max-inputGroup>span {
  padding-left: 5px;
  padding-right: 5px;
}

.advance-mask-delete-icon {
  margin: 10px 0px 10px 16px;
  color: #1ca4f3;
  font-size: 10px;
}

.advance-mask-delete-icon:hover {
  color: #1ca4f3;
}

.mask-min-inputGroup {
  display: flex;
  justify-content: space-around;
}

.mask-max-inputGroup {
  display: flex;
  justify-content: space-around;
}

.mask-link {
  width: 40px;
  border: 0.5px solid #d9d9d9;
  margin: 0px 10px;
}

.mask-input-show-noData {
  height: 28px;
}

.advance-mask-id {
  width: 100%;
  height: 100%;
}

.mask-input-show>ul {
  padding: 0px;
  margin: 0px;
}

.advance-mask-inputGroupList-box {
  display: flex;
  align-items: flex-start;
  margin: 10px 0px;
}

.advance-mask-inputGroupList {
  display: flex;
  flex-direction: column;
}

.advance-mask-dataRate-title {
  margin-right: 5px;
  width: 78px;
}

.advance-mask-dataRate-select-dropdown {
  z-index: 6000;
}