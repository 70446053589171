.andes-v2-result-experiment-display {
  line-height: 46px;
  white-space: nowrap;
  border: 0;
  min-width: 350px;
  float: right;
}

.andes-v2-result-experiment-select {
  width: 300px;
  margin-right: 50px;
}

.andes-v2-result-experiment-select .ant-select-selection--multiple {
  height: 28px;
}