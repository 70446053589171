.pre-layout-library-select-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.pre-layout-library-select-panel .library-select-content {
  padding: 8px 14px;
}

.pre-layout-library-select-panel .library-edit-value-label {
  display: inline-block;
  width: 110px;
  vertical-align: middle;
}

.pre-layout-library-select-panel .library-item {
  display: flex;
  line-height: 28px;
  height: 28px;
}

.pre-layout-library-select-panel .library-select,
.pre-layout-library-select-panel .library-input {
  width: calc(100% - 110px);
}

.pre-layout-library-select-panel .library-config-content {
  display: flex;
  border: 1px solid #ccc;
  background-color: #eee;
  margin: 0;
  padding: 6px;
  border-radius: 4px;
  flex-wrap: wrap;
  margin-top: 10px;
}

.pre-layout-library-select-panel .library-config-content>li {
  width: 50%;
  padding: 0px 10px;
}

.pre-layout-library-select-panel .library-select-content .library-item:nth-child(-n+2) {
  margin-bottom: 8px;
}

.pre-layout-library-row-background {
  display: inline-block;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}

.pre-layout-library-error {
  margin: 10px 0px;
  border: 1px solid #ffa39e;
  background-color: #fff1f0;
  line-height: 28px;
  text-indent: 10px;
  border-radius: 4px;
}

.pre-layout-center {
  text-align: center;
}

.pre-layout-input-model-checkbox {
  margin-left: 110px;
}

.pre-layout-input-model-copy {
  padding-top: 0px !important;
}

.pre-layout-input-model-copy span {
  font-size: 12px;
}

.pre-layout-input-model-copy .ant-checkbox-inner {
  border-radius: 4px;
}

.pre-layout-library-des {
  margin-top: 10px;
}

.pre-layout-library-des .ant-descriptions-item-label {
  font-size: 13px;
  white-space: nowrap;
}

.pre-layout-library-des .ant-descriptions-item-content {
  font-size: 13px;
  white-space: nowrap;
  padding: 8px;
}

.pre-layout-library-des .ant-descriptions-item-content>span {
  display: inline-block;
  height: 32px;
  vertical-align: middle;
}

.pre-layout-library-des .ant-descriptions-item-content .des-span {
  font-size: 13px;
  padding-left: 2px;
}

.pre-layout-library-des .ant-descriptions-item-content .des-input {
  min-width: 70px;
}

.pre-layout-library-des .ant-descriptions-item-content .des-input .ant-input {
  padding: 4px 5px;
}

.pre-layout-library-des .ant-descriptions-item-content .des-input .ant-input-group-addon {
  padding: 0px 5px;
  font-size: 12px;
}

.pre-layout-library-des .ant-descriptions-item-content .des-input .ant-input-group-addon .ant-select {
  margin: -6px;
  width: 50px;
}

.pre-layout-library-des .ant-descriptions-item-content .des-input .ant-input-group-addon .ant-select .ant-select-selector {
  margin-right: 10px;
}

.pre-layout-library-des .ant-descriptions-item-content .des-input .ant-input-group-addon .ant-select .ant-select-arrow {
  font-size: 10px;
  margin-top: -4px;
}

.pre-layout-library-des .ant-input-disabled {
  color: rgba(0, 0, 0, 0.65);
  background-color: #f8f8f8;
  cursor: not-allowed;
}

.pre-layout-library-des .aurora-input .ant-select-disabled {
  color: rgba(0, 0, 0, 0.65);
}