.csm-content-item {
    display: inline-block;
    width: calc(50% + 60px);
    margin: 3px 0px;
}

.csm-content-item>span {
    width: 120px;
    display: inline-block;
    line-height: 28px;
}

.csm-content-item .aurora-select {
    width: calc(100% - 120px);
    height: 28px;
}

.aurora-select.cpm-select-disable .ant-select-selection.ant-select-selection--single {
    color: #f30518;
    position: relative;
}

.cpm-select-disable .ant-select-selection .ant-select-arrow .aurora-file-check-icon {
    top: 6px;
}

.ant-select-selection.ant-select-selection--single:hover .ant-select-arrow .aurora-file-check-icon {
    right: 13px;
}

.csm-content-subckt-item {
    display: inline-block;
    width: calc(50% - 65px);
    margin-left: 5px;
}

.csm-content-subckt-item .aurora-select {
    width: calc(100%);
    height: 28px;
}

.csm-cpm-content-item {
    width: 100%;
    height: 28px;
    line-height: 28px;
    margin: 6px 0px;
}

.csm-cpm-content-item-flex {
    display: flex;
    justify-content: space-between;
}

.csm-cpm-content-item-flex>.aurora-select {
    width: calc(100% - 100px);
}

.csm-cpm-content .csm-cpm-content-item-flex>span {
    width: 100px;
}

.csm-cpm-content {
    margin: 10px 16px;
}

.csm-cpm-content-item>span {
    display: inline-block;
    width: 146px;
}

.csm-cpm-content-icon {
    cursor: pointer;
    color: #1890ff;
    margin-left: 10px;
}

.csm-connection-edit-main {
    padding: 12px 14px;
}

.csm-connection-edit-title {
    font-size: 16px;
    font-weight: 500;
}