.report-advances-collapse {
  border: none;
  background-color: #fff;
  margin-bottom: 10px;
}

.report-advances-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 6px 10px 6px 0px;
  width: fit-content;
  /* color: #696969; */
}

.report-advances-collapse>.ant-collapse-item {
  border-bottom: none;
}

.report-advances-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  left: 70px;
}

.report-advances-header {
  font-size: 13px;
  font-weight: 500;
  height: 22px;
  /* color: #000000a6; */
}

.report-advances-collapse .ant-collapse-content {
  border-top: none;
}

.report-advances-collapse .ant-collapse-content-box {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 10px 16px;
}

.report-advances-collapse .ant-collapse-content-box .ant-table-small {
  background-color: #fff;
}

.report-advances-collapse .report-advances-title {
  padding-bottom: 6px;
  font-size: 13px;
}

.report-advances-collapse .report-advances-table-title .report-advances-switch {
  margin-left: 10px;
}

.report-advances-collapse .report-advances-table-title {
  font-size: 13px;
}

.report-advances-table .ant-table-small>.ant-table-content>.aurora-table-body>table>.aurora-table-body>tr>td:nth-last-child(-n+2) {
  padding: 4px 8px;
}

.report-advances-table.ant-table-wrapper .ant-table.ant-table-small {
  font-size: 13px;
}

.report-advances-collapse .ant-table-wrapper.report-advances-table {
  margin-bottom: 10px;
}

.report-advances-spin {
  text-align: center;
  background-color: #fff;
  border-radius: 4px;
  padding: 30px 50px;
  margin: 20px 0;
}