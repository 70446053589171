.andes-content-left {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.aurora-content-right {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;
}

.aurora-content-right>.ant-spin-nested-loading,
.aurora-content-right>.ant-spin-nested-loading>.ant-spin-container {
  height: 100%;
}

.aurora-content-top {
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;
}

.aurora-content-top>.ant-spin-nested-loading,
.aurora-content-top>.ant-spin-nested-loading>.ant-spin-container {
  height: 100%;
}

.andes-content-bottom {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
}

.aurora-content .dividingLine {
  top: 0px;
}

.aurora-content>.ant-spin-nested-loading,
.aurora-content>.ant-spin-nested-loading>.ant-spin-container {
  height: 100%;
}

.andes-setup-close {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  position: absolute;
  right: 0px;
  top: 6px;
  z-index: 20;
  background: #4e4e4e;
  z-index: 20;
  opacity: 0.5;
  border-radius: 5px 0 0 5px;
}

.andes-setup-close .setup-close-icon {
  font-size: 16px;
  color: #fff;
  line-height: 26px;
  cursor: pointer;
  opacity: 1;
}

.andes-setup-close:hover {
  transition: 0.6s;
  opacity: 0.8;
}

.andes-pcb-close {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  position: absolute;
  right: 0px;
  top: 6px;
  z-index: 20;
  background: #fff;
  opacity: 0.8;
  border-radius: 5px 0 0 5px;
}

.andes-pcb-close .andes-close-icon {
  font-size: 16px;
  color: #000;
  cursor: pointer;
  opacity: 1;
}

.andes-pcb-close:hover {
  transition: 0.6s;
  opacity: 1;
}

.select-model {
  padding: 5px 10px 0px;
  width: 100%;
  line-height: 24px;
}

.select-model-content {
  margin-right: 16px;
}

.select-model-last {
  padding-bottom: 5px;
}

.select-model-selection,
.pintopin--buffer-selection {
  float: left;
  width: 100%;
}

.select-dialog-close {
  position: absolute;
  right: 1px;
  top: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  line-height: 24px;
  transition: 0.3s;
}

.select-dialog-close:hover {
  background: #ececec;
  transition: 0.3s;
}

.select-close-icon {
  color: rgba(0, 0, 0, 0.45);
}

.signal-delete-icon {
  position: absolute;
  right: 4px;
  top: 50%;
  margin-top: -7px;
  color: #ccc;
}

.pintopin-power-delete {
  position: absolute;
  right: -19px;
  top: 50%;
  margin-top: -7px;
  color: #ccc;
}

.andes-signal-name-cell {
  margin-right: 22px !important;
  width: auto !important;
}

.andes-PG-table tbody>tr>td:last-child,
.andes-signal-nets-table tbody>tr>td:first-child {
  position: relative;
}

.andes-PG-table .ant-table-small>.ant-table-content>.aurora-table-body .ant-table-row:hover .pintopin-power-delete,
.andes-signal-nets-table .ant-table-small>.ant-table-content>.aurora-table-body .andes-signal-name-cell:hover .signal-delete-icon {
  color: #1890ff;
}

.andes-table-comp {
  width: 100%;
  cursor: pointer;
}

.andes-PG-net-box {
  margin-top: 10px;
}

.andes-re-extraction {
  display: inline-block;
  padding-left: 20px;
}

.andes-re-extraction-switch {
  margin: 0 20px;
  margin-top: -2px;
}

.andes-type-select {
  margin-left: 14px;
  margin-right: 30px;
  padding: 2px 6px;
  height: 28px;
}

.andes-type-select . {
  width: 94px;
  padding: 2px 6px;
  height: 28px;
}

.andes-type-select .ant-select-selector {
  width: 94px;
  height: 28px;
  margin-left: 6px;
  line-height: 21px;
}

.andes-channel-select {
  margin: 0 20px;
  padding: 2px 6px;
  height: 28px;
}

.andes-channel-select . {
  width: 94px;
  padding: 2px 6px;
  height: 28px;
}

.andes-channel-select .ant-select-selector {
  width: 94px;
  height: 28px;
  margin-left: 6px;
  line-height: 21px;
}

.andes-type-input {
  margin-left: 14px;
  margin-right: 6px;
  width: 94px;
  padding: 2px 6px;
  height: 28px;
}

.andes-select-dropdown-menu {
  padding: 0px;
  margin: 0px;
  max-height: 250px;
  margin-bottom: 0px !important;
}

.andes-select-nets-div {
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.andes-select-nets-ul-1 {
  max-height: 138px;
  width: 100%;
  overflow: auto;
  padding-left: 15px;
  margin-bottom: 2px !important;
}

.andes-select-nets-ul-2 {
  max-height: 68px;
  width: 100%;
  overflow: auto;
  padding-left: 15px;
  margin-bottom: 4px !important;
}

.andes-select-span {
  font-weight: 600;
  font-size: 14px;
  display: block;
  margin: 0px;
  padding: 0px;
  padding-left: 8px;
  cursor: pointer;
}

.andes-select-nets-li {
  line-height: 28px;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
  padding-left: 4px;
}

.andes-select-nets-li:hover {
  background-color: rgb(223, 243, 255);
}

.andes-nets-selected {
  background-color: rgb(176, 226, 255);
  font-weight: 600;
}

.andes-nets-selected:hover {
  background-color: rgb(176, 226, 255);
}

.andes-nets-expand-icon {
  margin: 0 4px;
  font-size: 12px;
}

.andes-advance-content {
  margin-top: 10px;
}

.andes-advance-title,
.andes-optimization-title {
  font-weight: bold;
}

.andes-advance-option {
  display: inline-block;
  padding: 0 20px;
  vertical-align: middle;
}

.andes-extraction-button {
  margin: 0 20px;
  font-size: 17px;
  color: #0d87f7;
  vertical-align: middle;
  margin-top: -2px;
}

.andes-sub-extraction-box {
  margin-left: 26px !important;
}

.andes-debug-icon {
  font-size: 17px;
  color: #0d87f7;
  vertical-align: middle;
}

.andes-advance-panel {
  margin-top: 100px;
  min-width: 776px;
}

.andes-advance-panel .extraction-name {
  font-size: 16px;
  font-weight: bold;
}

.andes-advance-panel .extraction-main {
  margin-left: 20px;
  margin-bottom: 12px;
}

.andes-advance-panel .extraction-sub-title {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 8px;
}

.andes-advance-panel .extraction-content {
  line-height: 28px;
  margin-bottom: 5px;
}

.andes-advance-panel .extraction-content .extraction-input {
  display: inline-block;
  width: 44%;
  vertical-align: middle;
  height: 28px;
}

.andes-advance-panel .extraction-content-body {
  font-size: 14px;
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;
  width: 45%;
  padding-right: 10px;
}

.andes-advance-panel .sub-extraction-content-body {
  font-size: 14px;
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;
  width: 45%;
  text-align: 20px;
  font-style: italic;
}

.andes-advance-panel .sub-extraction-box {
  display: inline-block;
  margin-left: 20px;
  margin-right: 6px;
  width: 8px;
  height: 8px;
  background: cornflowerblue;
  border-radius: 4px;
}

.andes-advance-panel .extraction-option-error-msg {
  display: block;
  padding: 8px 10px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin: 10px 16px 10px 4px;
}