.pre-layout-relative {
  position: relative;
}

.pre-layout-center {
  text-align: center；
}

.pre-layout-section-img {
  width: 15px;
  height: 15px;
  margin-top: 7px;
  margin-left: 5px;
  position: absolute;
}

.pre-layout-section-title {
  min-width: 131px;
  display: inline;
}

.pre-layout-section-title>span {
  vertical-align: middle;
}

.pre-layout-section-title .pre-layout-add-section {
  margin-left: 6px;
  color: #3da4fa;
  cursor: pointer;
  font-size: 15px;
  vertical-align: middle;
}

.pre-layout-section-title .pre-layout-delete-section {
  float: right;
  color: #ccc;
  cursor: pointer;
  font-size: 14px;
  margin-top: 5px;
}

.pre-layout-section-title:hover .pre-layout-delete-section {
  color: #3da4fa;
}

.pre-layout-fold {
  cursor: pointer;
  display: inline-flex;
  margin-left: -20px;
}

.pre-layout-fold-icon {
  margin-top: 12px;
  margin-right: 5px;
  font-size: 10px;
}

.pre-layout-fold:hover .pre-layout-fold-icon {
  color: #1890ff;
}

.pre-layout-fold-icon-transform svg {
  transform: rotate(270deg);
}
