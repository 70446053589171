.ant-select-dropdown.export-content-drop-down-tree {
  max-height: 300px !important;
  z-index: 3000;
}

.export-content-footer {
  margin-top: 17px;
  position: relative;
  min-height: 27px;
  margin-bottom: 10px;
}

.export-content-footer .export-data-button {
  position: absolute;
  right: 0;
  bottom: 2px;
}

.export-content-footer .export-folder-message {
  line-height: 28px;
  text-indent: 10px;
  border-radius: 4px;
  font-size: 14px;
  padding: 0 8px;
  max-width: calc(100% - 90px);
}

.export-content-tip .export-content-footer .download-error {
  border: 1px solid #ffa39e;
  background-color: #fff1f0;
}

.export-content-tip .export-content-footer .download-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}

.ant-radio-group.ant-radio-group-outline.import-export-radio-group {
  display: block;
  height: 20px;
  margin: 16px 16px 0;
}

.import-export-radio-edit.ant-radio-wrapper.ant-radio-wrapper-checked {
  float: left;
  line-height: 20px;
  width: 120px;
  margin: 0 !important;
}

.import-export-Panel .export-content-tip {
  margin: 10px 20px 5px;
  font-size: 15px;
}

.import-export-radio-edit.ant-radio-wrapper {
  float: left;
  line-height: 20px;
  width: 120px;
  margin: 0 !important;
}

.ant-progress.ant-progress-line.ant-progress-status-normal.ant-progress-show-info.ant-progress-default.export-content-progress {
  margin-bottom: 5px;
}

.export-content-progress .ant-progress-outer .ant-progress-inner {
  background-color: #c7e0f4;
}

.export-data-tree {
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
  margin-top: 5px;
}