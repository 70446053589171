.andes-v2-channel-config-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.andes-v2-channel-config-panel .panel-modal-header,
.andes-v2-channel-component-panel .panel-modal-header {
  padding-left: 14px !important;
}

.andes-v2-channel-config-title,
.andes-v2-channel-config-title {
  font-size: 16px;
  font-weight: 500;
}

.channel-config-main .ant-popover-inner-content {
  padding: 10px 12px;
  box-shadow: rgb(76 77 78 / 14%) 0px -2px 8px 0px, rgb(0 0 0 / 10%) 0px 0px 10px 3px;
}

.channel-component-main {
  width: 100%;
  min-width: 150px;
}

.andes-v2-channel-config-panel .channel-config-main {
  padding: 10px 16px;
  min-width: 636px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-item {
  width: 100%;
  margin: 5px 0px;
  min-height: 28px;
  line-height: 28px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-item .aurora-select,
.andes-v2-channel-config-panel .channel-config-content .channel-config-item .aurora-input {
  width: calc(100% - 330px);
  vertical-align: middle;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-item>span {
  display: inline-block;
  width: 320px;
  vertical-align: middle;
  font-size: 13px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .ant-radio-group {
  width: 100%;
}

.andes-v2-channel-config-panel .channel-config-title {
  height: 30px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-jitters-content .channel-config-item>span {
  /* display: inline-block; */
  width: 338px;
  /* vertical-align: middle; */
  /* font-size: 13px; */
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-item>span {
  width: 338px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-jitters-content .channel-config-item .aurora-select,
.andes-v2-channel-config-panel .channel-config-content .channel-config-jitters-content .channel-config-item .aurora-input {
  width: calc(100% - 338px);
  /* vertical-align: middle; */
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-item .aurora-select,
.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-item .aurora-input {
  width: calc(100% - 315px);
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-jitters-content {
  margin-bottom: 10px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-jitters-content,
.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content {
  border-radius: 5px;
  background: #f3f3f3;
  border: 1px solid #e8e8e8;
  padding: 4px 10px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-jitters-content .channel-config-jitter-nui-item {
  width: calc(100% - 212px);
  margin: 5px 0px;
  margin-left: 212px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-jitters-content .channel-config-jitter-nui-item>span {
  width: 64px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-jitters-content .channel-config-jitter-nui-item .aurora-input {
  width: calc(100% - 64px);
}

.andes-v2-channel-config-panel .channel-config-options {
  height: 30px;
  line-height: 30px;
  display: flex;
  justify-content: space-around;
  /*  margin-top: 10px; */
}

.andes-v2-channel-config-panel .channel-config-options>div>div {
  cursor: pointer;
  color: #036fd2;
  font-size: 13px;
}

.andes-v2-channel-config-panel .channel-config-options>div>div:hover,
.andes-v2-channel-config-panel .channel-config-options>.channel-config-middle-options:hover {
  color: #1890ff;
}

.andes-v2-channel-config-panel .channel-config-options>div {
  width: 33.33%;
}

.andes-v2-channel-config-panel .channel-config-options>.channel-config-middle-options {
  text-align: center;
  color: #036fd2;
}

.andes-v2-channel-config-panel .channel-config-options>div>.channel-config-left-options {
  float: left;
}

.andes-v2-channel-config-panel .channel-config-options>div>.channel-config-right-options {
  float: right;
}

.andes-v2-channel-config-panel .channel-config-options>div .anticon {
  font-size: 13px;
  vertical-align: middle;
}

.andes-v2-channel-config-panel .channel-config-options>div>.channel-config-left-options .anticon {
  margin-right: 4px;
}

.andes-v2-channel-config-panel .channel-config-options>div>.channel-config-right-options .anticon {
  margin-left: 4px;
}

.andes-v2-channel-config-panel .channel-config-options>div>div span {
  vertical-align: middle;
}

.andes-v2-channel-config-panel .channel-config-middle-options {
  text-align: center;
}

.andes-v2-channel-config-panel .channel-config-options>div .ant-btn {
  font-size: 12px;
  color: #1890ff;
  background-color: #fff;
  border-color: #1890ff;
  height: 24px;
  line-height: 23px;
  padding: 0px 12px;
}

.andes-v2-channel-config-panel .channel-config-options>div .ant-btn:hover {
  color: #217ed4;
  border-color: #91d5ff;
  background: #e6f7ff;
}

.andes-v2-channel-config-panel .channels-component-title .anticon {
  color: #1890ff;
  margin-left: 10px;
  cursor: pointer;
}

.channel-select-component-select {
  width: 100%;
}

.channel-config-channels-table .editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.channel-config-channels-table tbody>tr>td .ant-select-selection--multiple {
  padding-bottom: 3px;
}

/* .channel-config-channels-table .ant-table-thead>tr>th:nth-child(2) .ant-table-header-column .ant-table-column-title,
.channel-config-channels-table .ant-table-thead>tr>th:nth-child(3) .ant-table-header-column .ant-table-column-title, */
.channel-config-channels-table .ant-table-thead>tr>th:nth-child(4) .ant-table-header-column .ant-table-column-title,
.channel-config-channels-table .ant-table-thead>tr>th:nth-child(5) .ant-table-header-column .ant-table-column-title {
  padding-left: 4px;
  display: block;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-sub-item {
  padding-left: 25px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-sub-children-item {
  padding-left: 66px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-sub-item>span {
  display: inline-block;
  width: 289px;
  vertical-align: middle;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-equalization-sub-item>span,
.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-sub-item>.channel-item-eq-tx-title {
  width: 166px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-item.channel-config-sub-item.channel-equalization-sub-item>span {
  width: 313px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-sub-item .aurora-select,
.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-sub-item .aurora-input,
.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-sub-item .aurora-tags-input-content {
  width: calc(100% - 349px);
  vertical-align: middle;
  height: auto;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-sub-children-item>span {
  width: 272px
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-sub-children-item .aurora-tags-input-content {
  width: calc(100% - 308px);
  min-height: 28px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-sub-item .channel-sub-item-title,
.andes-v2-channel-config-panel .channel-config-content .channel-config-item .channel-sub-item-title {
  display: inline-block;
  width: calc(100% - 24px);
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-item .channel-config-input-suffix {
  width: 24px !important;
  margin-left: 10px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-sub-item>.channel-item-title {
  width: 166px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-sub-item .channel-config-sub-half-input {
  width: calc(25% - 46px);
  margin-right: 5px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-item-analysis-title {
  padding-left: 177px;
  text-align: right;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-item-analysis-title>span {
  text-align: center;
  width: calc(25% - 5px);
  margin-right: 5px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-item-analysis-title>span:nth-last-child(1) {
  margin-right: 0px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-sub-item .channel-config-sub-half-input:nth-last-child(1) {
  margin-right: 0px;
}

.andes-v2-channel-config-panel .channel-advanced-collapse-content {
  background-color: #f3f3f3;
}

.andes-v2-channel-config-panel .channel-advanced-collapse-content>.ant-collapse-item>.ant-collapse-header {
  padding: 0px 0px 0px 20px;
}

.andes-v2-channel-config-panel .channel-advanced-collapse-content>.ant-collapse-item {
  border: 0;
}

.andes-v2-channel-config-panel .channel-advanced-collapse-content .ant-collapse-content-box {
  padding-bottom: 8px;
  padding-top: 0px;
  padding-right: 0px;
}

.andes-v2-channel-config-panel .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  left: 0px;
  font-size: 11px;
}

.andes-v2-channel-config-panel .aurora-model-name-error-msg {
  margin-top: 10px;
  margin-bottom: 4px;
}

.andes-v2-channel-config-panel .modal-foot-position {
  height: 51px;
}

.andes-v2-channel-config-panel .ant-modal-footer {
  padding: 10px 16px;
}

.andes-seasim-type-title {
  color: #3b70a7;
}

.channel-config-basic-content {
  border-radius: 5px;
  background: #f3f3f3;
  border: 1px solid #e8e8e8;
  padding: 4px 10px;
}

.andes-seasim-channels-component-content {
  display: flex;
  justify-content: space-between;
}

.andes-seasim-channels-component-content>div {
  width: calc(50% - 16px);
}

.andes-seasim-channels-component-content>div .aurora-select {
  width: calc(100% - 70px);
  vertical-align: middle;
}

.andes-seasim-channels-component-content>div>span {
  width: 70px;
  display: inline-block;
  vertical-align: middle;
}

.andes-seasim-channels-component-content>.andes-seasim-channels-component-device>span {
  padding-left: 16px;
}

.andes-seasim-channels-content {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #e8e8e8;
  background: #f3f3f3;
  border-radius: 5px;
}

.channel-config-channels-table {
  background-color: #ffffff;
}

.channel-config-content .aurora-select . .ant-select-selector {
  font-size: 13px
}

.direction-setting-icon {
  color: #188fff;
  margin-left: 10px;
}

.andes-v2-channel-direction-panel {
  margin-top: 140px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.andes-v2-channel-direction-panel .direction-setting-main {
  padding: 10px;
}

.direction-setting-main .direction-setting-checkbox-group-box .ant-checkbox-wrapper {
  margin: 8px 0px;
}

.channel-config-eq-children {
  margin-left: 24px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-eq-children>.channel-config-item>span {
  width: 314px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-eq-children>.channel-config-item .aurora-select {
  width: calc(100% - 315px);
}

.andes-v2-channel-config-panel .andes-compliance-error-content {
  margin-top: 10px;
  background: #ffdce0;
  border-radius: 4px;
  padding: 8px;
  color: #cb2431;
}

.andes-v2-channel-config-panel .andes-compliance-error-content .aurora-repeater-monitor-title {
  font-weight: 600;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-item.channel-config-sub-leq-children-item .channel-config-sub-leq-input {
  width: calc(50% - 160px);
  margin-right: 12px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-sub-leq-children-item .aurora-input,
.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-sub-leq-children-item .aurora-select {
  width: calc(100% - 308px);
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-sub-item .aurora-select.channel-config-sub-item-multiple-select {
  height: auto;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-item-analysis-title.channel-config-item-leq-analysis-title {
  padding-left: 339px;
  padding-right: 37px;
}

.andes-v2-channel-config-panel .channel-config-content .channel-config-equalization-content .channel-config-item-analysis-title.channel-config-item-leq-analysis-title>span {
  width: calc(50% - 3px);
}