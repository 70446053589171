.virtual-list-menu {
  position: absolute;
  height: 200px;
  width: 100%;
  overflow: auto;
  z-index: 9999;
  background-color: #fff;
  overflow-anchor: none;
}

.menu-no-result {
  padding-left: 15px;
  font-weight: 700;
  position: absolute;
  height: 30px;
  width: 100%;
  margin-top: 5px;
  z-index: 9999;
  line-height: 28px;
}

.menu-item-check {
  margin-right: 6px;
  visibility: hidden;
}

.virtual-list-menu .menu-list {
  margin: 2px;
  white-space: normal;
  padding-inline-start: 0px
}

.virtual-list-menu .menu-list li {
  padding: 3px 2px;
  width: 100%;
  white-space: nowrap;
  color: rgba(0, 0, 0, .85)
}

.virtual-list-menu .menu-list li:hover {
  background: #E6F7FF;
}

.virtual-list-menu .menu-list li:hover .menu-item-check {
  visibility: visible;
}

.menu-selected-item {
  background: #fafafa;
  font-weight: 600;
}

.menu-selected-item .menu-item-check {
  visibility: visible;
  color: #1890FF;
}

.menu-selecting-item {
  background: #d3eaff;
}

.menu-selecting-item .menu-item-check {
  visibility: hidden;
}

.aurora-virtual-list {
  z-index: 9999;
  padding: 0px;
  overflow: visible;
}

.virtual-list-select-show {
  position: absolute;
  width: 100%;
  text-align: center;
  line-height: 32px;
  background: #fbfbfb;
  color: #188fff;
  cursor: pointer;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 5px 7px 2px rgba(0, 0, 0, 0.15);
  z-index: 100000;
  font-weight: 800;
}

.menu-selecting-item-error {
  background: #fff;
  color: rgba(0, 0, 0, .25);
  cursor: not-allowed !important;
}

.virtual-list-menu .menu-list li.menu-selecting-item-error:hover {
  color: rgba(0, 0, 0, .25);
  cursor: not-allowed !important;
}

.virtual-list-menu .menu-list li.menu-selecting-item-error:hover .menu-item-check {
  visibility: hidden;
}

.vlrtual-select-dropdown-menu-item-group-title {
  height: 32px;
  padding: 0 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  line-height: 32px; 
}