.sierra-library-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  margin: 0 auto;
}

#sierra-file-view {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  border: 1px solid #cdcfd2;
}

.sierra-file-panel {
  min-height: 200px;
  min-width: 200px;
}

.sierra-file-panel .panel-body {
  position: relative;
}

.sierra-file-panel .dividingLine {
  top: 0px;
}

.sierra-file-content {
  background: #fafafa;
  padding: 16px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  height: 400px;
}

.vector-value-box {
  margin: 16px;
}

.vector-input,
.vector-value-input {
  margin: 16px 0;
  display: block;
}

.vector-name-error-msg {
  display: block;
  padding: 10px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
}

.vector-input-tooltip {
  z-index: 3000;
}

.file-content-panel {
  box-sizing: content-box;
}

.file-content-panel .panel-body {
  position: relative;
  padding: 16px !important;
}

.file-content-panel .dividingLine {
  top: 0px;
}

.file-content {
  background: #fafafa;
  padding: 16px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
}

#file-content-view {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  border: 1px solid #cdcfd2;
}

.library-edit-upload-progress-bar {
  width: 100%;
}

.library-edit-upload-progress-bar .ant-progress-outer {
  padding-right: 40px;
}

.library-edit-upload-progress-bar .ant-progress-text {
  position: absolute;
  right: 0px;
  width: 30px;
  height: 22px;
  line-height: 22px;
  font-weight: bold;
}

.vector-value-input .ant-input-group-addon {
  width: 50px;
}

.sierra-vector-radio-group {
  display: block;
  margin: 0 16px;
  height: 20px;
  margin-top: 16px;
}

.sierra-vector-radio-upload {
  float: left;
  line-height: 20px;
  width: 50%;
  margin: 0 !important;
}

.sierra-vector-radio-edit {
  float: left;
  line-height: 20px;
  width: 50%;
  margin: 0 !important;
}

.sierra-show-models .show-models-tip {
  margin: 10px 20px 5px 20px;
  font-size: 15px;
}

.sierra-show-models .show-models-list {
  margin: 0px 16px 16px 16px;
  background: #f5f5f5;
  max-height: 300px;
  overflow: auto;
  border: 1px dashed #d9d9d9;
}

.sierra-show-models .show-models-list ul {
  margin: 0;
  padding: 12px 16px;
}

.sierra-library-help-icon {
  color: #1890ff;
  font-size: 15px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -8px;
}

.sierra-library-panel .panel-ant-modal-title {
  position: relative;
}

.sierra-repeater-edit-icon {
  font-size: 15px;
  color: #1890ff;
  margin-right: 8px;
}

.file-textarea {
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  padding: 16px;
}

.edit-button-group {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.edit-button-group button:nth-child(1),
.edit-button-group button:nth-child(2),
.edit-button-group button:nth-child(3) {
  margin-right: 10px;
  margin-left: 10px;
}

.file-title {
  display: inline-block;
  margin-right: 10px;
}

.file-title-span {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}