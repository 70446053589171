.pdn-content-left {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.aurora-content-right {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;
}

.aurora-content-right>.ant-spin-nested-loading,
.aurora-content-right>.ant-spin-nested-loading>.ant-spin-container {
  height: 100%;
}

.aurora-content-top {
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;
}

.aurora-content-top>.ant-spin-nested-loading,
.aurora-content-top>.ant-spin-nested-loading>.ant-spin-container {
  height: 100%;
}

.pdn-content-bottom {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  background: #ffffff;
}

.my-pdn-content-tb {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
}

.my-pdn-content-tb>.ant-spin-nested-loading,
.my-pdn-content-tb>.ant-spin-nested-loading>.ant-spin-container {
  width: 100%;
  height: 100%;
}

.my-pdn-content-tb .bottomLineStyle {
  top: auto;
}

.aurora-content .dividingLine {
  top: 0px;
}

.aurora-content>.ant-spin-nested-loading,
.aurora-content>.ant-spin-nested-loading>.ant-spin-container {
  height: 100%;
}

.pdn-setup-close {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  position: absolute;
  right: 0px;
  top: 6px;
  z-index: 20;
  background: #4e4e4e;
  z-index: 20;
  opacity: 0.5;
  border-radius: 5px 0 0 5px;
}

.pdn-setup-close .setup-close-icon {
  font-size: 16px;
  color: #fff;
  line-height: 26px;
  cursor: pointer;
  opacity: 1;
}

.pdn-setup-close:hover {
  transition: 0.6s;
  opacity: 0.8;
}

.pdn-pcb-close {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  position: absolute;
  right: 0px;
  top: 6px;
  z-index: 20;
  background: #fff;
  opacity: 0.8;
  border-radius: 5px 0 0 5px;
}

.pdn-pcb-close .pcb-close-icon {
  font-size: 16px;
  color: #000;
  cursor: pointer;
  opacity: 1;
}

.pdn-pcb-close:hover {
  transition: 0.6s;
  opacity: 1;
}

.pdn-vrm-tables .vrm-model-seletion {
  position: relative;
  overflow: unset;
}

.pdn-vrm-tables .vrm-model-seletion>span:not(.anticon) {
  display: inline-block;
}

.VRM-delete-icon {
  position: absolute;
  right: -25px;
  top: 50%;
  margin-top: -6px;
  color: #ccc;
}

.pdn-vrm-tables .aurora-table-body .ant-table-row>td>.editable-cell-value-wrap .VRM-delete-icon {
  right: -30px;
}

.pdn-vrm-tables .ant-table-small>.ant-table-content>.aurora-table-body .ant-table-row:hover .VRM-delete-icon {
  color: #1890ff;
}

.pdn-vrm-tables .ant-table-small>.ant-table-content>.aurora-table-body {
  margin-right: 20px;
}

.pdn-vrm-tables-array .ant-table-small>.ant-table-content>.aurora-table-body {
  margin-right: 8px;
}

.fastpi-tb-setup-main,
.fastpi-tb-result-main {
  position: absolute;
  top: 45px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: auto;
}

.fastpi-normal-setup-main {
  top: 45px !important;
}

.fastpi-tb-result-main {
  top: 0px;
}