.pdn-model {
  padding: 0px 10px 5px;
  width: 100%;
  line-height: 24px;
}

.pdn-model-pd-top-s {
  padding-top: 5px;
}

.pdn-model-last {
  padding-bottom: 5px;
}

.pdn-model-input {
  margin-top: 5px;
}

.decap-dialog-title {
  cursor: pointer;
  height: 40px;
  padding: 10px;
  border-bottom: solid 1px #e8e8e8;
}

.decap-dialog-title .decap-dialog-title-content {
  width: calc(100% - 24px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.decap-dialog-close {
  position: absolute;
  right: 12px;
  top: 8px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  line-height: 24px;
  transition: 0.3s;
  z-index: 99999;
}

.decap-dialog-close:hover {
  background: #ececec;
  transition: 0.3s;
}

.decap-model-add {
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 12px;
  text-align: right;
  line-height: 30px;
  transition: 0.3s;
  z-index: 99999;
}

.decap-model-add .anticon-plus-circle {
  font-size: 15px;
  color: #63aef3;
}

.decap-model-edit {
  position: absolute;
  right: 17px;
  top: 0px;
  cursor: pointer;
  width: 15px;
  height: 30px;
  border-radius: 12px;
  text-align: right;
  line-height: 30px;
  transition: 0.3s;
  z-index: 99999;
}

.decap-model-edit .anticon-edit {
  font-size: 12px;
  color: #63aef3;
}

.decap-model-delete {
  position: absolute;
  right: 2px;
  top: 0px;
  cursor: pointer;
  width: 15px;
  height: 30px;
  border-radius: 12px;
  text-align: right;
  line-height: 30px;
  transition: 0.3s;
  z-index: 99999;
}

.decap-model-delete .anticon-close {
  font-size: 12px;
  color: #63aef3;
}

.pdn-model-box-title {
  padding-left: 10px;
  width: 200px;
}

.pdn-model-box-title .pdn-model-box-title-content {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.pdn-model-selection {
  float: left;
  width: 190px;
}

.pdn-model-selection-dropdown {
  max-height: 200px;
  overflow: auto;
  /* margin-top: 3px; */
  width: 100%;
  background-color: #fff;
  overflow-anchor: none;
  border-radius: 4px;
}

.pdn-model-selection-dropdown .menu-list {
  margin: 2px;
  padding-inline-start: 0px;
}

.pdn-model-selection-dropdown .menu-list li {
  padding: 3px 10px;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pdn-model-selection-dropdown .menu-list li:hover {
  background: #e6f7ff;
}

.pdn-model-selection-dropdown .menu-list li:hover .menu-item-check {
  visibility: visible;
}

.pdn-model-selection-dropdown .menu-list .menu-selected-item {
  background: #fafafa;
  font-weight: 700;
}

.pdn-model-value-error-msg {
  display: block;
  margin-top: 5px;
  padding: 4px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-bottom: 2px;
}

.pdn-apply-dialog {
  padding: 5px 10px;
  width: 100%;
  line-height: 24px;
  float: left;
  display: inline-flex;
}

.pdn-apply-model-title {
  width: 180px;
  margin-left: 5px;
}

.menu-no-result {
  padding-left: 15px;
  font-weight: 700;
  position: absolute;
  height: 30px;
  width: 100%;
  margin-top: 5px;
  z-index: 9999;
  line-height: 28px;
}

.system-library-search-title {
  margin: 5px 10px;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: bold;
  display: inline-block;
}

.system-library-search-item {
  line-height: 28px;
}

.system-library-search-span {
  padding-left: 5px;
  width: 130px;
  display: inline-block;
}

.system-library-search-input,
.system-library-search-select {
  width: calc(100% - 130px);
}

.system-library-search-clear {
  position: absolute;
  right: 8px;
  top: 5px;
  font-size: 12px;
  cursor: pointer;
}

.system-library-search-clear:hover {
  color: #63aef3;
}

.system-library-search-input .ant-input-group-addon {
  width: 30px;
}

.system-library-select-span {
  display: inline-block;
  width: 100%;
}

.model-select-content {
  padding: 0 10px;
  display: flex;
  overflow: hidden;
}

.pdn-model-select-dialog {
  width: 340px;
}

.model-select-menu-box .model-select-menu .model-select-menu-item-selectd {
  background-color: rgb(186, 231, 255);
}

.model-select-content .ant-menu-item {
  height: 30px;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  padding: 0;
}

.model-select-content .model-select-menu-item {
  border-top: solid 1px #e8e8e8;
  text-align: left;
  padding: 0 5px;
}

.ant-menu-item .decap-model-name-list .decap-model-name-title {
  height: 100%;
  width: calc(100% - 25px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.decap-model-name-title .ant-input {
  padding: 0;
  height: 30px;
  border: none;
  background: none;
  border-radius: 0;
}

.model-select-content .decap-models-content {
  padding: 0 5px;
  text-align: left;
  font-size: 15px;
  color: #000;
  cursor: default;
}

.model-select-menu-box {
  padding: 5px 0;
  border-right: solid 1px #e8e8e8;
  width: 110px;
  min-height: 180px;
  max-height: 220px;
  overflow-x: hidden;
  overflow-y: auto;
}

.model-select-selection-box .aurora-error-msg-span {
  font-size: 14px;
  width: 190px;
  border-radius: 8px;
}

.model-select-menu-box .model-select-menu {
  padding-right: 10px;
  border: none;
}

.model-select-selection-box {
  padding-top: 10px;
}

.model-select-selection-box .ant-list-item {
  padding-top: 0 !important;
}

.model-select-selection-box .ant-list-item-meta-content {
  padding-bottom: 10px;
  text-align: center;
}