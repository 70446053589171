#sierra-top-bar,
#sierra-pre-layout-top-bar {
  height: 45px;
  padding-left: 10px;
  background-color: #f3f2f1;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.sierra-top-bar-item {
  padding: 6px 10px;
  transition: all 0.3s;
  color: #005a9e;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.sierra-top-bar-item:hover {
  background-color: #edebe9;
}

.sierra-top-bar-item .iconfont,
.sierra-top-bar-item .anticon-play-circle,
.sierra-top-bar-item .anticon-setting,
.sierra-top-bar-item .anticon-loading {
  margin-right: 8px;
  font-size: 16px;
}

.sierra-top-bar-item .icon-Debug {
  font-size: 19px !important;
  line-height: 20px;
}

.sierra-top-bar-item+.sierra-top-bar-item {
  margin-left: 10px;
}

#sierra-top-bar .sierra-top-bar-item+.sierra-top-bar-result-item {
  margin-left: 0px;
  cursor: unset;
}

#sierra-top-bar .sierra-top-bar-result-item:hover {
  background-color: #f3f2f1;
}

.sierra-top-bar-item-disabled {
  color: #797979 !important;
  cursor: not-allowed !important;
}

.sierra-top-bar-option-panel .panel-modal-header {
  padding-left: 14px !important;
}

.sierra-top-bar-option-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.sierra-top-bar-option-panel .pintopin-simulation-config-block {
  min-width: 460px;
}

#sierra-top-bar.hide .sierra-top-bar-item-title {
  display: none;
}

#sierra-top-bar .sierra-top-bar-item-title {
  height: 22px;
  line-height: 20px;
}

#sierra-top-bar.hide .sierra-top-bar-item {
  padding: 6px 8px;
}

#sierra-top-bar.hide .sierra-top-bar-item .iconfont,
#sierra-top-bar.hide .sierra-top-bar-item .anticon-play-circle,
#sierra-top-bar.hide .sierra-top-bar-item .anticon-setting {
  margin-right: 0px;
}

.sierra-option-crosstalk-item {
  display: inline-block;
  position: relative;
  padding: 0px 16px 16px 16px;
}

.sierra-coupling-option-name {
  font-weight: 500;
  line-height: 28px;
  width: 80px;
  display: inline-block;
  font-size: 13px;
}

.sierra-coupling-option-sub-item {
  margin: 0px 0px 0px 30px;
  position: relative;
  display: inline-block;
}

.sierra-coupling-option-sub-item .sierra-coupling-option-name {
  font-size: 13px;
}

.sierra-coupling-options-crosstalk {
  display: inline-block;
}

.sierra-coupling-options-crosstalk .ant-switch-small {
  min-width: 26px;
  height: 14px;
  line-height: 12px;
}

.sierra-coupling-options-crosstalk .ant-switch-small::after {
  width: 10px;
  height: 10px;
}

.sierra-top-bar-item .icon-icons8-circuit {
  font-size: 19px !important;
  line-height: 20px;
}

.sierra-simulation-option-content {
  height: fit-content;
}

.sierra-top-bar-result-title {
  color: #ff610b;
  border: 1px solid #ff610b;
  border-radius: 4px;
  padding: 2px 10px;
  display: inline-block;
  cursor: pointer;
}

.sierra-top-bar-result-title .sierra-top-bar-result-icon {
  margin-left: 8px;
  font-size: 15px;
}

#sierra-top-bar.hide .sierra-top-bar-result-icon {
  margin-left: 0px;
}

#sierra-top-bar .sierra-top-bar-result:hover {
  background-color: #f3f2f1;
}

.sierra-top-bar-result-title:hover {
  color: #f87e3b;
  border: 1px solid #f87e3b;
}

.sierra-top-bar-right-item {
  position: absolute;
  right: 10px;
}

#sierra-top-bar .aurora-top-bar-vertical-line {
  margin-right: 20px;
}

.sierra-setup-measure-panel {
  top: 80px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.sierra-top-bar-report-item {
  position: relative;
  padding: 6px 0px;
}

.sierra-top-bar-report-item .sierra-top-bar-report {
  padding: 0px 14px;
}

.sierra-top-bar-report {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  height: 22px;
}

.sierra-top-bar-report-item::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  -webkit-animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  content: '';
}

.sierra-top-bar-report-icon {
  height: 22px;
  line-height: 20px;
}