.bottomLineStyle {
  z-index: 1000;
  width: 100%;
  cursor: row-resize;
  left: 0;
  position: absolute;
}

.defaultClass {
  z-index: 1;
  width: 100%;
  cursor: row-resize;
  left: 0;
  position: absolute;
  top: -4px;
}

.bottomLineStyle:hover +.bottom-line-hover {
  display: block;
}

.bottom-line-hover {
  position: absolute;
  width: 100%;
  left: 0;
  height: 16px;
  z-index: 998;
  background: #d0d0d045;
  display: none;
  transition: all 0.3s;
}

.bottom-line-border {
  z-index: 999;
  height: 1px;
  width: 100%;
  left: 0;
  background: #e2dfdf;
}