.pre-layout-component-table .ant-table-thead>tr>th .ant-table-header-column {
  display: block !important;
}

.pre-layout-component-table .ant-table-thead>tr>th:nth-child(2) .ant-table-header-column .ant-table-column-title,
.pre-layout-component-table .ant-table-thead>tr>th:nth-child(3) .ant-table-header-column .ant-table-column-title {
  padding-left: 4px
}

.pre-layout-component-table .editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.pre-layout-component-table {
  background: #ffffff;
}

.pre-layout-unit-select {
  display: flex;
  width: 100%;
  line-height: 34px;
  height: 34px;
}

.pre-layout-span {
  display: inline-block;
  width: 180px;
  height: 28px;
  line-height: 28px;
}

.pre-layout-component-table .ant-table-thead>tr>th,
.pre-layout-component-table .aurora-table-body>tr>td {
  text-align: center;
}

/* line tip */
.pre-layout-tip {
  position: absolute;
  top: 25px;
  right: 10px;
}

.pre-layout-tip .pre-layout-img-main {
  width: 15px;
  height: 15px;
  float: left;
  vertical-align: middle;
  text-align: center;
  margin-right: 5px;
  margin-top: -2px;
}

.pre-layout-tip span {
  margin-right: 25px;
  float: left;
}

.pre-layout-tip .pre-layout-img-main img {
  width: 15px;
  height: 15px;
  vertical-align: middle;
}

/* pre-layout setup table */

.pre-layout-component-table .ant-table .ant-table-content .aurora-table-body .ant-table-row td {
  position: relative;
}

.pre-layout-component-table .ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.pre-layout-component-table .aurora-table-body>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.pre-layout-component-table .ant-table-thead>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.pre-layout-component-table .aurora-table-body>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background-color: transparent;
}

.pre-layout-component-table .ant-table .ant-table-content .aurora-table-body .ant-table-row td .editable-cell-value-wrap{
  display: grid;
}

.pre-layout-component-table .ant-table .ant-table-content .aurora-table-body .ant-table-row td:hover {
  background-color: #e6f7ff;
}

.pre-layout-component-table .ant-table-header-column .ant-table-column-title {
  padding-left: 0px !important;
}

.pre-layout-component-table .ant-table-thead>tr>th {
  text-align: center;
}

.pre-layout-component-table .aurora-table-body,
.pre-layout-signals-table .aurora-table-body  {
  overflow: auto !important;
}

.pre-layout-component-table .aurora-table-body>tr>td:last-child {
  border-right: 1px solid #e8e8e8 !important;
}

.pre-layout-component-table .ant-table .aurora-table-body .ant-table-row td {
  text-align: center;
}

.pre-layout-component-table .ant-table .aurora-table-body .ant-table-row td:hover .pre-layout-hover-border {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.pre-layout-component-table .editable-row .ant-row,
.pre-layout-component-table .edit-form-item .ant-col,
.pre-layout-component-table .edit-form-item .ant-col .ant-form-item-control,
.pre-layout-component-table .edit-form-item .ant-col .ant-form-item-control .ant-form-item-children {
  position: unset !important;
}
