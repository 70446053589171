.ant-dropdown {
  z-index: 2001;
}

.ant-popover .ant-popover-inner {
  padding: 0px;
}

.ant-color-picker .ant-popover-inner {
  padding: 10px
}

.ant-input-group-addon .ant-select .ant-select-selector {
  padding: 0 5px;
}