.cascade-ir-content {
  padding: 20px;
  min-width: 1350px;
}

.cascad-content-box {
  border: 1px solid #a8c4e6;
  margin-top: 14px;
  padding: 10px;
  border-radius: 3px;
}

.ir-explorer-icon {
  color: #0d87f7;
  font-size: 16px;
  margin-left: 14px;
  cursor: pointer;
  vertical-align: middle;
}

.ir-explorer-icon-disable {
  color: #b8b8b8;
  cursor: not-allowed;
}

.ir-component-icon {
  font-size: 17px;
  color: #0d87f7;
  vertical-align: middle;
  margin-left: 20px;
}

.ir-component-icon-disable {
  color: #b8b8b8;
  cursor: not-allowed;
}

.ir-load-title .ir-add-load {
  margin-left: 6px;
  color: #3da4fa;
  cursor: pointer;
  font-size: 15px;
  vertical-align: middle;
}

.ir-load-title:hover .ir-delete-load {
  color: #3da4fa;
}

.ir-load-title .ir-delete-load {
  float: right;
  color: #ccc;
  cursor: pointer;
  font-size: 14px;
  margin-top: 5px;
}

.cascade-ir-table .ant-table-thead>tr>th .ant-table-header-column {
  display: block !important;
}

span.extend-net-select-color {
  color: #a6a6a6;
}

.ir-table-Delete {
  position: relative;
  overflow: unset;
}

.ir-load-select {
  margin-left: 10px;
}

.cascade-ir-table .ant-table-small>.ant-table-content>.aurora-table-body {
  margin-right: 20px;
  margin-left: 20px;
}

.cascade-ir-table tr.ant-table-row.editable-row.ant-table-row-level-0 {
  position: relative;
}

.ir-load-title {
  min-width: 131px;
  display: inline;
}

.ir-delete-icon {
  position: absolute;
  color: #ccc;
  right: -36px;
  top: 50%;
  margin-top: -6px;
}

.ir-delete-icon:hover {
  color: #3da4fa;
}

.ir-load-title .ir-add-error-load {
  color: #b7babd;
}

.editable-cell-value-wrap.ir-maxBgaDv-tr {
  position: relative;
  overflow: unset;
}

.IR-constraints-panel {
  overflow: unset !important;
  margin-top: 100px;
}

.constraints-panel-content {
  padding: 10px 14px;
  overflow: auto;
  font-size: 13px;
  height: 100%;
}

.content-input {
  width: 65%;
  margin-left: 15px;
}

.content-dialog {
  margin: 10px 0;
}

.content-dialog-title {
  width: 130px;
  display: inline-block;
}

.cascade-components-ignore-name {
  color: #e65b5b;
}

.cascade-components-unused-name {
  color: #e65b5b;
}

.cascade-ir-detail-icon {
  position: absolute;
  left: -30px;
  top: calc(50% - 15px);
  line-height: unset;
}

.ir-table-power {
  position: relative;
}

.cascade-ir-table .ant-table-small .ant-table-content>.aurora-table-body .editable-cell-value-wrap {
  text-overflow: unset;
  overflow: visible;
  white-space: unset;
  line-height: auto;
  height: auto;
  min-height: 30px;
  position: relative;
}