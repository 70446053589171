.aurora-library-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.aurora-library-panel .library-content-box {
  padding: 8px 14px;
}

.aurora-library-panel .library-edit-value-label {
  display: inline-block;
  width: 90px;
  vertical-align: middle;
}

.library-edit-value-label-via {
  width: 150px;
}

.aurora-library-panel .library-item {
  margin: 8px;
  display: flex;
  line-height: 28px;
  height: 28px;
}

.aurora-library-panel .library-select,
.aurora-library-panel .library-input {
  width: calc(100% - 90px);
}

.aurora-library-panel .library-input-via {
  width: calc(100% - 150px);
}

.aurora-library-panel .library-png {
  margin: 24px 0px;
  width: 100%;
  text-align: center;
  padding: 0px 16px;
}

.aurora-library-panel .library-png img {
  width: 84%;
}

.aurora-library-panel .library-item-half {
  width: calc(50% - 16px);
  float: left;
  margin-bottom: 8px;
  margin-top: 0px;
}

.aurora-library-panel .library-item-half .library-edit-value-label {
  width: 76px;
}

.aurora-library-panel .library-item-half .library-select,
.aurora-library-panel .library-item-half .library-input {
  width: calc(100% - 76px);
}

.aurora-library-panel .library-table {
  margin: 8px 8px 16px 6px;
}

.aurora-library-panel .library-item-zd-half .library-edit-value-label {
  text-align: center;
}

.aurora-library-panel .library-item-zd-half .ant-input-disabled {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
}