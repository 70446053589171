.btn-group-vertical .ant-menu-vertical.aurora-explorer-menu {
  background: unset;
  border: 0px;
  margin: 5px;
  border-inline-end: 0px;
}

.btn-group-vertical .aurora-explorer-menu .layBtn {
  margin: 0px;
  /* padding: 0 8px; */
  width: 34px;
  height: 40px;
  padding: 0px;
}

.btn-group-vertical .ant-menu-vertical .ant-menu-item {
  margin: 0px;
  padding: 0px;
  margin-bottom: 8px;
  width: 34px;
}

.btn-group-vertical .ant-menu-vertical .ant-menu-item {
  padding: 0px;
}

.btn-group-vertical .ant-menu-vertical .ant-menu-submenu-title {
  padding: 0px;
}

.btn-group-vertical .ant-menu-vertical .ant-menu-submenu-arrow {
  display: none;
}

.aurora-explorer-menu-dropdown {
  margin: 0px !important;
  width: 100% !important;
}

.aurora-explorer-menu-submenu .ant-menu-submenu-title {
  margin-left: 0px;
  margin-right: 8px;
}

.aurora-explorer-menu-submenu .ant-menu-vertical.ant-menu-sub {
  width: 120px;
  min-width: 120px;
}

.btn-group-vertical .aurora-explorer-menu .ant-menu-item-selected {
  background-color: unset;
}