.search-box {
  width: 250px;
}

.search-box .ant-input-group-addon {
  color: #fff;
  background-color: #188fff;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  cursor: pointer;
}

.search-box .ant-input-suffix>.anticon-close-circle {
  color: rgba(0, 0, 0, 0.35);
}

.search-result {
  /* max-height: 315px; */
  background-color: #fff;
  border-top: 2px solid #e4e6e7;
  border-radius: 0 0 2px 2px;
  overflow: auto;
  box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.15);
}

.result-title {
  padding-left: 15px;
  /* cursor: pointer; */
  font-weight: 700;
}

.result-list {
  padding-left: 10px;
  margin-bottom: 0;
  overflow: hidden;
}

.result-list li {
  padding-left: 20px;
  cursor: pointer;
  list-style: none;
}

.result-list li:hover {
  background: #eee;
}

.signal-select .ant-checkbox-group {
  padding: 5px 15px;
  padding-top: 5px;
  max-height: 238px;
  overflow: auto;
  width: 100%;
  border-bottom: 1px solid #ddd;
}

.signal-select>.selected>.ant-checkbox-group {
  max-height: 125px;
  overflow: auto;
}

.signal-select .ant-checkbox-group>label {
  display: block;
}

.signal-confirm {
  text-align: right;
  padding: 0 10px 5px;
}

.signal-confirm>button+button {
  /* float: right; */
  margin-left: 10px;
}

.aurora-content .nav-content .ant-checkbox-wrapper {
  color: #fff;
}

.pdn-search {
  right: 36px !important;
}

.search-select-background,
.search-select-background:hover {
  background-color: #bae7ff !important;
}

.search-select-show {
  position: relative;
  text-align: center;
  height: 28px;
  padding-top: 4px;
  background: #188fff;
  color: #fff;
  cursor: pointer;
  font-weight: 800;
}

.input-search-button {
  color: #ffffff;
}