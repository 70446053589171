/* === Sample panel === */
.vector-sample-file-panel .panel-body {
  background: #f1f1f1;
}

.vector-sample-file-panel .panel-body {
  position: relative;
}

.vector-sample-file-content {
  background: #fafafa;
  padding: 16px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  height: 400px;
}

.vector-sample-file-download {
  margin-left: 20px;
  font-size: 16px;
  color: #1890ff;
}

.vector-sample-icon-tooltip {
  z-index: 2010;
}

.vector-sample-icon-tooltip .ant-tooltip-inner {
  color: #1890ff;
  background-color: #fff;
}

.vector-sample-icon-tooltip .ant-tooltip-arrow::before {
  background-color: #fffdfd;
}

#vector-sample-file-view {
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  border: 1px solid #cdcfd2;
}