.file-content-panel {
  box-sizing: content-box;
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  border: 1px solid #cdcfd2;
}

.file-content-panel .panel-body {
  position: relative;
  padding: 16px !important;
}

.file-content-panel .dividingLine {
  top: 0px;
}

.file-content {
  background: #fafafa;
  padding: 16px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
}

.file-content-display-body {
  float: left;
  padding-left: 20px;
}

.hide-show-netlist {
  position: absolute;
  left: 6px;
  top: 18px;
  width: 24px;
  height: 24px;
  line-height: 27px;
  border-radius: 5px;
  color: #0d87f7;
  font-size: 16px;
  font-weight: bold;
  transition: 0.3s;
  box-shadow: #ffffff 0px 0px 0px 0px;
  background: #ffffff;
  z-index: 1;
  border: 1px solid#dedede;
  justify-content: center;
}

.hide-show-plot {
  position: absolute;
  left: 6px;
  top: 48px;
  width: 24px;
  height: 24px;
  line-height: 27px;
  border-radius: 5px;
  color: #0d87f7;
  font-size: 16px;
  font-weight: bold;
  transition: 0.3s;
  box-shadow: #ffffff 0px 0px 0px 0px;
  background: #ffffff;
  z-index: 1;
  border: 1px solid#dedede;
  justify-content: center;
}

.hide-show-netlist:hover,
.hide-show-plot:hover {
  color: #379af5;
  box-shadow: rgba(168, 204, 241, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.18) 0px 0px 5px 2px;
  transition: 0.3s;
  background: #fafafa;
}

.cpm-graph-right,
.cpm-graph-right-100 {
  float: right;
  height: 100%;
  width: 76%;
  position: relative;
}

.cpm-graph-right-100 {
  width: 100%;
}

.cpm-file-svg {
  position: absolute;
  left: 6px;
  top: 0;
  bottom: 0;
  right: 34%;
}

.cpm-graph-list {
  float: right;
  width: 34%;
  height: 100%;
  background: #f9f9f9;
  position: relative;
  z-index: 10;
  border-left: 1px solid #e2dfdf;
}

.cpm-graph-right-content {
  height: 100%;
}

.cpm-graph-option-list {
  overflow: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.cpm-radio-group {
  display: block;
  margin: 0 14px;
  height: 20px;
  margin-top: 16px;
}

.cpm-radio-item {
  float: left;
  line-height: 20px;
  width: 30%;
  margin: 0 !important;
}

.cpm-radio-item-current {
  width: 40%;
}

.cpm-graph-option-name {
  transition: all 0.3s;
  font-weight: bold;
  margin-top: 5px;
  cursor: pointer;
  line-height: 24px;
}

.cpm-graph-option-name .ant-checkbox-group {
  width: 100%;
}

.cpm-graph-option-name:hover {
  background-color: #e0e0e0;
  transition: all 0.3s;
}

.cpm-graph-checkbox-group>.ant-checkbox {
  top: 0px;
  left: 4px;
}

.cpm-graph-checkbox-group-name {
  display: inline-block;
  line-height: 20px;
}

.cpm-graph-checkbox-group .ant-checkbox+span {
  height: 26px;
  display: inline-block;
  padding-left: 6px;
  padding-right: 4px;
}

.cpm-graph-checkbox-group {
  width: 100%;
}

.cpm-graph-option-list .cpm-option-name {
  display: inline-block;
  max-width: 160px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.cpm-graph-curve-current {
  display: inline-block;
  margin-left: 4px;
  vertical-align: middle;
  font-weight: 400;
}

.cpm-gragh-checkbox {
  display: block;
  margin: 10px;
  min-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cpm-graph-option-content {
  min-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


/* .cpm-graph-list .decap-subckt-name {
  display: inline-block;
  max-width: 160px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
} */