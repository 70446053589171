.cascade-connection-content {
  width: 100%;
  height: 100%;
  padding: 5px;
  display: flex;
  justify-content: flex-start;
  flex-shrink: 0;
  overflow: auto;
  align-items: center;
  position: relative;
  align-items: flex-start;
}

.cascade-connection-content .plot {
  padding: 5px;
  z-index: 2000;
  position: absolute;
  top: 10px;
  left: 7px;
}

.cascade-connection-column {
  min-width: 300px;
  margin: 20px;
  min-height: 94%;
  border: 1px solid #a8c4e6;
  border-radius: 4px;
  flex-shrink: 0;
  z-index: 2001;
}

.cascade-connection-column .delete-icon:hover {
  color: #1890ff
}

.cascade-connection-column .connector-setting {
  height: 30px;
  position: relative;
}

.cascade-connection-column .connector-setting .add-pcb-button {
  padding-top: 3px
}

.cascade-connection-column .connector-setting .delete-icon {
  position: absolute;
  right: 5px;
  top: 5px;
}

.cascade-connector-setup-pcb {
  margin: 0px 10px 10px 10px;
  padding: 5px 5px 10px 5px;
  background: #eaeaea;
  border-radius: 4px;
  position: relative;
}

.cascade-connector-setup-pcb .pcb-select {
  margin-top: 10px;
  position: relative;
}

.cascade-connector-setup-pcb .pcb-select>span:not(.anticon) {
  width: 100px;
  padding-left: 10px;
  display: inline-block;
}

.cascade-connector-setup-pcb .pcb-select .ant-select {
  width: calc(100% - 120px)
}

.cascade-connector-setup-pcb .pcb-select .delete-icon {
  position: absolute;
  right: 0px;
  top: -3px;
}

.cascade-connector-setup-pcb .connector-select .delete-icon {
  position: absolute;
  right: 1px;
  top: 6px;
  font-size: 12px;
}

.cascade-select-dropdown-list {
  z-index: 2000;
}

.cascade-connection-empty-column {
  text-align: center;
  background: #fafafa;
  border: 1px dashed #a8c4e6;
  border-radius: 4px;
  cursor: pointer;
  vertical-align: middle;
  height: 94%
}

.cascade-connection-empty-column>div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.cascade-connection-empty-column>div .plus-icon {
  font-size: 48px;
  color: #a8c4e6;
}

.cascade-connector-setup-pcb .connector-select .connection-button,
.cascade-net-connection-column-content-net .connection-net-button {
  position: absolute;
  width: 10px;
  height: 10px;
  border: 3px solid #1890ff;
  border-radius: 50%;
  top: 7px;
  cursor: pointer;
}

.cascade-connector-setup-pcb .connector-select .prev-button {
  left: -15px;
}

.cascade-connector-setup-pcb .connector-select .next-button {
  right: -15px;
}

.cascade-net-connection-column-content-net .prev-button {
  left: -17px;
  top: 5px
}

.cascade-net-connection-column-content-net .next-button {
  right: -17px;
  top: 5px
}

.cascade-connect-draw-line-status {
  z-index: 2002 !important;
}

.cascade-connector-line:hover {
  stroke: red;
  cursor: pointer;
}

.cascade-nets-select {
  stroke: red;
}

.cascade-net-connection-content {
  justify-content: space-between;
  align-items: baseline;
}

#cascade-net-connection-content {
  overflow: hidden;
}

.cascade-net-connection-column {
  width: 40%;
  min-height: 92%;
}

.cascade-net-connection-column-content {
  text-align: center;
  padding: 5px;
  position: relative;
}

.cascade-net-connection-column-content-comp {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.cascade-net-connection-column-content-net {
  font-size: 12px;
  position: relative;
}

.cascade-net-connection-column-net-del {
  font-size: 8px;
  position: absolute;
  top: 4px;
  cursor: pointer;
  right: 0px;
}

.cascade-net-connection-delete {
  position: absolute;
  left: 44%;
  top: 40px;
  font-size: 12px;
  cursor: pointer;
  z-index: 2002;
}

.cascade-net-connection-delete:hover {
  color: #1890ff;
}

.cascade-net-connection-column-content-net .dashed {
  border-top: 1px dashed #000000;
  position: absolute;
  margin-top: 8px;
}

.cascade-schematic-content {
  width: calc(100% - 22px);
  height: calc(100% - 22px);
  border: 1px solid #a8c4e6;
  margin: 10px;
  border-radius: 4px;
  overflow: hidden;
}

.cascade-schematic-setting-icon {
  padding-left: 10px;
  font-size: 16px;
  color: #40a9ff;
  cursor: pointer;
}

.cascade-schematic-advanced {
  padding: 15px;
  width: 100%;
  height: 100%;
}

.cascade-schematic-advanced .advanced-item {
  width: 100%;
  padding: 0 5px;
  margin-bottom: 14px;
}

.cascade-schematic-advanced .advanced-item .advanced-title {
  width: 140px;
  display: inline-block;
}

.cascade-schematic-advanced .advanced-item .advanced-content {
  width: calc(100% - 150px);
  display: inline-block;
}

.cascade-schematic-advanced .advanced-item .advanced-content .font-button {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.cascade-schematic-advanced .advanced-item .advanced-content .schematic-download-button {
  height: 24px;
}

.cascade-schematic-loading {
  top: 40%
}

.cascade-additional-nets-radio {
  display: inline-block;
  margin-left: 20px;
}

.cascade-additional-nets-select {
  max-width: calc(100% - 440px);
  min-width: 300px;
}

.cascade-connection-nets-setting {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.cascade-connection-nets-setting:hover {
  color: #1890ff;
}

.cascade-connection-setting-option {
  margin: 20px;
}

.cascade-connection-setting-option-body {
  display: inline-block;
  width: 150px;
}

.cascade-connection-setting-option-select {
  width: calc(100% - 160px);
}

.cascade-connection-info-circle {
  color: #1890ff;
  padding-left: 5px;
}

.cascade-net-connection-column-content-input {
  padding: 10px;
}

.cascade-net-connection-column-content-auto-match-icon {
  vertical-align: middle;
}

.cascade-net-connection-column-content-auto-match-icon:hover {
  color: #1890ff;
}

.port-setup-tabs {
  width: 50%;
}

.port-setup-tabs-content {
  margin-left: 10px;
  margin-bottom: -15px;
  margin-top: 10px;
}

.cascade-rl-model-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.cascade-rl-model-title {
  font-size: 14px;
  font-weight: 500;
}

.cascade-rl-model-panel .cascade-rl-model-content {
  margin: 10px;
  overflow: hidden;
  font-size: 13px;
}

.cascade-rl-model-selection {
  width: 100%;
}

.cascade-rl-model-row .cascade-rl-model-selection .ant-select-selector,
.cascade-rl-model-row .cascade-rl-model-selection,
.cascade-rl-model-row .cascade-rl-model-selection .ant-select-selection {
  height: 24px;
  line-height: 22px;
  font-weight: 500;
  font-size: 13px;
}

.cascade-rl-model-row .cascade-rl-model-selection .ant-select-selection--multiple {
  min-height: 24px;
}

.cascade-rl-model-row .ant-select-selection--multiple .ant-select-selection__choice__content {
  font-size: 12px;
}

.cascade-rl-model-row .cascade-rl-model-selection .ant-select-selection--multiple .ant-select-selector>ul>.ant-select-search--inline {
  margin-top: 0px;
  font-size: 14px;
}

.cascade-rl-model-select-dropdown {
  z-index: 10000000;
}

.sierra-package-model-item-select {
  width: 50%;
}

.cascade-rl-model-panel .ant-input {
  height: 24px;
}

.cascade-rl-model-row {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  font-weight: 500;
}

.cascade-rl-model-row>*:first-child {
  width: 100px;
  height: 24px;
  line-height: 24px;
}

.cascade-rl-model-row>*:last-child {
  width: calc(100% - 100px);
}

.cascade-rl-sweep-create {
  display: flex;
  gap: 10px;
  padding: 0 10px;
  margin-top: 10px;
  justify-content: flex-end;
}

.cascade-rl-value-tagsInput {
  width: 100%;
}

.cascade-rl-sweep-create-btn {
  height: 28px;
}