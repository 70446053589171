.port-setup {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}

.port-tag-setup {
  height: 94%;
}

.port-setup-content {
  width: 50%;
  height: 100%;
}

.port-setup-canvas {
  height: 100%;
  padding-bottom: 0px;
}

.port-setup-border {
  border: 1px solid #a8c4e6;
  padding: 10px;
  border-radius: 3px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
}

.port-setup-add-icon {
  color: #0d87f7;
  font-size: 16px;
  margin-left: 14px;
  cursor: pointer;
  vertical-align: middle;
}

.port-setup-title-color {
  color: #0b3694
}

.port-setup-desc-gorup {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}

.port-setup-desc {
  width: 50%;
}

.port-setup-desc .desc-body {
  margin-top: 10px;
}

.port-setup-desc .desc-body .ant-descriptions-view .ant-descriptions-row {
  display: block;
  max-height: 300px;
  overflow-y: auto;
}

.port-setup-desc .desc-body .ant-descriptions-view .ant-descriptions-row th {
  display: block;
}

.port-setup-desc .desc-body .ant-descriptions-view .ant-descriptions-row .ant-descriptions-item-content {
  min-height: 40px;
}

.port-setup-desc .desc-body .ant-descriptions-view .ant-descriptions-row .ant-descriptions-item-content>span:empty {
  display: inline-block;
}

.port-setup-tag {
  margin: 4px;
  cursor: pointer;
  text-align: center;
  min-width: 32px;
}

.port-setup-canvas-pin-name {
  position: absolute;
  z-index: 9999;
  padding: 10px;
  display: inline-block;
  vertical-align: middle;
  background-color: #000000;
  width: fit-content;
  right: 0px;
  bottom: 0px;
}

.port-setup-layout-canvas-coord {
  position: absolute;
  padding: 5px 10px;
  bottom: 0px;
  color: #fff;
  float: right;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.port-setup-canvas-pin-title {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
}

.port-setup-canvas-pin-switch {
  margin-left: 10px;
  margin-top: -5px;
}

.port-setup-canvas-pin-name .unshow-switch {
  background-color: #999999;
}

.port-canvas-context-menu {
  position: absolute;
  width: 100px;
  height: 50px;
  background-color: #ffffff;
}

.ports-setup-table-icon-group {
  position: absolute;
  right: 5px;
  top: 8px;
}

.ports-setup-table-icon-group .anticon {
  width: 24px;
  height: 24px;
  line-height: 27px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  -webkit-transition: .3s;
  transition: .3s;
  box-shadow: 0 0 0 0 #fff;
  background: #fff;
  z-index: 1;
  border: 1px solid#dedede;
  cursor: pointer;
  margin: 0 5px;
  justify-content: center;
}

.ports-setup-table-icon-group .anticon:hover {
  color: #0d87f7;
  box-shadow: rgba(168, 204, 241, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.18) 0px 0px 5px 2px;
  transition: 0.3s;
  background: #fafafa;
}

.ploc-icon {
  color: rgb(89, 89, 89)
}

.ports-group-setup-table .ant-table-small .aurora-table-body .editable-cell-value-wrap {
  text-overflow: unset;
  overflow: visible;
  white-space: unset;
  line-height: auto;
  height: auto;
  min-height: 30px;
  position: relative;
  max-height: 200px;
  overflow-y: auto;
}

.ports-table-delete-icon {
  position: absolute;
  right: 0px;
  margin-top: 3px;
  color: #ccc;
  cursor: pointer;
}

.ports-table-delete-icon:hover {
  color: #3da4fa
}

.port-setup-table-index {
  cursor: pointer;
  position: relative;
}

.port-setup-table-eye-icon {
  margin-left: 10px;
  font-size: 16px;
  color: #40a9ff;
  cursor: pointer;
  vertical-align: middle;
}

#port-setup-canvas polygon:hover,
#port-setup-canvas cicle:hover,
#port-setup-canvas path:hover,
#port-setup-canvas polyline:hover {
  stroke: unset
}

#port-setup-canvas .lay_geom:hover {
  stroke: yellow;
}

#port-setup-canvas .layer_pin:hover {
  stroke: orange;
}

#port-setup-edit-ref-pin-menu {
  position: absolute;
  padding-right: 10px;
  padding-left: 8px;
  height: 32px;
  width: fit-content;
  background-color: #fff;
}

.port-setup-close-selected-icon {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.65);
  position: absolute;
  right: 8px;
  top: 50%;
  margin-top: -7px;
}

#port-setup-canvas-main {
  width: 100%;
  height: 100%;
  background-color: #000000;
}

.pin-positive-port-box {
  fill: red;
  opacity: 1;
  display: unset;
}

.pin-negative-port-box {
  fill: blue;
  opacity: 1;
  display: unset;
}

.pin-opacity-port-1 {
  opacity: 1;
}

.pin-opacity-port-0-6 {
  opacity: 0.6;
}

.pin-opacity-port-0-2 {
  opacity: 0.2;
}

.port-setup-panel {
  margin-top: 60px;
  box-shadow: rgb(76 77 78 / 14%) 0px -2px 7px 8px, rgb(0 0 0 / 10%) 0px 0px 10px 3px;
}

.port-setup-panel-name {
  font-size: 16px;
  font-weight: 500;
}

.port-setup-panel-content {
  padding-right: 15px;
  margin-bottom: 6px;
  overflow: hidden;
}

.port-setup-panel-main {
  margin: 15px;
}

.port-setup-panel-content .port-setup-panel-content-input {
  display: inline-block;
  width: 55%;
  vertical-align: middle;
  height: 28px;
  line-height: 28px;
  font-size: 13px;
}

.port-setup-panel-content .port-setup-panel-content-input.aurora-select {
  margin-top: 3px;
}

.port-setup-panel-content-body {
  padding-left: 15px;
  font-size: 14px;
  vertical-align: middle;
  display: inline-block;
  width: 45%;
  line-height: 28px;
}

.port-setup-panel-button {
  margin-left: 6px;
  float: right;
}

.port-setup-panel-error-msg {
  display: block;
  padding: 8px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 10px;
}

.port-setup-ploc-msg {
  display: block;
  padding: 8px;
  border-radius: 8px;
  font-weight: 500;
}

.port-setup-ploc-msg .anticon {
  margin-right: 10px;
}

.port-setup-ploc-error-msg {
  background: #ffdce0;
  color: #cb2431;
}

.port-setup-ploc-success-msg {
  background-color: #f6ffed;
  color: #1b8409;
}

.port-setup-ploc-loading-msg {
  background-color: #bae7ff;
  color: #0b3b94;
}

.port-setup-panel-content-slider {
  margin: 8px 0px -4px !important;
}

.port-setup-panel-advanced-body {
  padding-left: 0px;
}

.port-setup-panel-advanced-body .advanced-arrow-icon {
  font-size: 10px;
  padding-right: 5px;
}

.port-setup-panel-sub-span {
  padding-left: 30px;
}

.port-setup-panel-content-input .ant-input {
  height: 28px;
  line-height: 28px;
  font-size: 13px;
}

.port-setup-panel-content-input .ant-input-group-addon {
  font-size: 13px;
}

.port-setup-panel-content-input-addonAfter .ant-input-group-addon {
  width: 65px;
}

.ploc-content-item {
  display: inline-block;
  width: 100%;
  margin: 3px 0px;
}

.ploc-content-item>span:not(.anticon) {
  width: 140px;
  display: inline-block;
}

.ploc-content-item .aurora-select {
  width: calc(100% - 200px);
}

.ploc-edit-content-item {
  width: 100%;
  height: 28px;
  line-height: 28px;
  margin: 6px 0px;
}

.ploc-content-icon {
  cursor: pointer;
  color: #1890ff;
}

.ploc-content-body {
  margin: 10px 16px;
}

.port-setup-canvas-pin-show-text {
  position: absolute;
  z-index: 9999;
  padding: 10px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  width: 50%;
}

.port-setup-canvas-pin-show-span {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
}

.port-setup-canvas-pin-here-span {
  text-decoration: underline;
  cursor: pointer;
}

.port-setup-canvas-pin-here-span:hover {
  color: #bae7ff;
}