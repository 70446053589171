.pintopin-simulation-config-block {
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: stretch;
  padding-bottom: 10px;
}

.pintopin-simulation-config-item {
  line-height: 32px;
  display: flex;
  justify-content: center;
}

.pintopin-simulation-config-item+.pintopin-simulation-config-item {
  margin-top: 10px;
}

.pintopin-simulation-config-name {
  font-weight: 500;
  margin-right: 10px;
  width: 80px;
  font-size: 13px;
  line-height: 28px;
  color: rgb(0, 0, 0, 0.65);
}

.pintopin-simulation-config-advanced-name {
  font-weight: 500;
  margin-right: 10px;
  width: 240px;
  font-size: 13px;
  line-height: 28px;
  margin-left: 13px;
}

.pintopin-simulation-config-value {
  width: calc(100% - 100px);
}

.pintopin-simulation-config-item .ant-input,
.pintopin-simulation-config-item .ant-select-selector,
.pintopin-simulation-config-item .ant-select-selection-selected-value,
.pintopin-simulation-config-item .ant-select-selection {
  height: 28px !important;
  line-height: 28px !important;
  font-size: 13px !important;
}

.pintopin-simulation-config-item .ant-select-selector {
  margin: 0 6px;
}

.pintopin-simulation-config-item .pintopin-simulation-config-value.ant-select .ant-select-selector {
  margin: 0px;
}

.pintopin-simulation-config-item .ant-select-arrow {
  right: 5px;
  top: calc(50% + 1px);
}

.pintopin-simulation-config-item .ant-input-group-addon .ant-select {
  width: 70px;
  height: 28px;
}

.pintopin-simulation-config-item .ant-input-group-addon .ant-select-selection-item {
  height: 28px;
}

.pintopin-simulation-config-item .ant-select-dropdown-menu-item {
  font-size: 13px;
  line-height: 22px;
}

.pintopin-simulation-config-error-msg {
  display: block;
  padding: 6px 10px;
  margin-top: 8px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
}

.pintopin-simulation-options-input-select {
  position: absolute;
  left: 0px;
  bottom: 0px;
  right: 0px;
  height: 0px;
  border: none;
  box-shadow: 0px 0px 0px;
  width: 100%;
  height: 0px !important;
}

.pintopin-simulation-options-input-select .ant-select-selector {
  display: none;
}

.pintopin-simulation-options-input-select .ant-select-selection:focus,
.pintopin-simulation-options-input-select .ant-select-selection:active,
.pintopin-simulation-options-input-select .ant-select-selection {
  border: none;
  height: 0px;
  box-shadow: 0px 0px 0px;
}

.pintopin-simulation-options-select {
  z-index: 1000000;

}

.pintopin-simulation-options-select .ant-select-dropdown-menu-item {
  padding: 4px 11px;
  font-size: 13px;
  line-height: 20px;
}

.pintopin-simulation-option-mdlfm-content {
  width: calc(100% - 250px);
  display: inline-block;
  position: relative;
  line-height: 28px;
}

.pintopin-simulation-mdflm-input {
  z-index: 1000;
}

.pintopin-simulation-config-advanced {
  width: calc(100% - 90px);
  margin-left: 90px;
  margin-top: 10px;
}

.pintopin-simulation-config-advanced-title {
  font-size: 13px;
  cursor: pointer;
}

.pintopin-simulation-config-advanced-title i {
  font-size: 10px;
  margin-right: 4px;
  vertical-align: middle;
  margin-top: -4px;
}

.pintopin-simulation-config-advanced-name {
  font-weight: 500;
  margin-right: 10px;
  width: 240px;
  font-size: 13px;
  line-height: 28px;
  margin-left: 13px;
}

.pintopin-simulation-config-hspice-advanced .pintopin-simulation-config-item .pintopin-simulation-config-advanced-name {
  margin-left: 0px;
  width: 114px;
}

.pintopin-simulation-config-hspice-advanced .pintopin-simulation-config-item {
  justify-content: left;
  height: 28px;
}

.pintopin-simulation-config-hspice-advanced .pintopin-simulation-config-item .cores-help-icon {
  margin-right: 5px;
  line-height: 32px;
  height: 28px;
}

.pintopin-simulation-config-hspice-advanced .pintopin-simulation-config-item .pintopin-simulation-config-value {
  width: 70px;
  height: 28px;
  line-height: 28px;
  font-size: 12px;
}

.pintopin-simulation-config-hspice-advanced .pintopin-simulation-config-item .pintopin-simulation-config-hpice-content {
  line-height: 28px;
}

.pintopin-simulation-config-hspice-advanced .pintopin-simulation-config-item .pintopin-simulation-config-hpice-content .ant-input-number-input-wrap {
  height: 28px;
  line-height: 28px;
}

.pintopin-simulation-config-hspice-advanced .pintopin-simulation-config-item .pintopin-simulation-config-hpice-content .ant-input-number-input-wrap .ant-input-number-input {
  height: 26px;
  line-height: 26px;
}

.pintopin-simulation-config-setting {
  color: #1890ff;
  cursor: pointer;
  width: 26px;
  margin-top: 2px;
  padding-left: 10px;
}

.pintopin-simulation-config-item .pintopin-simulation-config-time-step-value {
  width: calc(100% - 126px);
}

.pull-down-select-dropdown {
  z-index: 999999;
}