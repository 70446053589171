.andes-v2-result-main .aurora-eye-diagram-main {
  position: absolute;
  top: 44px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  min-width: 520px;
  overflow: auto;
  padding: 14px 0px;
}

.andes-v2-result-main .aurora-result-eyediagram {
  position: relative;
  width: 100%;
  padding-top: 10px;
}

.andes-v2-eye-close {
  position: absolute;
  right: 20px;
  top: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: none;
  background-color: #c7e0f4;
  z-index: 40;
  text-align: center;
  line-height: 30px;
  border-radius: 4px 0px 0px 4px;
}

.andes-v2-result-main .aurora-result-eyediagram:hover .andes-v2-eye-close {
  display: block;
}

.andes-v2-eye-close .andes-v2-eye-close-icon {
  font-size: 16px;
  color: #40a9ff;
}

.andes-v2-result-main .aurora-result-half-eyediagram {
  width: 50%;
  min-width: 390px;
}

.andes-v2-eye-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.andes-v2-result-main .aurora-result-eyediagram .eyediagram-left {
  width: 100%;
  text-align: center;
  min-height: 100px;
}

.andes-v2-result-main .aurora-result-eyediagram .eyediagram-left .ant-spin-nested-loading,
.andes-v2-result-main .aurora-result-eyediagram .eyediagram-left .ant-spin-container {
  width: 100%;
  height: 100%;
  min-height: 100px;
}

.andes-v2-result-main .aurora-result-eyediagram .eyediagram-setting {
  width: 90%;
  padding: 10px;
}

.andes-v2-result-main .aurora-result-eyediagram .aurora-eye-item-flex {
  padding: 0px 10px;
  padding-bottom: 10px;
}

.andes-v2-result-main .aurora-result-eyediagram .aurora-eye-item {
  margin-top: 0px;
  margin-right: 20px;
  height: 100%;
  max-width: 100%;
}

/* @media (max-width:950px) {
  .andes-v2-result-main .aurora-result-half-eyediagram {
    width: 100%;
  }

  .andes-v2-result-main .andes-v2-eye-list {
    display: block;
  }
} */

.andes-result-eyediagram-title {
  display: inline-block;
  margin: 10px 20px;
  font-weight: bold;
  margin-top: 16px;
}

.andes-v2-eye-select-div {
  height: 28px;
  line-height: 28px;
  margin: 0px 20px;
}

.andes-v2-eye-select-title {
  float: left;
  width: 100px;
  font-weight: bold;
}

.andes-v2-eye-select-div .aurora-select {
  width: calc(100% - 100px);
}

.andes-v2-result-main .aurora-result-eyediagram .eyediagram-signals {
  border-top: none;
  border-radius: unset;
}

.andes-v2-result-main .aurora-result-eyediagram .eyediagram-result-select .signal-select {
  color: rgba(0, 0, 0, 0.85);
}

.aurora-eye-diagram-add-div {
  margin: 0px 20px;
  width: 190px;
  padding: 0px 4px;
  font-size: 12px;
  height: 28px;
  line-height: 25px;
}

.aurora-eye-diagram-add-div>span {
  vertical-align: middle;
}

.aurora-eye-diagram-add-div .anticon {
  font-size: 12px;
  vertical-align: middle;
}

.andes-result-eye-param-spec {
  color: #0c5fa3;
}

.andes-eye-diagram-setup-table .ant-table-small>.ant-table-content>.aurora-table-body>table>.aurora-table-body>tr>td {
  word-break: break-word;
}

.eye-setting-table-title {
  color: rgba(0, 0, 0, 0.85);
}

.eye-setting-collapse-content>.ant-collapse-item:last-child>.ant-collapse-header {
  padding: 4px 12px;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0px;
}

.eye-setting-collapse-content {
  border: none;
}

.eye-setting-collapse-content .ant-collapse-content>.ant-collapse-content-box {
  padding: 0px;
}

.eye-setting-collapse-content>.ant-collapse-item {
  border-bottom: none;
}

.eye-setting-collapse-content .ant-collapse-content {
  border-top: none;
}

.eye-setting-collapse-content>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  left: 10px;
}

.aurora-eye-diagram-main .andes-eye-img {
  max-width: 100%;
}

.andes-eye-params-ads-table .ant-table-small {
  border-radius: 0;
}

.andes-eye-diagram-setup-table .ant-table-small {
  border-radius: 0px 0px 4px 4px;
  border-top: none;
}

.andes-eye-params-compliance-table .ant-table-small {
  border-radius: 0px 0px 4px 4px;
}

.andes_eye-params-compliance-table .ant-table-small {
  border-radius: 0;
}

.andes-eye-params-compliance-collapse-table .ant-table-small {
  border-radius: 0;
  border-top: none;
}

.eye-compliance-collapse-content>.ant-collapse-item .ant-collapse-header {
  padding: 4px 12px;
  padding-left: 30px;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0px;
}

.andes-eye-params-compliance-collapse-table .ant-table .ant-table-row-indent+.ant-table-row-expand-icon {
  display: none;
}

.eye-param-table-disable-td,
.eye-param-table-fail-td {
  color: #f12727;
}

.eye-param-table-pass-td {
  color: #52c41a;
}