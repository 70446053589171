.virtual-component-table-main .virtual-component-add-icon {
  color: #1890ff;
  margin-left: 12px;
  cursor: pointer;
}

.virtual-component-table tbody>tr>td:last-child {
  position: relative;
}

#layout-setup-canvas-main {
  width: 100%;
  height: 100%;
  position: relative;
}

.layout-setup-canvas-net-name {
  position: absolute;
  z-index: 9999;
  padding: 10px;
  display: inline-block;
  vertical-align: middle;
  background-color: #000000;
  width: 320px;
}

.layout-setup-canvas-net-name .un-show-switch {
  background-color: #999999;
}

.layout-setup-canvas-net-title {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
}

.layout-setup-canvas-net-switch {
  margin-left: 10px;
  margin-top: -5px;
}

.layout-setup-canvas {
  height: 100%;
  padding-bottom: 0px;
  width: 100%;
  background-color: #000000;
  stroke-linecap: round;
  stroke-linejoin: round;
  transform: translateZ(0);
}

.virtual-component-edit-main {
  padding: 12px 14px;
}

.virtual-component-edit-table-content {
  margin-bottom: 10px;
}

.virtual-component-view-icon,
.virtual-component-clean-icon {
  cursor: pointer;
  margin-left: 10px;
  vertical-align: middle;
}

.virtual-component-view-selected-icon,
.virtual-component-clean-icon {
  color: #1890ff;
}

.virtual-component-comps {
  vertical-align: middle;
}

.virtual-component-edit-table tbody>tr>td:last-child {
  position: relative;
}

.virtual-component-edit-table .virtual-component-edit-cut-length {
  margin-right: 22px;
}

.virtual-component-edit-table .ant-table-content>.aurora-table-body .virtual-component-edit-cut-length:hover .signal-delete-icon {
  color: #1890ff;
}

.virtual-component-edit-main .aurora-model-name-error-msg {
  margin-top: 8px;
}

.virtual-component-layout-canvas-coord {
  position: absolute;
  left: 5px;
  bottom: 10px;
  color: #fff;
  float: right;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.virtual-component-selected-row,
.virtual-component-selected-row:hover,
.virtual-component-edit-table .virtual-component-selected-row:hover>td,
.virtual-component-edit-table .virtual-component-selected-row>td {
  background-color: #9cd8ff;
}

.virtual-component-edit-table .ant-table .ant-table-content .aurora-table-body>tr {
  cursor: pointer;
}

.virtual-component-table .editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.virtual-comp-value-item {
  height: 28px;
  line-height: 28px;
  display: flex;
  justify-content: space-between;
}

.virtual-comp-value-item>span {
  width: 114px;
}

.virtual-comp-value-item>.aurora-input {
  width: calc(100% - 114px);
}

.virtual-comp-value-item>.aurora-input .ant-input-group-addon {
  width: 60px;
}

.virtual-comp-cap-value-item {
  margin-bottom: 10px;
}