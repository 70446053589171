#cascade-top-bar {
  height: 45px;
  padding-left: 10px;
  background-color: #f3f2f1;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 300;
}

.cascade-top-bar-item {
  margin: 6px 6px 6px 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: #005a9e;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 33px;
}

.cascade-not-allow-pcb {
  color: #797979;
}

.cascade-top-bar-result-item {
  margin: 6px 6px 6px 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: #005a9e;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 33px;
}

.cascade-top-bar-item:hover {
  background-color: #e0e0e0;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.cascade-top-bar-item .anticon-play-circle,
.cascade-top-bar-item .anticon-loading {
  margin-right: 8px;
  font-size: 16px;
  margin-top: -2px;
}

.cascade-top-bar-item .anticon-file-text {
  margin-right: 8px;
  font-size: 16px;
  margin-top: -2px;
}

.cascade-top-bar-item-disabled {
  color: #797979 !important;
  cursor: not-allowed !important;
}

.cascade-top-bar-tip .ant-tooltip-inner {
  color: #00aaff;
  background-color: #fff;
}

.cascade-top-bar-tip .ant-tooltip-arrow::before {
  background-color: #fff;
}

.cascade-sus-report-icon {
  margin-right: 8px;
  font-size: 16px;
}

.cascade-confirm-Tips-icon {
  font-size: 22px;
}

.cascade-top-bar-item-disable {
  cursor: not-allowed !important;
  color: #909294 !important;
}

.import-panel-content {
  text-align: center;
}

.cascade-top-bar-setup {
  display: flex;
  align-items: center;
  margin: 6px 0;
  padding: 0 14px;
}

.cascade-top-bar-result-vertical-line {
  height: 16px;
  background-color: #c7c7c7;
  width: 1px;
  margin-right: 28px;
}

.cascade-top-bar-result-vertical-line.mirror-line {
  margin-right: 0px;
  margin-left: 28px;
}

.cascade-top-bar-item-disabled .cascade-top-bar-result-title,
.cascade-top-bar-item-disabled .cascade-top-bar-result-title:hover {
  color: #797979 !important;
  border: 1px solid #797979;
}

.cascade-top-bar-result-title {
  color: #ff610b;
  border: 1px solid #ff610b;
  border-radius: 4px;
  padding: 2px 10px;
  display: inline-block;
}

.cascade-top-bar-result-title .cascade-top-bar-result-icon {
  margin-left: 8px;
  font-size: 15px;
}

.cascade-top-bar-result-title .cascade-top-bar-result-icon.mirror-icon {
  margin-right: 8px;
  margin-left: 0px;
}

.cascade-top-bar-setup i.iconfont.icon-Debug {
  margin-right: 8px;
}

#cascade-top-bar.hide .cascade-top-bar-item-hide {
  display: none;
}

#cascade-top-bar.hide .top-bar-report-icon {
  margin-right: 0px;
  margin-top: -1px;
}

#cascade-top-bar.hide .cascade-top-bar-item .iconfont,
#cascade-top-bar.hide .cascade-top-bar-item .anticon-play-circle,
#cascade-top-bar.hide .cascade-top-bar-item .anticon-setting,
#cascade-top-bar.hide .cascade-top-bar-item .cascade-download-icon,
#cascade-top-bar.hide .cascade-top-bar-item .cascade-schematics-icon {
  margin-right: 0px;
}

#cascade-top-bar.hide .cascade-top-bar-result-icon {
  margin-left: 0px;
  margin-right: 0px;
}

.cascade-setting-icon {
  margin-right: 8px;
}

.cascade-schematics-icon {
  transform: rotate(90deg);
}

.cascade-download-icon {
  margin-right: 8px;
  margin-top: -2px;
}

.cascade-top-option-panel {
  margin-top: 60px;
  box-shadow: rgb(76 77 78 / 14%) 0px -2px 7px 8px, rgb(0 0 0 / 10%) 0px 0px 10px 3px;
}

.cascade-top-option-panel .panel-body .ant-tabs .ant-tabs-nav {
  padding: 0 20px;
}

.cascade-top-option-panel-name {
  font-size: 16px;
  font-weight: 500;
}

.cascade-top-option-panel-title {
  padding-left: 4px;
  font-weight: 500;
  margin-bottom: 4px;
}

.cascade-top-option-panel-main {
  margin: 20px;
}

.cascade-top-extraction-panel-main {
  min-width: 650px !important;
  min-height: 340px;
}

.cascade-top-opt-panel-main {
  min-width: 267px;
}

.cascade-top-option-panel-main .ant-divider-horizontal {
  margin: 10px 0px;
}

.cascade-top-option-panel-content {
  padding: 0 10px;
  margin-bottom: 6px;
}

.cascade-top-option-panel-content-sub-title {
  padding: 0 10px;
  margin-bottom: 6px;
  font-weight: 500;
}

.cascade-top-option-panel-content-title {
  font-weight: 700;
}

.cascade-top-option-panel-content-body {
  font-size: 14px;
  vertical-align: middle;
  display: inline-block;
  width: 45%;
  line-height: 28px;
}

.cascade-top-option-panel-extra-content-body {
  font-size: 13px;
  vertical-align: middle;
  display: inline-block;
  line-height: 20px;
}

.cascade-top-option-panel-content-pad-body {
  width: 25%;
}

.cascade-top-option-panel-content-longer-body {
  font-size: 14px;
  vertical-align: middle;
  display: inline-block;
  width: 70%;
  line-height: 28px;
}

.cascade-top-option-panel-content-body-sub {
  font-size: 14px;
  vertical-align: middle;
  display: inline-block;
  width: 16%;
  line-height: 28px;
}

.cascade-top-option-panel-content-sub-switch {
  display: inline-block;
  margin-left: 2%;
}

.cascade-top-config-panel .cascade-top-option-panel-content-body {
  width: 65%
}

.cascade-extraction-panel-sub-span {
  padding-left: 20px;
}

.cascade-extraction-panel-next-sub-span {
  padding-left: 40px;
}

.cascade-extraction-panel-next-next-sub-span {
  padding-left: 60px;
}

.cascade-top-option-panel-content .cascade-option-clipping-input {
  width: calc(45% - 80px) !important;
  margin-left: 20px;
}

.cascade-top-option-panel-content .cascade-option-clipping-input-siwave {
  width: calc(55% - 50px) !important;
  margin-left: 20px;
}

.cascade-top-option-panel-content .cascade-option-advanced-mix-input {
  width: 32% !important;
  margin-left: 20px;
}

.cascade-top-option-panel-content .preview-button,
.cascade-top-option-panel-content .preview-button-focus {
  margin-right: 0px;
}

.cascade-top-option-panel-content .cascade-top-option-panel-content-input {
  display: inline-block;
  width: 55%;
  vertical-align: middle;
  height: 28px;
  line-height: 28px;
}

.cascade-top-option-panel-content .cascade-top-option-panel-content-advanced-input {
  width: 28%;
}

.cascade-top-option-panel-content .cascade-top-option-panel-content-pad-input {
  width: 25%;
}

.cascade-top-option-panel-content .cascade-top-option-panel-content-mesh-input,
.cascade-top-option-panel-content-sub-title .cascade-top-option-panel-content-mesh-input {
  width: 22%;
  margin-left: 17px;
}

.cascade-top-option-panel-content-input {
  font-size: 13px;
}

.cascade-extraction-content {
  margin: 4px 0px;
  margin-left: 10px;
  line-height: 28px;
}

.cascade-top-option-panel-content .cascade-extraction-content-body {
  vertical-align: middle;
  display: inline-block;
  text-align: center;
}

.cascade-sweep-content {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  padding: 10px;
  border-radius: 4px;
}

.cascade-top-option-panel-content .cascade-extraction-input-item {
  width: calc(24% - 26px);
}

.cascade-top-option-panel-content .cascade-extraction-select-item,
.cascade-top-option-panel-content .cascade-extraction-step-width {
  width: calc(14% - 6px);
}

.cascade-extraction-delete-icon {
  margin-left: 18px;
  color: #ccc;
  vertical-align: middle;
}

.cascade-extraction-delete-icon:hover {
  color: #3da4fa;
}

.cascade-extraction-empty {
  color: rgba(0, 0, 0, .25);
  font-size: 14px;
  text-align: center;
  padding: 4px;
}

.cascade-top-option-panel-content-input .ant-input {
  height: 28px;
  line-height: 28px;
  font-size: 13px;
}

.cascade-top-option-panel-content-input .ant-input-group-addon,
.cascade-top-option-panel-content-input .ant-input-group-addon .ant-select-selection-selected-value {
  font-size: 13px;
}

.cascade-top-option-panel-content-input .ant-input-group-addon .ant-select .ant-select-selector {
  padding: 0px;
}

.cascade-top-option-panel-sub-content-body {
  font-size: 14px;
  vertical-align: middle;
  display: inline-block;
  width: 55%;
  text-align: 20px;
  font-style: italic;
}

.cascade-top-option-panel-sub-box {
  display: inline-block;
  margin-left: 20px;
  margin-right: 6px;
  width: 8px;
  height: 8px;
  background: cornflowerblue;
  border-radius: 4px;
}

.cascade-option-input-addonAfter .ant-input-group-addon {
  width: 65px;
}

.cascade-top-option-panel-error-msg {
  display: block;
  padding: 8px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 10px;
}

.cascade-load-value-box {
  margin: 10px;
  border: 1px solid #a8c4e6;
  padding: 10px;
  border-radius: 3px;
}

.cascade-load-value-box .load-value-line {
  display: inline-block;
  margin: 5px 0;
}

.cascade-load-value-box .load-value-line .load-title {
  width: 35px;
  display: inline-block;
  text-align: center;
}

.cascade-load-value-box .load-value-line .load-input {
  width: calc(100% - 60px);
  display: inline-block;
}

.cascade-top-bar-right-item {
  position: absolute;
  right: 0;
  display: flex;
}

/* report panel */
.cascade-top-bar-report-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.cascade-top-bar-report-panel .top-bar-report-content {
  padding: 10px 16px;
  border-radius: 6px 6px 0 0;
  height: 100%;
  min-width: 500px;
}

.cascade-top-bar-report-panel button.panel-modal-minimize-button {
  right: 34px;
}

.cascade-top-bar-report-panel label.report-item-title {
  font-weight: 500;
  line-height: 28px;
  width: 116px;
  display: inline-block;
  font-size: 13px;
  position: relative;
}

.cascade-top-bar-report-item {
  position: relative;
  padding: 6px 0px;
}

.cascade-top-bar-report-item::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  -webkit-animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  content: '';
}

.cascade-top-bar-report {
  line-height: 21px;
  height: 21px;
  position: relative;
  z-index: 2;
}

.cascade-report-select-dropdown {
  z-index: 1000000;
}

.cascade-report-select-dropdown .ant-select-dropdown-menu-item {
  padding: 4px 12px;
  font-size: 13px;
}

.cascade-report-select-dropdown .ant-select-dropdown-menu-item-group .ant-select-dropdown-menu-item-group-list {
  padding-left: 14px;
}

.cascade-report-title-select {
  width: 110px;
  font-size: 13px;
  min-height: 28px;
  height: auto;
  margin-right: 6px;
}

.cascade-report-select {
  width: calc(100% - 116px);
  font-size: 13px;
  min-height: 28px;
  height: auto;
  margin: 6px 0px;
}

#cascade-create-dropdown {
  padding: 0;
}

.dcr-dropdown-checkbox {
  margin-right: 18px;
  line-height: 14px;
}

.dcr-dropdown-run {
  text-align: center;
  background: #e6f7ff;
  color: #1890ff !important;
  margin-top: 5px;
  cursor: pointer;
  margin-bottom: 0 !important;
  padding: 5px 12px !important;
  line-height: 22px !important;
  height: unset !important;
}

.dcr-dropdown-run span {
  justify-content: center;
}

.dcr-dropdown-run:hover {
  background: #1890ff !important;
  color: #ffffff !important;
}

.dcr-dropdown-run-disabled,
.dcr-dropdown-run-disabled:hover {
  background: #eeeeee;
  color: #555555 !important;
  cursor: not-allowed;
}

.cascade-root-pcb-content {
  width: 100%;
  height: 100%;
  padding: 5px;
}

.cascade-root-pcb-content .pcb-select {
  margin: 10px 20px;
}

.cascade-root-pcb-content .pcb-select>span {
  display: inline-block;
  width: 80px;
}

.cascade-root-pcb-content .pcb-select>.ant-select {
  width: calc(100% - 90px)
}

.cascade-root-pcb-table-border {
  margin: 10px 20px;
}

.cascade-root-pcb-pin-map-table .ant-table-small>.ant-table-content>.aurora-table-body {
  margin: 0 15px;
}

.cascade-root-pcb-pin-map-table td:empty {
  height: 32px;
}

.cascade-root-pcb-pin-map-table td {
  position: relative;
}

#cascade-root-pcb-dialog,
#cascade-root-pcb-dialog .ant-select-dropdown {
  z-index: 3000
}

.cascade-root-pcb-edit-icon {
  font-size: 16px;
  color: #1ca4f3;
  position: absolute;
  right: 3px;
  top: 6px;
}

.cascade-root-pin-edit-icon {
  right: -13px;
  top: 14px;
  font-size: 12px;
}

.cascade-root-pcb-library-cell {
  position: relative;
}

.cascade-root-pcb-library-value {
  display: block;
  width: calc(100% - 30px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  position: relative;
}

.cascade-s-parameter-download-panel .aurora-debug-download-content {
  min-width: 260px;
}

.cascade-s-parameter-download-panel .aurora-debug-download-content .aurora-debug-item-content {
  width: 100%;
}

.cascade-top-option-panel-content .extraction-content-sub-body {
  margin-left: 30px;
  font-style: normal;
  font-size: 14px;
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;
  width: calc(55% - 70px);
}

.cascade-top-option-panel-content .extraction-content-sub-body .cascade-top-option-panel-content-body {
  padding-left: 0px;
  width: 124px;
}

.create-menu-item {
  margin: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding: 5px 12px !important;
  line-height: 22px !important;
  height: unset !important;
}

#cascade-create-dropdown {
  box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
}

.cascade-top-option-panel-advanced-body {
  color: #8c8c8c;
  cursor: pointer;
}

.cascade-top-option-panel-advanced-body .advanced-arrow-icon {
  font-size: 10px;
  padding-right: 5px;
}


.cascade-top-option-panel-content-core-input.cascade-top-option-panel-content-input {
  width: calc(27.5% - 50px);
}

.cascade-option-input-or-span {
  display: inline-block;
  margin: 0 43px;
}

.cascade-top-option-panel-content-body .span-msg-icon {
  color: #1890ff;
  margin-left: 5px;
}

.cascade-top-option-extraction-apply {
  text-align: left;
}

.cascade-top-option-extraction-apply>label {
  margin: 0px 10px;
  vertical-align: middle;
}

.cascade-top-option-panel-content-body.cascade-extraction-panel-sub-span.treate-pad-span {
  width: 70%;
}

.cascade-top-option-panel-content-input.treate-pad-input {
  width: calc(30% - 52px);
  margin-left: 24px;
}

.cascade-top-option-panel-content-body.power-dc-span {
  width: 55%;
}

.cascade-top-option-panel-content-input.power-dc-input {
  width: 45%
}

.cascade-top-option-panel-content-input.power-dc-max-edge-input {
  width: calc(45% - 52px);
  margin-left: 24px;
}

.adaptive-dc-span {
  margin-left: 20px;
}

.cascade-top-option-panel-content-input.power-dc-input.adaptive-dc-input {
  width: calc(45% - 223px);
}

.cascade-top-bar-dropdown-menu {
  padding: 0px;
  padding-top: 4px;
}

.cascade-top-bar-dropdown-menu .create-menu-item>span {
  cursor: text;
}

.cascade-show-result-checkbox {
  margin-right: 8px;
  height: 31px;
}

.cascade-tree-plot-net-color {
  vertical-align: middle;
}

.cascade-tree-plot-net-color>input {
  height: 24px;
}

.cascade-extraction-panel-note-span {
  font-size: 12px;
  font-style: italic;
  line-height: 18px;
  opacity: 0.8;
}

.cascade-question-icon {
  color: #0d87f7;
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
  vertical-align: text-bottom;
}

.cascade-extraction-panel-tabs>.ant-tabs-nav {
  margin-bottom: 0px;
}

.cascade-extraction-panel-radio-group {
  width: 100%
}