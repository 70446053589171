.spice-pin-model-subckt-select {
  width: calc(50% - 100px);
  font-size: 13px;
  height: 24px;
  line-height: 24px;
}

.pin-spice-model-select-dropdown {
  z-index: 10000000;
}

.pin-spice-model-select-dropdown .ant-select-dropdown-menu-item {
  font-size: 13px;
  padding: 2px 12px;
}

.spice-pin-model-file-selection-content>.pin-spice-model-file-selection:first-child {
  width: calc(50% + 100px);
}

.pin-spice-model-file-selection {
  display: flex;
  width: calc(50% + 100px);
  justify-content: space-between;
  padding: 5px 10px;
}

.pin-spice-model-file-selection>span {
  display: block;
  line-height: 24px;
  font-weight: 500;
  width: 100px;
  height: 24px;
}

.pin-spice-model-file-selection>.aurora-select {
  width: calc(100% - 100px);
  height: 24px;
  line-height: 24px;
}

.pin-spice-model-file-selection>.aurora-select .ant-select-selection {
  height: 24px;
  line-height: 24px;
}

.pin-spice-model-file-selection>.aurora-select .ant-select-selection .ant-select-selector .ant-select-selection-selected-value {
  font-size: 13px;
}

.pin-spice-model-file-selection>.aurora-select .ant-select-selection .ant-select-selector {
  height: 22px;
  line-height: 22px;
}

.pin-spice-model-file-selection>.spice-pin-model-subckt-select {
  width: 100%;
}

.spice-pin-model-file-selection-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.spice-pin-model-assign-content {
  width: 100%;
}

/* .spice-pin-model-assign-content .spice-pin-die-box{
width: fit;
} */

.spice-pin-model-assign-content .spice-pin-list-ul {
  width: 100%;
}

.spice-pin-model-assign-content .spice-pin-connect-node-item {
  width: 100%;
}

.spice-pin-model-ports-content {
  padding: 12px 12px;
  width: 100%;
  position: relative;
  padding-top: 2px;
  max-width: 600px;
  min-width: 200px;
}

.spice-pin-model-ports-content-header {
  text-align: center;
  line-height: 28px;
  font-weight: 500;
}

.spice-pin-model-ports-content-close-icon {
  color: rgba(0, 0, 0, 0.45);
}

.spice-pin-model-ports-content-close {
  position: absolute;
  right: 8px;
  top: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  line-height: 24px;
  transition: 0.3s;
}

.spice-pin-model-ports-content-close:hover {
  background: #ececec;
  transition: 0.3s;
}

.spice-pin-model-port-list-body-with-search {
  height: 100%;
}

.spice-pin-model-port-list-body-search-wrapper {
  width: 100%;
}

.spice-pin-model-port-list-body-search-wrapper .ant-input-affix-wrapper,
.spice-pin-model-port-list-body-search-wrapper .ant-input-affix-wrapper .ant-input {
  height: 28px;
  line-height: 28px;
  font-size: 13px;
}

.spice-pin-model-port-list-ul {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: auto;
  list-style: none;
  min-width: 20px;
  max-height: 230px;
  border: 1px solid #d9d9d9;
  border-top: none;
  border-radius: 0px 0px 4px 4px;
}

.spice-pin-model-port-list-ul>li {
  height: 24px;
  line-height: 24px;
  padding: 0px 10px;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
}

.spice-pin-model-port-list-ul>li:hover {
  background-color: #cfedff;
}


.spice-pin-model-port-list-ul>.spice-pin-model-selected-port-li:hover {
  background-color: #93e6ff;
}

.spice-pin-model-port-list-ul>.spice-pin-model-port-li-selected,
.spice-pin-model-port-list-ul>.spice-pin-model-port-li-selected:hover {
  background-color: #e4e4e4;
  cursor: not-allowed;
}

.spice-pin-model-port-list-ul>.spice-pin-model-selected-port-li {
  background-color: #93e6ff;
}

.spice-pin-model-assign-content .spice-pin-input,
.spice-pin-model-assign-content .spice-pin-input>span:not(.anticon) {
  max-width: 110px;
}

.spice-pin-model-assign-content .spice-pin-input {
  min-width: 42px;
}

.spice-pin-model-assign-content .spice-pin-input>span:not(.anticon) {
  margin-right: 12px;
  min-width: 30px;
}

.spice-pin-model-assign-content .spice-pin-node-delete-icon {
  right: 0px;
}

.spice-pin-model-assign-content .spice-pin-die-box {
  min-width: 20px;
}

.spice-pin-model-file-selection-content>.pin-spice-model-file-selection {
  width: calc(50% - 50px);
}

.spice-pin-model-file-selection-content>.pin-spice-model-file-selection:first-child {
  width: calc(50% + 50px);
}

.spice-top-box {
  position: relative;
  height: 100%;
}

.spice-top-box .spice-pin-circle {
  position: absolute;
  bottom: 0;
}

.spice-top-box .spice-pin-line {
  transform: rotate(90deg);
  position: absolute;
  bottom: 19px;
  left: -5px;
}

.spice-pin-vertical-line {
  width: 1px;
  flex: 0 0 auto;
  height: 20px;
  background-color: #a6a6a6;
}

.spice-pin-node-item-center.spice-pin-node-item-vertical-center {
  height: 74px;
}

.spice-vertical-pin-die {
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.spice-pin-model-assign-content .spice-vertical-pin-die .spice-pin-input>span:not(.anticon) {
  margin-right: 0;
}

.spice-pin-model-assign-content .spice-vertical-pin-die .spice-pin-node-delete-icon {
  right: -12px;
}

.spice-pin-model-assign-content .spice-pin-input.ant-popover-open {
  padding: 4px 5px;
}