.andes-v2-result-content {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 45px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
}

.andes-v2-result-content .ant-spin-nested-loading,
.andes-v2-result-content .ant-spin-container,
.andes-v2-result-content .rocky-result-parameter-left {
  height: 100%;
}

.andes-v2-result-parameter-left {
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
}

.andes-v2-waveform-setting-css {
  padding: 0 10px 10px 10px;
}

.andes-v2-delay-table {
  margin: 10px 20px;
  margin-bottom: 30px;
  overflow: hidden;
}

.tdr-sbr-setting {
  padding: 0px 12px 12px 12px;
}

.tdr-sbr-setting .ant-input-group-wrapper,
.tdr-sbr-setting input.duration-ratio-input {
  width: calc(100% - 120px);
  max-width: 200px;
}

.tdr-sbr-setting .powersum-target-lines-tags-input {
  width: calc(100% - 166px);
}

.tdr-sbr-setting .powersum-setting-tags-input {
  width: calc(100% - 200px) !important;
}

.tdr-sbr-setting .config-title {
  display: inline-block;
  width: 120px;
  line-height: 28px;
}

.tdr-sbr-setting .powersum-config-title {
  display: inline-block;
  width: 200px;
  line-height: 28px;
}

.tdr-sbr-setting .ant-row {
  margin-bottom: 6px;
}

.tdr-sbr-setting .ant-btn {
  width: 100%;
  margin-top: 2px;
}

.tdr-sbr-setting .ant-input-group-addon {
  min-width: 40px;
}

.tdr-sbr-setting .error-msg {
  padding: 6px 8px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 4px;
  margin-bottom: 6px;
}

.andes-v2-delay-select-div {
  height: 28px;
  line-height: 28px;
}

.andes-v2-delay-table .aurora-select {
  float: left;
  width: calc(100% - 100px);
}

.andes-v2-delay-select-title {
  float: left;
  width: 100px;
  font-weight: bold;
}

.andes-v2-delay-name-item {
  display: inline-block;
  margin: 10px 0;
  font-weight: bold;
}

.andes-v2-delay-result-table {
  border: 1px solid #e8e8e8;
}

.andes-v2-delay-result-table .ant-table-thead tr>th:not(:last-child),
.andes-v2-delay-result-table .ant-table-tbody tr>td:not(.andes-v2-delay-result-column-skew) {
  border-right: 1px solid #e8e8e8;
}

.andes-v2-delay-result-table .ant-table-thead tr>th::before {
  width: 0px !important;
}

.andes-v2-delay-table .ant-table-thead tr>th.andes-v2-delay-result-column-comp {
  text-align: left;
}

.andes-v2-delay-table .ant-table-tbody tr>td.border-bottom-0 {
  border-bottom: 0px;
}