.chip-selection,
.spim-selection {
  width: 100%;
}

.chip-dropdown-selection {
  z-index: 3000 !important;
}

.package-mode-box-setup {
  padding: 16px 16px 0px;
}

.package-mode-box-setup:nth-child(2) {
  padding-bottom: 16px;
}

.package-title {
  margin-right: 10px;
}

.package-panel {
  margin: 0 auto;
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  border: 1px solid #cdcfd2;
}

.package-selection-tooltip {
  z-index: 2001 !important;
}

.pdn-package-setting-icon {
  padding-left: 10px;
  font-size: 16px;
  color: #40a9ff;
  cursor: pointer;
}

.pdn-package-setting-input {
  width: 266px;
  display: block;
}

.pdn-package-setting-input .ant-input-group-addon {
  width: 56px;
  padding: 0 8px;
}

.pdn-package-setting-error {
  display: block;
  padding: 4px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 10px;
  font-size: 14px;
  padding-left: 10px;
}

.pdn-package-popover {
  z-index: 3000;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.pdn-package-popover .ant-popover-content {
  box-shadow: rgba(76, 77, 78, 0.14) 0px 0px 4px 0px, rgba(0, 0, 0, 0.1) 0px 0px 4px 2px;
  border-radius: 4px;
}

.pdn-package-popover .ant-popover-inner-content {
  padding: 0px;
}

.pdn-package-popover .ant-popover-arrow {
  display: none;
}

.pdn-package-setting-span {
  font-size: 14px;
  display: inline-block;
  padding: 2px;
  margin-top: 4px;
  margin-bottom: 18px;
}

.pdn-package-setting-close {
  font-size: 16px;
  position: absolute;
  right: 12px;
  top: 14px;
  color: rgba(0, 0, 0, 0.45);
}

.pdn-package-setting-close:hover {
  color: rgba(0, 0, 0, 0.75);
}

.pdn-package-setting-header {
  padding: 11px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
  font-size: 15px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  padding-left: 12px;
  position: relative;
}

.pdn-package-setting-content {
  padding: 16px 12px;
}

.pdn-package-save-panel {
  top: 200px;
}

.pdn-package-save-panel .rightBorder,
.pdn-package-save-panel .bottomBorder,
.pdn-package-save-panel .lowerRightCorner {
  cursor: unset;
}

.pdn-package-create-btn {
  margin-top: 10px;
}

.pdn-package-create-main {
  padding: 10px;
}

.pdn-package-create-content {
  padding: 10px 8px;
  line-height:25px;
}