.collection-icon {
  transform: rotate(90deg);
  font-size: 20px !important;
  margin-top: -2px;
}

.collection-menu-icon {
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  margin-right: 4px !important;
}

.tree-icon-collection-dropdown {
  z-index: 2001;
}