/* === Extraction options panel === */
.andes_v2-channel-extraction-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.andes_v2-channel-extraction-panel .panel-modal-header {
  padding-left: 14px !important;
}

.andes_v2-channel-extraction-panel .ant-divider-horizontal {
  margin: 10px 0px;
}


.andes_v2-channel-extraction-option-title {
  font-size: 16px;
  font-weight: 500;
}

.andes_v2-channel-extraction-panel-content {
  padding: 10px 16px;
  min-width: 830px;
  /* min-height: 450px; */
  min-height: 360px;
}

/* === extraction options === */
.andes_v2-channel-extraction-main {
  margin-left: 14px;
  margin-bottom: 10px;
  font-size: 13px;
}

.extraction-setup-footer-options {
  height: 30px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
}

.extraction-setup-footer-options>div .extraction-setup-left-options .anticon {
  font-size: 13px;
  vertical-align: middle;
}

.extraction-setup-footer-options>div>div {
  cursor: pointer;
  color: #036fd2;
  font-size: 13px;
}

.extraction-setup-footer-options>div>div:hover,
.extraction-setup-footer-options>.extraction-setup-middle-options:hover {
  color: #1890ff;
}

.extraction-setup-footer-options>div {
  width: 33.33%;
}

.extraction-setup-footer-options>.extraction-setup-middle-options {
  text-align: center;
  color: #036fd2;
  position: relative;
}

.extraction-setup-footer-options>div .anticon {
  font-size: 13px;
  vertical-align: middle;
}

.extraction-setup-footer-options>div>.extraction-setup-left-options .anticon {
  margin-right: 4px;
}

.extraction-setup-footer-options>div>.extraction-setup-right-options .anticon {
  margin-left: 4px;
}

/* .extraction-setup-footer-options>div>div span {
  vertical-align: middle;
} */

.extraction-setup-footer-options>div .modeling-button {
  font-size: 12px;
  color: #1890ff;
  background-color: #fff;
  height: 24px;
  line-height: 23px;
  padding: 0px 12px;
  border-radius: 4px;
  border: 1px solid #1890ff;
  white-space: nowrap;
  text-align: center;
  display: inline-block;
  width: 144px;
  position: relative;
  z-index: 10;
}

.extraction-setup-footer-options>div .modeling-button:hover {
  color: #217ed4;
  border-color: #91d5ff;
  background: #e6f7ff;
}

.extraction-setup-footer-options>div>.extraction-setup-left-options {
  float: left;
}

.extraction-setup-footer-options>div>.extraction-setup-right-options {
  float: right;
}

.extraction-setup-footer-options>.extraction-setup-middle-options .andes-extraction-modeling-adet-icon-content .andes-extraction-modeling-adet-icon {
  color: #1890ff;
  font-size: 14px;
  margin-left: 8px;
  cursor: pointer;
}

.modeling-aedt-menu {
  z-index: 10000000000;
}

.andes-extraction-modeling-adet-icon-content {
  position: absolute;
  left: calc((100% - 144px) / 2);
  top: 0px;
  width: 170px;
  text-align: right;
}

.hybrid-extraction-panel-content .ant-table-placeholder {
  color: rgba(0, 0, 0, 0.45);
}

/* --- Hybrid extraction --- */
.hybrid-extraction-panel-content .hybrid-title {
  font-size: 13px;
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;
  padding-right: 10px;
  width: 150px;
}

.hybrid-extraction-table {
  /*  max-height: 200px; */
  padding-left: 10px;
  /* overflow: auto; */
}

.select-extraction-setting-content {
  margin-bottom: 10px;
}

.select-extraction-setting-content .aurora-select {
  width: 200px;
}

.andes_v2-channel-extraction-main .extraction-content .extraction-content-title {
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
}

.andes_v2-channel-extraction-main .extraction-content .extraction-content-advanced {
  color: #036fd2;
  cursor: pointer;
}

.andes_v2-channel-extraction-advanced-panel {
  margin-top: 150px;
}

.andes_v2-channel-extraction-advanced-panel .ant-divider-horizontal {
  margin: 10px 0;
}

.andes_v2-channel-extraction-advanced-panel .andes_v2-channel-extraction-advanced-content {
  padding: 10px 16px;
  min-width: 930px;
}

.andes_v2-channel-extraction-advanced-footer {
  display: flex;
  justify-content: space-between;
  padding: 10px 16px;
  height: 50px;
  line-height: 30px;
}

.andes_v2-channel-extraction-advanced-footer button.ant-btn {
  height: 26px;
  font-size: 13px;
}

.andes_v2-channel-extraction-advanced-footer .extraction-advanced-footer-sides {
  cursor: pointer;
  color: #036fd2;
  font-size: 13px;
}

.SIwave-extraction-options-content .extraction-content-body {
  width: 310px;
}

.SIwave-extraction-setting-content .extraction-content .extraction-content-body {
  width: 130px;
}

.SIwave-extraction-setting-content>span.extraction-content-body {
  width: 310px;
}

.SIwave-extraction-setting-content .extraction-content,
.SIwave-extraction-options-content .extraction-content,
.SIwave-extraction-airbox-content .extraction-content {
  padding-left: 20px;
}

.extraction-SIwave-radio-item.ant-radio-wrapper {
  margin-right: 60px;
}

.SIwave-extraction-airbox-content>.extraction-content>div {
  display: inline-block;
  width: 50%;
}

.SIwave-extraction-setting-content .radio-hfss-type-group {
  margin-left: 30px;
  width: 98%;
}

.SIwave-extraction-setting-content .radio-hfss-type-group>label {
  width: 22%;
}

.extraction-content-body-span {
  margin-left: 28px;
}

.extraction-content .extraction-specify-switch {
  margin-right: 25px;
}

.extraction-content-back-frequency-input {
  display: inline-block;
  width: calc(100% - 158px);
  padding-left: 29px;
}

.SIwave-extraction-setting-content .extraction-content-back-frequency-input .extraction-content-body {
  width: 125px;
  padding-left: 25px;
}

.SIwave-extraction-setting-content .extraction-content-back-frequency-input .extraction-input {
  width: calc(100% - 133px);
}

.extraction-setup-footer-options>div>.extraction-restore-button {
  font-size: 12px;
  color: #1890ff;
  background-color: #fff;
  padding: 0 12px;
  margin: 4px 0;
  line-height: 23px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #1890ff;
}

.extraction-content.extraction-points-content {
  display: flex;
  justify-content: space-between;
}

.extraction-points-content .extraction-points-content-body {
  color: #036fd2;
  width: 173px;
}

.extraction-content.extraction-points-content>div {
  width: calc(100% - 180px);
}

.extraction-content.extraction-points-content>div .extraction-input-item {
  width: calc(35% - 42px);
}

.andes_v2-channel-extraction-Misc-content>.extraction-content>.extraction-content-body {
  width: 170px;
}

.andes_v2-channel-extraction-Misc-content .extraction-content .extraction-content-lic-wait-input,
.andes_v2-channel-extraction-Misc-content .extraction-content .extraction-content-time-out-input {
  width: calc(100% - 200px);
}


.andes_v2-channel-extraction-main .extraction-content.extraction-regions-disable-content .ant-spin-nested-loading {
  cursor: not-allowed;
}

.andes_v2-channel-extraction-main .extraction-content.extraction-regions-disable-content .ant-table-thead>tr>th,
.andes_v2-channel-extraction-main .extraction-content.extraction-regions-disable-content .aurora-table-body>tr>td {
  color: #00000040;
}

.andes_v2-channel-extraction-main .hybrid-extraction-panel-content .hybrid-title {
  font-size: 14px;
  font-weight: 600;
  padding-left: 0px;
}

.SIwave-extraction-setting-content .ant-collapse-borderless.ant-collapse-icon-position-left .ant-collapse-item {
  background: #fff;
  border-bottom: none;
}

.SIwave-extraction-setting-content .ant-collapse-borderless.ant-collapse-icon-position-left .ant-collapse-item .ant-collapse-header {
  padding: 4px 16px 4px 40px;
}

.SIwave-extraction-setting-content .ant-collapse-borderless.ant-collapse-icon-position-left .ant-collapse-item .ant-collapse-content-box {
  padding-bottom: 5px;
}

.SIwave-extraction-setting-content .ant-collapse-borderless .ant-collapse-item .ant-collapse-content-box .extraction-content-body {
  width: 260px;
}

.hybrid-extraction-panel-content .hybrid-extraction-radio-content {
  padding-left: 10px;
}

.hybrid-extraction-panel-content .hybrid-extraction-radio-content .ant-radio-group.ant-radio-group-outline {
  width: 100%;
}

.hybrid-extraction-radio-content .ant-radio-group.ant-radio-group-outline .channel-config-item.channel-config-sub-item {
  padding-top: 10px;
}

.hybrid-extraction-panel-content .hybrid-extraction-radio-content .channel-config-sub-item>.ant-spin-nested-loading {
  padding-top: 7px;
}

.andes_v2-channel-extraction-main .extraction-content .extraction-content-title.extraction-content-hfss-title {
  display: inline-block;
  width: 200px;
}

.extraction-content .extraction-input.extraction-hfss-input-width {
  width: calc(100% - 210px);
}

.extraction-content .extraction-input.extraction-hfss-input-width .ant-select-arrow{
  margin-top: -6px;
}

.andes_v2-channel-extraction-main .extraction-content .comp-extraction-option-content-body {
  width: 150px;
  padding: 0px 10px;
  font-size: 13px;
}

.andes_v2-channel-extraction-main .extraction-content .comp-extraction-option-content-body.comp-extraction-option-next-sub-span {
  padding-left: 40px;
}

.andes_v2-channel-extraction-main .comp-extraction-option-content {
  padding: 0px;
}