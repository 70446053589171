.cascade-impedance-content {
  padding: 20px;
  min-width: 1350px;
}

.cascade-imp-icon {
  color: #0d87f7;
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
  vertical-align: middle;
}

.cascade-imp-icon-disable {
  color: #b8b8b8;
  cursor: not-allowed;
}

.cascade-imp-table .ant-table-thead>tr>th .ant-table-header-column {
  display: block !important;
}

.cascade-imp-component-icon {
  font-size: 17px;
  color: #0d87f7;
  vertical-align: middle;
  margin-left: 20px;
}

.cascade-imp-component-icon-disable {
  color: #b8b8b8;
  cursor: not-allowed;
}

.cascade-impedance-relative {
  position: relative;
}

.cascade-imp-table .ant-table-small .ant-table-content>.aurora-table-body {
  margin: 0 20px;
}

.impedance-delete-icon {
  position: absolute;
  right: -30px;
  margin-top: -5px;
  top: 48%;
  color: #ccc;
  cursor: pointer;
}

.cascade-imp-table .ant-table-small .ant-table-content>.aurora-table-body .editable-cell-value-wrap {
  text-overflow: unset;
  overflow: visible;
  white-space: unset;
  line-height: auto;
  height: auto;
  min-height: 30px;
  position: relative;
}

.impedance-delete-icon:hover,
.impedance-vrm-delete-icon:hover,
.imp-target-delete-icon:hover,
.imp-current-delete-icon:hover {
  color: #3da4fa;
}

.impedance-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}


.impedance-panel .panel-body {
  position: relative;
}

.impedance-panel .impedance-panel-title {
  font-size: 16px;
  font-weight: 600;
}

.impedance-vrm-table {
  margin: 10px;
}

.impedance-margin-left-3 {
  margin-left: 3px;
}

.impedance-comp-tabs .ant-tabs-tab-prev-icon,
.impedance-comp-tabs .ant-tabs-tab-next-icon {
  color: #777
}

.impedance-comp-add-icon {
  color: #0d87f7;
  font-size: 16px;
  margin-left: 14px;
}

.impedance-vrm-delete-icon {
  position: absolute;
  right: -24px;
  top: 3px;
  color: #ccc;
}

.impedance-include-extended-content {
  display: inline-block;
}

.impedance-include-extended-content .impedance-include-extended-title {
  vertical-align: middle;
  font-weight: bold;
}

.impedance-include-extended-content .impedance-include-extended-title:not(:first-child).impedance-show-full-title {
  margin-left: 10px;
  padding-left: 10px;
  display: inline-block;
  border-left: 1px solid #c7c7c7;
}

.impedance-include-extended-content .ant-switch-small {
  margin-left: 10px;
  vertical-align: middle;
}

.impedance-detail-icon {
  position: absolute;
  left: -25px;
  top: calc(50% - 15px);
  line-height: unset;
}

.imp-domain-advanced-icon {
  position: absolute;
  right: 22px;
  top: 12px;
  cursor: pointer;
}

.imp-domain-advanced-icon:hover {
  color: #0d87f7;
}

.imp-target-panel,
.imp-current-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  margin: 0 auto;
}

.imp-target-radio,
.imp-current-radio {
  display: block;
  margin: 0 16px;
  height: 20px;
  margin-top: 16px;
}

.imp-target-input,
.imp-target-value-input {
  margin: 8px 0;
  display: inline-block;
  width: calc(100% - 120px);
  margin-right: 5px;
  vertical-align: middle;
}

.imp-target-name-error-msg,
.imp-current-name-error-msg {
  display: block;
  padding: 10px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin: 12px;
}

.imp-target-input-tooltip {
  z-index: 3000;
}

.imp-target-value-span,
.imp-current-value-span {
  display: inline-block;
  min-width: 20px;
  vertical-align: middle;
  position: relative;
  line-height: 18px;
  height: 18px;
}

.imp-target-value-span>span:first-child,
.imp-current-value-span>span:first-child {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  display: inline-block;
}

.imp-target-frequency,
.imp-target-RL-value,
.impedance-target-file,
.imp-current-pwl {
  margin: 12px;
}

.imp-target-frequency-table,
.imp-current-pwl-table {
  margin-top: 10px;
}

.imp-target-frequency-table .ant-table .aurora-table-body .editable-row td,
.imp-current-pwl-table .ant-table .aurora-table-tbody .editable-row td {
  height: 32px;
  position: relative;
}

.imp-target-frequency-table .ant-table .aurora-table-body .editable-row td .editable-cell-value-wrap,
.imp-current-pwl-table .ant-table .aurora-table-tbody .editable-row td .editable-cell-value-wrap {
  position: unset;
  padding: 0px 8px;
}

.imp-current-pwl-table {
  height: auto;
  max-height: 340px;
  overflow-y: auto;
}

.imp-target-table-icon {
  margin-left: 5px;
}

.imp-target-name-input {
  width: calc(100% - 65px);
}

.imp-target-delete-icon,
.imp-current-delete-icon {
  color: #ccc;
  cursor: pointer;
  position: absolute;
  right: -16px;
  top: 12px;
}

.imp-target-RL-content,
.imp-current-value-content {
  margin: 16px 10px;
  display: block;
}

.imp-target-RL-content .imp-target-RL-content-span {
  width: 100px;
  display: inline-block;
  height: 32px;
  line-height: 32px;
  vertical-align: middle;
}

.imp-current-value-content .imp-current-value-content-span {
  width: 80px;
  display: inline-block;
  height: 32px;
  line-height: 32px;
  vertical-align: middle;
}

.imp-current-pwl .imp-current-file-content {
  margin-left: 10px;
  margin-bottom: 10px;
  height: 32px;
  line-height: 32px;
}

.imp-current-pwl .imp-current-file-title {
  display: inline-block;
  width: 20%;
}

.imp-current-pwl .imp-current-select {
  width: 80%;
}

.imp-target-RL-content .ant-input-group-wrapper {
  width: calc(100% - 100px);
}

.imp-current-value-content .ant-input-group-wrapper {
  width: calc(100% - 80px);
}

.imp-target-RL-content .ant-input-group-wrapper .ant-input-group-addon .aurora-select {
  width: 65px;
  text-align: center;
}

.imp-current-value-content .ant-input-group-wrapper .ant-input-group-addon .imp-current-after,
.imp-transient-setting-content .ant-input-group-wrapper .ant-input-group-addon .imp-current-after {
  width: 30px;
  text-align: center;
}

.imp-target-file-content {
  background: #fafafa;
  padding: 16px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
}

.imp-target-file-content-display {
  margin-top: 10px;
}

.cascade-imp-edit-icon {
  color: #0d87f7;
  font-size: 16px;
  position: absolute;
  right: -18px;
}

.imp-target-action-group,
.imp-current-action-group {
  position: absolute;
  right: 10px;
  top: -5px;
}

.imp-target-action-group>span,
.imp-current-action-group>span {
  width: 24px;
  height: 24px;
  line-height: 27px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  -webkit-transition: .3s;
  transition: .3s;
  box-shadow: 0 0 0 0 #fff;
  background: #fff;
  z-index: 1;
  border: 1px solid#dedede;
  cursor: pointer;
  margin: 0 5px;
  justify-content: center;
}

.imp-target-action-group>span:hover,
.imp-current-action-group>span:hover {
  color: #0d87f7;
  box-shadow: rgba(168, 204, 241, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.18) 0px 0px 5px 2px;
  transition: 0.3s;
  background: #fafafa;
}

.impedance-target-setting-single,
.impedance-current-setting-single {
  position: relative;
  height: 100%;
  float: left;
  width: 100%;
  padding: 5px;
}

.impedance-target-setting,
.impedance-current-setting {
  border-right: 1px solid #e8e8e8;
  float: left;
  height: 100%;
  overflow-y: auto;
  position: relative;
  width: 50%;
  min-width: 300px;
  padding: 5px;
}

.imp-transient-setting-content {
  padding: 2px 6px 12px;
}

.imp-transient-setting-body {
  margin: 10px;
  display: block;
}

.imp-transient-setting-title {
  display: inline-block;
  width: 150px;
  height: 28px;
  line-height: 28px;
}

.imp-transient-setting-body .ant-input-group-wrapper {
  width: calc(100% - 150px);
}

.imp-transient-setting-body .ant-input {
  height: 28px;
  line-height: 28px;
}

.impedance-target-plot,
.impedance-current-plot {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 50%;
  min-width: 400px;
  min-height: 400px;
}

.imp-target-text {
  width: 100%;
  position: relative;
}

.imp-target-text-delete-icon {
  visibility: hidden;
  position: absolute;
  color: #c9c9c9;
  right: 0;
  font-size: 14px;
  top: 7px;
}

.imp-target-text:hover .imp-target-text-delete-icon {
  visibility: visible;
}

.imp-port-loading-content {
  line-height: 600px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
}

.impedance-part-switch-icon {
  color: #3da4fa;
  margin-left: 10px;
  font-size: 15px;
  vertical-align: middle;
}

.cascade-component-model-span {
  width: 100%;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cascade-detail-sense-port-title {
  margin-top: 5px;
  font-weight: bold;
  cursor: default;
}

.cascade-display-detail-box .cascade-detail-sense-port-item {
  margin-left: 10px;
  position: relative;
}

.cascade-sense-detail-line {
  display: flex;
}

.cascade-detail-line .sense-delete-icon {
  font-size: 12px;
  position: relative;
  margin-left: 5px;
  top: 5px;
}

.cascade-detail-line .sense-delete-icon:hover {
  color: #3da4fa;
}

.cascade-sense-detail-line>div {
  margin: 0px !important;
}

.cascade-sense-detail-line-text {
  width: unset !important;
}

.impedance-package-ports-switch-icon {
  margin-top: -2px;
  cursor: pointer;
}

.cascade-imp-overview {
  min-height: 200px;
  min-width: 1000px;
  position: relative;
}

.cascade-imp-overview-button-group {
  display: inline;
  margin-left: 4px;
}

.cascade-imp-overview-button-group>i {
  margin-left: 10px;
}

.cascade-imp-multi-pcb-target {
  display: inline;
  margin-left: 10px;
}

.cascade-imp-multi-pcb-target>span,
.cascade-imp-decap-group-span {
  vertical-align: middle;
  font-size: 12px;
  border: 1px solid #0d87f7;
  display: inline-block;
  border-radius: 5px;
  cursor: pointer;
  padding: 1px 5px;
  color: #0d87f7;
}

.cascade-imp-decap-group-span {
  width: 86px;
  text-align: center;
  width: 100px;
  height: 28px;
  text-align: center;
  line-height: 24px;
}

.cascade-imp-multi-pcb-target:hover>span {
  color: #1890ff
}

.cascade-imp-overview-content {
  position: relative;
}

.cascade-imp-overview-layouts-box {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}

.cascade-imp-overview-column {
  width: 300px;
}

.cascade-imp-overview-layout {
  width: 100%;
  padding: 10px;
}

.cascade-imp-overview-layout .layout-title {
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  margin-bottom: 5px;
}

.cascade-imp-overview-layout .layout-title>span:not(.anticon) {
  max-width: 230px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  padding-right: 5px;
}

.cascade-imp-overview-layout .layout-title>.anticon {
  margin-top: 2px;
}

.cascade-imp-overview-layout .layout-delete-icon,
.cascade-imp-overview-layout .layout-in-icon {
  font-size: 12px;
  vertical-align: middle;
}

.cascade-imp-overview-layout .layout-switch-icon {
  margin: 0px 6px;
  font-size: 14px;
  vertical-align: middle;
}

.cascade-imp-overview-layout .layout-delete-icon:hover,
.cascade-imp-overview-layout .layout-title:hover .layout-in-icon,
.cascade-imp-overview-layout .layout-switch-icon:hover {
  color: #3da4fa;
}

.cascade-imp-overview-column .layout-content {
  border: 1px solid #a8c4e6;
  border-radius: 3px;
  position: relative;
  min-height: 120px;
}

.impedance-overview-canvas {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
}

.cascade-imp-overview-column .layout-content .layout-components {
  position: relative;
  height: calc(100% - 80px);
}

.cascade-imp-overview-column .layout-content .layout-components .layout-item {
  position: absolute;
  width: 100px;
  height: 70px;
  /* line-height: 60px; */
  margin: 30px 0px;
  border: 1px solid gray;
  border-radius: 10px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cascade-imp-overview-column .layout-content .layout-components .component-input {
  display: inline-block;
  max-width: 50px;
  min-width: 10px;
  height: 20px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-item .component-value {
  font-size: 14px;
  font-weight: 400;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-item .cascade-component-value-title {
  vertical-align: middle;
  display: inline-block;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-item .layout-gnd-v-line {
  position: absolute;
  height: 20%;
  bottom: -20%;
  border-right: solid #202020 1px
}

.cascade-imp-overview-column .layout-content .layout-components .layout-item .layout-gnd-h-line {
  position: absolute;
  width: 40%;
  bottom: -20%;
  border-top: solid #202020 2px
}

.cascade-imp-overview-column .layout-content .layout-components .layout-item .layout-gnd-text {
  position: absolute;
  bottom: -50%;
  font-size: 12px;
  color: #202020;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-die-model,
.cascade-imp-overview-column .layout-content .layout-components .layout-die-model-reverse {
  position: absolute;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-top: 76px;
  width: 70px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-die-model {
  right: 136px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-die-model-reverse {
  left: 122px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-die-model .layout-rdie,
.cascade-imp-overview-column .layout-content .layout-components .layout-die-model .layout-cdie {
  margin-left: 32px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-cdie {
  margin-top: 20px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-model-pairs,
.cascade-imp-overview-column .layout-content .layout-components .layout-model-pairs-reverse {
  position: absolute;
  height: 62px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-top: 72px;
  width: 20px;
  justify-content: center;
  align-items: center;
  border: 2px solid #889FBA;
  background-color: #fff;
  border-radius: 2px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-model-pairs {
  right: 172px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-model-pairs-reverse {
  left: 172px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-model-cpm-pairs-reverse,
.cascade-imp-overview-column .layout-content .layout-components .layout-model-cpm-pairs {
  position: absolute;
  height: 80px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-top: 65px;
  width: 180px;
  justify-content: center;
  align-items: center;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-model-cpm-pairs-reverse {
  left: 30px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-model-cpm-pairs {
  right: 30px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-cpm-item {
  position: absolute;
  display: flex;
  right: 10px
}

.cascade-imp-overview-column .layout-content .layout-components .layout-cpm-item div {
  display: inline-block;
  margin-right: 5px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-node-2 {
  margin-top: 26px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-rleak,
.cascade-imp-overview-column .layout-content .layout-components .layout-rleak-reverse {
  position: absolute;
  display: flex;
  flex-direction: row;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-rdl,
.cascade-imp-overview-column .layout-content .layout-components .layout-rdl-reverse {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-rleak,
.cascade-imp-overview-column .layout-content .layout-components .layout-rdl {
  left: 170px
}

.cascade-imp-overview-column .layout-content .layout-components .layout-rleak-reverse,
.cascade-imp-overview-column .layout-content .layout-components .layout-rdl-reverse {
  right: 170px
}

.cascade-imp-overview-column .layout-content .layout-components .layout-rdl-title {
  display: inline-block;
  vertical-align: middle;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-port-select,
.cascade-imp-overview-column .layout-content .layout-components .layout-port-select-reverse {
  position: absolute;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-port-select-box {
  width: 180px;
  margin-right: 6px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-port-title {
  margin: 0 10px;
}

.layout-port-treeSelect .ant-select-tree {
  margin: 4px 0px;
}

.layout-port-treeSelect .ant-select-tree-title {
  font-size: 13px;
}

.layout-port-treeSelect .ant-select-tree li span.ant-select-tree-switcher,
.layout-port-treeSelect .ant-select-tree li span.ant-select-tree-iconEle {
  width: auto;
}

.layout-port-treeSelect .ant-select-tree li {
  margin: 0 0;
}

.layout-interposer {
  position: absolute;
  background-color: #fff;
  top: 0px;
  left: 80px;
  width: 120px;
  height: 50px;
  margin: 40px 0;
  border: 2px solid #889FBA;
  cursor: pointer;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.layout-node-empty {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  display: inline-block;
  width: 50px;
  height: 20px;
}

.layout-die-node,
.layout-pkg-node-reverse {
  position: absolute;
  top: 40px;
  left: 10px;
}

.layout-pkg-node,
.layout-die-node-reverse {
  position: absolute;
  top: 40px;
  right: 10px;
}

.layout-node-select {
  width: 60px;
  height: 28px;
  line-height: 28px;
}

.layout-bga-port-count {
  height: 40px;
  line-height: 40px;
  text-align: right;
  margin-right: 10px;
  font-weight: bold;
}

.layout-bga-port-count-reverse {
  height: 40px;
  line-height: 40px;
  text-align: left;
  margin-left: 10px;
  font-weight: bold;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-port-count {
  position: absolute;
  right: 10px;
  font-weight: bold;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-port-count-reverse {
  position: absolute;
  left: 10px;
  font-weight: bold;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-item .delete-connector-icon {
  position: absolute;
  right: 3px;
  top: 3px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-item-left {
  left: 10px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-item-right {
  right: 10px;
}

.cascade-imp-overview-column .layout-content .layout-add-connector {
  width: 100%;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: right;
}

.cascade-imp-overview-column .layout-content .layout-add-connector .connector-button {
  margin-right: 10px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-current-icon,
.cascade-imp-overview-column .layout-content .layout-components .layout-current-icon-reverse {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #687A8F;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 86px;
  cursor: pointer;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-current-icon-disabled {
  cursor: auto;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-current-icon {
  left: 6px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-current-icon-reverse {
  right: 6px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-unified-die,
.cascade-imp-overview-column .layout-content .layout-components .layout-unified-die-reverse {
  position: absolute;
  width: 180px;
  height: 100px;
  display: flex;
  cursor: pointer;
  margin-top: 16px;
  border: 2px solid #889FBA;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-unified-die {
  left: 20px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-unified-die-reverse {
  right: 20px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-unified-node,
.cascade-imp-overview-column .layout-content .layout-components .layout-unified-node-reverse {
  position: absolute;
  font-weight: bold;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-unified-node {
  right: 10px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-unified-node-reverse {
  left: 10px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-unified-current {
  position: absolute;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  border: 1px solid #687A8F;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-unified-current-1 {
  top: 20px;
  left: 20px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-unified-current-2 {
  top: 30px;
  left: 56px;
  font-size: 16px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-unified-current-3 {
  top: 40px;
  left: 92px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-unified-current-1-reverse {
  top: 20px;
  right: 20px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-unified-current-2-reverse {
  top: 30px;
  right: 56px;
  font-size: 16px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-unified-current-3-reverse {
  top: 40px;
  right: 92px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-unified-current-name {
  font-size: 12px;
  margin-top: 10px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-ic-current-icon-box {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #0d87f7;
  vertical-align: middle;
  background-color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-ic-current-box {
  font-weight: 400;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-ic-current-box .ant-checkbox-inner {
  width: 12px;
  height: 12px;
}

.cascade-imp-overview-column .layout-content .layout-components .layout-ic-current-title {
  margin: 0 4px;
}

.layout-ic-current-icon {
  cursor: pointer;
  color: #0d87f7;
  font-size: 10px;
}

.layout-ic-current-icon-disabled {
  cursor: not-allowed;
  font-size: 10px;
}

.cascade-add-pcb-panel {
  box-shadow: 2px 4px 7px 5px rgb(76 77 78 / 14%), 0 0 10px 3px rgb(0 0 0 / 10%);
}

.cascade-add-pcb-content {
  margin: 20px;
}

.cascade-add-pcb-content>span {
  width: 150px;
  display: inline-block;
}

.cascade-add-pcb-content .pcb-select {
  width: calc(100% - 180px);
}

.cascade-add-pcb-interposer-content {
  margin: 20px;
}

.cascade-add-pcb-interposer-content>span {
  width: 220px;
  display: inline-block;
}

.cascade-add-pcb-interposer-content .pcb-select {
  width: calc(100% - 240px);
}

.cascade-add-pcb-connector-content {
  position: relative;
  margin: 20px;
}

.cascade-imp-add-connection {
  margin: 10px;
}

.cascade-imp-add-connection .add-connection-content>span {
  width: 140px;
  display: inline-block;
}

.cascade-imp-add-connection .add-connection-content .add-connection-item {
  width: calc(100% - 160px);
}

.cascade-imp-add-connection .add-connection-relationship>span {
  width: 100%;
  display: inline-block;
  margin: 20px 0px;
}

.cascade-conn-ship-content {
  margin-left: 10px;
}

.cascade-imp-add-connection .cascade-conn-ship-content .add-connection-content .add-connection-item {
  margin-bottom: 10px;
}

.cascade-imp-add-connection .ant-divider-horizontal {
  margin: 10px 0px;
}

.cascade-ship-connect-table {
  border: 1px solid #a8c4e6;
  padding: 10px;
  border-radius: 3px;
  position: relative;
}

#cascade-add-connection-dialog,
#cascade-add-connection-dialog>div {
  z-index: 3000
}

.cascade-setup-border-position {
  position: relative;
}

.cascade-change-page-button {
  color: #ff610b;
  border: 1px solid #ff610b;
  border-radius: 4px;
  padding: 0px 10px;
  display: inline-block;
}

.cascade-change-page-button:not(:first-child) {
  margin-left: 15px;
}

.cascade-pcb-setup-content {
  line-height: 32px;
  vertical-align: middle;
}

.cascade-imp-add-connection #cascade-net-connection-content {
  overflow: hidden;
}

.impedance-target-setting .ant-tabs-content,
.impedance-target-setting-single .ant-tabs-content {
  position: relative;
}

.cascade-imp-pcb-table .aurora-table-body .editable-row td:not(:last-child) {
  position: relative;
}

.cascade-imp-decap-group-span.no-decap-group {
  border: 1px solid #85837d;
  color: #85837d;
}

.cascade-decap-group-panel {
  margin-top: 126px;
  margin-left: -440px;
  box-shadow: rgb(76 77 78 / 14%) 0px -2px 7px 8px, rgb(0 0 0 / 10%) 0px 0px 10px 3px;
}

.cascade-decap-group-panel .cascade-decap-group-table {
  margin: 10px;
}

.cascade-cap-components-table .cascade-components-table .ant-table-small>.ant-table-content .aurora-table-body .cascade-cap-comp-group-border {
  border-bottom: 1px solid #a8c4e6;
}

.cascade-cap-components-table .cascade-components-table .ant-table-small>.ant-table-content .aurora-table-body .cascade-cap-comp-group-border>td,
.cascade-cap-components-table .cascade-components-table .ant-table-small>.ant-table-content .aurora-table-body>tr>.cascade-cap-comp-group-border-td {
  border-bottom: none;
}

.cascade-cap-components-table .cascade-components-table .ant-table table {
  border-collapse: collapse;
}

.cascade-cap-comp-pcb-column {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.impedance-decap-setting-main {
  margin: 10px;
}

.impedance-decap-setting-content {
  padding: 0 10px;
  margin-bottom: 6px;
}

.impedance-decap-setting-content-body {
  font-size: 14px;
  vertical-align: middle;
  display: inline-block;
  width: 45%;
  line-height: 28px;
}

.impedance-decap-setting-button {
  width: 80px;
}

.imp-current-apply {
  text-align: left;
}

.imp-current-apply>label {
  margin: 0px 10px;
  vertical-align: middle;
}

.interposer-content {
  padding: 10px;
}

.rleak-rdl-content {
  padding: 10px 16px;
}

.rleak-rdl-body {
  padding: 5px 0;
}

.rleak-rdl-input-title {
  width: 80px;
  display: inline-block;
  line-height: 28px;
}

.rleak-rdl-input {
  width: calc(100% - 80px);
}

.rleak-rdl-body .ant-input {
  line-height: 28px;
  height: 28px;
}

.apply-footer {
  text-align: left;
}

.apply-footer>label {
  margin-right: 10px;
  vertical-align: middle;
}