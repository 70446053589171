.andes-result-file-name {
  transition: all 0.3s;
  font-weight: 600;
  margin-top: 5px;
  padding-left: 8px;
  cursor: pointer;
  line-height: 24px;
}

.andes-result-file-name:hover {
  background-color: #e0e0e0;
  transition: all 0.3s;
}

.andes-result-checkbox-group {
  width: 99%;
  padding-left: 10px;
}

.andes-file-expand-icon {
  padding-right: 4px;
  font-size: 10px;
  vertical-align: baseline;
}

.title-expand-icon {
  padding-right: 10px;
  float: left;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 10px;
}

.andes-result-parameter-file-list {
  background-color: #fafafa;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 318px;
  padding: 8px 0 8px 8px;
}

.andes-result-parameter-right {
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
}

.andes-result-parameter-left {
  background-color: #fafafa;
  border-left-color: #e8e8e8;
  border-left-width: 1px;
  border-left-style: solid;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.font-bold {
  font-weight: bold;
}

.andes-result-checkbox-group>.ant-checkbox {
  top: 0px;
}

.andes-result-parameter-file-list .andes-result-file-name .span-file-name {
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.andes-returnLoss-result-main {
  position: relative;
}

.andes-returnLoss-result-main,
.andes-returnLoss-result-main .ant-spin-nested-loading,
.andes-returnLoss-result-main .ant-spin-container {
  height: 100%;
}

.andes-returnLoss-result-main .andes-result-setting {
  position: absolute;
  left: 0;
  right: 0;
  max-height: 50%;
  bottom: 0;
  background-color: #fafafa;
}

.andes-result-curve-current {
  margin-left: 10px;
  vertical-align: middle;
  font-weight: bold;
}

.andes-result-checkbox-group-name {
  display: inline-block;
  line-height: 20px;
}

.andes-result-checkbox-group-name .andes-result-checkbox-label {
  color: #000;
  vertical-align: middle;
  margin-left: 4px;
}

.andes-result-checkbox-group .ant-checkbox+span {
  height: 26px;
  display: inline-block;
  padding-left: 3px;
  padding-right: 0px;
}

.andes-result-parameter-file-list .ant-checkbox-wrapper+.ant-checkbox-wrapper {
  margin-left: 0px;
}

.andes-result-ports-list {
  padding-left: 10px;
}

.andes-file-download-icon {
  margin-left: 10px;
  font-size: 16px;
  color: #40a9ff;
}

.andes-result-file-content {
  /* overflow-y: auto; */
  width: 100%;
  overflow: auto;
}

.andes-result-history-file-content {
  width: 100%;
  overflow: auto;
  margin-left: 8px;
}

.andes-result-parameter-file-list .ant-divider-inner-text {
  padding-left: 2px !important;
  padding-right: 28px !important;
}

.andes-result-parameter-file-list .ant-divider-horizontal.ant-divider-with-text-left::before{
  width: 0%;
}

.andes-result-menu-title {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-weight: bold;
}

.andes-channel-port-name {
  height: 24px;
  line-height: 24px;
  margin-left: 6px;
  padding-left: 6px;
  cursor: pointer;
}

.andes-channel-port-name-general {
  padding-left: 6px;
  margin-left: 0px;
}

.andes-channel-port-name:hover {
  background: #efefef;
}

.andes-result-ports-list .ant-checkbox-group {
  width: 100%;
}

.andes-download-single-file-div {
  position: absolute;
  top: 21px;
  right: 80px;
}

.andes-single-file-download-icon {
  font-size: 20px;
  color: #40a9ff;
}

.andes-result-save-icon {
  margin-left: 9px;
  margin-right: 12px;
  padding: 0 5px;
  color: #40a9ff;
  font-size: 17px;
}