.sierra-total-capacitance-freq-content {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2px;
  float: right;
  margin-right: 20px;
}

.sierra-total-capacitance-freq-content>span {
  width: 160px;
  font-weight: 500;
}

.sierra-total-capacitance-freq-content>.aurora-input {
  width: calc(100% - 160px);
}

.sierra-total-capacitance-main .aurora-error-msg-span {
  width: 50%;
}

.sierra-total-capacitance-main {
  /*  padding: 0px 20px; */
  height: 100%;
  position: relative;
}

.sierra-total-capacitance-table-main {
  overflow: auto;
  width: 100%;
  height: calc(100% - 30px);
  padding: 0px 20px;
}

.sierra-total-capacitance-main::after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.sierra-total-capacitance-main .sierra-result-content-box {
  padding: 0px;
  padding-top: 20px;
}

.sierra-total-capacitance-top-menu {
  width: 100%;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  height: 36px;
  background-color: #f9f9f9;
  box-sizing: border-box;
}

.sierra-total-capacitance-main .sierra-total-capacitance-curve-right {
  background-color: #f9f9f9;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding-left: 10px;
  width: 100%;
}

.sierra-total-capacitance-main .sparameter-display-mode {
  width: calc(100% - 24px)
}

.sierra-total-capacitance-main .sparameter-display-mode label.ant-radio-wrapper {
  width: 100px;
  margin-right: 0px;
}

.sierra-total-capacitance-left {
  /*  width: 70%; */
  height: 100%;
}

.sierra-total-capacitance-left .total-capacitance-svg {
  width: 100%;
  height: 100%;
}

.sierra-total-capacitance-curve-design-item .total-capacitance-curve-design-title {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
}

.sierra-total-capacitance-curve-design-item .total-capacitance-curve-design-title>span:not(.anticon) {
  font-weight: bold;
  width: calc(100% - 20px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgb(0, 0, 0, 0.65);
}

.total-capacitance-curve-segment-checkbox {
  width: 100%;
}

.total-capacitance-curve-checkbox {
  padding-right: 5px;
}

.sierra-total-capacitance-curve-item {
  padding-left: 12px;
}

.sierra-total-capacitance-curve-design-item .sierra-total-capacitance-curve-item .color-select {
  padding: 0px;
  margin: 0px;
  background-color: #ffffff;
  border: 0px;
  width: 19px;
  margin-right: 2px;
  cursor: pointer;
  outline: none;
  height: 23px;
}

.total-capacitance-curve-title {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
}

.total-capacitance-curve-title-span {
  font-weight: 500;
  max-width: calc(100% - 50px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 6px;
  width: fit-content;
}

.sierra-total-capacitance-curve-item .total-capacitance-curve-signal-item {
  padding-left: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sierra-result-content-box .sierra-trace-capacitance-table .ant-table-small.ant-table-bordered .aurora-table-body>tr>td:nth-child(2) {
  border-right: 1px solid #e8e8e8;
}

.total-capacitance-unit-select {
  width: 80px;
}

.total-capacitance-tabs .ant-tabs-bar {
  margin-bottom: 0px;

}

.total-capacitance-tabs .ant-tabs-nav .ant-tabs-tab {
  padding: 6px 16px;
}

.sierra-total-capacitance-main .result-right {
  top: 0px;
}

.sierra-total-capacitance-main .result-curve-current {
  margin-left: 8px;
  vertical-align: middle;
  font-weight: bold;
}

.sierra-total-capacitance-curve-right-setting {
  height: 200px;
}

.sierra-total-capacitance-curve-right-result {
  height: calc(100% - 200px);
  overflow: auto;
}

.sierra-title-expand-icon {
  padding-right: 6px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 10px;
  line-height: 18px;
}

.sierra-total-capacitance-table-content {
  padding-top: 36px;
  height: 100%;
  padding-bottom: 10px;
}

.sierra-total-capacitance-curve {
  height: calc(100% - 36px) !important;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 36px;
}

.sierra-total-capacitance-main .sierra-total-capacitance-curve .dividingLine,
.sierra-total-capacitance-main .sierra-total-capacitance-curve .dividingLine-hover {
  top: 36px;
  height: calc(100% - 36px);
}

.sierra-total-capacitance-curve-item .total-capacitance-curve-single-title {
  padding-left: 16px;
}

.sierra-total-capacitance-curve-item .total-capacitance-curve-single-title .ant-checkbox-wrapper {
  padding-right: 5px;
}

.sierra-total-capacitance-curve-design-item .sierra-total-capacitance-curve-item .total-capacitance-curve-single-title .color-select {
  margin-right: 0px;
}

.sierra-total-capacitance-curve-design-item .sierra-total-capacitance-curve-item .total-capacitance-curve-single-title .total-capacitance-curve-title-span {
  margin-left: 4px;
}

.sierra-total-capacitance-curve-right-setting .waveform-setting-title {
  margin: 0px;
  padding: 10px 0px;
}

.sierra-total-capacitance-curve-right-setting .result-waveform-setting .waveform-setting-row {
  margin: 0px;
  padding: 5px 0px;
}

.sierra-total-capacitance-curve>.result-left {
  height: calc(100% - 6px) !important;
}