#andes-v2-top-bar,
#andes-v2-prelayout-top-bar {
  height: 45px;
  padding-left: 10px;
  background-color: #f3f2f1;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
}

.andes-v2-top-bar-item {
  padding: 6px 14px;
  transition: all 0.3s;
  color: #005a9e;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 33px;
}

.andes-v2-top-bar-item:hover {
  background-color: #edebe9;
}

.andes-v2-top-bar-item .iconfont,
.andes-v2-top-bar-item .anticon-play-circle,
.andes-v2-top-bar-item .anticon-setting,
.andes-v2-top-bar-item .anticon-question-circle,
.andes-v2-top-bar-item .anticon-experiment,
.andes-v2-top-bar-item .anticon-download {
  margin-right: 8px;
  font-size: 16px;
  margin-top: -2px;
}

.andes-v2-top-bar-item+.andes-v2-top-bar-item {
  margin-left: 20px;
}

.andes-v2-top-bar-item-disabled {
  color: #797979 !important;
  cursor: not-allowed !important;
}

.andes-v2-top-bar-item .andes-v2-top-bar-debug-icon {
  margin: -2px 8px 0px 0px;
  font-size: 19px;
  display: inline-block;
  vertical-align: bottom;
  padding-top: 2px;
}

#andes-v2-top-bar.hide .andes-v2-top-bar-item-title {
  display: none;
}

#andes-v2-prelayout-top-bar.hide .andes-v2-top-bar-item-title {
  display: none;
}


#andes-v2-top-bar.hide .andes-v2-top-bar-item .iconfont,
#andes-v2-top-bar.hide .andes-v2-top-bar-item .anticon-play-circle,
#andes-v2-top-bar.hide .andes-v2-top-bar-item .anticon-setting {
  margin-right: 0px;
}

#andes-v2-prelayout-top-bar.hide .andes-v2-top-bar-item .anticon-setting {
  margin-right: 0px;
}

#andes-v2-top-bar .extraction-dropdown-button .ant-dropdown-menu-item:hover {
  color: #1890ff;
}

li.ant-select-dropdown-menu-item.aurora-debug-select-failed {
  color: #ff0000;
}

.andes-v2-top-bar-result-title {
  color: #ff610b;
  border: 1px solid #ff610b;
  border-radius: 4px;
  padding: 2px 10px;
  display: inline-block;
}

.andes_v2_top-bar-debug {
  position: absolute;
  right: 10px;
}

.andes-v2-top-bar-result-title .andes-v2-top-bar-result-icon {
  margin-left: 8px;
  font-size: 15px;
}

#andes-v2-top-bar.hide .andes-v2-top-bar-result-icon {
  margin-left: 0px;
}

#andes-v2-top-bar .andes-v2-top-bar-result:hover {
  background-color: #f3f2f1;
}

.andes-v2-top-bar-result-title:hover {
  color: #f87e3b;
  border: 1px solid #f87e3b;
}

.andes-v2-top-bar-item+.andes-v2-top-bar-result {
  margin-left: 0px;
}

.andes-v2-top-bar-item-disabled .andes-v2-top-bar-result-title,
.andes-v2-top-bar-item-disabled .andes-v2-top-bar-result-title:hover {
  color: #797979 !important;
  border: 1px solid #797979;
}

.andes-v2-top-bar-item .andes-v2-top-bar-circuit-icon {
  font-size: 20px;
}

.andes-v2-top-sus-report-item {
  position: relative;
  padding: 6px 0px;
}

.andes-v2-top-sus-report-item::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  -webkit-animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  content: '';
}

.andes-v2-top-sus-report {
  line-height: 21px;
  height: 21px;
  position: relative;
  z-index: 2;
}

.andes-v2-top-sus-report-icon {
  margin: -3px 8px 0px 0px;
  font-size: 16px;
  display: inline-block;
  vertical-align: bottom;
}

.andes-v2-top-sus-icon-diff {
  padding-top: 2px;
}

.andes-v2-top-sus-disabled {
  color: #797979;
  cursor: not-allowed;
}

.andes-v2-top-sus-report-progress {
  width: 0px;
  height: 33px;
  background-color: #82c2fd;
  float: left;
  position: absolute;
  overflow: visible;
  top: 0;
  z-index: 1;
}

.andes-v2-report-progress-span {
  height: 33px;
  display: block;
  float: left;
  line-height: 34px;
}

.andes-v2-top-sus-report-item .andes-v2-top-sus-report {
  padding: 0px 14px;
}

.andes-v2-simulation-radio {
  display: block;
  padding: 12px 16px;
  margin: 0;
}

.andes-v2-simulation-radio:hover {
  background-color: #e6f7ff;
}

.andes-v2-simulation-popover .ant-popover-inner-content {
  padding: 0px;
}