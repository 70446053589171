.save-result-popover {
  height: 100px;
}

.save-result-popover .ant-popover-inner-content {
  padding: 8px;
}

.result-save-div {
  width: 50px;
  line-height: 44px;
  text-align: center;
  margin-right: 40px;
  float: left;
  height: 45px;
}

.result-save-icon {
  font-size: 19px;
  color: #40a9ff;
  cursor: pointer;
  vertical-align: text-bottom;
}

.save-result-name .ant-input-group-addon {
  width: 56px;
  padding: 0 8px;
}

.save-result-name {
  width: 250px;
}