.extraction-content {
  line-height: 28px;
  margin-bottom: 5px;
}

.extraction-content-body,
.extraction-content-sub-body {
  width: 45%;
}

.extraction-content-sub-body {
  margin-left: 88px;
  font-style: normal;
  font-size: 14px;
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;
  width: 45%;
}

.extraction-content-body {
  font-size: 13px;
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;
  padding-right: 10px;
  width: 150px;
}

.extraction-content-sub-body .extraction-content-body {
  padding-left: 0px;
}

.extraction-radio-group {
  margin-bottom: 8px;
}

.extraction-radio-group .ant-divider-horizontal {
  margin: 10px 0px;
}

.extraction-radio-group-span {
  display: inline-block;
  margin-right: 14px;
}

.extraction-gray {
  color: #9c9c9c;
}

.extraction-radio-clip-group {
  margin: 0px 10px;
  margin-left: 87px;
}

.extraction-radio-clip-group .extraction-clip-radio-item {
  vertical-align: middle;
  margin-left: 8px;
}

.extraction-content .extraction-input,
.extraction-content .sub-extraction-input {
  display: inline-block;
  width: auto;
  vertical-align: middle;
  height: 28px;
}

.extraction-content .sub-extraction-input {
  margin-left: 10px;
}

.extraction-content .extraction-input .ant-input-group .ant-input,
.extraction-content .sub-extraction-input .ant-input-group .ant-input {
  height: 28px;
}

.extraction-content .extraction-input .ant-input-group-addon,
.extraction-content .sub-extraction-input .ant-input-group-addon {
  width: 65px;
  padding: 0px 6px;
  font-size: 13px;
}

.extraction-content .extraction-input .ant-input-group-addon .ant-select,
.extraction-content .sub-extraction-input .ant-input-group-addon .ant-select {
  font-size: 13px;
  width: 68px;
  margin: -5px -8px;
}

.extraction-content .extraction-input .ant-input-group-addon .ant-select,
.extraction-content .sub-extraction-input .ant-input-group-addon .ant-select {
  width: 68px;
}


.extraction-option-error-msg {
  display: block;
  padding: 6px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin: 10px 16px 4px 20px;
  font-size: 14px;
}

.extraction-content .extraction-input-width {
  width: calc(100% - 320px);
}

.extraction-content .extraction-input-item {
  width: calc((100% - 300px) * 0.35);
}

.extraction-content .extraction-input-item-sub {
  width: calc((100% - 300px) * 0.3);
}

.extraction-content .extraction-step-width {
  width: 112px;
}

.extraction-setting-icon {
  color: #188fff;
  vertical-align: middle;
}

.extraction-content-back-drill-input .extraction-content-body {
  width: 192px;
}

.extraction-content-back-drill-input,
.extraction-content-lic-wait-input,
.extraction-content-time-out-input {
  display: inline-block;
  width: calc(100% - 200px);
  padding-left: 29px;
}

.extraction-content-back-drill-input .extraction-input {
  width: calc(100% - 282px);
}

.extraction-content-lic-wait-input .extraction-input {
  width: calc(100% - 92px);
  margin-left: 81px;
}

.extraction-content-time-out-input {
  padding-left: 112px;
}

.extraction-content-time-out-input .extraction-input {
  width: calc((100% - 182px) / 2);
}

.extraction-content-time-out-input>.extraction-content-body {
  width: 160px;
  padding-right: 0px;
  padding-left: 0px;
}

.extraction-content .extraction-clip-design-input {
  width: calc(100% - 478px);
}

.extraction-content-hpc .extraction-content-body {
  width: 288px;
}

.extraction-content-hpc>.extraction-content-body {
  width: 150px;
}

.extraction-content-hpc .extraction-content {
  padding-left: 20px;
}

.extraction-content-hpc .extraction-content .extraction-input-width {
  width: calc(100% - 300px);
}

.extraction-content .extraction-content-log-sweep-body {
  width: 180px;
}