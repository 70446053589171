.report-template-item-title {
  font-weight: 500;
  line-height: 28px;
  width: 84px;
  display: inline-block;
  font-size: 13px;
  position: relative;
}

.report-template-content .report-template-upload-icon {
  float: unset;
  margin-left: -20px;
  line-height: 28px;
  position: relative;
  top: 2px;
}

.report-template-thumbnail {
  width: calc(100% - 92px);
  display: inline-block;
  vertical-align: middle;
  margin: 6px 0;
  margin-left: 10px;
}

.report-thumbnail-img {
  border: 2px solid #ffffff;
  opacity: 0.8;
}

.report-thumbnail-img-select {
  border: 1px solid #1890ff;
  box-shadow: 0px 0px 8px 2px #73bbfd;
}

.report-check-img {
  width: 100%;
  height: 100%;
}

.report-template-thumbnail .ant-radio-group {
  display: flex;
}

.report-template-thumbnail .ant-radio {
  display: none;
}

.report-template-thumbnail .change-thumbnail-icon {
  margin-top: 7%;
  font-size: 12px;
  display: inline-block;
  line-height: 50px;
  height: 50px;
  cursor: not-allowed;
}

.report-template-thumbnail .change-thumbnail-icon .anticon {
  margin-left: -1.5px;
}

.report-template-thumbnail .change-thumbnail-icon:hover {
  background-color: #bbd6f0;
}

.report-thumbnail-desc {
  font-size: 12px;
  text-align: center;
  width: 100%;
}

.report-thumbnail-desc div {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 3px;
}

.report-thumbnail-main .anticon {
  color: #1890ff;
  margin: 0 3px;
}

.report-thumbnail-main .del-icon {
  position: absolute;
  right: 22px;
  top: 2px;
  background-color: #fff;
  display: none;
}

.report-thumbnail-main:hover .del-icon {
  display: block;
}

.report-check-img {
  width: 100%;
  height: 100%;
}

.report-template-thumbnail .change-thumbnail-icon.change-thumbnail-icon-pointer {
  cursor: pointer;
}

.change-thumbnail-icon-color {
  color: #1890ff;
}