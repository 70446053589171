/** antd 5 add panel css */
.panel-ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, .45);
  filter: alpha(opacity=50);
}

.panel-ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 4px;
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
  box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
  pointer-events: auto;
}

.panel-ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, .45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  -webkit-transition: color .3s;
  transition: color .3s
}

.panel-ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}

.panel-ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, .65);
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
}

.panel-ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, .85);
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}

.panel-ant-modal-title:empty {
  height: 44px;
  line-height: 44px;
}

.panel-ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}

.panel-ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
}

/** panel css */
.panel-close {
  display: block;
  font-style: normal;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  width: 56px;
  height: 56px;
  line-height: 56px;
  font-size: 16px;
}

.panel-body {
  padding: 0 !important;
}

.panel-center,
.panel-top-left,
.panel-top-right,
.panel-bottom-left,
.panel-bottom-right,
.panel-center-left,
.panel-center-max,
.panel-center-transform,
.panel-center-top,
.panel-top-right-50 {
  position: absolute !important;
}

.panel-center {
  top: 40px;
  left: 50%;
  margin-left: -500px;
}

.panel-center-top {
  top: 80px;
  left: 50%;
  margin-left: -200px;
}

.panel-top-left {
  top: 5px;
  left: 5px;
}

.panel-center-left {
  top: 150px;
  left: 50%;
  margin-left: -300px;
}

.panel-top-right {
  top: 5px;
  right: 5px;
}

.panel-top-right-50 {
  top: 50px;
  right: 5px;
}

.panel-bottom-left {
  bottom: 5px;
  left: 5px;
}

.panel-bottom-right {
  bottom: 5px;
  right: 5px;
}

.panel-center-max {
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
}

.panel-center-transform {
  top: 40px;
  left: 50%;
  transform: translate(-50%, 0) !important;
}

.rightBorder {
  position: absolute;
  z-index: 1;
  top: 0;
  right: -2px;
  width: 2px;
  height: 100%;
  cursor: e-resize;
}

.leftBorder {
  position: absolute;
  z-index: 1;
  top: 0;
  left: -2px;
  width: 2px;
  height: 100%;
  cursor: e-resize;
}

.bottomBorder {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  cursor: s-resize;
}

.topBorder {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  cursor: n-resize;
}

.lowerRightCorner {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  width: 5px;
  height: 5px;
  overflow: hidden;
  cursor: se-resize;
}

.lowerLeftCorner {
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  width: 5px;
  height: 5px;
  overflow: hidden;
  cursor: sw-resize;
}

.upprRightCorner {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 5px;
  height: 5px;
  overflow: hidden;
  cursor: ne-resize;
}

.upprLeftCorner {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 5px;
  height: 5px;
  overflow: hidden;
  cursor: nw-resize;
}

.hidden {
  display: none;
}

.modal-foot-position {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 52px;
  /* border-top: 1px solid #e8e8e8; */
  box-sizing: border-box;
}

.panel-modal-header {
  padding: 10px 20px !important;
}

.panel-modal-header .ant-modal-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.panel-modal-close-x {
  width: 42px !important;
  height: 42px !important;
  line-height: 42px !important;
}

.panel-modal-minimize-button {
  position: absolute;
  top: 0;
  right: 50px;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.panel-modal-minimize {
  display: block;
  width: 42px;
  height: 42px;
  line-height: 42px;
  font-size: 16px;
  font-style: normal;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}

.panel-modal-minimize-button:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
  transition: 0.3s;
}

.panel-mask {
  z-index: 1000000 !important;
}

.panel-tooltip {
  z-index: 1000000;
}

.panel-border-color {
  background-color: #999999;
}

.aurora-x-scroll-hidden .rightBorder {
  right: 0px;
}

.aurora-x-scroll-hidden .leftBorder {
  left: 0px;
}

.panel-box-shadow {
  box-shadow: rgb(76 77 78 / 14%) 0px -2px 7px 8px, rgb(0 0 0 / 10%) 0px 0px 10px 3px !important;
}

.panel-graph-maximize {
  color: #1890ff;
  font-size: 18px;
  margin-right: 46px;
  float: right;
  cursor: pointer;
  width: 42px;
  height: 42px;
  line-height: 46px;
}