.aurora-rich-text-editor {
  max-width: 500px;
  max-height: 500px
}

.aurora-rich-text-editor .bf-content {
  height: 300px;
}

.text-editor-popover .ant-popover-content {
  box-shadow: 0 2px 8px 8px rgb(0 0 0 / 15%);
  ;
}

.rich-text-tooltip {
  max-height: 300px;
  max-width: 300px;
  text-overflow: ellipsis;
}

.text-editor-tooltip {
  overflow: auto;
  max-width: 300px;
  max-height: 300px;
}

.text-editor-tooltip p {
  margin: 0;
  padding: 0;
}