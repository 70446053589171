.end-to-end-channel-select-content>div {
  padding: 5px 8px;
  width: 100%;
  line-height: 24px;
}

.end-to-end-channel-select-content .end-to-end-channel-select {
  width: calc(100% - 70px);
  margin: 5px 0px;
  display: inline-block;
}

.end-to-end-channel-select-content .end-to-end-channel-label {
  display: inline-block;
  width: 70px;
  line-height: 28px;
  height: 28px;
}

.end-to-end-channel-select-content .aurora-error-msg-span {
  margin: 5px 0px;
}