.sierra-nets-filter-library-panel {
  box-shadow: 0 4px 12px 4px rgba(0, 0, 0, 0.15);
}

.nets-filter-library-content {
  width: 100%;
  padding: 10px 12px;
  min-width: 300px;
}

.nets-filter-library-upload-button,
.nets-filter-library-add-button {
  padding: 0px 8px;
}

.nets-filter-library-content .aurora-model-name-error-msg,
.nets-filter-library-content .aurora-success-msg {
  margin-bottom: 6px;
}

.nets-filter-menu-list {
  margin-bottom: 8px;
}

.nets-filter-menu-list .ant-divider-vertical {
  margin: 0px 4px;
}

.sierra-nets-filter-library-table-part-text {
  width: 100%;
  min-height: 22px;
}

.sierra-nets-filter-library-table-part-text>span:not(.anticon) {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nets-filter-library-content .nets-filter-library-search-table .ant-table-small .ant-table-content .aurora-table-body>table tr>td:nth-child(1) {
  text-align: center;
}

/* .nets-filter-library-content .nets-filter-library-table .ant-table-small .ant-table-content .aurora-table-body>table tr>td:nth-child(1):hover, */
.nets-filter-library-content .nets-filter-library-search-table .ant-table-small .ant-table-content .aurora-table-body>table tr>td:nth-child(2):hover {
  padding-right: 17px;
}

.nets-filter-library-content .nets-filter-library-table .ant-table-small .ant-table-content .aurora-table-body>table tr>td:nth-child(1),
.nets-filter-library-content .nets-filter-library-search-table .ant-table-small .ant-table-content .aurora-table-body>table tr>td:nth-child(2) {
  position: relative;
}

.nets-filter-library-content .nets-filter-library-table .ant-table-small .ant-table-content .aurora-table-body>table tr>td:nth-child(1):hover .sierra-nets-filter-library-delete-icon,
.nets-filter-library-content .nets-filter-library-search-table .ant-table-small .ant-table-content .aurora-table-body>table tr>td:nth-child(2):hover .sierra-nets-filter-library-delete-icon {
  color: #1890ff;
  display: block;
}

.sierra-nets-filter-library-table-part-text .sierra-nets-filter-library-delete-icon {
  color: #ccc;
  font-size: 14px;
  display: none;
  top: 12px;
  position: absolute;
  right: 2px;
}


.sierra-nets-filter-item {
  height: 28px;
  line-height: 28px;
  margin: 6px 0px;
}

.sierra-nets-filter-item .ant-checkbox-wrapper {
  margin-right: 10px;
}

.sierra-nets-filter-apply-item {
  width: 100%;
  height: 28px;
  line-height: 28px;
  text-align: center;
}

.sierra-nets-filter-item .ant-btn {
  height: 28px;
  padding: 0 10px
}

.nets-filter-library-table .aurora-table-body>tr.ant-table-row-selected td {
  background-color: #fff;
}