.stackup-table-prev .ant-table-footer {
  padding: 8px 0;
}

.stackup-table-prev .aurora-table .ant-table-row:hover>td>.editable-cell-value-wrap {
  background-color: rgba(0, 0, 0, 0);
}

.stackup-table-prev .ant-table-footer div {
  margin-left: 76px;
  font-weight: bold;
}

.stackup-table-prev .ant-table-container .ant-table-header>table>.ant-table-thead>tr>th {
  background: #fafafa;
}

.stackup-input-group {
  width: 160px;
  float: left;
}

.stackup-input-group .ant-select-selection-selected-value {
  padding-right: 0px;
}

.stackup-thickness-name {
  float: left;
  margin-left: 0px;
  margin-right: 8px;
}

.stackup-input-col,
.stackup-input-row {
  line-height: 32px;
}

.stackup-input-col {
  float: left;
}

.stackup-set-name {
  margin: 0px 8px 0 16px;
}

.stackup-footer-value-setting {
  height: 32px;
  line-height: 32px;
  width: 100%;
  text-align: left;
  margin: 0px;
}

.stackup-set-button {
  margin-left: 8px;
  margin-right: 8px;
}

.stackup-clear {
  zoom: 1
}

.stackup-clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.stackup-dropdown-button .ant-dropdown-menu-item button {
  width: 140px;
  padding-left: 15px;
  text-align: left;
  border: 0px;
}

.stackup-menu-button {
  padding: 0px;
  vertical-align: middle;
}

.stackup-dropdown-button .ant-dropdown-menu-item {
  padding: 0px;
}

.stackup-dropdown-button .ant-dropdown-menu-item button:hover {
  background-color: #e6f7ff
}

.stackup-conductivity {
  width: 160px;
}

.stackup-unit-selection {
  height: 26px;
}

.stackup-unit-selection .ant-select-selector {
  line-height: 24px;
}

.stackup-unit-selection .ant-select-arrow {
  right: 6px;
}

.stackup-table-edit div {
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}

.stackup-table-edit div+div {
  margin-top: 4px;
}

.stackup-table-edit .anticon-vertical-align-top,
.stackup-table-edit .anticon-vertical-align-bottom {
  font-size: 16px;
}

.stackup-table-edit .anticon-close {
  font-size: 13px;
}

.stackup-table tr td:first-child>div {
  cursor: pointer;
}

.stackup-table-row-light {
  background-color: #f3eecf;
}

.stackup-total-thickness-content {
  display: flex;
  width: 100%;
  margin-left: 0px !important;
  justify-content: space-around;
  font-weight: bold;
}

.stackup-out-dielectric-color {
  background-color: #cfeb7e;
}

.stackup-all-metal-color {
  background-color: #f9c236;
}

.stackup-in-dielectric-color {
  background-color: #fffecf;
}

.stackup-out-dielectric-color .editable-cell-value-wrap {
  border-color: #9b9b9b;
}

.stackup-all-metal-color .editable-cell-value-wrap {
  border-color: #d1d1d1;
}

.stackup-in-dielectric-color .editable-cell-value-wrap {
  border-color: #d8d8d8;
}

.stackup-all-layer>td {
  border-right: 1px solid #d3d3d3 !important;
  border-bottom: 1px solid #d3d3d3 !important;
}

.stackup-out-dielectric-color:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background: #cce779 !important;
}

.stackup-all-metal-color:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background: #fdcb4c !important;
}

.stackup-in-dielectric-color:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background: #ffffbc !important;
}