.aurora-simulation-msg {
  position: relative;
  float: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  min-width: 160px;
  text-align: center;
  margin-left: 10px;
  height: 44px;
}

.aurora-simulation-span {
  position: relative;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  min-width: 80px;
  padding: 0px 6px;
}

.aurora-simulation-project {
  min-width: 80px;
}

.aurora-span-msg,
.aurora-span-msg-project {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 40px;
}

.aurora-span-msg-project {
  font-weight: bold;
  margin-right: 10px;
}

.my-aurora-middle {
  position: relative;
  height: 44px;
  overflow: hidden;
  padding-right: 20px;
  max-width: 70%;
  flex: auto;
}

.my-aurora-monitor-progress {
  position: absolute;
  top: 0px;
  height: 44px;
  line-height: 44px;
  width: 100%;
}

.aurora-monitor-upload-progress-bar {
  width: 100%;
}

.aurora-monitor-upload-progress-bar .ant-progress-outer {
  width: 54%;
}

.aurora-monitor-upload-progress-bar .ant-progress-text {
  font-weight: bold;
  vertical-align: unset;
}

.aurora-simulation-btn {
  position: relative;
  float: right;
  height: 44px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  width: 80px;
  margin-right: 12px;
}

.aurora-simulation-btn .icon-cancel9 {
  font-size: 20px;
  line-height: 44px;
  color: #1890ff;
}

.aurora-simulation-button,
.aurora-cancel-button,
.aurora-kill-button {
  float: right;
  line-height: 44px;
  height: 44px;
  font-size: 18px;
  color: #1890ff;
  position: relative;
  z-index: 20;
  cursor: pointer;
  margin-left: 16px;
  box-sizing: border-box;
}

.aurora-cancel-button {
  line-height: 43px;
}

.aurora-upload-debug-button-icon {
  font-size: 22px;
}

.my-aurora-monitor {
  overflow: auto;
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  top: 44px;
  left: 102px;
  right: 14px;
  bottom: 14px;
}

.aurora-simulation-title {
  width: 100%;
  position: relative;
  height: 44px;
}

.aurora-simulation-holygrail {
  position: absolute;
  right: 0px;
  left: 0;
  box-sizing: border-box;
}

.aurora-span-msg-tooltip {
  display: block;
  text-indent: 8px;
}

.aurora-simulation-title .span-msg-icon {
  font-size: 18px;
  color: #1890ff;
}

.aurora-simulation-title .span-msg-icon-div {
  padding-left: 12px;
  margin-left: 4px;
  width: 100%;
  margin-top: 12px;
}

.aurora-simulation-middle {
  position: relative;
  float: left;
  height: 44px;
  overflow: hidden;
  padding-right: 0px;
  box-sizing: border-box;
  float: right;
  margin-top: 2px;
  flex: auto;
}

.aurora-simulation-middle .ant-progress .ant-progress-inner {
  background-color: #f9f9f9;
}

.aurora-simulation-progress {
  position: absolute;
  top: 0px;
  left: 10px;
  height: 40px;
  width: 100%;
  line-height: 44px;
  box-sizing: border-box;
}

.aurora-layout-simulation-progress {
  height: 40px;
  width: 100%;
  line-height: 44px;
  box-sizing: border-box;
  flex: 1;
}

.aurora-simulation-progress .simulation-progress-bar {
  width: 93%;
}

.aurora-layout-simulation-progress .simulation-progress-bar {
  width: 93%;
}

/* .aurora-simulation-progress .ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 26px);
  padding-right: calc(2em + 28px);
} */

.aurora-simulation-progress .ant-progress-show-info .ant-progress-text {
  margin-left: 4px;
}

.aurora-simulation-middle .simulation-waiting-title {
  line-height: 42px;
  font-weight: bold;
  float: right;
  margin-right: 50px;
}

.aurora-simulation-title .waiting-time-span {
  display: inline-block;
  margin-left: 10px;
  font-weight: 500;
}

.aurora-simulation-btn {
  position: relative;
  float: right;
  height: 44px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  width: 40px;
  margin-right: 12px;
}

.aurora-simulation-btn .icon-cancel9 {
  font-size: 20px;
  line-height: 44px;
  color: #1890ff;
}

.aurora-cancel-button {
  float: right;
  line-height: 43px;
  height: 44px;
  font-size: 18px;
  color: #1890ff;
  position: relative;
  z-index: 20;
  cursor: pointer;
  margin-left: 16px;
  box-sizing: border-box;
}

.aurora-monitor-ul {
  margin: 0;
  padding: 0;
  height: fit-content;
}

.aurora-simulation-monitor-select {
  max-width: 100%;
  min-width: 54px;
}

.aurora-simulation-monitor-select .ant-select-selection .ant-select-arrow {
  right: 6px;
}

.debug-mes-span {
  display: block;
}

.aurora-stackup-error-dialog {
  padding-top: 10px;
  padding-left: 20px;
  color: #ff4848;
}

.macro-modeling-error-span {
  display: block;
  color: #ff4848;
  line-height: 22px;
  padding: 0px 30px;
}

.aurora-monitor-backbottom {
  position: absolute;
  font-size: 18px;
  right: 40px;
  bottom: 40px;
  opacity: 0.6;
  background-color: #1890ff;
  color: #fff;
  transform: rotate(90deg);
  padding: 4px;
  border-radius: 4px;
  z-index: 999;
}

.aurora-monitor-backbottom:hover {
  opacity: 0.8;
  font-weight: bold;
}

.simulation-waiting-title {
  line-height: 42px;
  font-weight: bold;
  float: right;
}

.aurora-simulation-monitor-select .ant-select-selection .ant-select-arrow {
  right: 6px;
}

.my-aurora-monitor-pre {
  margin: 0px;
  overflow: unset;
  padding: 0 20px;
  padding-top: 14px;
}

.aurora-simulation-monitor {
  padding-top: 14px;
}

.aurora-simulation-monitor .my-aurora-monitor-pre {
  padding-top: 0px;
}

.my-aurora-monitor-pre .error-check-warning-dialog {
  padding: 0px;
}

.aurora-monitor-ul li {
  list-style: none;
  width: 100%;
}

.aurora-monitor-ul li>p {
  margin: 0px;
}

.aurora-repeater-monitor-content,
.aurora-stackup-error-monitor-content {
  margin-bottom: 10px;
}

.aurora-repeater-monitor-content .ant-divider-dashed {
  border-color: #c9c9c9;
  margin: 6px 0;
}

.aurora-stackup-error-monitor-content {
  color: #ff0000;
}

.aurora-stackup-error-monitor-content .aurora-monitor-ul li {
  line-height: 24px
}

.aurora-stackup-error-monitor-content>span {
  display: block;
  line-height: 24px;
}

.aurora-stackup-error-span {
  font-weight: bold;
}

.aurora-monitor-error {
  margin-left: 10px;
}

.aurora-stackup-error-monitor-content .aurora-error-list-display-content {
  padding-left: 10px;
}

.aurora-monitor-list-main {
  position: absolute;
  top: 44px;
  left: 102px;
  right: 14px;
  bottom: 14px;
}

.aurora-monitor-list-main .my-aurora-monitor {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  border-radius: 0px;
}

.aurora-monitor-list-content {
  position: absolute;
  top: 0px;
  left: 102px;
  right: 0px;
  bottom: 0px;
  padding-top: 14px;
  padding-left: 8px;
}

.aurora-repeater-monitor-children {
  margin-left: 10px;
}

.aurora-repeater-monitor-children .aurora-repeater-monitor-title {
  font-weight: 500;
}

.aurora-error-content {
  color: #ff4848;
}

.aurora-simulation-selection {
  max-width: 200px;
  min-width: 100px;
  line-height: 42px;
  margin-left: 10px;
}

.aurora-simulation-selection-dropdown {
  z-index: 999999999999;
}