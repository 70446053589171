/* === package model panel === */
.package-model-select {
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 4px 10px;
}

.package-model-selection {
  width: calc(100% - 100px);
}

.package-model-selection .ant-select-arrow {
  right: 11px !important;
}

.package-model-select-dropdown {
  z-index: 10000000;
}

.package-model-select>*:first-child {
  width: 100px;
  height: 24px;
  line-height: 22px;
}

.package-model-title {
  font-size: 14px;
  font-weight: 500;
}

.package-model-select .package-model-selection {
  height: 24px;
  line-height: 22px;
  font-weight: 500;
  font-size: 13px;
  margin-left: 16px;
}

.spice-model-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}


.spice-model-panel .panel-body {
  position: relative;
}

.spice-model-panel .spice-model-panel-title {
  font-size: 14px;
  font-weight: 500;
}

.pkg-panel .spice-model-content>div {
  min-width: 650px;
}

.pkg-panel .spice-pin-list-ul {
  padding: 3px 10px;
}

.pkg-panel .find-package-layout-errorMsg-box {
  margin: 4px 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 7px;
  max-height: 130px;
  overflow: auto;
  font-size: 14px;
}

.pkg-panel .find-package-layout-errorMsg-box .errorMsg-box-div {
  padding-left: 5px;
}

.pkg-panel .find-package-layout-errorMsg-box .errorMsg-box-span {
  padding-left: 15px;
}

.spice-pin-list-ul .spice-pin-connect-node-title {
  width: 100%;
  text-align: center;
  color: #005a9e;
  height: 32px;
  font-size: 16px;
  line-height: 32px;
  padding-left: 8px;
}

.spice-pin-list-ul .spice-pin-connect-node-title .anticon-delete {
  margin-left: 8px;
}