.clear {
  zoom: 1
}

.clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.serdes-config-row {
  margin: 10px !important;
}

.font-bold {
  font-weight: bold;
}

.serdes-name-input,
.serdes-datarate-input {
  width: 130px;
  margin-left: 10px;
}

.serdes-select-version {
  min-width: 50px;
  margin-left: 10px;
  margin-bottom: 2px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.serdes-config-divider {
  margin: 0px;
}

.serdes-interface-icon {
  margin-left: 10px;
  font-size: 18px;
  transition: all 0.5s;
}

.serdes-interface-icon:hover {
  cursor: pointer;
  color: #1890ff;
  transition: all 0.5s;
}

.config-serdes-ground-nets {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  height: 32px;
  margin-left: 10px;
  padding: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  transition: all 0.3s;
  color: rgba(0, 0, 0, 0.65);
  min-width: 150px;
  float: left;
}

.fl-l {
  float: left;
}

.serdes-ground-nets:hover {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  transition: all 0.3s;
}

.ground-nets {
  height: 32px;
  line-height: 32px;
}

.serdes-table {
  margin-top: 10px;
}

.serdes-table-auto .ant-table-middle .ant-table-container .aurora-table-body>table>.ant-table-thead>tr>th,
.serdes-table-auto .ant-table-middle .ant-table-container .aurora-table-body>table>.ant-table-tbody>tr>td {
  height: 38px !important;
  line-height: 38px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.col-padding-0 {
  padding: 0px !important;
}