.connection-channel-title>div {
  font-size: 14px;
  margin: 0px 16px;
  min-width: 100px;
}

.connection-channel-title>div .connection-pcb-name {
  color: #0d82d7;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  padding-right: 10px;
  line-height: 32px;
}

.connection-channel-title .connection-border {
  height: 20px;
  width: 1px;
  min-width: 1px;
  background: #ccc;
  margin-top: 26px;
}

.connection-channel-title>div .connection-channel-name {
  color: #67676f;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  line-height: 32px;
}

.connection-channel-title {
  display: flex;
  justify-content: center;
  transition: 0.3s;
}

.connection-model-content-wrapper {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  background-color: #fff;
  position: relative;
  margin: 15px 0;
  padding: 10px 0;
}

.aurora-connector-option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.connector-add-button {
  margin-left: 10px;
  cursor: pointer;
}

.connector-delete-button {
  font-size: 16px;
  /* color: #0d82d7; */
  color: #1890ff;
  position: absolute;
  cursor: pointer;
  top: 11px;
  right: 11px;
  transform: translate(50%, -50%);
}

.connection-model-content-wrapper .connector-delete-button.connector-delete-button-disable {
  color: #ccc;
  cursor: not-allowed;
}

.connection-model-content {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.connection-model-content .change-connection-icon {
  font-size: 16px;
  width: 26px;
  display: inline-block;
  cursor: not-allowed;
  padding-left: 7px !important;
  padding-right: 8px !important;
  line-height: 32px;
  height: 30px;
  margin-bottom: 2px;
}

.connection-model-content .change-connection-icon i {
  margin-left: -1.5px;
}

.connection-model-content .change-connection-icon:hover {
  background-color: #bbd6f0;
}

.connection-model-list {
  width: fit-content;
  display: inline-block;
}

.connection-title-pcb {
  text-align: center;
  font-weight: 600;
}

.connection-model-main {
  transition: 0.3s;
  width: 100%;
}

.connection-name-content {
  text-align: center;
}

.connection-model-box {
  position: relative;
}

.connection-channel-tile-title {
  transition: 0.3s;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin: 0 20px;
  color: #005a9e;
}

.connection-channel-tile-title>div {
  margin: 0px 14px;
  width: 36%;
  text-align: center;
}

.connection-title-tile-pcb {
  /* width: 134px; */
  /* display: inline-block; */
  margin-right: 30px;
  color: #005a9e;
}

.connection-model-tile-list {
  width: 100%;
  display: inline-block;
}

.anticon.anticon-plus-circle.signal-add--connection-icon {
  position: absolute;
  right: 10px;
  top: 6px;
  color: #0d87f7;
  font-size: 16px;
  margin-left: 14px;
}