.menu-list>ul {
  padding: 14px;
  margin: 0px;
}

.menu-list li {
  list-style: none;
  cursor: pointer;
  line-height: 24px;
}

.modelIbis-list li {
  list-style: none;
  padding-inline-start: 0px;
}

.menu-list>ul>li>span {
  font-weight: bold;
}

.expand-icon {
  font-size: 12px;
  padding-right: 10px;
}

.menu-list-title {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
}

.menu-panel>.ant-modal-close>.ant-modal-close-x {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.menu-panel>.ant-modal-header {
  padding: 12px 18px;
}

.menu-list-title:hover, .menu-list-pintopin-title a:hover {
  color: #1890ff;
  transition: .5s all;
}

.menu-list-pintopin-title a {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
  font-weight: bold;
}

.menu-list-pintopin-title {
  list-style: square inside !important;
  padding-left: 4px;
}