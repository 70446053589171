.aurora-msg-dialog-panel,
.aurora-msg-dialog-info-panel {
  top: 200px;
}

.aurora-msg-dialog-mask-panel {
  z-index: 100000000 !important;
}

.aurora-msg-dialog-info-panel .panel-modal-header {
  padding: 0px !important;
}

.aurora-msg-dialog-panel .ant-modal-header,
.aurora-msg-dialog-panel .ant-modal-close {
  display: none;
}

.aurora-msg-dialog-panel .rightBorder,
.aurora-msg-dialog-panel .bottomBorder,
.aurora-msg-dialog-panel .lowerRightCorner {
  cursor: unset;
}

.aurora-msg-dialog-main {
  padding: 10px 14px;
  /* max-height: 600px; */
  /*  overflow: auto; */
  width: 100%;
  height: 100%;
}

.aurora-msg-dialog-info-panel .aurora-msg-dialog-main {
  padding: 14px;
}

.aurora-msg-dialog-title {
  text-align: center;
}

.aurora-msg-dialog-content {
  width: 100%;
  max-height: 600px;
  overflow: auto;
  height: 100%;
}

.aurora-msg-dialog-info-panel .aurora-msg-dialog-main .aurora-msg-dialog-content {
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  padding: 6px 16px;
  height: calc(100% - 36px);
}

.aurora-msg-dialog-item,
.aurora-msg-dialog-info-item {
  margin: 6px 0px;
  font-size: 14px;
  color: #1ca4f3;
  /*   text-align: center; */
  width: 100%;
}

.aurora-msg-dialog-content .aurora-msg-dialog-info-item {
  color: rgb(0, 0, 0, 0.65);
}

.message-dialog-ready-icon {
  color: #57f618;
  margin-right: 10px;
}

.message-dialog-loading-icon {
  color: #1ca4f3;
  margin-right: 10px;
}

.aurora-msg-footer-main {
  text-align: center;
}

.aurora-msg-footer-main .ant-btn {
  height: 28px;
  width: 75px;
  /* border: none; */
  /*  color: #6f6f6f;
  line-height: 26px;
  border-color: #eee; */
}

/* .aurora-msg-footer-main .ant-btn:hover {
  color: #6f6f6f;
  background-color: #eee;
} */

.aurora-msg-dialog-main .aurora-error-msg-dialog-item {
  color: #f3231c;
  text-align: center;
}

.aurora-msg-warning-png {
  width: 24px;
  height: 24px;
}

.aurora-msg-error-dialog-title {
  padding-bottom: 10px;
  text-align: center;
}

.aurora-msg-dialog-panel .modal-foot-position {
  height: 40px !important;
}

.aurora-msg-dialog-panel .ant-modal-footer {
  padding: 6px 16px;
}

.aurora-msg-Tips-icon {
  font-size: 22px;
}

.aurora-msg-error-dialog-title>span {
  font-weight: 600;
}

.aurora-msg-footer-main .ant-btn.aurora-msg-dialog-download-button {
  width: 100px;
}

.aurora-msg-dialog-info-download {
  color: #1890ff;
  font-size: 17px;
  float: right;
  cursor: pointer;
  width: 42px;
  height: 42px;
  line-height: 46px;
}

.aurora-msg-dialog-title {
  height: 42px;
  line-height: 42px;
  display: inline-block;
}

.aurora-msg-dialog-search {
  margin-bottom: 8px;
}

.aurora-msg-dialog-search .aurora-input {
  width: 100%;
}

.aurora-msg-children-dialog-item {
  padding-left: 10px;
  color: rgb(0, 0, 0, 0.65);
}