/*right*/
/** top */
.aurora-result-mode-tab.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav .ant-tabs-tab-active {
  color: #005a9e;
  background: #c7e0f4;
  border-radius: 0px;
  border-color: #c7e0f4;
  height: 30px;
  /* 
  line-height: 30px; */
}

.aurora-result-mode-tab.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
  height: 30px;
}

.aurora-result-mode-tab.ant-tabs-card .ant-tabs-bar {
  margin: 0px;
}

.aurora-result-mode-tab.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav .ant-tabs-tab {
  line-height: 28px;
  border-radius: 0px;
  height: 30px;
}

.aurora-result-mode-tab.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:first-child {
  border-left: 0px;
}

/*** powerSumSetting  */
.tdr-sbr-setting {
  padding: 0px 12px 12px 12px;
}

.tdr-sbr-setting .ant-input-group-wrapper,
.tdr-sbr-setting input.duration-ratio-input {
  width: calc(100% - 120px);
  max-width: 200px;
}

.tdr-sbr-setting .powersum-target-lines-tags-input {
  width: calc(100% - 166px);
}

.tdr-sbr-setting .powersum-setting-tags-input {
  width: calc(100% - 200px) !important;
}

.tdr-sbr-setting .config-title {
  display: inline-block;
  width: 120px;
  line-height: 28px;
}

.tdr-sbr-setting .powersum-config-title {
  display: inline-block;
  width: 200px;
  line-height: 28px;
}

.tdr-sbr-setting .ant-row {
  margin-bottom: 6px;
}

.tdr-sbr-setting .ant-btn {
  width: 100%;
  margin-top: 2px;
}

.tdr-sbr-setting .ant-input-group-addon {
  min-width: 40px;
}

.tdr-sbr-setting .error-msg {
  padding: 6px 8px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 4px;
  margin-bottom: 6px;
}

/** list */
/** setting */
.aurora-v2-waveform-setting-css {
  padding: 0 10px 10px 10px;
}