.sierra-debug-download-panel {
  margin-top: 80px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.sierra-debug-download-title {
  font-size: 16px;
  font-weight: 500;
}

.sierra-debug-download-content {
  padding: 10px;
  border: 1px solid #f3f2f1;
  border-radius: 6px 6px 0 0;
  overflow: hidden;
  min-width: 580px;
  min-height: min-content;
}

.sierra-debug-download-content .sierra-debug-download-menu {
  border-radius: inherit;
  height: 32px;
  background: #f3f2f1;
  font-size: 14px;
  color: #005a9e;
  display: flex;
  justify-content: left;
  width: 100%;
}

.sierra-debug-download-content .sierra-debug-download-menu .sierra-debug-download-menu-btn {
  line-height: 23px;
  padding: 4px 16px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  outline: none;
  border-radius: inherit;
  text-align: left;
  float: left;
  color: #005a9e;
  display: flex;
}

.sierra-debug-download-menu-btn:hover {
  background-color: #edebe9;
}

.sierra-debug-download-menu-btn>div {
  display: inline-block;
  white-space: nowrap;
  word-break: keep-all;
  width: fit-content;
}

.sierra-debug-download-menu-btn:first-child>span {
  display: inline-block;
  width: max-content;
}

.sierra-debug-download-menu-select {
  font-size: 13px;
  height: 24px;
  color: #005a9e;
  width: 100%;
  max-width: 280px;
  min-width: 100px;
}

.sierra-debug-download-checkbox-group {
  flex-direction: column;
}

.sierra-debug-download-menu-select {
  height: 24px;
}

.sierra-debug-download-menu-select .ant-select-selector {
  line-height: 22px;
}

.sierra-debug-download-menu-select .ant-select-arrow {
  right: 6px;
  color: #005a9e;
}

.sierra-debug-download-menu-select .ant-input {
  height: 24px
}

.design-failed-Option {
  color: #ff0000;
}

.sierra-debug-download-menu-select-drop {
  z-index: 10000000;
}

.sierra-debug-download-list {
  margin-top: 6px;
  overflow: auto;
}

.sierra-debug-download-list .sierra-debug-download-checkbox,
.sierra-debug-download-list .sierra-debug-download-group-checkbox {
  margin: 0;
  padding: 5px 10px;
}

.sierra-debug-download-list .sierra-debug-download-group-checkbox .sierra-debug-download-checkbox {
  padding: 0px 6px;
}

.sierra-debug-download-content .sierra-debug-download-list .sierra-debug-download-sub-checkbox {
  padding-left: 28px;
}

.sierra-debug-download-list .sierra-debug-download-group-checkbox .anticon {
  font-size: 12px;
  vertical-align: baseline;
}

.sierra-debug-download-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 8px 10px;
}

.sierra-debug-download-footer-btn {
  font-size: 13px;
  line-height: 24px;
  width: 80px;
  height: 26px;
  padding: 0;
  margin: 2px 0 2px 5%;
}

.sierra-debug-download-footer-message {
  line-height: 28px;
  height: 30px;
  text-indent: 10px;
  border-radius: 4px;
  font-size: 14px;
  flex-grow: 1;
}

.sierra-debug-download-footer-message.success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}

.sierra-debug-download-footer-message.error {
  border: 1px solid #ffa39e;
  background-color: #fff1f0;
}

.sierra-debug-download-footer-progress {
  padding: 2px 10px 2px 0;
  flex-grow: 1;
}

.sierra-debug-download-footer-progress .ant-progress-inner {
  background-color: #c7e0f4;
}

.sierra-debug-download-footer-progress .ant-progress-text {
  vertical-align: unset;
}

.sierra-debug-download-footer-progress .ant-progress-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  -webkit-animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  content: '';
}