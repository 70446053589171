.top-sus-wire-bond-panel .top-sus-wire-bond-content {
  padding: 10px 16px;
  border-radius: 6px 6px 0px 0px;
  height: 100%;
}

.top-sus-wire-bond-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.top-sus-wire-bond-title {
  font-size: 16px;
  font-weight: 500;
  position: relative;
}

.top-sus-wire-bond-item>span {
  width: 70px;
}

.top-sus-wire-bond-item>.aurora-select {
  width: calc(100% - 70px);
}

.wire-bond-geometry>span {
  display: inline-block;
}

.wire-bond-geometry>span:nth-child(1) {
  margin-right: 10px;
}

.wire-bond-img {
  width: 100%;
}

.wire-bond-help-icon {
  position: absolute;
  right: 10px;
  top: 2px;
  color: #1890ff;
  cursor: pointer;
}

.top-sus-wire-bond-help-content {
  padding: 0px;
  border-radius: 6px 6px 0px 0px;
  height: fit-content;
}

.top-sus-wire-bond-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 28px;
  line-height: 28px;
  margin: 8px 0px;
}