.andes-ads-model-setup-main .netlist-model-row .ant-select-selector,
.andes-ads-model-setup-main .netlist-model-row .netlist-model-selection,
.andes-ads-model-setup-main .netlist-model-row .ant-select-selection,
.andes-ads-model-setup-main .netlist-model-table-rows .editable-cell-value-wrap {
  height: 26px;
  line-height: 24px;
}

.andes-ads-model-setup-main .netlist-model-row {
  padding: 5px 0px;
}

.andes-ads-model-setup-main .netlist-model-content div>.netlist-model-simall-row:last-child {
  padding-left: 10px;
}

.ads-model-apply-all-content.ads-circuit-model-apply-all-content>span {
  width: 204px;
}