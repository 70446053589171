.sierra-multi-setup-table .editable-cell-value-wrap,
.sierra-multi-buffer-table .editable-cell-value-wrap,
.sierra-multi-passive-table .editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.sierra-multi-setup-table .aurora-table-body>tr>td,
.sierra-multi-buffer-table .aurora-table-body>tr>td:nth-child(1),
.sierra-multi-buffer-table .aurora-table-body>tr>td:nth-child(2),
.sierra-multi-buffer-table .aurora-table-body>tr>td:nth-child(3),
.sierra-multi-passive-table .aurora-table-body>tr>td:nth-child(1),
.sierra-multi-passive-table .aurora-table-body>tr>td:nth-child(2) {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.sierra-multi-setup-content .sierra-multi-setup-table .ant-table-small>.ant-table-content>.aurora-table-body>table>.ant-table-thead>tr>th:nth-child(6),
.sierra-multi-setup-content .sierra-multi-setup-table .ant-table-small>.ant-table-content>.aurora-table-body>table>.ant-table-thead>tr>th:nth-child(7) {
  padding-left: 12px;
}

.sierra-multi-setup-table .ant-table .interface-table-checkBox {
  width: 35px;
  text-align: center;
}

.sierra-multi-setup-table .multi-setup-setting-icon {
  cursor: pointer;
}

.sierra-multi-setup-table .multi-setup-setting-selected-icon {
  color: #1890ff;
}

.sierra-multi-setup-table .interface-table-setting-content {
  width: 100%;
  text-align: center;
}

/* buffer */
.sierra-multi-buffer-panel,
.sierra-multi-passive-panel {
  box-shadow: 0 4px 12px 4px rgba(0, 0, 0, 0.15);
}

.multi-buffer-content,
.multi-passive-content {
  width: 100%;
  padding: 10px 12px;
  min-width: 1078px;
}

.sierra-multi-buffer-copy-usage {
  display: inline-block;
  line-height: 28px;
  height: 28px;
}

.sierra-multi-buffer-copy-usage>.sierra-multi-buffer-copy-usage-switch {
  margin-left: 10px;
}

.sierra-multi-setup-buffer-title {
  width: 100%;
  height: 100%;
  cursor: pointer;
  color: #0b3b94;
}

.sierra-content-group-selection {
  width: calc(100% - 300px);
  display: flex;
  line-height: 28px;
  height: 28px;
}

.sierra-content-group-selection>span {
  display: block;
  width: 90px;
}

.sierra-content-group-selection>.aurora-select {
  width: calc(100% - 90px);
}

.multi-setup-simulation-apply-content {
  width: 100%;
  display: flex;
  line-height: 28px;
  padding: 0px 16px;
  padding-bottom: 10px;
  justify-content: space-between;
}

.multi-setup-simulation-apply-content>span {
  display: block;
  width: 172px;
  font-size: 13px;
}

.multi-setup-simulation-apply-content .aurora-select {
  width: calc(100% - 392px);
  font-size: 13px;
  min-height: 28px;
  height: auto;
}

.sierra-simulation-option-content .multi-setup-simulation-option-apply-content>span {
  display: block;
  width: 100px;
  font-size: 13px;
}

.sierra-simulation-option-content .multi-setup-simulation-option-apply-content>.aurora-select {
  width: calc(100% - 100px);
}

.multi-setup-option-select-dropdown {
  z-index: 100000000;
  max-height: 500px;
}

.multi-setup-buffer-model-assigning {
  display: inline-block;
  margin-left: 20px;
  color: #1890ff;
}

.multi-setup-buffer-model-re-match-icon {
  color: #1890ff;
  margin-left: 10px;
  cursor: pointer;
  font-size: 14px;
}

.sierra-multi-setup-content .ant-table-pagination.ant-pagination,
.sierra-multi-buffer-content .ant-table-pagination.ant-pagination,
.sierra-multi-passive-content .ant-table-pagination.ant-pagination {
  margin-top: -36px;
  margin-bottom: 8px;
}

.sierra-multi-edit-content {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.multi-setup-buffer-stimulus-content {
  width: 100%;
  height: 100%;
  position: relative;
}

.multi-setup-buffer-stimulus-content .stimulus-copy-setup-icon {
  position: absolute;
  right: 2px;
  top: 50%;
  margin-top: -6px;
  display: none;
  color: #1890ff;
  cursor: pointer;
}

.multi-setup-buffer-stimulus-content .stimulus-copy-setup-icon-1 {
  margin-top: -7px;
}

.stimulus-paste-setup-icon {
  font-size: 15px;
  margin-right: 22px;
  float: right;
  display: none;
  color: #1890ff;
  cursor: pointer;
  padding-top: 2px;
  vertical-align: middle;
  /*   position: absolute;
  right: 22px;
  top: 50%;
  margin-top: -13px; */
}

.multi-setup-buffer-stimulus-content .stimulus-paste-setup-icon-1 {
  margin-right: 2px;
}

.multi-setup-buffer-stimulus-content:hover .stimulus-copy-setup-icon,
.multi-setup-buffer-stimulus-content:hover .stimulus-paste-setup-icon {
  display: inline-block;
}

.multi-setup-buffer-stimulus-text {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
}

.multi-setup-buffer-stimulus-content:hover .multi-setup-buffer-stimulus-text-1 {
  width: calc(100% - 38px);
}

.multi-setup-buffer-stimulus-content:hover .multi-setup-buffer-stimulus-text-2 {
  width: calc(100% - 18px);
}

.sierra-extraction-content-apply-checkbox {
  vertical-align: middle;
}

.sierra-multi_setup-result-icon {
  font-size: 13px;
}

.sierra-multi_setup-result-icon:hover {
  color: #1890ff;
}

.sierra-multi-setup-table .aurora-table-tbody>tr>.sierra-multi-interface-result {
  text-align: center;
}

.sierra-multi-setup-content .sierra-multi-setup-table .ant-table-small.ant-table-bordered .aurora-table-body>tr>td {
  border-right: 1px solid #e8e8e8;
}

.sierra-multi-extraction-content-apply-item {
  width: 220px;
  padding-left: 10px;
  display: flex;
}

.sierra-multi-extraction-content-apply-item>.aurora-select {
  width: calc(100% - 52px);
}

.sierra-multi-extraction-content-apply-item>span {
  display: block;
  width: 52px;
  padding-right: 10px;
  text-align: center;
}