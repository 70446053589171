.related-nets-select-div {
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.related-nets-select-span {
  font-weight: 600;
  font-size: 14px;
  display: block;
  margin: 0px;
  padding: 0px;
  padding-left: 8px;
  cursor: pointer;
}

.related-nets-select-ul-1 {
  max-height: 138px;
  width: 100%;
  overflow: auto;
  padding-left: 15px;
  margin-bottom: 2px !important;
}

.related-nets-select-ul-2 {
  max-height: 68px;
  width: 100%;
  overflow: auto;
  padding-left: 15px;
  margin-bottom: 4px !important;
}

.related-nets-select-li {
  line-height: 28px;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
  padding-left: 4px;
}

.related-nets-select-li:hover {
  background-color: rgb(223, 243, 255);
}

.related-nets-selected {
  background-color: rgb(176, 226, 255);
  font-weight: 600;
}

.related-nets-selected:hover {
  background-color: rgb(176, 226, 255);
}

.related-nets-expand-icon {
  margin: 0 4px;
  font-size: 12px;
}
