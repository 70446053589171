.andes-create-end-to-end-channel-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.andes-create-end-to-end-channel-panel .create-end-to-end-channel-content {
  padding: 8px 14px;
}

.andes-create-end-to-end-channel-panel .end-to-end-channel-input-item {
  margin: 8px;
  display: flex;
  line-height: 28px;
  height: 28px;
}

.andes-create-end-to-end-channel-panel .end-to-end-channel-edit-input-label {
  width: 80px;
  height: 28px;
  line-height: 28px;
}

.andes-create-end-to-end-channel-panel .end-to-end-channel-input,
.andes-create-end-to-end-channel-panel .end-to-end-channel-select {
  width: calc(100% - 80px);
}

.end-to-end-channel-input-item .aurora-model-name-error-msg {
  width: 100%;
  padding: 0px 10px;
}