/* === Extraction ports panel === */
.extraction-ports-panel {
  margin-top: 100px;
  overflow: auto;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.extraction-ports-panel .panel-modal-header {
  padding-left: 14px !important;
}

.extraction-ports-panel .ant-divider-horizontal {
  margin: 10px 0px;
}

.extraction-ports-title {
  font-size: 16px;
  font-weight: 500;
}

.extraction-ports-content {
  padding: 10px 16px;
  min-width: 800px;
  min-height: 265px;
  height: max-content;
}

.extraction-ports-input-content {
  width: 65%;
  min-height: 28px;
  display: inline-block;
}

.extraction-ports-z0-content {
  width: 35%;
  display: inline-block;
  height: 28px;
}

.extraction-ports-z0-content label {
  width: 70px;
  display: inline-block;
  text-align: end;
  padding-right: 10px;
}

.extraction-ports-reference-main .extraction-ports-z0-content .aurora-input {
  width: calc(100% - 70px);
}

.extraction-ports-z0-content .extraction-ports-checkbox {
  width: 50px;
}

.extraction-ports-input-content label {
  width: 170px;
  vertical-align: middle;
  display: inline-block;
}

.extraction-ports-input-content .aurora-select,
.extraction-ports-input-content .port-select {
  width: calc(100% - 170px);
}

.extraction-ports-input-content .port-select,
.extraction-ports-input-content .port-select .ant-select-selector {
  min-height: 28px;
  height: auto;
}

.extraction-ports-input-content .port-select .ant-select-selector .ant-select-selection-wrap .ant-select-selection-overflow{
  display: block;
}

.extraction-port-button {
  overflow: hidden;
  margin: 10px 0px;
}

.ports-component-pin-add-content {
  display: inline-block;
}

.ports-component-pin-add-content>span {
  vertical-align: middle;
}

.ports-component-pin-add-content>.anticon {
  vertical-align: middle;
  margin-left: 10px;
  color: #188fff;
  font-size: 15px;
  cursor: pointer;
}

.extraction-port-edit {
  color: #188fff;
  cursor: pointer;
  text-align: center;
}

/* auto generation */
.extraction-ports-reference-main {
  padding-bottom: 10px;
}

.extraction-ports-auto-setup {
  border-radius: 5px;
  background: #f3f3f3;
  border: 1px solid #e8e8e8;
  position: relative;
  padding: 10px 0px;
}

.extraction-ports-content .extraction-ports-reference-main .extraction-ports-auto-title {
  font-size: 15px;
  font-weight: 600;
}

.extraction-ports-content .extraction-ports-reference-main .extraction-ports-auto-title .anticon {
  font-size: 13px;
  vertical-align: middle;
  margin-right: 10px;
}

.extraction-ports-reference-main>.extraction-ports-auto-setup {
  padding: 10px;
  line-height: 28px;
}

.extraction-ports-reference-main .extraction-ports-reference-sub-div {
  margin-left: 48px;
}

.extraction-ports-reference-main .extraction-ports-reference-sub-div .extraction-ports-radio,
.extraction-ports-reference-main .extraction-ports-reference-sub-div .extraction-ports-sub-radio {
  width: 242px;
}

.extraction-ports-reference-main .extraction-ports-radio {
  margin-right: 10px;
  display: block;
  line-height: 28px;
}

.extraction-ports-reference-main .extraction-ports-sub-radio {
  display: inline-block;
  vertical-align: middle;
  line-height: 28px;
}

.extraction-ports-reference-main .aurora-input {
  width: calc(100% - 170px);
}

.extraction-ports-generate-button {
  margin-top: 8px;
  width: 100%;
  height: 24px;
}

.extraction-ports-generate-button .ant-btn {
  float: right;
}

.extraction-ports-generate-button-active .ant-btn {
  color: #40a9ff;
  background-color: #fff;
  border-color: #40a9ff;
}

.extraction-ports-generate-button .ant-btn:hover,
.extraction-ports-generate-button .ant-btn:focus {
  color: #40a9ff;
  background-color: #fff;
  border-color: #d9d9d9;
}

.extraction-ports-generate-button-active .ant-btn:hover,
.extraction-ports-generate-button-active .ant-btn:focus {
  border-color: #40a9ff;
}

.extraction-ports-z0-sub {
  vertical-align: sub;
  font-size: 10px;
}

.extraction-ports-ref-type-radio-main {
  margin-left: 26px;
}

.extraction-ports-disabled {
  color: #ccc;
}

.extraction-ports-panel .aurora-model-name-error-msg,
.extraction-ports-panel .aurora-model-name-warning-msg {
  padding: 2px 8px;
  margin-top: 6px;
}

.extraction-ports-panel .aurora-model-name-error-msg {
  border: 1px solid #ffa39e;
}

.extraction-ports-panel .aurora-model-name-warning-msg {
  margin-top: 6px;
}

.extraction-ports-table-reference {
  max-height: 22px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.extraction-ports-table .ant-table-thead>tr>th,
.extraction-ports-table .ant-table-body .aurora-table-body tr>td {
  text-align: center;
}

.aurora-extraction-ports-input-main {
  margin: 6px 0px;
}

.aurora-extraction-ports-input-main>span {
  display: inline-block;
  width: 180px;
  vertical-align: middle;
}

.aurora-extraction-ports-input-main>.aurora-input {
  display: inline-block;
  width: calc(100% - 180px);
  vertical-align: middle;
}

.extraction-ports-components-checkbox {
  margin-right: 16px;
}

.extraction-ports-unit-select {
  width: 60px;
}

.extraction-ports-auto-setup .signal-port-edit-item>label {
  width: 180px;
}

.signal-port-edit-item>.aurora-select,
.signal-port-edit-item>.aurora-input {
  width: calc(100% - 180px);
  font-size: 13px;
}

.signal-port-edit-item>.aurora-select,
.signal-port-edit-item>ant-select-selector {
  min-height: 28px;
}

.extraction-ports-reference-main .extraction-ports-auto-checkbox-main .aurora-input {
  width: calc(100% - 180px);
}

.extraction-ports-auto-checkbox-main .extraction-ports-sub-checkbox {
  width: 180px;
}

.extraction-ports-auto-checkbox-1 {
  margin-bottom: 6px !important;
}

.signal-port-edit-item-checkbox {
  display: block;
}

.extraction-ports-auto-setup .signal-port-edit-item>.signal-port-edit-item-apply-label {
  width: 240px;
}

.extraction-ports-auto-setup .signal-port-edit-item>.signal-port-edit-item-switch {
  line-height: 24px;
}

.extraction-ports-auto-setup .extraction-ports-copy-content {
  display: inline-block;
  margin-left: 10px;
}

.extraction-ports-copy-content .extraction-port-content-body {
  font-size: 14px;
  vertical-align: middle;
  display: inline-block;
  padding-right: 10px;
  width: 240px;
  color: rgba(0, 0, 0, 0.65)
}

.aurora-select-nets-icon {
  margin-left: 10px;
  font-size: 16px;
  color: #40a9ff;
  cursor: pointer;
  line-height: 20px;
  vertical-align: middle;
}

.extraction-ports-reference-main .aurora-model-name-error-msg,
.extraction-ports-reference-main .aurora-model-name-warning-msg {
  margin-top: 6px;
}


.port-setup-edit-reference-icon {
  margin-left: 8px;
  color: #188fff;
  cursor: pointer;
}

/* edit reference pin panel */
.port-setup-reference-edit-main {
  padding: 10px 16px;
  height: max-content;
  min-width: 800px;
  display: flex;
  min-height: 100%;
}

.port-setup-reference-edit-table {
  width: 50%;
  padding-right: 10px;
}

.port-setup-reference-edit-canvas {
  width: 50%;
  position: relative;
}

.port-setup-reference-edit-main .aurora-extraction-tabs.ant-tabs-card .ant-tabs-top-content {
  border: none;
}

.ports-setup-edit-reference-dropdown {
  z-index: 999999;
}

.port-setup-reference-edit-canvas .port-setup-canvas-pin-name {
  left: 0px;
  width: 100%;
}

.port-setup-select-pin-main {
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.port-setup-select-pin-ul {
  max-height: 200px;
  width: 100%;
  overflow: auto;
  padding-left: 10px;
  margin-bottom: 2px !important;
}

.port-setup-select-pin-net-li {
  line-height: 28px;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
  padding-left: 4px;
}

.port-setup-select-pin-li {
  line-height: 28px;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
  padding-left: 4px;
  margin-left: 14px;
}

.port-setup-select-pin-li:hover {
  background-color: rgb(223, 243, 255);
}

.port-setup-select-pin-selected {
  background-color: rgb(176, 226, 255);
  font-weight: 600;
}

.port-setup-select-pin-selected:hover {
  background-color: rgb(176, 226, 255);
}

.port-setup-select-pin-icon {
  margin-right: 6px;
  font-size: 13px;
  color: rgb(0, 0, 0, 0.65);
}

.port-setup-editing-port-row {
  background-color: #adddfd;
}

.port-setup-edit-port-index {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.aurora-table-body>.port-setup-editing-port-row:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background-color: #adddfd;
}

.port-setup-edit-port-pin {
  cursor: pointer;
}

.port-setup-edit-eye-icon {
  margin-left: 10px;
  font-size: 16px;
  color: #666;
  cursor: pointer;
  vertical-align: middle;
}

.port-setup-reference-edit-canvas .ant-spin-nested-loading,
.port-setup-reference-edit-canvas .ant-spin-container {
  height: 100%;
  width: 100%;
}

.port-setup-help-icon {
  margin: -3px 0px 0px 10px;
  font-size: 18px;
  vertical-align: middle;
  color: #188fff;
  cursor: pointer;
}

.port-setup-help-icon:hover {
  color: #40a9ff;
}

.aurora-extraction-ports-ball-material-input {
  cursor: pointer;
}

.aurora-extraction-ports-ball-material-main {
  position: relative;
}

.aurora-extraction-ports-question-circle-icon {
  position: absolute;
  right: 5px;
  top: 50%;
  margin-top: -8px;
  color: #f13140;
  font-size: 14px;
}

.user-default-setting-ports-content .aurora-model-name-error-msg {
  margin-top: 6px;
}

.extraction-ports-input-content .aurora-select.ant-select-multiple .ant-select-selection-item {
  height: 20px;
  margin-top: -2px;
  line-height: 18px;
}

.extraction-ports-auto-select{
  height: 28px;
}