.netlist-model-selection {
  width: 100%;
}

.netlist-model-row .ant-select-selector,
.netlist-model-row .netlist-model-selection,
.netlist-model-row .netlist-model-selection-item,
.netlist-model-row .ant-select-selection {
  height: 24px;
  line-height: 22px;
  font-weight: 500;
  font-size: 13px;
}

.netlist-model-dropdown {
  z-index: 10000000;
}

.netlist-model-item-select {
  width: 50%;
}

.netlist-model-content .ant-input {
  height: 24px;
}

.netlist-model-row {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  font-weight: 500;
}

.netlist-model-row>*:first-child {
  width: 100px;
  height: 24px;
  line-height: 24px;
}

.netlist-model-row>*:last-child {
  width: calc(100% - 100px);
}

.netlist-model-table {
  padding: 10px 10px 0 10px;
}

.netlist-model-simall-row {
  display: inline-block;
  width: 50%;
}

.netlist-model-table-rows {
  margin-top: 10px;
}

.netlist-model-table-rows th,
.netlist-model-table-rows td {
  padding: 0 8px !important;
  font-size: 13px;
  font-weight: 500;
  text-align: center !important;
  height: 33px;
  line-height: 33px;
  color: rgba(0, 0, 0, 0.65) !important;
}

.netlist-model-table-rows .editable-cell-value-wrap {
  height: 24px;
  line-height: 23px;
}

.spice-pin-node-pairs-item-center .spice-total-pin-box {
  height: 40px;
}

.spice-pin-model-TX-main,
.spice-pin-model-RX-main {
  width: 50px;
  height: 80px;
  position: relative;
}

.spice-pin-model-Channel-main {
  width: 70px;
  height: 80px;
  position: relative;
}

.spice-pin-model-TX-content,
.spice-pin-model-RX-content {
  width: 0;
  height: 0;
  border-left: 48px solid skyblue;
  border-top: 35px solid transparent;
  border-bottom: 35px solid transparent;
  position: relative;
  margin-top: 5px;
}

.spice-pin-model-TX-title,
.spice-pin-model-RX-title {
  width: fit-content;
  height: 80px;
  line-height: 80px;
  position: absolute;
  left: 2px;
  top: 0px;
}

.spice-pin-model-TX-title>span,
.spice-pin-model-RX-title>span {
  white-space: nowrap;
  font-size: 12px;
}

.spice-pin-model-Channel-content {
  width: 70px;
  height: 80px;
  background-color: skyblue;
  text-align: center;
  line-height: 80px;
}

.spice-pin-model-Channel-title {
  width: 70px;
  height: 70px;
  text-align: center;
  line-height: 80px;
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 12px;
}

.spice-pin-node-pairs-item-center .spice-box {
  width: 100px;
  min-width: 100px;
}

.spice-pin-node-pairs-item-center .spice-pin-select {
  min-width: 60px;
  width: 70px;
  max-width: 100px;
}

.spice-pin-model-tx-rx-main {
  margin-left: -32px;
}

.spice-pin-model-left-main,
.spice-pin-model-right-main {
  display: flex;
  height: 80px;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 19px;
  justify-content: space-between;
}

.spice-pin-model-left-main .spice-pin-line,
.spice-pin-model-right-main .spice-pin-line {
  width: 40px;
}

.spice-pin-model-line-TX-main>.spice-pin-line,
.spice-pin-model-line-RX-main>.spice-pin-line {
  width: 130px;
}

.spice-pin-node-pairs-item-center {
  margin: 0 auto;
}

.spice-pin-node-pairs-item-center .spice-pin-list-li-left,
.spice-pin-node-pairs-item-center .spice-pin-list-li-right {
  width: min-content;
  display: flex;
  flex-direction: column;
  height: 80px;
}

.spice-pin-node-pairs-item-center .spice-pin-connect-model-content {
  width: fit-content;
  min-width: 200px;
  background-color: #eeeeee;
  height: 100%;
  text-align: center;
  padding: 0px 8px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.spice-pin-node-pairs-item-center .spice-pin-connect-model-content .spice-box-signal-text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  text-align: center;
  width: 100%;
  max-width: 100%;
}

.spice-pin-node-pairs-item-center .spice-pin-connect-model-content .spice-box-title {
  height: 80px;
  text-align: center;
  line-height: 80px;
  width: 48px;
}

.spice-pin-node-pairs-item-center .spice-pin-connect-model-content .spice-pin-list-li-left .spice-pin-die-box,
.spice-pin-node-pairs-item-center .spice-pin-connect-model-content .spice-pin-list-li-right .spice-pin-die-box {
  position: absolute;
  width: 128px;
  text-align: center;
  padding: 0px 4px;
  margin: 0px 0px;
  margin-bottom: 18px;
}

.spice-pin-node-pairs-item-center .spice-pin-connect-model-content .spice-pin-list-li-left .spice-pin-die-box {
  left: -128px;
}

.spice-pin-node-pairs-item-center .spice-pin-connect-model-content .spice-pin-list-li-right .spice-pin-die-box {
  right: -128px;
}

.spice-pin-node-pairs-item-center .spice-pin-connect-model-content>.spice-pin-list-li-left .spice-pin-die-bottom-box,
.spice-pin-node-pairs-item-center .spice-pin-connect-model-content>.spice-pin-list-li-right .spice-pin-die-bottom-box {
  margin-top: 18px;
  margin-bottom: 0px;
}

.netlist-node-current {
  position: absolute;
  right: 10px;
  top: 6px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid #687A8F;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}