.copy-setup-panel-content {
  padding: 10px 12px;
}

.copy-setup-panel-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.copy-setup-panel-footer>span {
  width: 114px;
  line-height: 28px;
}

.copy-setup-panel-footer>.aurora-select {
  width: calc(100% - 114px);
  height: fit-content;
  min-height: 28px;
}

.copy-setup-panel-select-dropdown-menu {
  z-index: 10000000000;
}