.cascade-tree-list-container {
  position: relative;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
  box-shadow: 0 2px 5px -2px rgba(0, 0, 0, .05),
    0 4px 10px 0 rgba(0, 0, 0, .08),
    0 6px 20px 4px rgba(0, 0, 0, .05);
}

.cascade-tree-list-container .tree-list-phantom {
  width: 100%;
  background: #fff;
}

.cascade-tree-list-container .tree-list-view {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding-top: 6px;
}

.cascade-tree-list-container .tree-list-view .tree-list-content {
  padding: 0 6px 6px 6px;
}

.cascade-tree-list-container.tree-list-view .tree-list-content .ant-checkbox-wrapper {
  padding-left: 4px;
}

.cascade-tree-list-container .tree-list-view .tree-list-content .tree-list-content-net {
  padding-left: 6px;
  cursor: pointer;
}

.cascade-tree-list-container .tree-list-view .tree-list-dropdown {
  margin: 0;
}

.cascade-tree-list-container .tree-list-view .tree-list-dropdown .tree-list-content {
  padding-top: 0;
}