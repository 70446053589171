/* === Report panel === */
.andes-v2-report-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.andes-v2-report-panel .panel-modal-header {
  padding-left: 14px !important;
}

.andes-v2-report-panel .report-panel-title {
  font-size: 16px;
  font-weight: 500;
}

.andes-v2-report-panel .report-content {
  padding: 10px 16px;
  border-radius: 6px 6px 0px 0px;
  height: 100%;
  min-width: 500px;
}

.andes-v2-report-panel .report-content .report-message-main {
  margin-top: 10px;
  position: relative;
  min-height: 27px;
}

.andes-v2-report-panel .report-content .report-item-title {
  font-weight: 500;
  line-height: 28px;
  width: 84px;
  display: inline-block;
  font-size: 13px;
  position: relative;
}

.andes-v2-report-panel .report-content .report-select {
  width: calc(100% - 84px);
  font-size: 13px;
  min-height: 28px;
  height: auto;
  margin: 6px 0px;
}

.andes-v2-report-select-dropdown {
  z-index: 10000000;
  max-height: 500px;
}

.andes-v2-report-select-dropdown .ant-select-dropdown-menu-item {
  padding: 4px 12px;
  font-size: 13px;
}

.andes-v2-report-panel .report-content .report-download-message {
  line-height: 28px;
  text-indent: 10px;
  border-radius: 4px;
  font-size: 14px;
  max-width: calc(100% - 90px);
  padding: 0px 8px;
}

.andes-v2-report-panel .report-content .report-download-button {
  position: absolute;
  right: 0px;
  bottom: 2px;
}

.andes-v2-report-panel .report-content .report-download-btn {
  width: 80px;
  height: 26px;
  padding: 0px;
  font-size: 13px;
  line-height: 24px;
}

.andes-v2-report-panel .report-content .report-progress {
  right: 10px;
  padding: 2px 0px;
  position: absolute;
  width: auto;
  left: 0px;
}

.andes-v2-report-panel .report-content .report-progress .ant-progress-inner {
  background-color: #c7e0f4;
}

.andes-v2-report-panel .report-content .report-progress .ant-progress-text {
  vertical-align: unset;
}

.andes-v2-report-panel .report-content .report-progress .ant-progress-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  -webkit-animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  content: '';
}

.andes-v2-report-panel .report-content .download-error {
  border: 1px solid #ffa39e;
  background-color: #fff1f0;
}

.andes-v2-report-panel .report-content .download-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}

.report-bottom-severed {
  height: 5px;
}

.andes-v2-report-input-box {
  font-size: 13px;
  margin: 10px 0px;
}

.andes-v2-report-input-box .ant-input-sm,
.andes-v2-report-input-box .ant-input-group-addon,
.andes-v2-report-collapse .ant-input-sm,
.andes-v2-report-collapse .ant-input-group-addon {
  font-size: 13px;
}

.andes-v2-report-label {
  display: inline-block;
  width: 100px;
  line-height: 24px;
}

.andes-v2-report-input {
  width: calc(100% - 100px);
}

.andes-v2-report-frequency-input {
  width: 310px;
}

.andes-v2-report-frequency {
  display: flex;
  align-items: center;
  padding: 5px 0px;
}

.andes-v2-report-frequency-title {
  margin-right: 5px;
  width: 84px;
}

.andes-v2-report-divider {
  margin: 8px 0px;
}

.andes-v2-report-collapse {
  border: none;
  background-color: #f5f5f5;
  font-size: 13px;
  margin-top: 10px;
}

.andes-v2-report-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 0px;
  width: fit-content;
  /* color: #696969; */
}

.andes-v2-report-collapse>.ant-collapse-item {
  border-bottom: none;
}

.andes-v2-report-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  left: 70px;
}

.andes-v2-report-collapse .ant-collapse-content {
  border-top: none;
}

.andes-v2-report-collapse .ant-collapse-content-box {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 0px 16px;
}

.andes-v2-report-frequency-title {
  display: inline-block;
  width: 54px;
}

.andes-v2-report-frequency-title-fMax,
.andes-v2-report-frequency-title-fLPMax {
  margin-left: 40px;
}

.andes-v2-report-frequency-title-prefix {
  font-size: 14px;
}

.andes-v2-report-frequency-title-subscript {
  font-size: 10px;
  font-weight: 400;
}

.andes-v2-report-select-box {
  display: flex;
  font-size: 13px;
}

.andes-v2-report-flex-item {
  flex: 1;
}

.andes-v2-report-flex-item-select {
  width: calc(100% - 100px);
}

.andes-v2-report-frequency-flex-item-input {
  width: calc(100% - 64px);
}