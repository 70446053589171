.pkg-model-select-panel {
  top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.pkg-model-select-panel .panel-body {
  overflow: auto;
}

.pkg-model-select-main {
  padding: 8px 14px;
  min-height: 200px;
  min-width: 720px;
  overflow: auto;
  font-size: 13px;
}

.pkg-model-select-main .pkg-model-select-item {
  height: 28px;
  line-height: 28px;
  display: flex;
  margin: 6px 0px;
  position: relative;
}

.pkg-model-select-main .pkg-model-select-content .pkg-model-select-item {
  min-height: 28px;
  line-height: 28px;
  height: fit-content;
}

.pkg-model-select-main .pkg-model-select-item>span {
  width: 134px;
}

.pkg-model-select-main .pkg-model-select-item>.pkg-model-select {
  width: calc(100% - 134px);
  height: fit-content;
  min-height: 28px;
}

.pkg-model-select-main .pkg-model-select-item>.pkg-model-select.ant-select-multiple.ant-select-sm .ant-select-selection-item {
  height: 20px;
  line-height: 18px;
}

.pkg-model-select-main .pkg-model-select-item>.pkg-model-select>.ant-select-selector {
  height: auto;
}

.pkg-model-select-main .pkg-model-select-ports-main {
  display: block;
  min-height: 100px;
  border-bottom: 1px solid #eee;
  background-color: #eee;
  padding: 5px 10px;
  position: relative;
  font-size: 12px;
  margin: auto;
  width: 100%;
}

.pkg-model-select-main .pkg-model-select-ports-main-fit-content {
  width: fit-content;
}

.pkg-model-select-main .pkg-model-port-signal-main,
.pkg-model-select-main .pkg-model-port-copy-signal-main {
  width: 160px;
  min-height: 90px;
  text-align: center;
  color: #005a9e;
  background: #d5d5d5;
  position: relative;
  z-index: 1;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pkg-model-select-main .pkg-model-port-signal-main-cphy {
  min-height: 120px;
}

.pkg-model-select-main .pkg-model-port-signal,
.pkg-model-select-main .pkg-model-port-net {
  width: 160px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 30px;
  text-align: center;
  line-height: 30px;
}

.pkg-model-select-main .pkg-model-port-signal {
  color: #575757;
}

.pkg-model-select-main .pkg-model-port-copy-signal .aurora-select {
  width: calc(100% - 10px);
  margin: 0px 5px;
}

.pkg-model-select-main .pkg-model-port-pins,
.pkg-model-select-main .pkg-model-port-copy-pins {
  min-height: 90px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.pkg-model-select-main .pkg-model-port-pin {
  display: flex;
  justify-content: space-between;
  height: 30px;
  line-height: 30px;
}

.pkg-model-select-main .pkg-model-port-pin-title {
  width: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 28px;
  text-align: center;
  line-height: 28px;
  color: #005a9e;
  display: inline-block;
  background: #eee;
  z-index: 1;
  position: relative;
  vertical-align: middle;
}

.pkg-model-select-main .pkg-model-pin-input-div {
  flex: 0 0 auto;
  text-align: center;
  max-width: 100px;
  width: 90px;
  min-width: 50px;
  display: inline-block;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  position: relative;
  /*  vertical-align: top; */
  background: #eee;
  z-index: 1;
}

.pkg-model-select-main .connection-pin-input-pin-port .connection-pin-input-pin-port-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  z-index: 1;
}

.pkg-model-select-main .pkg-model-pin-input {
  flex: 0 0 auto;
  text-align: center;
  max-width: 100px;
  width: 90px;
  min-width: 50px;
  display: inline-block;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  position: relative;
  vertical-align: top;
  background: #ffffff;
}

.pkg-model-select-main .pkg-model-select-pins-content {
  padding: 6px 10px;
  background-color: #eee;
  border-radius: 4px;
}

/* port popover */

.pkg-model-ports-content {
  padding: 12px 12px;
  width: 100%;
  position: relative;
  padding-top: 2px;
  max-width: 600px;
}

.pkg-model-ports-content-header {
  text-align: center;
  line-height: 28px;
  font-weight: 500;
}

.pkg-model-ports-content-close-icon {
  color: rgba(0, 0, 0, 0.45);
}

.pkg-model-ports-content-close {
  position: absolute;
  right: 8px;
  top: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  line-height: 24px;
  transition: 0.3s;
}

.pkg-model-ports-content-close:hover {
  background: #ececec;
  transition: 0.3s;
}

.pkg-model-port-list-body-with-search {
  height: 100%;
}

.pkg-model-port-list-body-search-wrapper {
  width: 100%;
}

.pkg-model-port-list-body-search-wrapper .ant-input-affix-wrapper,
.pkg-model-port-list-body-search-wrapper .ant-input-affix-wrapper .ant-input {
  height: 28px;
  line-height: 28px;
  font-size: 13px;
}

.pkg-model-port-list-body-search-wrapper .ant-input-affix-wrapper.pkg-model-port-list-body-with-search-input .ant-input {
  height: 18px;
}

.pkg-model-port-list-ul {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: auto;
  list-style: none;
  min-width: 20px;
  max-height: 230px;
  border: 1px solid #d9d9d9;
  border-top: none;
  border-radius: 0px 0px 4px 4px;
}

.pkg-model-port-list-ul>li {
  height: 24px;
  line-height: 24px;
  padding: 0px 10px;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
}

.pkg-model-port-list-ul>li:hover {
  background-color: #cfedff;
}

.pkg-model-port-list-ul>.pkg-current-pin-selected-port-li:hover {
  background-color: #93e6ff;
}

.pkg-model-port-list-ul>.pkg-port-li-selected,
.pkg-model-port-list-ul>.pkg-port-li-selected:hover {
  background-color: #e4e4e4;
  cursor: not-allowed;
}

.pkg-model-port-list-ul>.pkg-current-pin-selected-port-li {
  background-color: #93e6ff;
}

.pkg-model-pin-node-delete-icon {
  position: absolute;
  right: 2px;
  top: 8px;
  display: none;
  color: rgba(0, 0, 0, 0.25);
  z-index: 2;
}

.pkg-model-pin-node-delete-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}

.pkg-model-pin-input-div:hover .pkg-model-pin-node-delete-icon {
  display: inline-block;
}

.pkg-model-select-copy-group {
  border: 1px solid #aaa;
  border-radius: 4px;
  padding: 6px;
  margin: 6px 0px;
  position: relative;
}

.pkg-model-select-copy-icon {
  color: #3da4fa;
  margin-top: 7px;
  cursor: pointer;
  margin-left: 10px;
}

.pkg-model-select-item>.connector-model-select-span {
  width: 196px;
}

.pkg-model-select-delete-group-icon {
  position: absolute;
  right: 0px;
  top: calc(50% - 25px);
  height: 50px;
  width: 16px;
  line-height: 50px;
  background: #b6d9f6;
  color: #3da4fa;
  cursor: pointer;
  display: none;
  transition: 0.3s;
}

.pkg-model-select-copy-group:hover .pkg-model-select-delete-group-icon {
  display: block;
}

.pkg-model-port-pin .pkg-pin-long-line {
  right: 2px;
}

.pkg-selected-apply-signals {
  color: rgb(109, 109, 109);
  background-color: #ececec;
}

.pkg-model-pin-type-span {
  font-size: 16px;
  height: 28px;
  text-align: center;
  display: inline-block;
  line-height: 28px;
  color: #005a9e;
  background: #eee;
  z-index: 1;
  position: relative;
  vertical-align: middle;
}

.pkg-model-select-main .pkg-model-port-pin-content {
  width: 90px;
  height: 30px;
  background: #eee;
  position: relative;
  z-index: 1;
  padding-left: 4px;
  line-height: 30px;
}

.pkg-model-select-delete-icon {
  position: absolute;
  right: 0px;
  top: 8px;
  font-size: 12px;
}

.pkg-model-select-item:hover .pkg-model-select-delete-icon {
  color: #0d87f7;
}

.pkg-model-select-main .pkg-model-select-item>.pkg-model-select-delete-icon {
  width: 14px;
}

.pkg-model-select-main .multi-pkg-model-file-content>.pkg-model-select {
  width: calc(100% - 156px);
}

.pkg-select-dropdown-menu {
  z-index: 10000000000;
  max-height: 300px !important;
  overflow: auto;
}

.pkg-model-pin-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pkg-model-pin-item {
  display: flex;
  justify-content: space-between;
  height: 30px;
  width: 230px;
}

.pkg-model-long-line-content {
  position: absolute;
  left: 10px;
  right: 10px;
  top: 5px;
  bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pkg-model-long-line-content .pkg-pin-long-line-item {
  width: 100%;
  height: 30px;
}

.pkg-model-long-line-content .pkg-pin-long-line {
  width: 100%;
  height: 5px;
  background-color: #a6a6a6;
  margin-top: 14.5px;
  transform: scaleY(0.2);
}

.pkg-model-port-pins-title {
  height: 32px;
  display: flex;
  justify-content: space-around;
  padding: 0px 10px;
}

.pkg-model-select-main .pkg-model-port-pins.pkg-model-port-pins-title {
  min-height: 32px;
  width: fit-content;
  margin: auto;
}

.pkg-model-port-pins-title>.pkg-model-port-pins-div {
  color: #005a9e;
  line-height: 32px;
  height: 32px;
  text-align: center;
  width: 230px;
}

.pkg-model-port-pins-title>.pkg-model-port-pins-div:nth-of-type(2) {
  width: 160px;
}

.pkg-model-port-pins-title>.pkg-model-port-pins-net {
  width: 230px
}

.pkg-model-port-pins-title>.pkg-die-model-port-pins-net {
  width: 0px;
}

.pkg-model-select-panel .pkg-model-select-ports-main .pkg-model-port-pins .single-pin-port-content {
  justify-content: center !important;
}

.pkg-model-select-main .pkg-multi-model-port-pins .pkg-model-port-pin-content {
  background: unset;
}

.pkg-model-select-main .pkg-multi-model-port-pins .pkg-model-port-left-pin-content {
  width: 90px;
  height: 30px;
  background: unset;
  position: relative;
  z-index: 1;
  /* padding-left: 4px; */
  line-height: 30px;
}

.pkg-multi-model-port-pins .pkg-model-port-left-pin-content .pkg-model-port-left-pin-title {
  width: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 28px;
  text-align: center;
  line-height: 28px;
  color: #005a9e;
  display: inline-block;
  background: #eee;
  z-index: 1;
  position: relative;
  vertical-align: middle;
}

.pkg-multi-model-port-pins .pkg-model-pin-item {
  width: 200px;
}

.pkg-multi-model-port-pins.pkg-model-port-pins .pkg-model-pin-list {
  width: 305px;
}

.pkg-multi-model-port-pins.pkg-model-port-pins .pkg-model-pin-list:last-child {
  width: 230px;
}

.pkg-multi-model-port-pins-div {
  color: #005a9e;
  line-height: 32px;
  height: 32px;
  text-align: center;
}

.pkg-model-select-content .icon-xinpian_chip {
  cursor: pointer;
}

.pkg-model-select-content .pkg-multi-model-port-pins-div-model-type,
.pkg-model-select-content .pkg-model-port-pins-div-model-type {
  display: inline-block;
  padding-right: 10px;
}

.pkg-multi-model-port-pins-content {
  height: 32px;
  padding-left: 84px;
}

.pkg-model-select-content .pkg-model-port-pins-title .icon-xinpian_chip {
  color: #005a9e;
}

.pkg-model-select-main .pkg-model-select-subckt-item.pkg-model-select-item>.pkg-model-select {
  width: 40%;
}

.pkg-model-select-main .pkg-model-select-subckt-item.pkg-model-select-item.pkg-multi-model-select>.pkg-model-select {
  width: calc(40% - 12px);
}

.pkg-model-select-main .pkg-model-select-subckt-item.pkg-model-select-item>.pkg-model-select:nth-of-type(2) {
  margin-left: 2%;
}

.pkg-model-select-content .pkg-model-select-pins-content .pkg-model-port-pins.pkg-model-port-pins-title {
  height: 16px;
}

.pkg-model-select-main .pkg-model-select-ports-main.pkg-model-select-ports-item-flex {
  display: flex;
  align-items: center;
}

.pkg-model-select-rank-main .pkg-model-left-pin-list .pkg-model-pin-item .spice-pin-line-box {
  display: flex;
  align-items: center;
  height: 30px;
}

.pkg-model-select-rank-main .pkg-model-left-pin-list .pkg-model-pin-item {
  background-color: #eee;
  z-index: 1000;
}

.pkg-model-select-item>.model-title {
  font-weight: 500;
  font-size: 14px;
}

.pkg-die-model-pin-item {
  position: relative;
  justify-content: flex-end;
  width: 100px;
}

.pkg-die-model-pin-item .ant-input-affix-wrapper {
  width: 90px;
  border-radius: 6px;
}