.result-reference-header {
  font-weight: 500;
  height: 22px;
}

.result-reference-icon {
  font-size: 16px;
  margin-left: 12px;
  transform: translateY(10%);
}

.result-reference-title {
  margin-right: 5px;
}

.result-reference-panel {
  padding: 0px 14px 14px;
}

.result-reference-table {
  margin-top: 10px;
}

.result-reference-input-box {
  padding: 10px 0px 10px 10px;
}

.result-reference-select-box {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.result-reference-flex-item {
  flex: 1;
}

.result-reference-label {
  display: inline-block;
  width: 100px;
}

.result-reference-flex-item-select {
  width: calc(100% - 100px);
}

.result-reference-frequency-flex-item-input {
  width: calc(100% - 84px);
}

.result-reference-frequency-flex-item-input .ant-input-group-addon {
  font-size: 13px;
}

.result-reference-divider {
  margin-top: 10px;
  margin-bottom: 0px;
}

.result-reference-collapse {
  border: none;
  background-color: #fff;
  margin-left: 10px;
}

.result-reference-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 6px 10px 6px 0px;
  width: fit-content;
  color: #696969;
}

.result-reference-collapse>.ant-collapse-item {
  border-bottom: none;
}

.result-reference-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  left: 70px;
}

.result-reference-collapse .ant-collapse-content {
  border-top: none;
}

.result-reference-collapse .ant-collapse-content-box {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 5px 16px;
}

.result-reference-frequency-title {
  display: inline-block;
  width: 84px;
}

.result-reference-frequency-title-prefix {
  font-size: 14px;
}

.result-reference-frequency-title-subscript {
  font-size: 10px;
  font-weight: 400;
}

.result-reference-info-circle {
  margin-left: 5px;
  color: #1890ff;
}