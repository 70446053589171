.cascade-decap-row {
  margin-top: 16px;
}

.cascade-decap-setup-border {
  border: 1px solid #a8c4e6;
  padding: 10px;
  border-radius: 3px;
}

.cascade-decap-main {
  height: 100%;
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
}

.cascade-decap-content {
  padding-left: 20px;
  padding-right: 20px;
  min-width: 579px;
}

.cascade-decap-icon {
  color: rgba(0, 0, 0, 0.65);
  font-size: 13px;
  margin-left: 14px;
  margin-right: 10px;
  cursor: pointer;
  vertical-align: middle;
}

.cascade-decap-eye-icon {
  margin-left: 10px;
  margin-right: 14px;
  color: #40a9ff;
  font-size: 17px;
  vertical-align: middle;
  cursor: pointer;
}

.cascade-decap-content .cascade-decap-eye-icon-disable {
  color: rgba(0, 0, 0, 0.65);
  cursor: not-allowed;
}