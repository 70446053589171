.sierra-sweeping-add-icon,
.sierra-sweeping-delete-icon {
  vertical-align: middle;
}

.sierra-sweeping-table .ant-table-thead>tr>th,
.sierra-sweeping-table .aurora-table-body>tr>td {
  text-align: center;
}

.sierra-sweeping-select-dropdown-menu {
  text-align: left;
}

/* columns */

.sweeping-columns-checkbox {
  padding-left: 6px !important;
}

.sierra-sweeping-pin-panel {
  margin-top: 126px;
  margin-left: -440px;
  box-shadow: rgb(76 77 78 / 14%) 0px -2px 7px 8px, rgb(0 0 0 / 10%) 0px 0px 10px 3px;
}

.sierra-sweeping-pin-panel .sierra-sweeping-pin-table {
  margin: 10px;
}

.sierra-sweeping-table-title {
  position: relative;
}

.sierra-sweeping-table-title .sierra-sweeping-title-value {
  cursor: pointer;
}

.sierra-sweeping-table-title .sierra-sweeping-title-value i {
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -2px;
  color: #1890ff;
  font-size: 12px;
}

.sierra-sweep-close-icon {
  visibility: hidden;
  position: absolute;
  margin-top: 5px !important;
  font-size: 14px !important;
  margin-left: 5px !important;
}

.sierra-sweeping-table .ant-table-thead>tr>th:hover .sierra-sweeping-table-title .sierra-sweep-close-icon {
  visibility: visible;
}


.sierra-sweeping-display-comp {
  position: absolute;
  width: 140px;
  background-color: #fff;
  margin-left: 20px;
  border-radius: 0 0 2px 2px;
  z-index: 999;
  overflow: auto;
  box-shadow: 1px 4px 6px rgb(0 0 0 / 31%);
}

.sierra-sweeping-display-comp ul {
  margin: 0;
  padding: 0;
}

.sierra-sweeping-display-comp ul li {
  width: 100%;
  text-align: left;
  padding: 4px 23px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sierra-sweeping-column-name {
  line-height: 24px;
  vertical-align: middle;
  position: relative;
}

.sierra-sweeping-column-name-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px 16px;
}

.sierra-sweeping-column-name .anticon {
  color: #1890ff;
  position: absolute;
  visibility: hidden;
  top: 0px;
  right: 5px;
}

.sierra-sweeping-table .aurora-table-body tr:hover .sierra-sweeping-column-name .anticon {
  visibility: visible;
}

.sweeping-model-not-exsit td,
.sweeping-table-td-not-exsit {
  background-color: #fafafa;
  cursor: not-allowed;
}

.sweeping-table-td-not-used {
  background-color: #fafafa;
}

.sweeping-table-td-not-used .editable-cell-value-wrap {
  border: 1px solid #fafafa;
}

.sweeping-table-td-font-color {
  color: #1890ff;
}

.sweeping-model-exsit {
  background-color: #ffffff !important
}

.sierra-sweeping-table .ant-table-thead>tr>th {
  position: relative;
}

.sierra-sweep-table-add-icon {
  position: absolute;
  line-height: unset;
  margin-top: -4px;
  right: 10px;
  font-size: 16px !important;
  z-index: 20;
}

.sierra-sweeping-table .ant-table .aurora-table-body .ant-table-tbody tr .sweeping-table-td-padding {
  padding-left: 14px;
}

.sierra-sweeping-table .ant-table.ant-table.ant-table-bordered .aurora-table-body .ant-table-tbody>tr>td:last-child {
  text-align: center;
}