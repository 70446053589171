.tab-footer {
  width: 100%;
  background: #e0e0e0;
  position: relative;
}

.tab-sider .tab-menu {
  position: relative;
  z-index: 20;
  line-height: 42px;
  font-weight: bold;
  box-sizing: border-box;
  background: #e0e0e0;
  color: #696969;
  border: none;
  transition: 0.3s;
  min-height: 200px;
}

.tab-menu .ant-menu-item-selected {
  width: 100%;
}

.tab-menu .ant-menu-item,
.tab-menu .ant-menu-submenu {
  border-radius: 8px 0 0 8px;
  border-bottom: none;
}

.tab-menu .ant-menu-item-active,
.tab-menu .ant-menu-item:hover,
.tab-sub-menu:hover,
.tab-menu .ant-menu-submenu-active,
.tab-menu .ant-menu-submenu:hover {
  border: none;
  background: #f0f0f0;
  color: rgba(0, 0, 0, 0.65);
}

.tab-right-close {
  float: right;
  line-height: 45px;
  height: 44px;
  font-size: 19px;
  color: #1890ff;
  position: relative;
  z-index: 20;
  cursor: pointer;
  box-sizing: border-box;
  width: 34px;
}

.tab-button {
  float: right;
  margin-right: 14px;
}

.tab-header {
  height: 44px;
  /*  padding-top: 8px; */
}

.tab-sider .ant-menu-vertical .ant-menu-item-selected,
.tab-sider .ant-menu-vertical .ant-menu-submenu-selected {
  border-right: none;
  background: #fff;
  color: rgba(0, 0, 0, 0.65);
}

.tab-sider .ant-menu-vertical .ant-menu-submenu-title:active {
  background: #fff;
  color: rgba(0, 0, 0, 0.65);
}

.tab-sider .ant-menu-vertical .ant-menu-item-selected::after,
.tab-sider .ant-menu-vertical .ant-menu-submenu-selected::after {
  display: none;
}

.tab-sider {
  width: 88px;
  position: absolute;
  left: 2px;
  bottom: 14px;
  top: 40px;
  background: #e0e0e0;
  margin-left: 14px;
  overflow: hidden;
}

.tab-sider .ant-menu-vertical .ant-menu-item {
  padding: 0 8px;
}

.tab-subMenu-children>.ant-menu-sub {
  min-width: 88px;
}

.tab-menu .ant-menu-submenu-active,
.tab-menu .ant-menu-submenu-open {
  background: #f0f0f0;
}

.tab-subMenu-children .ant-menu-item:active,
.tab-subMenu-children .ant-menu-item:hover,
.tab-subMenu-children .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.tab-subMenu-children .ant-menu-submenu-title:active {
  background: #f0f0f0;
  color: rgba(0, 0, 0, 0.65);
}

.tab-subMenu-title {
  line-height: 35px;
  height: 40px;
  display: block;
}

.tab-sider .ant-menu-vertical .ant-menu-item:not(:last-child),
.tab-sider .ant-menu-vertical-left .ant-menu-item:not(:last-child),
.tab-sider .ant-menu-vertical-right .ant-menu-item:not(:last-child),
.tab-sider .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}

.tab-sub-menu {
  border-radius: 8px 0 0 8px;
}

.tab-sub-menu .ant-menu-submenu-title,
.tab-sub-menu:hover .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.65);
  border-radius: 8px 0 0 8px;
}

.tab-sub-menu .ant-menu-item:active,
.tab-sub-menu .ant-menu-submenu-title:active {
  background: #f0f0f0;
  border-radius: 8px 0 0 8px;
}

.tab-sub-menu .ant-menu-item:hover,
.tab-sub-menu .ant-menu-item-active,
.tab-sub-menu .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.tab-sub-menu .ant-menu-submenu-active,
.tab-sub-menu .ant-menu-submenu-title:hover {
  background: #f0f0f0;
  color: rgba(0, 0, 0, 0.65);
}

.tab-sider .ant-menu-submenu-vertical>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.tab-sider .ant-menu-submenu-vertical-left>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.tab-sider .ant-menu-submenu-vertical-right>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.tab-sider .ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none;
}

.tab-menu .ant-menu-submenu-active,
.tab-menu .ant-menu-submenu-title:hover {
  color: rgba(0, 0, 0, 0.65);
}

.tab-menu .tab-sub-menu .ant-menu-submenu-title:hover {
  background-color: #f0f0f0;
}