.my-pdn-layout {
  position: absolute;
  top: 54px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.aurora-content {
  background-color: #ffffff;
  position: absolute;
  top: 0px;
  left: 240px;
  right: 0px;
  bottom: 0px;
}

.my-pdn-content {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
}

.my-pdn-content-bottom {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
}

.my-pdn-content-bottom>.ant-spin-nested-loading,
.my-pdn-content-bottom>.ant-spin-nested-loading>.ant-spin-container {
  width: 100%;
  height: 100%;
}

.my-pdn-content>.ant-spin-nested-loading,
.my-pdn-content>.ant-spin-nested-loading>.ant-spin-container {
  width: 100%;
  height: 100%;
}

.pdn-sider {
  position: relative;
  width: 240px;
  background: #f9f9f9;
  height: 100%;
  border-right: 1px solid #e2dfdf;
}

.pdn-sider-footer {
  width: 100%;
  position: absolute;
  box-sizing: border-box;
  bottom: 0;
  height: 42px;
  border-top: 1px solid #e2dfdf;
}

.pdn-sider-footer .tab-close-box {
  float: left;
  margin: 0;
  margin-right: 10px;
  padding: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  line-height: 30px;
  border-radius: 5px;
  color: #0d87f7;
  font-size: 19px;
  text-align: center;
  transition: 0.3s;
  box-shadow: #fff 0px 0px 0px 0px;
}

.pdn-sider-footer .pdn-sider-monitor {
  line-height: 34px;
  font-size: 22px;
  cursor: pointer;
}

.pdn-sider-footer .pdn-sider-layout {
  line-height: 36px;
  font-weight: bold;
}

.pdn-sider-footer .tab-close-box:hover {
  color: #379af5;
  box-shadow: rgba(168, 204, 241, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.18) 0px 0px 5px 2px;
  transition: 0.3s;
}

.mg-r-8 {
  margin-right: 8px;
}

.project-name-input .ant-input-group {
  float: left;
  width: 100%;
}


.pdn-sider-menu-tree .tree-node .ant-tree-title>.project-rename-input {
  position: absolute;
  width: 95%;
}

.pdn-sider-menu-tree .tree-node .ant-tree-title>.project-rename-input .ant-input {
  height: 24px;
}

.pdn-sider-menu-tree .ant-tree-title .project-rename-input .aurora-tree-create-icon {
  visibility: visible;
  position: static;
}

.pdn-sider-menu-tree .tree-node-pcb-name .tree-node-pcb-replace-icon {
  position: absolute;
  right: 0px;
}

.pdn-sider-menu-tree .ant-tree-title .aurora-replace-icon {
  visibility: hidden;
}

.pdn-sider-menu-tree .ant-tree-title:hover .aurora-replace-icon {
  visibility: visible;
  margin-right: 0px;
  position: absolute;
  right: 36px;
}

.project-rename-input .ant-input-group {
  float: left;
  width: 100%;
}

.project-name .ant-input-group-addon {
  width: 56px;
  padding: 0 8px;
}

.pdn-tooltip-span {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.pdn-preparing {
  width: 85%;
}

.pdn-create-name {
  width: 100%;
}

.pdn-sider-menu-tree .ant-tree-title .ant-checkbox-wrapper {
  /* float: left;
  margin-right: 6px; */
  position: absolute;
  left: 2px;
  line-height: 21px;
  top: 2px;
}

.pdn-simulate-button>.ant-btn {
  line-height: 18px;
  width: 68px;
  height: 20px;
  padding: 0 6px;
  margin-top: 6px;
  border: 1px solid #1890ff;
  color: #1890ff;
  position: absolute;
  right: 14px;
}

.result-style {
  padding-left: 12px;
  font-style: italic;
}

.pdn-delete-content {
  width: 208px;
}

.pdn-delete-title {
  display: block;
  font-size: 14px;
  color: #f12727;
}

.delete-confirm {
  color: #1890ff;
}

.delete-confirm:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.pdn-delete-pop-confirm .ant-popover-buttons {
  display: none;
}

.pdn-delete-pop-confirm .ant-popover-inner-content {
  padding: 8px 16px;
}

.pdn-delete-pop-confirm .ant-popover-message {
  padding-bottom: 0;
}

.pdn-delete-pop-confirm .ant-popover-message>.pdn-confirm-icon {
  display: none;
}

.pdn-delete-pop-confirm .ant-popover-message-title {
  padding-left: 0;
}

.pdn-delete-pop-confirm .pdn-delete-button {
  display: block;
  width: 30px;
  height: 20px;
  float: right;
  text-align: center;
  line-height: 16px;
  padding: 0;
  font-size: 12px;
  border-radius: 4px;
  box-sizing: border-box;
}

.pdn-clean-pop-confirm .ant-popover-inner {
  box-shadow: 0px 2px 8px 5px rgba(0, 0, 0, 0.15);
}

.pdn-clean-pop-confirm .ant-popover-buttons .ant-btn {
  display: none;
}

.pdn-clean-pop-confirm .ant-popover-buttons .ant-btn-primary {
  display: inline-block;
}

.pdn-clean-pop-confirm .ant-popover-inner-content {
  padding: 8px 16px;
  width: 204px;
}

.pdn-clean-pop-confirm .ant-popover-message {
  padding-bottom: 0;
}

.pdn-clean-pop-confirm .ant-popover-message>.pdn-confirm-icon {
  display: none;
}

.pdn-clean-pop-confirm .ant-popover-message-title {
  padding-left: 0;
  display: block;
  font-size: 14px;
  color: #f12727;
}

.pdn-clean-pop-confirm .pdn-delete-button {
  display: block;
  width: 30px;
  height: 20px;
  float: right;
  text-align: center;
  line-height: 16px;
  padding: 0;
  font-size: 12px;
  border-radius: 4px;
  box-sizing: border-box;
}

.pdn-clean-pop-confirm .ant-popover-inner {
  box-shadow: 0px 2px 8px 5px rgba(0, 0, 0, 0.15);
}

.pdn-sider-menu {
  margin: 12px;
  padding: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  line-height: 30px;
  border-radius: 5px;
  color: #0d87f7;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  transition: 0.3s;
  box-shadow: #fff 0px 0px 0px 0px;
}

.pdn-sider-menu:hover {
  color: #379af5;
  box-shadow: rgba(168, 204, 241, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.18) 0px 0px 5px 2px;
  transition: 0.3s;
}

.pdn-layout-msg {
  font-size: 12px;
  margin-top: 10px;
  vertical-align: middle;
}

.pdn-layout-msg>span {
  display: inline-block;
}

.pdn-layout-msg>span:nth-child(1) {
  margin-right: 6px;
}

.pdn-layout-popover .ant-popover-inner-content {
  padding: 6px;
}

#switch-icon-selected {
  color: #0d87f7;
}

.pcb-icon {
  font-size: 25px;
  line-height: 32px;
  cursor: pointer;
}

.pdn-view-popover .ant-popover-inner-content {
  padding: 0;
}

.icon-Monitor {
  font-size: 20px;
  cursor: pointer;
}

.icon-setup {
  font-size: 19px;
  cursor: pointer;
}

.pdn-sider-menu-tree .ant-tree-title .aurora-tree-import-export-project-icon,
.pdn-sider-menu-tree .ant-tree-title .aurora-tree-import-export-library-icon {
  position: absolute;
  right: 21px;
}

.pdn-sider-menu-tree .ant-tree-title:hover .aurora-tree-import-export-project-icon {
  right: 48px;
}

.pdn-sider-menu-tree .ant-tree-title .aurora-tree-layout-check-loading-icon {
  visibility: visible;
  right: 14px
}

.pdn-sider-menu-tree .ant-tree-title .ant-select-arrow .anticon {
  right: 0px;
}

.pdn-sider-menu-tree .ant-tree-title .anticon {
  position: absolute;
  right: 14px;
  visibility: hidden;
}

.pdn-sider-menu-tree .ant-tree-title:hover .anticon {
  visibility: visible;
}

.pdn-sider-menu-tree .ant-tree-title:hover {
  padding-right: 48px;
}

.pdn-sider-menu-tree .ant-tree-title .project-name-input .aurora-tree-create-icon {
  visibility: visible;
  position: static;
}

.pdn-icon-color:hover {
  color: #1890ff;
}

.pdn-sider-menu-tree .ant-tree-title:hover .aurora-tree-simulate-icon {
  right: 38px;
}

.pdn-sider-menu-tree .anticon-play-circle {
  visibility: visible !important;
  transition: right .2s linear;
}

.pdn-sider-menu-tree .anticon-play-circle.simulate-icon-disabled {
  color: #a0a0a0;
}

.pdn-sider-footer .view-icon-disabled {
  color: #a0a0a0;
  cursor: not-allowed;
}

.pdn-sider-footer .layout-icon-disabled {
  border: 1px #c5c5c5 solid;
  color: #a0a0a0;
  cursor: not-allowed;
}

.sider-switch-icon {
  display: inline-block;
  vertical-align: middle;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 6px;
  color: #737373;
}

.sider-switch-vertical-icon {
  padding: 0 17px;
  padding-bottom: 4px;
}

.sider-switch-vertical-icon .pcb-icon {
  margin-left: -3px;
}

.sider-layout-div {
  display: inline-block;
}

.sider-layout-vertical-div {
  padding: 0 10px;
  padding-bottom: 4px;
}

.pdn-sider-result-spin {
  position: absolute;
  right: 16px;
  line-height: 32px;
}

.pdn-sider-result-spin i {
  visibility: visible !important;
  right: 0 !important;
}

.pdn-simulate-pop-confirm .ant-btn-sm {
  display: none;
}

.pdn-simulate-pop-confirm .ant-popover-buttons .ant-btn-primary {
  display: block;
  width: 60px;
  height: 20px;
  float: right;
  text-align: center;
  line-height: 16px;
  padding: 0;
  font-size: 12px;
  border-radius: 4px;
  box-sizing: border-box;
}

.pdn-simulate-pop-confirm .ant-popover-buttons:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.pdn-simulate-pop-confirm .ant-popover-buttons {
  margin-bottom: 0px;
  margin-top: 2px;
}

.pdn-simulate-pop-confirm .ant-popover-inner-content {
  padding: 8px 8px;
  width: 240px;
}

.pdn-simulate-pop-confirm .ant-popover-inner-content .ant-popover-message-title {
  font-size: 14px;
  color: #f12727;
}

.pdn-simulate-pop-confirm .ant-popover-message {
  padding-bottom: 0;
}

.pdn-simulate-pop-confirm .ant-popover-message>.pdn-confirm-icon {
  display: none;
}

.pdn-simulate-pop-confirm .ant-popover-message-title {
  padding-left: 0;
}

.pdn-sider-menu-tree .ant-tree-title:hover .pdn-project-title {
  padding-right: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pdn-sider-menu-tree .ant-tree-title .pdn-project-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

/* PDN create hover */

.pdn-sider-menu-tree .pdn-create-node .ant-tree-title:hover {
  padding-right: 0px;
}

/* PDN Tree name hover */

.pdn-sider-menu-tree .tree-node-pcb-name .ant-tree-title:hover,
.pdn-sider-menu-tree .tree-node-pdn-name .ant-tree-title:hover,
.pdn-sider-menu-tree .tree-node-pkg-name .ant-tree-title:hover,
.pdn-sider-menu-tree .tree-node-vrm-name .ant-tree-title:hover {
  padding-right: 28px;
}

.pdn-sider-menu-tree .tree-node-pdn-name .ant-tree-title {
  padding-left: 22px;
}

.pdn-sider-menu-tree .tree-node-pdn-name .tree-node-pdn-result-name .ant-tree-title {
  padding-left: 2px;
}

.pdn-sider-menu-tree .tree-node-spim-file-name .ant-tree-title:hover,
.pdn-sider-menu-tree .tree-node-power-domain-folder-name .ant-tree-title:hover {
  padding-right: 0px;
}

.pdn-sider-menu-tree .project-create-node .ant-tree-title:hover {
  padding-right: 0px;
}

.fastpi-library-span {
  display: inline-block;
  max-width: 86%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.pdn-sider-menu-tree .tree-library-file {
  position: relative;
}

.pdn-sider-menu-tree .download-progress-bar {
  height: 36px;
  width: 100%;
  float: left;
  overflow: visible;
  position: relative;
}

.pdn-sider-menu-tree .ant-tree-title .download-progress-bar i {
  right: 9px;
}

.pdn-sider-menu-tree .tree-library-file .ant-tree-title .download-progress-bar .tree-library-content {
  padding-right: 40px;
}

.pdn-sider-menu-tree .tree-library-file .ant-tree-title:hover .download-progress-bar .tree-library-content {
  padding-right: 20px;
}

.pdn-sider-menu-tree .tree-library-file .ant-tree-title:hover .tree-library-content {
  padding-right: 20px;
}

.pdn-sider-menu-tree .tree-library-file .ant-tree-title:hover {
  padding-right: 0px;
}

.pdn-sider-menu-tree .ant-tree-title:hover .fastpi-library-span {
  width: 60%
}

.pdn-sider-menu-tree .ant-tree-title:hover.prepare-loading-icon {
  visibility: visible;
  right: 14px !important;
}


.pdn-sider-menu-tree .download-progress-bar::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #2486ff;
  text-align: right;
  white-space: nowrap;
  opacity: 0.4;
  overflow: hidden;
  color: #fff;
  counter-reset: progress var(--percent);
  content: "";
  transition: 0.6s;
  width: calc(1%*var(--percent));
}

.pdn-sider-menu-tree .tree-library-file .download-progress-bar-success::before {
  content: counter(progress) '%\2002';
}

.pdn-sider-menu-tree .ant-tree-title {
  cursor: pointer;
}

.decap-default-title {
  float: left;
  width: 68%;
}

.decap-default-title:hover {
  width: 100%;
}

.pdn-sider-menu-tree .tree-library-content {
  position: relative;
}

.pdn-sider-menu-tree .my-tree .tree-library-file .ant-tree-node-content-wrapper {
  width: calc(100% - 18px);
}

.fastpi-decap-default.ant-tag {
  position: absolute;
  top: 2px;
  right: 4px;
  border-radius: 4px;
  font-size: 12px;
  transform: scale(0.9, 0.9);
  border: 1px solid #a3a3a3;
  color: #a3a3a3;
}

.pdn-sider-menu-tree .ant-tree-title:hover .fastpi-decap-default {
  display: none;
}

.pdn-sider-menu-tree .ant-tree-title .aurora-tree-warning-icon {
  visibility: visible !important;
  display: inline-block;
  line-height: 30px;
}

.pdn-sider-menu-tree .ant-tree-title:hover .aurora-tree-warning-icon {
  margin-right: 20px !important;
}

.pdn-warning-tooltip .ant-tooltip-inner {
  color: #ff0000;
}

.pdn-warning {
  max-width: 86%;
}

.PDN-debug-tooltip-span {
  display: inline-block;
  max-width: 88%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  color: red;
}

.pdn-success-tooltip-span {
  display: inline-block;
  max-width: 88%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.anticon.anticon-exclamation-circle.debug-exclamation-SPIM-icon {
  visibility: initial;
  font-size: 15px;
  line-height: 36px;
  color: #f39300;
  top: 9px;
}

.pdn-sider-menu-tree .ant-tree-title:hover .debug-exclamation-SPIM-icon-hover {
  right: 82px;
}

.anticon.anticon-question-circle.debug-question-SPIM-children-icon {
  visibility: initial;
  font-size: 15px;
  line-height: 36px;
  color: red;
  top: 9px;
}

.fastpi-missing-spim-span {
  width: 80%;
}

.pdn-sider-menu-tree .ant-tree-title .anticon-play-circle {
  visibility: visible;
  transition: right .2s linear;
  right: 38px;
}

.pdn-sider-menu-tree .ant-tree-title .aurora-tree-batch-delete-icon {
  visibility: visible;
  transition: right .2s linear;
}

.pdn-sider-menu-tree .ant-tree-title:hover .anticon-play-circle {
  right: 63px;
}

.pdn-sider-menu-tree .ant-tree-title:hover .aurora-tree-batch-delete-icon {
  right: 38px;
}

.pdn-package-library-error-span,
.pdn-sider-menu-tree .tree-node-pkg-name .ant-tree-title:hover .pdn-package-library-error-span {
  color: #f13140;
}

.pdn-sider-menu-tree .ant-tree-title .aurora-tree-file-text-icon {
  right: 58px;
}

.pdn-layout-check-icon {
  margin-top: -2px;
}

.pdn-sider-menu-tree .ant-tree-title .pdn-layout-check-icon {
  position: absolute;
  right: 36px;
  visibility: hidden;
}

.pdn-sider-menu-tree .ant-tree-title:hover .pdn-layout-check-icon {
  visibility: visible;
}

.pdn-sider-menu-tree .ant-tree-title:hover .aurora-tree-layout-check-loading-icon {
  right: 65px;
}

.pdn-sider-menu-tree .ant-tree-title .aurora-tree-import-export-loading-icon {
  right: 14px;
  visibility: visible;
  text-align: center;
  line-height: 30px;
  font-size: 15px;
  color: #1890ff;
  float: right;
}

.pdn-sider-menu-tree .ant-tree-title .aurora-tree-import-export-library-loading,
.pdn-sider-menu-tree .ant-tree-title .aurora-tree-import-export-project-loading {
  position: absolute;
  right: 14px;
  margin-top: calc(5% - 9px);
}

.pdn-sider-menu-tree .ant-tree-title:hover .aurora-tree-import-export-project-loading {
  right: 42px;
  right: 65px;
}

.pdn-sider-menu-tree .ant-tree-title .aurora-tree-import-export-loading-icon {
  right: 14px;
  visibility: visible;
  text-align: center;
  line-height: 30px;
  font-size: 15px;
  color: #1890ff;
  float: right;
}

.pdn-sider-menu-tree .ant-tree-title .aurora-tree-import-export-library-loading,
.pdn-sider-menu-tree .ant-tree-title .aurora-tree-import-export-project-loading {
  position: absolute;
  right: 14px;
  margin-top: calc(5% - 9px);
}

.pdn-sider-menu-tree .ant-tree-title:hover .aurora-tree-import-export-project-loading {
  right: 42px;
}

.pdn-sider-menu-tree .ant-tree-title .aurora-tree-import-export-loading-icon {
  right: 14px;
  visibility: visible;
  text-align: center;
  line-height: 30px;
  font-size: 15px;
  color: #1890ff;
  float: right;
}

.pdn-sider-menu-tree .ant-tree-title .aurora-tree-import-export-library-loading,
.pdn-sider-menu-tree .ant-tree-title .aurora-tree-import-export-project-loading {
  position: absolute;
  right: 14px;
  margin-top: calc(5% - 9px);
}

.pdn-sider-menu-tree .ant-tree-title:hover .aurora-tree-import-export-project-loading {
  right: 42px;
}