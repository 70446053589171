/* === spice model panel === */
.spice-model-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.spice-model-panel .panel-body {
  position: relative;
}

.spice-model-panel .spice-model-panel-title {
  font-size: 14px;
  font-weight: 500;
}

.spice-model-panel .spice-model-content {
  font-size: 13px;
  position: absolute;
  bottom: 0px;
  top: 0px;
  right: 0px;
  left: 0px;
  padding: 10px 14px;
  overflow: auto;
  /* overflow-x: hidden; */
}

.spice-model-panel .spice-model-content .spice-model-content-sub-box {
  min-width: 650px;
}

.spice-model-stimulus-error {
  display: block;
  padding: 6px 10px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin: 5px 10px 0px;
  line-height: 18px;
}

.title-remove-float {
  float: none !important;
}

.stimulus-setup-model-radio {
  height: 32px;
  line-height: 32px;
  font-size: 13px;
  padding-left: 100px;
  /* width: 100%; */
}

.stimulus-setup-model-radio-group {
  width: 100%;
}

.stimulus-setup-model-radio-content {
  display: flex;
  font-size: 13px;
}

.stimulus-setup-model-radio-title {
  flex: 1;
/*   margin-right: 10px; */
}

.stimulus-setup-model-radio-title-dutyCycle {
  flex: 2;
  /* margin-right: 10px; */
}

.stimulus-setup-model-radio-input {
  width: calc(100% - 210px);
  vertical-align: middle;
  margin-top: 6px;
  flex: 2;
}

.stimulus-setup-model-radio .stimulus-setup-model-radio-duty-cycle-input {
  width: calc(100% - 309px);
}

.stimulus-question-icon {
  color: #1890ff;
  margin-left: 8px;
}

.stimulus-setup-tooltop {
  z-index: 9999;
  max-width: 600px !important;
}