.result-right {
  border-left: 1px solid #e8e8e8;
  background-color: #f9f9f9;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 30%;
}


.result-right .result-setting-menu {
  margin: 10px;
  padding: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  line-height: 30px;
  border-radius: 5px;
  color: #0d87f7;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  transition: 0.3s;
  box-shadow: #fff 0px 0px 0px 0px;
}

.result-right .result-setting-menu:hover {
  color: #379af5;
  box-shadow: rgba(168, 204, 241, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.18) 0px 0px 5px 2px;
  transition: 0.3s;
}