.setup-input-group-flex-box {
  display: flex;
  justify-content: space-between;
}

.setup-input-group-select-item {
  width: 60px !important;
}

.setup-input-group-select-item>.ant-select-selection {
  background-color: #fafafa;
}

.setup-input-group-select-item .ant-select-selection--single .ant-select-selection__rendered {
  margin-right: 11px;
}

.setup-input-group-select-item-dropdown {
  z-index: 5000;
}

.setup-input-group-flex-box span.ant-input-group-addon {
  min-width: 60px;
  max-width: 60px;
  padding: 0px;
  text-align: center;
}

.setup-info-circle-icon {
  margin-left: 5px;
}

.setup-info-circle-icon:hover {
  color: rgb(24, 144, 255);
}

.setup-question-circle-icon {
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translate(0, -50%);
  color: red;
}

.setup-select-width {
  width: 100%;
}

.setup-select-width-not-exist {
  width: calc(100% - 24px);
  color: red;
}