.dividingLine {
  z-index: 1000;
  width: 3px;
  height: 100%;
  cursor: col-resize;
}

.dividingLine:hover +.dividingLine-hover {
  display: block;
  transition: all 0.3s;
}

.dividingLine-hover {
  z-index: 998;
  width: 16px;
  height: 100%;
  background: #d0d0d045;
  top: 0px;
  display: none;
  transition: all 0.3s;
}

.dividingLine-border {
  z-index: 999;
  width: 1px;
  height: 100%;
  top: 0px;
  background: #e2dfdf;
}