.cascade-panel-error-msg {
  display: block;
  padding: 8px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 10px;
}

.cascade-content-bottom {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  background: #ffffff;
}

.cascade-content-left {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.cascade-sider-menu-tree .tree-node .ant-tree-title {
  cursor: pointer;
}

.cascade-setup-border {
  border: 1px solid #a8c4e6;
  padding: 10px;
  border-radius: 3px;
}

.cascade-setup-title-color {
  vertical-align: middle;
  color: #0b3694;
}

.cascade-setup-title-right {
  float: right;
}

.cascade-setup-title-color-only {
  color: #0b3694;
}

.cascade-components-table .ant-table-content>.aurora-table-body tr>td {
  word-break: break-word;
}

.cascade-components-table td {
  position: relative;
}

.cascade-ir-table .ant-table-content>.aurora-table-body .ant-table-cell-ellipsis.editable-cell-value-wrap,
.cascade-components-table .ant-table-content>.aurora-table-body .ant-table-cell-ellipsis.editable-cell-value-wrap,
.cascade-decap-group-table .ant-table-content>.aurora-table-body .ant-table-cell-ellipsis.editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  overflow: unset;
  height: auto;
  white-space: normal;
  padding: 0px 2px;
}

.cascade-components-table .ant-table-column-title {
  padding-left: 4px;
}

.cascade-components-table .select-width .ant-select-selection {
  margin: -4px 0px;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.cascade-components-table .editable-row:hover .editable-cell-value-wrap {
  border-color: #d9d9d9;
  background: #e6f7ff;
}

.cascade-components-table tbody>tr>td>span {
  display: block;
  max-height: 80px;
  overflow: auto;
}

.cascade-components-table tbody>tr>td .ant-select-selection--multiple {
  padding-bottom: 3px;
}

.cascade-components-table .editable-cell-value-wrap .cascade-components-ignore-name {
  padding-left: 0px;
}

.cascade-component-split-row,
.cascade-component-split-row>td {
  background-color: #e6ffed !important;
}

.cascade-component-split-row:hover>td,
.cascade-component-split-row:hover .editable-cell-value-wrap {
  background-color: #e6ffed !important;
}

.cascade-component-split-row:hover .editable-cell-value-wrap {
  border-color: #fff !important;
}

.cascade-component-select-row,
.cascade-component-select-row>td {
  background-color: #adddfd !important;
}

.cascade-component-select-row:hover>td,
.cascade-component-select-row:hover .editable-cell-value-wrap {
  background-color: #adddfd !important;
}

.cascade-component-select-row:hover .editable-cell-value-wrap {
  border-color: #fff !important;
}

.cascade-component-merge-row,
.cascade-component-merge-row>td {
  background-color: #fffbdd !important;
}

.cascade-component-merge-row:hover>td,
.cascade-component-merge-row:hover .editable-cell-value-wrap {
  background-color: #fffbdd !important;
}

.cascade-component-merge-row:hover .editable-cell-value-wrap {
  border-color: #fff !important;
}

.cascade-refresh-icon {
  color: #1890ff;
  margin-left: 15px;
  font-size: 15px;
  cursor: pointer;
}

.cascade-detail-tooltip,
.cascade-detail-tooltip .ant-tooltip-content .ant-tooltip-inner {
  width: fit-content;
}

.cascade-text-editor-tooltip {
  max-height: 400px;
}

.cascade-detail-tooltip p {
  margin: 0;
  padding: 0;
}


.cascade-display-detail-box {
  padding: 10px;
  border: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  overflow: auto;
  max-height: 400px;
}

.cascade-display-detail-box>div {
  width: 100%;
  word-break: keep-all;
  white-space: nowrap;
  margin: 5px 0;
}

.cascade-display-detail-box>div:first-child {
  margin-top: 0;
}

.cascade-display-detail-box>div:last-child {
  margin-bottom: 0;
}

.cascade-detail-line {
  cursor: pointer;
}


.cascade-detail-line span,
.cascade-sense-detail-line>div span {
  line-height: 21px;
  height: 21px;
  display: inline-block;
}

.cascade-detail-line:hover,
.cascade-detail-line:hover span,
.cascade-sense-detail-line:hover>div span {
  background-color: #bae7ff
}

.cascade-power-select-panel {
  box-shadow: 2px 4px 7px 5px rgb(76 77 78 / 14%), 0 0 10px 3px rgb(0 0 0 / 10%);
}

.cascade-power-select-panel .power-select-content {
  padding: 10px 16px;
  border-radius: 6px 6px 0px 0px;
  width: 100%;
  display: inline-block;
}

.cascade-power-select-panel .power-select-content .power-select-apply {
  width: calc(100% - 100px);
  float: left;
  padding-top: 2px;
}

.cascade-power-select-panel .power-select-content .power-select-apply .power-select-apply-tip {
  margin-right: 20px
}

.cascade-power-select-panel .power-select-content .power-select-apply .power-select-apply-select {
  width: calc(100% - 85px);
}

.cascade-power-select-panel .power-select-comp-select {
  padding: 10px 16px;
  padding-bottom: 0px;
}

.cascade-power-select-panel .power-select-comp-select>span {
  display: inline-block;
  width: 150px
}

.cascade-power-select-panel .power-select-comp-select>.aurora-select {
  width: calc(100% - 160px);
}

.cascade-power-select-apply-select-dropdown {
  z-index: 2000;
}

.cascade-power-select-panel .ant-spin-nested-loading,
.cascade-power-select-panel .ant-spin-container {
  width: 100%;
  height: 100%;
}

.cascade-power-select-title {
  font-size: 16px;
  font-weight: 500;
}

.cascade-power-select-btn {
  height: 28px;
}

.cascade-power-select-panel .cascade-power-transfer-outside {
  display: inline-flex;
  width: 100%;
  height: 90%;
  padding: 16px;
}

.cascade-power-select-panel .cascade-power-transfer-outside-has-select {
  height: 80%
}

.cascade-power-transfer-outside .cascade-power-transfer {
  font-weight: 500;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.cascade-power-transfer-outside .ant-btn {
  margin: 10px 0px;
}

.cascade-power-select-panel .power-select-content-button {
  text-align: right;
  padding: 0;
  padding-bottom: 16px;
  padding-right: 16px;
}

.cascade-power-transfer .ant-transfer-operation {
  width: 25px;
}

.cascade-pcb-select-box {
  float: left;
  width: fit-content;
}

.cascade-pcb-target-box {
  margin-left: 20px;
  display: inline-block;
  border-left: 1px solid #c7c7c7;
  padding-left: 20px;
}

.cascade-decap-default.ant-tag {
  position: absolute;
  top: 2px;
  right: 4px;
  border-radius: 4px;
  font-size: 12px;
  transform: scale(0.9, 0.9);
  border: 1px solid #a3a3a3;
  color: #a3a3a3;
}

.cascade-custom-tag {
  position: absolute;
  top: 0px;
  right: -5px;
  border-radius: 4px;
  font-size: 12px;
  transform: scale(0.9, 0.9);
}

.cascade-library-default-decap-title-main:hover .cascade-decap-default,
.cascade-library-default-decap-title-main:hover .cascade-custom-tag {
  display: none;
}

.cascade-package-node {
  border-top: 1px solid;
  border-image: linear-gradient(to right, #ffffff, #e0e0e0, #e0e0e0, #e0e0e0, #e0e0e0, #e0e0e0, #ffffff) 1;
  padding-top: 2px !important;
}

.cascade-hidden-node {
  display: none;
}

.cascade-setup-normal {
  top: 45px;
}

.cascade-lr-content {
  top: 45px;
}

.cascade-lr-result {
  top: 0px;
}

.cascade-tb-setup-main,
.cascade-tb-result-main {
  position: absolute;
  top: 45px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
}

.cascade-tb-result-main {
  top: 0px;
}

.cascade-tree-node-sign-off-template .cascade-sign-off-status-icon,
.tree-node-channel-name .cascade-sim-status-icon {
  position: absolute;
  right: 14px;
}

.cascade-tree-node-sign-off-template:hover .ant-tree-title .cascade-sign-off-status-icon,
.tree-node-channel-name .ant-tree-title:hover .cascade-sim-status-icon {
  right: 58px;
}

.cascade-tree-node-design-tree:hover .cascade-sim-status-icon,
.cascade-tree-node-design-tree .ant-tree-title:hover .cascade-sim-status-icon {
  right: 14px !important;
}

.cascade-tree-node-sign-off-template .cascade-sign-off-status-icon,
.tree-node-channel-name .cascade-sim-status-icon-hasCopy {
  position: absolute;
}

.cascade-tree-node-sign-off-template:hover .ant-tree-title .cascade-sign-off-status-icon,
.tree-node-channel-name .ant-tree-title:hover .cascade-sim-status-icon-hasCopy {
  right: 78px;
}

.cascade-tree-node-design-tree:hover .cascade-sim-status-icon-hasCopy,
.cascade-tree-node-design-tree .ant-tree-title:hover .cascade-sim-status-icon-hasCopy {
  right: 26px !important;
}

.cascade-tree-node-design-tree .ant-tree-title:hover .cascade-tree-channel-name {
  width: calc(100% - 30px);
}

/* == cascade-search-library-panel == */
.cascade-search-library-panel {
  box-shadow: 2px 4px 7px 5px rgb(76 77 78 / 14%), 0 0 10px 3px rgb(0 0 0 / 10%);
}

.cascade-search-library-panel .search-library-content {
  padding: 0px 16px;
  border-radius: 6px 6px 0px 0px;
}

.cascade-search-library-title {
  font-size: 16px;
  font-weight: 500;
}

.cascade-search-library-panel .search-library-content-item {
  margin: 10px 0px;
  height: 28px;
  line-height: 28px;
  display: flex;
  justify-content: space-between;
}

.cascade-search-library-panel .search-library-content-item>span {
  width: 50px;
}

.cascade-search-library-panel .search-library-content-item .search-library-input {
  width: calc(100% - 140px);
  height: 28px;
  line-height: 28px;
  margin: 0px 10px;
}

.cascade-search-library-panel .search-library-content-item .search-library-content-button {
  width: 70px;
  text-align: right;
}

.cascade-search-library-panel .search-library-content-item .search-library-content-button .search-library-btn {
  height: 28px;
}

.cascade-decap-re-match-icon {
  color: #1890ff;
  margin-left: 20px;
  cursor: pointer;
  vertical-align: middle;
  font-size: 14px;
}

.cascade-decap-model-title {
  vertical-align: middle;
}

.cascade-decap-model-assigning {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
  color: #1890ff;
}

.cascade-ball-size-content {
  width: 100%;
  padding: 10px;
}

.cascade-ball-size-line {
  width: 100%;
  padding: 5px;
}

.cascade-ball-size-line .cascade-ball-size-span {
  width: 120px;
  display: inline-block
}

.cascade-ball-size-line .cascade-ball-size-not-group-span {
  width: 200px;
  display: inline-block;
}

.cascade-ball-size-line .cascade-ball-size-question-circle-icon {
  margin-left: 6px;
  color: #f13140;
  font-size: 14px;
}

.cascade-ball-size-line .cascade-ball-size-input {
  width: calc(100% - 140px);
}

tbody>tr>td .cascade-target-actual-value-span {
  color: #0d76e4;
  text-indent: 4px;
}

tbody>tr>td .cascade-target-actual-error-value {
  color: #e51212;
}

.cascade-position-relative {
  position: relative;
}

.cascade-ignore-color {
  color: #e65b5b
}

.cascade-component-model-info-icon {
  position: absolute;
  right: 12px;
  top: calc(50% - 6px);
  color: #1890ff;
}

.cascade-power-tree-detail-tree-title {
  font-weight: bold;
  display: inline-block;
}

.cascade-power-tree-detail-tree-success {
  color: green
}

.cascade-power-tree-detail-tree-error {
  color: red
}

.cascade-power-tree-detail-tree-warning {
  color: orange
}

.cascade-power-tree-detail-tree-normal {
  color: rgba(0, 0, 0, 0.6)
}

.cascade-power-tree-detail-tree-empty {
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  height: 20px
}

.cascade-page-advanced-title {
  color: #8c8c8c;
  font-size: 12px;
}

.cascade-page-advanced-title>span {
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}

.cascade-page-advanced-title>i {
  cursor: pointer;
}

.power-select-content-button .power-select-switch {
  float: left;
  margin-left: 18px;
  height: 28px;
  line-height: 28px;
}

.power-select-content-button .power-select-switch>span,
.power-select-content-button .power-select-switch>button {
  vertical-align: middle;
}

.cascade-table-row-disabled {
  display: inline-block;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: #f3f3f3;
  cursor: not-allowed;
  text-indent: 13px;
  line-height: 38px;
  color: rgb(0, 0, 0, 0.45);
}