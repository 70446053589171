.clear {
  zoom: 1;
}

.clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.comp-model-dialog {
  bottom: 100%;
  width: 100%;
  overflow: auto;
  background-color: #fdfdfd;
  /* box-shadow: rgba(76, 77, 78, 0.14) 0px 0px 5px 8px,
    rgba(0, 0, 0, 0.1) 0px 4px 11px; */
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px,
    rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  margin-bottom: 3px;
  margin-top: 3px;
  position: absolute;
  z-index: 1000;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0px;
  /* border: 1px solid #2692f6; */
  border: 1px solid #cdcfd2;
  /* min-width: 290px; */
}

.pintopin-model-title,
.spice-model-title {
  line-height: 24px;
  font-weight: bold;
}

.pintopin-model-select {
  margin-bottom: 0px;
  max-height: 150px;
}

.model-pin-tabs,
.model-pin-tabs .ant-tabs-nav {
  width: 100%;
  padding-bottom: 0px;
  padding-top: 10px;
}

.model-pin-tabs .ant-tabs-tab {
  margin: 0px;
  padding: 10px 0px;
  width: 50%;
  text-align: center;
}

.model-pin-tabs .ant-tabs-bar {
  margin: 0px;
}

.pintopin-model-select {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100px;
}

.pintopin-model-select > ul {
  margin: 0px;
  padding: 0 0 10px;
}

.pintopin-model-select > ul > li {
  line-height: 24px;
  list-style: none;
  cursor: pointer;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  padding-left: 5px;
}

.pintopin-model-select li:hover {
  background: #e9e9e9;
}

.pintopin-ibis-dialog {
  padding: 5px 10px 0px;
  width: 100%;
  line-height: 24px;
}

.pintopin-model-list-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 30px;
  text-align: center;
  background: #c1c1c18f;
  font-weight: bold;
  border-bottom: 1px solid #cfcfcf;
}

.pintopin-model-select-box {
  margin-top: 10px;
  border-top: 1px solid #dfdfdf;
  background-color: #fff;
}

.border-right {
  border-right: 1px solid #c5c5c5;
}

.border-left {
  border-left: 1px solid #c5c5c5;
}

.pintopin-comment {
  float: left;
  width: 84%;
  padding-right: 10px;
  overflow: hidden;
}

.pintopin-comment > div,
.stimulus-comment > div {
  float: left;
  line-height: 20px;
  padding-left: 10px;
  font-size: 12px;
}

.pintopin-comment span,
.stimulus-comment span {
  font-weight: bold;
  padding-left: 5px;
}

.pintopin-model-ok {
  float: right;
  width: 40px;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 0px;
}

.editable-cell-value-wrap {
  border: 1px solid #fff;
  border-radius: 4px;
  cursor: pointer;
  height: 30px;
  line-height: 28px;
  margin: -4px;
  padding: 0 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  width: 100%;
}

.pintopin-model-footer {
  width: 100%;
  float: left;
  border-top: 1px solid #cfcfcf;
}

/* .stimulus-unit-change {
  margin-left: 10px;
}

.stimulus-unit-change .ant-select-selector {
  margin-right: 5px;
} */

.pintopint-model-upload {
  float: left;
  width: 150px;
}

.pintopin-modeltype-select {
  float: left;
  width: 150px;
}

.pintopint-model-upload-disable {
  color: rgba(0, 0, 0, 0.65);
  background: #ffffff;
}

.pintopint-model-upload-button {
  font-weight: 400;
  cursor: pointer;
  margin-left: 10px;
  font-size: 16px;
  color: #1890ff;
}

.pintopin-model-title,
.spice-model-title {
  float: left;
}

.pintopin-model-title {
  width: 50px;
}

.spice-model-title {
  width: 110px;
}

.spice_dialog {
  margin-bottom: 5px;
}

#library-file-view,
#template-file-view {
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px,
    rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  border: 1px solid #cdcfd2;
}

.file-display {
  height: 400px;
}

.file-panel .panel-body {
  background: #f1f1f1;
}

.aurora-model-dialog{
  z-index: 1000000;
}
