.tag-box {
  width: 100%;
  border: 1px solid #63b4ff;
  height: auto;
  border-radius: 4px;
  line-height: 30px;
  background: #fff;
  box-shadow: rgba(9, 128, 247, 0.14) 0px 0px 0px 2px,
    rgba(13, 151, 249, 0.1) 0px 0px 7px 0px;
  padding: 0px 5px;
}

.tag-item {
  margin: 4px 4px;
  cursor: pointer;
}

.tag-box .tag-item:hover {
  border-color: #91d5ff;
}

.tag-box .ant-tag-checkable {
  color: rgba(0, 0, 0, 0.65);
  border-color: #ccc;
  background-color: #fff;
}

.tag-box .ant-tag-checkable-checked {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}

.check-tag-popover .ant-popover-inner-content {
  padding: 2px 6px;
  border-radius: 4px;
  border: 1px solid#ffadd2;
  color: #eb2f96;
  background: #fff0f6;
}

.check-tag-popover .ant-popover-inner-content:hover {
  color: #eb58a9;
}

.check-tag-popover .ant-popover-content {
  border-radius: 4px;
}

.check-tag-popover .ant-popover-content .ant-popover-arrow {
  border-right-color: #fff0f6;
  border-bottom-color: #fff0f6;
}

.check-tag-popover .ant-popover-inner-content:hover .ant-popover-arrow {
  border-right-color: #fff0f6;
  border-bottom-color: #fff0f6;
  bottom: 6.2px;
}

.check-tab-popover-content {
  cursor: pointer;
}

.un-select-node {
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
}

.select-tab-popover-content {
  display: inline-block;
  padding: 0px 4px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid#ffadd2;
  color: #eb2f96;
  background: #fff0f6;
  margin: 0px 4px;
}

#my-tag-box .selected-node {
  background: #1890ff;
  color: #e6f7ff;
  border-color: #1890ff;
}

.select-tag-popover {
  min-width: 251px;
}

.select-tag-popover .ant-popover-inner-content {
  padding: 6px;
}

.select-tag-popover .ant-popover-content {
  box-shadow: rgba(197, 197, 197, 0.78) 0px 0px 6px 2px,
    rgba(158, 157, 157, 0.1) 1px 0px 7px 0px;
}

.select-tab-popover-content:hover {
  color: #eb58a9;
}

.select-tag-popover .ant-popover-content .ant-popover-inner {
  padding: 0px;
}

.select-tag-popover .tag-disabled {
  border: 1px solid#c5c5c5;
  color: #696969;
  background: #f9f9f9;
  cursor: no-drop;
}

.tag-tooltip {
  max-width: 260px;
}

.select-tag-popover {
  z-index: 1000000;
}

#tab-help-main {
  width: 100%;
  overflow: auto;
  padding: 10px;
}

.tab-help-item {
  line-height: 22px;
  width: 100%;
  margin: 4px 0px;
}

.tab-help-item>span {
  color: #0b3694;
}

.tab-help-Popover {
  z-index: 1000000000;
}

.tab-help-Popover .ant-popover-inner-content {
  padding: 0px 0px;
}

.tab-help-Popover .ant-popover-content {
  box-shadow: 0px 0px 8px 2px #969696;
}