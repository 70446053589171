/* === Service option panel === */
.service-option-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.service-option-panel .panel-modal-header {
  padding-left: 14px !important;
}

.service-option-title {
  font-size: 16px;
  font-weight: 500;
}

.service-option-panel .service-option-content {
  padding: 10px 16px;
}

.service-option-item {
  position: relative;
}

.service-option-item+.service-option-item {
  margin-top: 10px;
}

.service-option-name {
  font-weight: 500;
  line-height: 28px;
  width: 110px;
  display: inline-block;
  font-size: 13px;
}

.service-option-input {
  width: calc(100% - 110px);
  font-size: 13px;
  height: 28px;
}

.service-option-input .ant-input {
  font-size: 13px;
  height: 28px;
}