.pcb-channel-main .pcb-channel-signal-setting-icon {
  font-size: 17px;
  color: #0d87f7;
  vertical-align: middle;
  cursor: pointer;
  margin-left: 12px;
}

.pcb-channel-main .pcb-channel-edit-signal-label {
  vertical-align: middle;
  margin-left: 8px;
}

.channel-signals-pins-table .ant-table-small {
  /*   min-width: 1250px; */
  table-layout: fixed;
}

.channel-component-table .ant-table-thead>tr>th .ant-table-header-column,
.channel-signals-pins-table .ant-table-thead>tr>th .ant-table-header-column {
  display: block !important;
}

.channel-component-table .ant-table-thead>tr>th:nth-child(3) .ant-table-header-column .ant-table-column-title,
.channel-component-table .pcb-channel-rlc-div {
  padding-left: 4px
}

.channel-component-table .editable-cell-value-wrap,
.channel-signals-pins-table .editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.andes-v2-PG-table .ant-table-small .ant-table-container .aurora-table-body {
  padding-right: 14px;
}

.andes-v2-PG-table .ant-table-small .ant-table-container .ant-table-body .aurora-table-body {
  padding-right: 22px;
}

.andes-v2-PG-table tbody>tr>td:last-child,
.andes-v2-PG-table tbody>tr>td:first-child .editable-cell-value-wrap {
  position: relative;
}

.andes-v2-PG-table .ant-table-small .ant-table-container .power-nets-delete {
  position: absolute;
  right: -25px;
  top: 50%;
  margin-top: -7px;
  color: #ccc;
}

.andes-v2-PG-table .ant-table-small .ant-table-container .aurora-table-body .ant-table-row:hover .power-nets-delete,
.channel-signals-pins-table .ant-table-small .ant-table-container .aurora-table-body .channel-signal-name:hover .signal-delete-icon {
  color: #1890ff;
}

.channel-signals-pins-table .aurora-signal-background .aurora-group-background,
.channel-signals-pins-table .aurora-signal-background .channel-signal-name,
.channel-signals-pins-table .aurora-signal-background .aurora-group-background .editable-cell-value-wrap,
.channel-signals-pins-table .aurora-signal-background .aurora-group-background .editable-cell-value-wrap.ant-table-cell,
.channel-signals-pins-table .aurora-signal-background .channel-signal-name .editable-cell-value-wrap,
.channel-signals-pins-table .aurora-signal-background .channel-signal-name .editable-cell-value-wrap.ant-table-cell {
  background-color: #edf7ff;
}

.aurora-table.channel-signals-pins-table .ant-table-row.aurora-group-background:hover>td,
.aurora-table.channel-signals-pins-table .ant-table-row:hover>td.channel-signal-name,
.aurora-table.channel-signals-pins-table .ant-table-row.aurora-group-background:hover>td>.editable-cell-value-wrap,
.aurora-table.channel-signals-pins-table .ant-table-row:hover>td.channel-signal-name>.editable-cell-value-wrap,
.aurora-table.channel-signals-pins-table .ant-table-row.aurora-group-background:hover>td>.editable-cell-value-wrap.ant-table-cell,
.aurora-table.channel-signals-pins-table .ant-table-row:hover>td.channel-signal-name>.editable-cell-value-wrap.ant-table-cell {
  background-color: #e6f7ff;
}

.channel-signals-pins-table .aurora-signal-background .aurora-group-background .editable-cell-value-wrap,
.channel-signals-pins-table .aurora-signal-background .aurora-group-background .editable-cell-value-wrap.ant-table-cell,
.channel-signals-pins-table .aurora-signal-background .channel-signal-name .editable-cell-value-wrap,
.channel-signals-pins-table .aurora-signal-background .channel-signal-name .editable-cell-value-wrap.ant-table-cell {
  border-color: #edf7ff;
}

.channel-signals-pins-table .aurora-table-body>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background-color: inherit;
}

.channel-signals-pins-table tbody>tr .channel-signal-name {
  position: relative;
}

.channel-signals-pins-table tbody>tr .channel-signal-name .editable-cell-value-wrap {
  width: calc(100% - 14px);
}

.channel-signals-pins-table .ant-table-small .pcb-channel-table-checkBox {
  width: 35px;
  text-align: center;
}

.channel-signals-pins-table .ant-table-small .pcb-channel-table-checkBox .anticon {
  margin-left: 6px;
  color: #0d87f7;
}

.andes-v2-select-nets-icon {
  margin-left: 10px;
  font-size: 16px;
  color: #40a9ff;
  cursor: pointer;
  vertical-align: middle;
  margin-bottom: 2px;
}

.channel-add-signal-dropdown .ant-dropdown-menu-item {
  font-size: 13px;
}

.channel-add-signal-dropdown .ant-dropdown-menu-item:hover {
  color: #1890ff;
}

.channel-add-signal-icon {
  vertical-align: text-bottom;
}

.andes-v2-channel-select-dropdown-menu {
  padding: 0px;
  margin: 0px;
  max-height: 250px;
  margin-bottom: 0px !important;
}

.andes-v2-channel-select-dropdown-menu .ant-select-dropdown-menu-item {
  position: relative;
  padding-left: 30px;
}

.andes-v2-channel-select-dropdown-menu .ant-select-dropdown-menu-item .ant-select-selected-icon {
  text-align: left !important;
  left: 10px !important;
  font-weight: bold;
}

.channel-signals-pins-table .ant-table-thead>tr>th {
  white-space: nowrap;
}

.channel-signals-pins-table .aurora-table-body td {
  word-break: break-all;
}

.channel-component-table .ant-table>.ant-table-content .aurora-table-body .ant-table-row td {
  position: relative;
}

.cmc-connect-pins-icon {
  margin-left: 16px;
  color: #0d87f7;
  cursor: pointer;
}

.cmc-components-pins-columns {
  width: 100%;
  height: 100%;
}

.cmc-components-pins-columns-select-dropdown {
  z-index: 30000;
}

.pcb-channel-main {
  min-width: 1350px;
}

.setup-package-model-content .pkg-new-panel .spice-model-content>div {
  width: 100%;
}

.setup-package-model-content .spice-connect-content {
  overflow: auto;
  padding-bottom: 10px;
}

.package-model-columns-content {
  padding-right: 15px;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.package-model-columns-title {
  color: rgb(0, 0, 0, 0.35);
}

.package-model-jump-info-icon {
  width: 24px;
  color: #1ca4f3;
  font-size: 16px;
  position: absolute;
  right: 2px;
  top: calc(50% - 8px);
}

.setup-package-model-content {
  border: 1px solid #e8e8e8;
  padding: 10px 0;
  margin-bottom: 15px;
}

.setup-package-model-content:last-child {
  margin-bottom: 0px;
}

.setup-package-model-content .pkg-panel.pkg-new-panel {
  width: 100%;
}

.setup-package-model-content .package-setup-name-title {
  padding-left: 10px;
  color: #005a9e;
}

.setup-package-model-content .pkg-new-panel .spice-model-content .package-setup-name-divider {
  margin: 5px;
  width: 99%;
}

.setup-package-model-content .pkg-new-panel .pkg-model-select>*:first-child {
  position: relative;
}

.setup-package-model-content .spice-model-add-file-icon {
  position: absolute;
  right: 8px;
  top: 4px;
}

.setup-package-model-content .pkg-new-panel .pkg-model-select-width>.pkg-model-selection-div {
  width: calc(100% - 130px);
}

.andes-channel-diode-panel .create-channel-content {
  min-width: 400px;
}

.andes-channel-diode-panel .component-diode-pin-select {
  height: auto;
  min-height: 28px;
}

.andes-diode-component-pin-table .editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.andes-channel-diode-panel .diode-component-pins-apply-all-content {
  margin-top: 10px;
  width: 100%;
}

.andes-channel-diode-panel .diode-component-pins-apply-all-content>span {
  display: inline-block;
  width: 284px;
  font-size: 12px;
  vertical-align: middle;
}

.andes-channel-diode-panel .diode-component-pins-apply-all-content>.ant-checkbox-wrapper {
  vertical-align: middle;
}

.setup-package-model-content .pkg-new-panel .pkg-model-select-width>.pkg-model-selection-div {
  width: calc(100% - 130px);
}

.channel-advanced-title {
  color: #8c8c8c;
  font-size: 12px;
}

.channel-advanced-title>span {
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}

.channel-advanced-title>i {
  cursor: pointer;
}

.channel-setup-border {
  border: 1px solid #a8c4e6;
  padding: 10px;
  border-radius: 3px;
}

.setup-package-model-content .pkg-new-panel .pkg-model-selection-div .pkg-model-selection.ant-select-disabled .ant-select-selector {
  color: rgb(0, 0, 0, 0.88);
  background-color: #fefefe
}

.aurora-table .aurora-table-body .ant-table-row>td>.editable-cell-value-wrap {
  overflow: hidden;
}