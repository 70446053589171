.himalayas-simulation-title .aurora-simulation-holygrail {
  display: flex;
}

.himalayas-monitor-title-box {
  display: flex;
  padding-right: 10px;
}

.himalayas-simulation-title .span-msg-icon-div {
  margin-right: 10px;
  margin-top: 13px;
}

.himalayas-simulation-title .aurora-simulation-progress {
  line-height: 42px
}

.himalayas-simulation-title .aurora-simulation-span {
  line-height: 42px;
}

.himalayas-monitor-title-box .aurora-simulation-span .aurora-span-msg {
  white-space: pre;
}