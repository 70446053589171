.surface-roughness-panel {
  margin-top: 100px;
  min-width: 370px;
  overflow: auto;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.surface-roughness-panel .panel-modal-header {
  padding-left: 14px !important;
}

.surface-roughness-title {
  font-size: 16px;
  font-weight: 500;
}

.surface-roughness-panel .surface-roughness-content {
  padding: 10px 16px;
  padding-top: 2px;
}

.surface-roughness-panel .surface-roughness-item {
  margin: 6px 0px;
  line-height: 28px;
  height: 28px;
}

.surface-roughness-panel .surface-roughness-item-tabs {
  padding: 0px 8px;
}

.surface-roughness-content .surface-roughness-edit-value-label {
  display: inline-block;
  width: 164px;
  margin-right: 10px;
  vertical-align: middle;
}

.surface-roughness-panel .surface-roughness-select,
.surface-roughness-panel .surface-roughness-input {
  width: calc(100% - 174px);
  font-size: 13px;
  vertical-align: middle;
}

.surface-roughness-panel .surface-roughness-content .surface-roughness-edit-value-label-long {
  width: 300px;
  margin-right: 0px;
}

.surface-roughness-panel .surface-roughness-content .surface-roughness-item-half {
  width: calc(100% - 188px);
  display: inline-block;
}

.surface-roughness-panel .surface-roughness-content .surface-roughness-item-checkbox {
  width: 30px;
  vertical-align: middle;
}

.surface-roughness-panel .surface-roughness-content .aurora-model-name-error-msg {
  margin: 8px 0px;
}

/* tabs */
.surface-roughness-panel .surface-roughness-content .roughness-tabs .ant-tabs-bar {
  margin-bottom: 0px;
}

.surface-roughness-panel .surface-roughness-content .roughness-tabs .ant-tabs-content {
  border: 1px solid #E8E8E8;
  border-top: 0px;
  padding: 6px 0px;
}

.surface-roughness-panel .surface-roughness-content .roughness-tabs .ant-tabs-card-bar .ant-tabs-nav-container {
  height: 32px;
}

.surface-roughness-panel .surface-roughness-content .roughness-tabs .ant-tabs-card-bar .ant-tabs-tab {
  height: 32px;
  padding: 0px 10px;
  line-height: 30px;
}

.surface-roughness-panel .surface-roughness-content .roughness-tabs .ant-tabs-card-bar .ant-tabs-tab-active {
  height: 32px;
  padding: 0px 10px;
}

.surface-roughness-panel .surface-roughness-content .surface-roughness-divider {
  margin-top: 10px;
  margin-bottom: 2px;
}

.surface-roughness-panel .surface-roughness-content .surface-roughness-item-apply {
  margin: 0px 0px;
}

.surface-roughness-panel .surface-roughness-content .ant-tabs .ant-tabs-nav {
  margin: 0px;
}