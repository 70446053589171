.stimulus-setup-model {
  width: 100%;
  padding: 5px 10px;
}

.stimulus-setup-model-title {
  float: left;
  line-height: 24px;
  width: 100px;
}

.stimulus-setup-model .stimulus-setup-model-select {
  width: calc(100% - 100px);
  font-size: 13px;
}

.stimulus-setup-model-select.ant-select-single.ant-select-sm .ant-select-selector {
  font-size: 13px;
}

.stimulus-setup-model-select-dropdown {
  z-index: 100000000;
}

.stimulus-apply-model-title {
  width: 330px;
  display: inline-block;
  line-height: 24px;
}

.stimulus-setup-model-driver .stimulus-apply-model-title {
  width: 210px;
}