/* === Report panel === */
.sierra-report-panel {
  margin-top: 80px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.sierra-report-panel .panel-modal-header {
  padding-left: 14px !important;
}

.sierra-report-panel .report-panel-title {
  font-size: 16px;
  font-weight: 500;
}

.sierra-report-panel .report-content {
  padding: 10px 16px;
  border-radius: 6px 6px 0px 0px;
  height: 100%;
  min-width: 500px;
}

.sierra-report-panel .report-content .report-message-main {
  margin-top: 10px;
  position: relative;
  min-height: 27px;
}

.sierra-report-panel .report-content .report-item-title {
  font-weight: 500;
  line-height: 28px;
  width: 84px;
  display: inline-block;
  font-size: 13px;
  position: relative;
}

.sierra-report-panel .report-content .report-select {
  width: calc(100% - 84px);
  font-size: 13px;
  min-height: 28px;
  height: auto;
  margin: 6px 0px;
}

.sierra-report-select-dropdown {
  z-index: 10000000;
  max-height: 500px;
}

.sierra-report-select-dropdown .ant-select-dropdown-menu-item {
  padding: 4px 12px;
  font-size: 13px;
}

.sierra-report-panel .report-content .report-download-message {
  line-height: 28px;
  text-indent: 10px;
  border-radius: 4px;
  font-size: 14px;
  max-width: calc(100% - 110px);
  padding: 0px 8px;
}

.sierra-report-panel .report-content .report-download-button {
  position: absolute;
  right: 0px;
  bottom: 2px;
}

.sierra-report-panel .report-content .report-download-btn {
  width: 80px;
  height: 26px;
  padding: 0px;
  font-size: 13px;
  line-height: 24px;
}

.sierra-report-panel .report-content .report-progress {
  right: 40px;
  padding: 2px 0px;
  position: absolute;
  width: auto;
  left: 0px;
}

.sierra-report-panel .report-content .report-progress .ant-progress-inner {
  background-color: #c7e0f4;
}

.sierra-report-panel .report-content .report-progress .ant-progress-text {
  vertical-align: unset;
}

.sierra-report-panel .report-content .report-progress .ant-progress-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  -webkit-animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  content: '';
}

.sierra-report-panel .report-content .download-error {
  border: 1px solid #ffa39e;
  background-color: #fff1f0;
}

.sierra-report-panel .report-content .download-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}

.report-download-log-icon {
  margin-right: 10px;
  color: #1896ff;
}

.sierra-report-panel .sierra-download-report-log-panel {
  top: 46px;
}

.sierra-download-report-log-panel .aurora-msg-dialog-item {
  color: rgba(0, 0, 0, 0.85);
}

.sierra-report-panel .sierra-download-report-log-panel .aurora-msg-dialog-main {
  padding: 8px;
}

.sierra-report-advances-collapse {
  border: none;
  background-color: #fff;
  margin-bottom: 10px;
}

.sierra-report-advances-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 6px 10px 6px 0px;
  width: fit-content;
  color: #696969;
}

.sierra-report-advances-collapse>.ant-collapse-item {
  border-bottom: none;
}

.sierra-report-advances-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  left: 70px;
}

.sierra-report-advances-collapse .report-advances-header {
  font-size: 13px;
  font-weight: 500;
  height: 22px;
  color: #000000a6;
}

.sierra-report-advances-collapse .ant-collapse-content {
  border-top: none;
}

.sierra-report-advances-collapse .ant-collapse-content-box {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 10px 16px;
}

.sierra-report-advances-enable-title {
  font-size: 13px;
  font-weight: 500;
  width: 116px;
  line-height: 28px;
}

.sierra-report-advances-enable-options {
  display: flex;
  justify-content: space-between;
}

.sierra-report-advances-enable-select {
  width: calc(100% - 116px);
  min-height: 28px;
  line-height: 28px;
}

.sierra-report-advances-enable-select .ant-select-selection__rendered {
  line-height: 26px;
}

.sierra-report-advances-enable-select .ant-select-selection--multiple {
  min-height: 28px;
}

.sierra-report-advances-enable-select .ant-select-selection--multiple>ul>li,
.sierra-report-advances-enable-select .ant-select-selection--multiple .ant-select-selection__rendered>ul>li {
  height: 22px;
  margin-top: 3px;
  line-height: 20px;
}

.sierra-report-advances-enable-select-dropdown {
  z-index: 1000000;
}