.automatic-match-ports-panel .automatic-model-file-select {
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 8px 10px;
}

.automatic-match-ports-panel .automatic-model-file-add-box {
  width: 90px;
  line-height: 24px;
}

.automatic-match-ports-panel .automatic-model-file-select .automatic-model-file-type-box {
  line-height: 20px;
}

.automatic-match-ports-panel .automatic-model-selection-div {
  display: flex;
  width: calc(100% - 100px);
}

.automatic-match-ports-panel .automatic-model-selection-div .automatic-model-selection.ant-select {
  width: 100%;
  height: 24px;
  line-height: 22px;
}

.automatic-match-ports-panel .automatic-model-selection-div .automatic-model-selection.ant-select:last-child {
  margin-left: 10px;
}

.automatic-match-ports-panel .automatic-model-selection-div .automatic-model-selection.ant-select .ant-select-selection-item {
  height: 24px;
  line-height: 22px;
}

.automatic-model-selection-div .automatic-model-selection.ant-select .ant-select-selector {
  line-height: 22px;
}

.automatic-match-ports-radio label.ant-radio-wrapper,
.automatic-match-ports-radio-bottom label.ant-radio-wrapper,
.automatic-match-ports-radio-bottom>div {
  position: relative;
  width: 90px;
}

.automatic-match-ports-panel .radio-item-content {
  position: absolute;
  top: 0;
  left: 15px;
}

.automatic-match-ports-panel .radio-item-div {
  display: flex;
}

.automatic-match-ports-panel .radio-item-div div {
  padding: 0 15px;
  width: 38px;
}

.automatic-match-ports-panel .radio-item-div .radio-item-div-right {
  border-left: 1px solid;
}

.automatic-match-ports-content {
  padding: 7px 5px;
}

.ant-radio-group.ant-radio-group-outline .automatic-match-ports-radio,
.automatic-match-ports-radio-bottom {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.ant-radio-group.ant-radio-group-outline .automatic-match-ports-radio {
  height: 100px;
}

/* .automatic-match-ports-radio-group {
  width: 100%;
} */

.automatic-footer-content {
  display: flex;
  padding: 10px 16px 5px;
  justify-content: center;
  border-top: 1px solid #e8e8e8;
  /*   margin-top: 80px; */
}

.automatic-footer-content .ant-btn {
  height: 28px;
  width: 75px;
}

.automatic-match-ports-radio-group {
  display: flex;
  flex-wrap: wrap;
  column-gap: 50px;
  width: 100%;
}

.automatic-match-ports-radio-group>.ant-radio-wrapper {
  position: relative;
  flex: 0 1 calc(33.33% - 50px);
  height: 90px;
}

.automatic-match-ports-radio-group>.ant-radio-wrapper.radio-text {
  height: auto;
}

.automatic-match-ports-radio-content {
  display: flex;
  position: absolute;
  top: 0px;
  left: 10px
}

.automatic-match-ports-vLine {
  width: 0px;
  height: 84px;
  border-left: 1px solid black;
}

.automatic-match-ports-vLine-left,
.automatic-match-ports-vLine-right {
  width: 10px;
  word-wrap: break-word;
  white-space: normal;
  margin: 0 15px;
}