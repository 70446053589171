.my-tree {
  position: absolute;
  bottom: 42px;
  top: 0px;
  left: 4px;
  right: 0px;
  background: #f9f9f9;
  overflow: hidden;
  margin-top: 7px;
}

.my-tree:hover {
  overflow-y: auto;
}

.my-tree .expand-icon-large {
  font-weight: bold;
  color: #001529;
}

.my-tree .expand-icon-middle {
  font-weight: 500;
}

.my-tree .tree-node {
  line-height: 24px !important;
  height: 24px;
  padding: 0px;
  margin-bottom: 4px;
}


/* .my-tree .tree-node .ant-tree-indent .ant-tree-indent-unit {
  width: 10px;
} */

.my-tree .tree-node .ant-tree-node-content-wrapper {
  padding-left: 4px;
}

.my-tree .tree-node .ant-tree-node-content-wrapper,
.my-tree .tree-node .ant-tree-title {
  height: 24px;
}

.my-tree .ant-tree-iconEle,
.my-tree .ant-tree-switcher {
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.65);
}

.my-tree .ant-tree-switcher {
  margin-inline-end: 0px;
  height: 24px;
  line-height: 24px;
}

.my-tree .ant-tree-switcher:before {
  height: 24px;
  line-height: 24px;
}

.tree-node {
  width: 100%;
  display: block;
}

.tree-node {
  position: relative;
}

.tree-node .ant-tree-title {
  width: 100%;
  display: inline-block;
  /* padding-right: 16px; */
}

.tree-node .ant-tree-title>span:not(.anticon) {
  display: inline-block;
  width: 100%;
  line-height: 24px;
  height: 24px;
  /*   overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
}

.tree-node .ant-tree-title .tooltip-span {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.my-tree .tree-node div .ant-tree-node-content-wrapper {
  cursor: unset;
}

.my-tree .my-tree-switch-icon {
  vertical-align: middle;
  font-size: 12px;
  color: rgb(151, 151, 151);
  margin-top: 0px;
}

.my-tree .tree-icon-node .ant-tree-switcher {
  line-height: 24px !important;
}

.my-tree .my-tree-opened-icon {
  color: rgba(60, 95, 165);
}

.tree-file-folder-icon {
  font-size: 14px !important;
  padding-top: 3px;
  display: inline-block;
}

/* Modify the antd default styled  */
.my-tree .tree-node-project-name[draggable],
.my-tree .tree-node-project-name[draggable='true'] {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

/* Repair cannot drag the node is not selected but the background color appears*/
.my-tree .ant-tree-treenode-switcher-close.ant-tree-treenode-selected>.ant-tree-node-content-wrapper.draggable {
  background-color: #bae7ff;
}

.my-tree .ant-tree-treenode-switcher-open.ant-tree-treenode-selected>.ant-tree-node-content-wrapper.draggable {
  background-color: #bae7ff;
}

.my-tree div .ant-tree-node-content-wrapper.draggable.ant-tree-node-selected {
  background-color: transparent;
}

.ant-tree .ant-tree-treenode-selected.andes-pcb-channel-node-name .ant-tree-node-content-wrapper.draggable.ant-tree-node-selected,
.my-tree .ant-tree-treenode-selected.andes-end-to-end-channel-children .ant-tree-node-content-wrapper.draggable.ant-tree-node-selected {
  background-color: #bae7ff;
}

.my-tree .tree-node .ant-tree-node-content-wrapper {
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ant-tree .andes-pcb-channel-node-name .ant-tree-node-content-wrapper.draggable.ant-tree-node-selected {
  background-color: #bae7ff;
}

.ant-tree .ant-tree-treenode-selected.andes-end-to-end-channel-children .ant-tree-node-content-wrapper.draggable.ant-tree-node-selected {
  background-color: #bae7ff;
}

.my-tree .expand-icon-large>.ant-tree-switcher .ant-tree-switcher-icon {
  font-size: 12px !important;
}

.my-tree .ant-tree-drop-indicator {
  bottom: 0px !important;
  left: 14px !important;
}

.my-tree .ant-tree-child-tree>li:first-child {
  padding-top: 0px;
}

.my-tree .my-tree-folder-icon {
  cursor: pointer;
}

.my-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging:after {
  border: 0px;
}