.waveform {
  width: 100%;
  height: 100%;
}

.waveform-clear {
  zoom: 1;
}

.waveform .waveform-clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.waveform .waveform-left {
  height: 100%;
  float: left;
  width: 100%;
}

.waveform .waveform-left-spin {
  height: 100%;
  max-height: 100%;
}

.waveform .waveform-svg {
  height: 100%;
}

.waveform .ant-spin-nested-loading,
.waveform .ant-spin-container {
  height: 100%;
  width: 100%;
}


.waveform .ant-spin-nested-loading>div>.ant-spin {
  height: 100%;
  width: 100%;
  max-height: 100%;
}

.waveform .waveform-setting {
  border-left: 1px solid #e8e8e8;
  background-color: #f9f9f9;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 30%;
}

.waveform .waveform-setting .dividingLine {
  height: 100%;
}

.waveform .waveform-setting>.ant-checkbox-group>.ant-checkbox-wrapper {
  margin-left: 0px;
  display: inline-block;
  width: 100%;
  height: 22px;
  line-height: 22px;
}

/* Setting */
.waveform .waveform-setting-box {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.waveform .waveform-setting-box-setup {
  padding: 10px;
}

.waveform .display-mode-title {
  font-weight: bold;
  margin-right: 8px;
  color: rgb(0, 0, 0, 0.65);
}

.waveform .waveform-setting-title {
  margin: 0px;
}


/* waveform selection */
.waveform .waveform-checkboxgroup {
  width: 100%;
  overflow-y: auto;
  background-color: #fafafa;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  padding-left: 10px;
  padding-top: 10px;
  position: absolute;
  bottom: 210px;
  top: 0px;
  flex-direction: column;
  flex-wrap: nowrap;
}

.waveform .waveform-checkboxgroup .waveform-checkbox {
  margin-left: 0px;
  /* margin-bottom: 6px;
  margin-top: 4px; */
}

.waveform .waveform-checkboxgroup .waveform-checkbox .ant-checkbox {
  float: left;
  margin-right: 5px;
  margin-left: 5px;
  top: -1px
}

.waveform .waveform-signal-name-down-icon {
  color: rgba(0, 0, 0, .44);
  font-size: 10px;
  padding-right: 9px;
  padding-left: 3px;
}

.waveform .waveform-color-select {
  padding: 0px;
  background-color: #fafafa;
  border: 0px;
  width: 18px;
  height: 22px;
  cursor: pointer;
  display: block;
  float: left;
  outline: none;
}

.waveform .waveform-checkbox span {
  padding: 0px;
}

.waveform .waveform-checkbox-lable {
  width: 100%;
  height: 22px;
  line-height: 22px;
  display: inline-flex;
  align-items: center;
}

.waveform .waveform-checkbox-lable .ant-color-picker-trigger {
  flex: none;
}

.waveform-sweep-checkbox-label {
  height: 22px;
  line-height: 22px;
  display: inline-flex;
}

.waveform .waveform-tooltip {
  width: 100%;
  height: 22px;
  line-height: 22px;
  display: block;
}

.waveform .waveform-checkbox {
  background: #fafafa;
}

.waveform .waveform-checkbox:hover {
  background: #dbdbdb;
}

.waveform .waveform-checkbox:hover .waveform-color-select {
  background-color: #dbdbdb;
}

.waveform .waveform-check-lable {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  float: left;
  margin-left: 4px;
  margin-right: 2px;
}

.waveform .waveform-comp-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  float: left;
  max-width: 150px;
  min-width: 70px;
}


.waveform .waveform-current {
  float: left;
  margin-left: 4px;
}

.waveform .waveform-signal-name {
  display: block;
  padding-left: 5px;
  cursor: pointer;
  background: #fafafa;
  transition: all 0.3s;
}

.waveform-net-name {
  display: block;
  padding-left: 15px;
  cursor: pointer;
  background: #fafafa;
  transition: all 0.3s;
}

.waveform-sweep-signal-name {
  display: block;
  padding-left: 25px;
  cursor: pointer;
  background: #fafafa;
  transition: all 0.3s;
}

.waveform-select-label {
  margin-bottom: 3px;
  margin-top: 3px;
}

.waveform .waveform-net-name .waveform-sweep-signal-name .waveform-signal-name:hover {
  background: #dbdbdb;
  transition: all 0.3s;
}

/* 2D curve plotting */
.plot .domain {
  display: none;
}

.plot .curve {
  fill: none;
}

.plot .tick line {
  stroke: #ccc;
}

.selector-backgroud {
  fill: #fff;
  stroke: #666;
}

.selector-slide {
  cursor: move;
  fill: #337ab7;
}

.selector-traveller {
  cursor: col-resize;
  fill: #444;
}

.range-text-style {
  font-size: 10px;
  color: #555;
}

.curve-mark {
  fill: #ff0000;
}

.mark-text {
  font-size: 10px;
  text-anchor: middle;
}

/* 2D curve plotting end  */