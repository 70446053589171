@font-face {
  font-family: "IndieFlower";
  src: url("/fonts/IndieFlower-Regular.ttf")format("truetype")
}

@font-face {
  font-family: "PingFang";
  src: url("/fonts/PingFang-SC-Regular.ttf")format("truetype")
}

.clear {
  zoom: 1;
}

.clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.aurora-home-page {
  position: relative;
  background-color: #f4f4f4;
  margin-top: 63px;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

.aurora-home-page-en {
  position: absolute;
  top: 54px;
  bottom: 0;
  width: 100%;
}

.aurora-home-page-en>.home-banner,
.aurora-home-page-en>.home-banner>.overlay {
  height: 100%;
}

.overlay {
  position: absolute;
  background-color: #212529;
  height: 550px;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}

.home-banner h1,
.home-banner h2,
.home-banner h3 {
  color: #ffffff;
}

.home-banner h1 {
  font-size: 48px;
  margin-left: 20%;
  top: 130px;
}

.home-banner h2,
.home-banner .home-banner-comming {
  font-size: 32px;
  margin-left: 20%;
  top: 230px;
  opacity: 0.8;
}

.home-banner .home-banner-comming {
  top: 350px;
}

.home-banner {
  width: 100%;
  height: 550px;
  /* -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
  /* display: flex;
  justify-content: center;
  vertical-align: middle; */
  display: relative;
}

.home-banner-background {
  background: url("/lib/imgs/aurora-bg.png") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.home-banner-lcfc {
  background: url("/lib/imgs/lcfc_bg.png") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.aurora-home-page-en>.home-banner-lcfc>.overlay {
  opacity: 0;
}

.home-footer {
  text-align: center;
  width: 100%;
}

.home-banner-title,
.home-banner-content,
.home-banner-comming {
  position: absolute;
  z-index: 100;
}

.home-content-2 {
  background: #ffffff;
}

.home-content-description {
  padding: 32px;
}

.comming-soon {
  position: relative;
  width: 100%;
  background: url("/lib/imgs/aurora-bg.png") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  text-align: center;
  height: 300px;
}

.comming-soon h3 {
  position: absolute;
  font-size: 38px;
  color: #ffffff;
  width: 100%;
  top: 112px;
  text-align: center;
}

.overlay-comming {
  position: absolute;
  background-color: #212529;
  height: 300px;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}

.home-footer {
  text-align: center;
  padding: 64px 0;
}

.home-footer>ul {
  width: 100px;
  height: 24px;
  margin: 0 auto;
  padding: 0;
}

.home-footer>ul>li {
  float: left;
  height: 24px;
  line-height: 24px;
  font-size: 16px;
}

.home-footer p {
  margin-bottom: 0px;
}

.home-page-about {
  margin-top: 64px;
}

.rounded-circle {
  border-radius: 50% !important;
}

.home-page-about {
  background: #ffffff;
  padding: 50px;
}

.home-page-about h1 {
  font-size: 42px;
}

.home-page-about p {
  font-size: 16px;
}

.homt-about-team {
  width: 350px;
}

.home-page-about h2 {
  font-size: 32px;
}

.homt-about-team img {
  margin: 0 auto;
}

.home-icon {
  font-size: 70px;
  color: #1890ff;
}

.home-content-1 {
  text-align: center;
  margin: 112px auto;
}

.home-content-1 h3,
.home-content-2 h3 {
  font-size: 28px;
  color: #212529;
}

.home-content-2 h3 {
  text-align: left;
}

.home-content-1 h3 {
  text-align: center;
  margin-top: 14px;
}

.home-content-1 p,
.home-content-2 p {
  color: #212529;
  font-size: 18px;
}

.sierra-img {
  width: 300px;
  transition: 0.3s;
  border-radius: 20px;
  padding: 10px 15px;
}

.sierra-img img {
  width: 100%;
  opacity: 0.8;
}

.sierra-img:hover,
.pdn-img:hover,
.sierra-name:hover,
.pdn-name:hover,
.home-page-name:hover,
.rocky-name:hover,
.andes-v2-name:hover,
.andes-v2-logo:hover,
.andes-img:hover,
.fastpi-name:hover,
.cascade-name:hover,
.himalayas-logo:hover,
.himalayas-name:hover {
  background: #ffffff10;
  transition: 0.3s;
  box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 4px 2px, rgba(255, 255, 255, 0.2) 0px 0px 2px 2px;
}

.sierra-img:hover img {
  opacity: 1;
}

.pdn-img {
  width: 300px;
  transition: 0.3s;
  border-radius: 20px;
  padding: 10px 15px;
}

.pdn-img img {
  width: 100%;
  opacity: 0.8;
}

.pdn-img:hover img {
  opacity: 1;
}

.sierra-name {
  position: absolute;
  left: 50%;
  width: 50%;
  top: 40%;
  transform: translateX(-50%) translateY(-50%);
  transition: 0.3s;
  border-radius: 20px;
}

.sierra-name img {
  width: 100%;
  opacity: 0.8;
}

.sierra-name:hover img {
  opacity: 1;
}

.pdn-name {
  position: absolute;
  left: 50%;
  top: 40%;
  width: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: 0.3s;
  border-radius: 20px;
}

.pdn-name img {
  width: 100%;
  opacity: 0.8;
}

.pdn-name:hover img {
  opacity: 1;
}

.aurora-home {
  padding-top: 20px;
  margin-left: 90px;
  position: relative;
  /* width: 300px; */
}

.aurora-home-page-en .aurora-home-flex {
  flex-wrap: wrap;
  display: flex;
}

.aurora-home-page-en .aurora-home-flex .home-page {
  width: 33.33%;
}

.aurora-home-page-en .aurora-home-flex .home-page-name {
  margin: 0px 16px;
}

/* .aurora-home .rocky-logo {
  width: 214px;
}

.aurora-home .andes-logo {
  width: 245px;
}

.aurora-home .cascade-logo {
  width: 312px;
} */

.aliyunHome {
  position: absolute;
  bottom: 0px;
  text-align: center;
  width: 100%;
}

.aliyunHome-beianhao {
  display: inline-block;
  text-decoration: none;
  height: 40px;
  line-height: 40px;
  color: #939393;
}

.aliyunHome-beianhao-1 {
  display: inline-block;
  text-decoration: underline;
  height: 40px;
  line-height: 40px;
  color: #939393;
  margin-left: 10px;
}

.home-page-name {
  transition: 0.3s;
  border-radius: 20px;
  height: 80px;
  padding: 10px 22px 10px 15px;
  cursor: pointer;
  width: fit-content;
}

.home-page-name div {
  font-family: 'IndieFlower';
  font-size: 70px;
  transition: 0.3s;
  line-height: 80px;
  height: 60px;
  color: #ed7d30;
  opacity: 0.8;
}

.home-page-name img,
.rocky-name img {
  width: 100%;
  opacity: 0.8;
}

.home-page-name:hover img,
.home-page-name:hover div,
.rocky-name:hover img {
  opacity: 1;
}

.rocky-name,
.andes-v2-home,
.fastpi-name,
.cascade-name,
.himalayas-name {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translateX(-50%) translateY(-50%);
  transition: 0.3s;
  border-radius: 20px;
  text-align: center;
  padding: 0px;
  cursor: pointer;
}

.fastpi-name {
  width: 54%;
  max-width: 988px;
  min-width: 340px;
}

.cascade-name {
  width: 66%;
  max-width: 1230px;
  min-width: 340px;
}

.rocky-name {
  width: 44%;
  max-width: 850px;
  text-align: center;
  padding: 0px;
}

.andes-v2-name {
  width: 44%;
  max-width: 850px;
  text-align: center;
  padding: 0px;
}

.himalayas-name {
  width: 100%;
  max-width: fit-content;
  text-align: center;
  padding: 0px;
}

.andes-img,
.andes-v2-logo {
  margin-top: 40px;
  width: 354px;
  transition: 0.3s;
  border-radius: 20px;
  padding: 10px 15px;
}

.andes-img img,
.andes-name img {
  width: 100%;
  opacity: 0.8;
}

.andes-img:hover img,
.andes-name:hover img {
  opacity: 1;
}

.andes-name {
  position: absolute;
  left: 50%;
  top: 40%;
  width: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: 0.3s;
  border-radius: 20px;
}


.home-page-img-div,
.home-page-img-div-fastpi {
  font-family: 'IndieFlower';
  transition: 0.3s;
  border-radius: 20px;
  padding: 10px 15px;
  height: 250px;
  line-height: 274px;
  color: #ed7d30;
  opacity: 0.8;
  font-size: 300px;
  transition: font-size 0.2s ease-out;
}

.home-page-img-div-fastpi {
  height: 280px;
  line-height: 330px;
  padding-left: 25px;
}

.rocky-name:hover div,
.andes-v2-name:hover div,
.fastpi-name:hover div,
.cascade-name:hover div {
  opacity: 1;
}

.home-page {
  position: relative;
  margin-top: 30px;
}

.home-description {
  position: absolute;
  color: #eee4b4;
  font-size: 19px;
  width: 800px;
  left: 318px;
  /*  top: 26px; */
  top: 0px;
  height: 80px;
  line-height: 80px;
}

.home-description>span {
  display: inline-block;
  line-height: 20px;
}

.home-description-below {
  position: absolute;
  color: #eee4b4;
  font-size: 19px;
  width: 58%;
  top: 59%;
  left: 34%;
}

.home-page:hover .home-description {
  text-shadow: 0 0 0px #f0e715d1, 0 0 1px #f0e715d1, 0 0 0px #f0e715d1, 0 0 0px #f0e715d1;
  transition: 0.3s;
}

.home-banner-lcfc .aurora-home {
  margin-left: 150px
}

.home-banner-lcfc .home-page-name div {
  color: rgba(69, 246, 255, 1);
  font-family: PingFang;
  font-size: 60px;
  line-height: 60px;
}

.home-banner-lcfc .home-page-name {
  text-align: center;
  border-radius: 40px;
}

.home-banner-lcfc .home-page-name:hover {
  background: rgba(66, 190, 255, 0.1);
  box-shadow: rgba(131, 213, 255, 0.4) 0px 0px 4px 2px, rgba(131, 213, 255, 0.4) 0px 0px 2px 2px;
}

@media only screen and (max-width: 1850px) {
  .home-page-img-div {
    font-size: 294px;
    height: 234px;
    line-height: 258px;
  }

  .home-page-img-div-fastpi {
    font-size: 294px;
    height: 264px;
    line-height: 320px;
  }

  .home-description-below {
    left: 36%;
  }
}

@media only screen and (max-width: 1800px) {
  .home-page-img-div {
    font-size: 288px;
    height: 234px;
    line-height: 258px;
  }

  .home-page-img-div-fastpi {
    font-size: 288px;
    height: 254px;
    line-height: 310px;
  }

  .home-description-below {
    left: 35%;
  }
}

@media only screen and (max-width: 1760px) {

  .home-page-img-div {
    font-size: 280px;
    height: 234px;
    line-height: 258px;
  }

  .home-page-img-div-fastpi {
    font-size: 268px;
    height: 254px;
    line-height: 300px;
  }
}

@media only screen and (max-width: 1700px) {
  .home-page-img-div {
    font-size: 268px;
    height: 224px;
    line-height: 250px;
  }

  .home-page-img-div-fastpi {
    height: 244px;
    line-height: 290px;
  }
}

@media only screen and (max-width: 1650px) {
  .home-page-img-div {
    font-size: 260px;
    height: 224px;
    line-height: 250px;
  }

  .home-page-img-div-fastpi {
    font-size: 260px;
    height: 244px;
    line-height: 290px;
  }
}

@media only screen and (max-width: 1600px) {

  .home-page-img-div {
    font-size: 254px;
    height: 220px;
    line-height: 244px;
  }

  .home-page-img-div-fastpi {
    font-size: 254px;
    height: 240px;
    line-height: 294px;
  }

  .home-description-below {
    left: 32%;
  }
}

@media only screen and (max-width: 1550px) {

  .home-page-img-div {
    font-size: 244px;
    height: 220px;
    line-height: 244px;
  }

  .home-page-img-div-fastpi {
    font-size: 244px;
    height: 230px;
    line-height: 264px;
  }
}

@media only screen and (max-width: 1500px) {

  .home-page-img-div {
    font-size: 238px;
    height: 220px;
    line-height: 244px;
  }

  .home-page-img-div-fastpi {
    font-size: 238px;
    height: 230px;
    line-height: 274px;
  }
}

@media only screen and (max-width: 1480px) {

  .home-page-img-div {
    font-size: 230px;
    height: 200px;
    line-height: 215px;
  }

  .home-page-img-div-fastpi {
    font-size: 230px;
    height: 220px;
    line-height: 255px;
  }
}

@media only screen and (max-width: 1450px) {

  .home-page-img-div {
    font-size: 227px;
    height: 200px;
    line-height: 215px;
  }

  .home-page-img-div-fastpi {
    font-size: 227px;
    height: 220px;
    line-height: 255px;
  }
}

@media only screen and (max-width: 1400px) {

  .home-page-img-div {
    font-size: 220px;
    height: 200px;
    line-height: 215px;
  }

  .home-page-img-div-fastpi {
    font-size: 220px;
    height: 210px;
    line-height: 245px;
  }
}


@media only screen and (max-width: 1350px) {

  .home-page-img-div {
    font-size: 208px;
    height: 180px;
    line-height: 204px;
  }

  .home-page-img-div-fastpi {
    font-size: 208px;
    height: 200px;
    line-height: 230px;
  }
}

@media only screen and (max-width: 1300px) {

  .home-page-img-div {
    font-size: 200px;
    height: 176px;
    line-height: 200px;
  }

  .home-page-img-div-fastpi {
    font-size: 200px;
    height: 190px;
    line-height: 225px;
  }
}

@media only screen and (max-width: 1250px) {

  .home-page-img-div {
    font-size: 188px;
    height: 156px;
    line-height: 174px;
  }

  .home-page-img-div-fastpi {
    font-size: 188px;
    height: 180px;
    line-height: 214px;
  }
}

@media only screen and (max-width: 1200px) {

  .home-page-img-div {
    font-size: 182px;
    height: 150px;
    line-height: 168px;
  }

  .home-page-img-div-fastpi {
    font-size: 182px;
    height: 170px;
    line-height: 200px;
  }
}

@media only screen and (max-width: 1150px) {
  .home-page-img-div {
    font-size: 178px;
    height: 150px;
    line-height: 166px;
  }

  .home-page-img-div-fastpi {
    font-size: 178px;
    height: 170px;
    line-height: 196px;
  }
}

@media only screen and (max-width: 1100px) {
  .home-page-img-div {
    font-size: 166px;
    height: 140px;
    line-height: 152px;
  }

  .home-page-img-div-fastpi {
    font-size: 166px;
    height: 160px;
    line-height: 186px;
  }
}

@media only screen and (max-width: 1050px) {
  .home-page-img-div {
    font-size: 162px;
    height: 135px;
    line-height: 142px;
  }

  .home-page-img-div-fastpi {
    font-size: 162px;
    height: 150px;
    line-height: 176px;
  }
}

@media only screen and (max-width: 1000px) {

  .home-page-img-div,
  .home-page-img-div-fastpi {
    font-size: 156px;
    height: 135px;
    line-height: 142px;
  }

  .home-page-img-div-fastpi {
    font-size: 145px;
    height: 150px;
    line-height: 176px;
  }
}

@media only screen and (max-width: 950px) {
  .home-page-img-div {
    font-size: 145px;
    height: 128px;
    line-height: 138px;
  }

  .home-page-img-div-fastpi {
    font-size: 145px;
    height: 138px;
    line-height: 156px;
  }

  .home-description {
    width: 500px;
  }
}

@media only screen and (max-width: 900px) {

  .home-page-img-div,
  .home-page-img-div-fastpi {
    font-size: 138px;
    height: 128px;
    line-height: 138px;
  }

  .home-page-img-div-fastpi {
    font-size: 138px;
    height: 136px;
    line-height: 150px;
  }
}

@media only screen and (max-width: 850px) {

  .home-page-img-div {
    font-size: 130px;
    height: 120px;
    line-height: 130px;
  }

  .home-page-img-div-fastpi {
    font-size: 130px;
    height: 130px;
    line-height: 140px;
  }

  .home-description {
    width: 430px;
  }

  .aurora-home-page-en .aurora-home-flex .home-page {
    width: 50%;
  }
}


@media only screen and (max-width: 800px) {
  .home-page-img-div {
    font-size: 120px;
    height: 110px;
    line-height: 116px;
  }

  .home-page-img-div-fastpi {
    font-size: 120px;
    height: 120px;
    line-height: 130px;
  }

  .home-description {
    width: 400px;
  }
}

@media only screen and (max-width: 750px) {

  .home-page-img-div {
    font-size: 114px;
    height: 105px;
    line-height: 110px;
  }

  .home-page-img-div-fastpi {
    font-size: 114px;
    height: 110px;
    line-height: 120px;
  }

  .home-description {
    width: 360px;
  }
}

@media only screen and (max-width: 700px) {

  .home-page-img-div,
  .home-page-img-div-fastpi {
    font-size: 104px;
    height: 100px;
    line-height: 104px;
  }

  .home-page-img-div-fastpi {
    font-size: 104px;
    height: 100px;
    line-height: 104px;
  }

  .home-description {
    width: 300px;
  }
}

@media only screen and (max-width: 650px) {

  .home-page-img-div {
    font-size: 95px;
    height: 80px;
    line-height: 80px;
  }

  .home-page-img-div-fastpi {
    font-size: 95px;
    height: 90px;
    line-height: 90px;
  }
}


@media only screen and (max-width: 600px) {

  .home-page-img-div,
  .home-page-img-div-fastpi {
    font-size: 85px;
    height: 75px;
    line-height: 75px;
  }
}

@media only screen and (max-height: 1000px) {

  .home-banner-lcfc .aurora-home {
    margin-left: 150px;
  }

  .home-banner-lcfc .home-page-name div {
    font-size: 50px;
    line-height: 50px;
  }
}

@media only screen and (max-height: 900px) {

  .home-banner-lcfc .aurora-home {
    margin-left: 150px;
  }

  .home-banner-lcfc .home-page-name {
    height: 70px;
  }

  .home-banner-lcfc .home-page-name div {
    font-size: 45px;
    line-height: 45px;
  }
}

@media only screen and (max-height: 850px) {

  .home-page-name {
    height: 64px;
  }

  .home-page-name div {
    font-size: 50px;
    line-height: 50px;
  }

  .home-banner-lcfc .home-page-name {
    height: 64px;
  }

  .home-banner-lcfc .home-page-name div {
    font-size: 40px;
    line-height: 40px;
  }
}

@media only screen and (max-height: 800px) {

  .home-page-name {
    height: 50px;
  }

  .home-page-name div {
    font-size: 40px;
    line-height: 40px;
  }

  .home-banner-lcfc .home-page-name {
    height: 60px;
  }

  .home-banner-lcfc .home-page-name div {
    font-size: 36px;
    line-height: 36px;
  }
}