.stackup-multi-zone {
  margin: 0px 6px;
  width: 65px;
}

.stackup-multi-zone-panel {
  z-index: 1010;
  background: #fff;
  position: absolute;
  /*  width: 300px; */
  bottom: 34px;
  right: 5px;
}

.stackup-zones-display-icon,
.stackup-zones-edit-icon {
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.stackup-zones-display-highlight-icon,
.stackup-zones-edit-icon {
  color: #1890ff;
}

.stackup-multi-zone-content {
  display: flex;
}

.stackup-multi-zone-body {
  border: 1px solid #63b4ff;
  border-radius: 4px;
  padding: 10px;
  margin: 10px;
}

.stackup-multi-zone-body>span {
  font-weight: bold;
  padding-bottom: 10px;
  display: inline-block;
}

.stackup-multi-zone-panel .panel-body {
  width: fit-content !important;
}