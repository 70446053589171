.my-sierra-layout {
  position: absolute;
  top: 54px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.aurora-content {
  background-color: #ffffff;
  position: absolute;
  top: 0px;
  left: 240px;
  right: 0px;
  bottom: 0px;
}

.my-sierra-content {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
}

.my-sierra-content>.ant-spin-nested-loading,
.my-sierra-content>.ant-spin-nested-loading>.ant-spin-container {
  width: 100%;
  height: 100%;
}

.sierra-sider {
  position: relative;
  width: 240px;
  background: #f9f9f9;
  height: 100%;
  border-right: 1px solid #e2dfdf;
}

.sierra-sider-footer {
  width: 100%;
  position: absolute;
  box-sizing: border-box;
  bottom: 0;
  height: 42px;
  border-top: 1px solid #e2dfdf;
}

.sierra-close-box {
  float: left;
  margin: 0;
  margin-right: 10px;
  padding: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  color: #0d87f7;
  text-align: center;
  transition: 0.3s;
  box-shadow: #fff 0px 0px 0px 0px;
}

.sierra-sider-layout {
  line-height: 36px;
  font-size: 19px;
}

.sierra-sider-footer .sierra-close-box:hover {
  color: #379af5;
  box-shadow: rgba(168, 204, 241, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.18) 0px 0px 5px 2px;
  transition: 0.3s;
}

.sierra-sider-footer .sierra-sider-monitor {
  line-height: 34px;
  font-size: 22px;
}

.mg-r-8 {
  margin-right: 8px;
}

.project-name-input .ant-input-group {
  float: left;
  width: 100%;
}

.project-name-input .project-name {
  height: 24px;
}

.project-name-input .project-name .ant-input {
  height: 24px;
}

.tree-node .ant-tree-title>.verification-name-input {
  position: absolute;
  right: 8px;
  left: 0px;
  top: 8px;
  width: auto;
}

.verification-name-input .ant-input-group {
  float: left;
  width: 100%;
}

.verification-name-input .ant-input-group>input {
  height: 24px;
}

.sierra-sider-menu-tree .ant-tree-title .verification-name-input .aurora-tree-create-icon {
  visibility: visible;
  position: static;
}

.project-name .ant-input-group-addon {
  width: 56px;
  padding: 0 8px;
}

.sierra-tooltip-span {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.anticon.anticon-exclamation-circle.debug-design-icon {
  visibility: initial;
  font-size: 15px;
  line-height: 36px;
  color: #ff0000;
}

span.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal:hover .debug-design-icon {
  right: 36px;
}

.aurora-design-tooltip .ant-tooltip-inner {
  color: #ff0000;
}

.sierra-debug-tooltip-span {
  display: inline-block;
  max-width: 88%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  color: #ff0000;
}

.sierra-create-name {
  width: 100%;
}

.sierra-sider-menu-tree .ant-tree-title .ant-checkbox-wrapper {
  position: absolute;
  left: 3px;
  top: 2px;
}

.sierra-sider-menu-tree .ant-tree-title:hover .sierra-project-title {
  padding-right: 72px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sierra-sider-menu-tree .ant-tree-title:hover .sierra-sweep-title {
  padding-right: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sierra-sider-menu-tree .ant-tree-title .sierra-project-title,
.sierra-sider-menu-tree .ant-tree-title .sierra-sweep-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.sierra-sider-menu-tree .ant-tree-title:hover .simulation-btn-disable,
.sierra-sider-menu-tree .ant-tree-title:hover .simulation-btn {
  right: 38px;
}

.result-style {
  padding-left: 12px;
  font-style: italic;
}

.sierra-delete-content {
  width: 196px;
}

.sierra-delete-title {
  display: block;
  font-size: 14px;
  color: #f12727;
}

.sierra-delete-confirm {
  color: #1890ff;
}

.sierra-delete-confirm:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.sierra-delete-pop-confirm .ant-popover-buttons {
  display: none;
}

.sierra-delete-pop-confirm .ant-popover-inner-content {
  padding: 8px 16px;
}

.sierra-delete-pop-confirm .ant-popover-message {
  padding-bottom: 0;
}

.sierra-delete-pop-confirm .ant-popover-message>.sierra-confirm-icon {
  display: none;
}

.sierra-delete-pop-confirm .ant-popover-message-title {
  padding-left: 0;
}

.sierra-delete-pop-confirm .sierra-delete-button {
  display: block;
  width: 30px;
  height: 20px;
  float: right;
  text-align: center;
  line-height: 16px;
  padding: 0;
  font-size: 12px;
  border-radius: 4px;
  box-sizing: border-box;
}

.sierra-clean-pop-confirm .ant-popover-inner {
  box-shadow: 0px 2px 8px 5px rgba(0, 0, 0, 0.15);
}

.sierra-clean-pop-confirm .ant-popover-buttons .ant-btn {
  display: none;
}

.sierra-clean-pop-confirm .ant-popover-buttons .ant-btn-primary {
  display: inline-block;
}

.sierra-clean-pop-confirm .ant-popover-inner-content {
  padding: 8px 16px;
  width: 204px;
}

.sierra-clean-pop-confirm .ant-popover-message {
  padding-bottom: 0;
}

.sierra-clean-pop-confirm .ant-popover-message>.sierra-confirm-icon {
  display: none;
}

.sierra-clean-pop-confirm .ant-popover-message-title {
  padding-left: 0;
  display: block;
  font-size: 14px;
  color: #f12727;
}

.sierra-clean-pop-confirm .sierra-delete-button {
  display: block;
  width: 30px;
  height: 20px;
  float: right;
  text-align: center;
  line-height: 16px;
  padding: 0;
  font-size: 12px;
  border-radius: 4px;
  box-sizing: border-box;
}

.sierra-clean-pop-confirm .ant-popover-inner {
  box-shadow: 0px 2px 8px 5px rgba(0, 0, 0, 0.15);
}

.sierra-sider-menu {
  margin: 12px;
  padding: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  line-height: 30px;
  border-radius: 5px;
  color: #0d87f7;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  transition: 0.3s;
  box-shadow: #fff 0px 0px 0px 0px;
}

.sierra-sider-menu:hover {
  color: #379af5;
  box-shadow: rgba(168, 204, 241, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.18) 0px 0px 5px 2px;
  transition: 0.3s;
}

.sierra-layout-msg {
  font-size: 12px;
  margin-top: 10px;
  vertical-align: middle;
}

.sierra-layout-msg>span {
  display: inline-block;
}

.sierra-layout-msg>span:nth-child(1) {
  margin-right: 6px;
}

.sierra-layout-popover .ant-popover-inner-content {
  padding: 6px;
}

.sierra-sider-menu-tree .ant-tree-title .anticon {
  position: absolute;
  right: 14px;
  visibility: hidden;
}

.sierra-sider-menu-tree .ant-tree-title .aurora-status-icon {
  visibility: visible;
  position: absolute;
  right: 68px;
}

.sierra-sider-menu-tree .ant-tree-title:hover .aurora-status-icon {
  right: 24px;
}

.sierra-sider-menu-tree .ant-tree-title .aurora-tree-loading-icon {
  visibility: visible;
}

.sierra-sider-menu-tree .ant-tree-title:hover .copy-loading-icon {
  visibility: visible;
  right: 14px !important;
}

.sierra-sider-menu-tree .ant-tree-title:hover .aurora-tree-loading-icon {
  visibility: visible;
  right: 36px;
}

.sierra-sider-menu-tree .ant-tree-title:hover .anticon {
  visibility: visible;
}

.sierra-sider-menu-tree .ant-tree-title .project-name-input .aurora-tree-create-icon {
  visibility: visible;
  position: static;
}

.sierra-icon-color:hover {
  color: #1890ff;
}

.sierra-view-popover .ant-popover-inner-content {
  padding: 0;
}

.sierra-switch-icon {
  display: inline-block;
  font-weight: 500;
  padding: 0 6px;
  cursor: pointer;
  font-size: 18px;
  vertical-align: middle;
  height: 30px;
  line-height: 30px;
  color: #737373;
}

#sierra-switch-icon-selected {
  color: #0d87f7;
}

.sierra-sider-footer .view-icon-disabled {
  color: #a0a0a0;
  cursor: not-allowed;
}

.sierra-sider-footer .layout-icon-disabled {
  border: 1px #c5c5c5 solid;
  color: #a0a0a0;
  cursor: not-allowed;
}

.sierra-verification-name-span {
  cursor: pointer;
}

.sierra-verification-status {
  display: block;
}

.sierra-sider .tree-node .ant-tree-node-content-wrapper:hover .aurora-tree-info-icon {
  margin-right: 22px;
}

.sierra-sider .tree-node .ant-tree-node-content-wrapper:hover .aurora-status-span,
.sierra-sider .tree-node .ant-tree-node-content-wrapper:hover .aurora-status-icon {
  right: 58px;
}

.sierra-sider .aurora-status-span {
  right: 64px;
  height: 24px;
  line-height: 22px;
}

.sierra-sider-menu-tree .ant-tree-title .aurora-replace-icon {
  visibility: hidden;
}

.sierra-sider-menu-tree .ant-tree-title:hover .aurora-replace-icon {
  visibility: visible;
  margin-right: 0px;
}


.sierra-sider-menu-tree .ant-tree-title:hover .tree-library-content .aurora-replace-icon {
  visibility: visible;
  margin-right: 0px;
  right: 36px;
  position: absolute;
}

.sierra-sider-menu-tree .ant-tree-title .aurora-tree-import-export-library-icon {
  position: absolute;
  right: 21px;
}

.sierra-sider-menu-tree .ant-tree-title .aurora-tree-import-export-project-icon {
  position: absolute;
  right: 21px;
}

.sierra-sider-menu-tree .ant-tree-title:hover .aurora-tree-import-export-project-icon {
  right: 48px;
}

.sierra-copy-name .ant-input-group-addon {
  width: 56px;
  padding: 0 8px;
}

.sierra-copy-name {
  width: 283px;
}

.sierra-copy-error {
  display: block;
  padding: 4px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 10px;
  font-size: 14px;
  padding-left: 10px;
}

.sierra-copy-popover {
  width: 300px;
}

.sierra-copy-popover .ant-popover-inner-content {
  padding: 8px;
}

.sierra-sider-menu-tree .tree-node-interface-name .ant-tree-title {
  padding-left: 22px;
  padding-right: 22px;
}

.sierra-sider-menu-tree .tree-node-interface-name .ant-tree-title:hover {
  padding-right: 24px;
}

.sierra-sider-menu-tree .tree-node-interface-name .tree-node-interface-result-name .ant-tree-title {
  padding-left: 2px;
}

.sierra-verification-tooltip {
  display: inline-block;
  width: 66%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  height: 24px;
  line-height: 24px;
}

.sierra-sider-menu-tree .tree-node-experiment-name .ant-tree-title {
  padding-left: 0px;
  padding-right: 0px;
}

.sierra-sider .sierra-sider-menu-tree .tree-node-experiment-name .ant-tree-switcher {
  margin-left: 14px;
  line-height: 22px !important;
}

.sierra-sider-menu-tree .tree-node-experiment-name .ant-tree-title:hover {
  padding-right: 0px;
}

.sierra-sider-menu-tree .tree-node-experiment-result-name {
  font-style: oblique;
}

/* .sierra-sider-menu-tree .tree-node-interface-name .ant-tree-title:hover .aurora-status-span {
  right: 38px;
} */

.sierra-sider-menu-tree .verification-create-node .ant-tree-title:hover,
.sierra-sider-menu-tree .experiments-create-node .ant-tree-title:hover {
  padding-right: 0px;
}

.sierra-sider-menu-tree .experiments-create-node .ant-tree-title {
  padding-left: 0px;
  padding-right: 0px;
}

.sierra-sider-menu-tree .project-create-node .ant-tree-title:hover {
  padding-right: 0px;
}

.sierra-sider-menu-tree .folder-create-node .ant-tree-title:hover {
  padding-right: 0px;
}

.sierra-sider-menu-tree .tree-node-pcb-name .tree-node-pcb-replace-icon {
  position: absolute;
  right: 24px;
}

.sierra-sider-menu-tree .tree-node-pcb-name .ant-tree-title:hover .tree-node-pcb-replace-icon {
  position: absolute;
  right: 60px;
}

.sierra-sider-menu-tree .tree-node-pcb-name .ant-tree-title:hover {
  padding-right: 90px;
}

.sierra-sider-menu-tree .ant-tree-title {
  cursor: pointer;
  height: 32px;
}

.sierra-sider-menu-tree .ant-tree-title .tree-library-content {
  height: 32px;
  line-height: 30px;
}

.sierra-sider-menu-tree .simulate-icon-disabled {
  color: #a0a0a0;
  cursor: not-allowed;
}

.sierra-sider-menu-tree .ant-tree-title .anticon-play-circle {
  visibility: visible;
  transition: right .2s linear;
  right: 38px;
}

.sierra-sider-menu-tree .ant-tree-title .aurora-tree-batch-delete-icon {
  visibility: visible;
  transition: right .2s linear;
}

.sierra-sider-menu-tree .ant-tree-title .aurora-tree-combine-interface-icon {
  visibility: visible;
  transition: right .2s linear;
  right: 63px;
}

.sierra-sider-menu-tree .ant-tree-title:hover .anticon-play-circle {
  right: 63px;
}

.sierra-sider-menu-tree .ant-tree-title:hover .aurora-tree-batch-delete-icon {
  right: 38px;
}

.sierra-sider-menu-tree .ant-tree-title:hover .aurora-tree-combine-interface-icon {
  right: 88px;
}

.sierra-sider-menu-tree .ant-tree-title .tree-library-content .sierra-touchstone-status-icon {
  text-align: center;
  line-height: 31px;
  font-size: 15px;
  color: #f13140;
  position: absolute;
  right: 14px;
  visibility: visible;
  height: 31px;
}

.sierra-sider-menu-tree .ant-tree-title:hover .tree-library-content .sierra-touchstone-status-icon {
  right: 62px;
}

.sierra-sider-menu-tree .ant-tree-title .sierra-pdn-tree-loading-icon {
  color: #1890ff;
  float: right;
}

.sierra-sider-menu-tree .ant-tree-title .tree-library-content .sierra-library-touchstone-file-span {
  padding-right: 30px;
}

.sierra-sider-menu-tree .ant-tree-title:hover .tree-library-content .sierra-library-touchstone-file-span {
  padding-right: 42px;
}

.sierra-sider-menu-tree .ant-tree-title:hover .sierra-library-file-span {
  padding-right: 47px;
}

.sierra-sider .sierra-sider-menu-tree .ant-tree-title:hover .sierra-default-library-file-span {
  padding-right: 68px;
}

.sierra-sider-menu-tree .ant-tree-title .tree-library-content .aurora-macro-icon {
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  color: #ff5500;
  position: absolute;
  right: 14px;
  visibility: visible;
}

.sierra-sider .tree-node .ant-tree-node-content-wrapper:hover .aurora-macro-icon {
  right: 64px;
}

/* folder */
.sierra-folder-title {
  font-weight: 600;
}

.sierra-library-folder .aurora-tree-add-icon {
  margin-right: 24px;
}

.sierra-library-folder .sierra-folder-icon {
  position: unset !important;
  visibility: visible !important;
  margin-right: 5px;
  color: rgba(0, 0, 0, 0.65);
}

.sierra-sider-menu-tree .tree-node-add-interface-name .ant-tree-node-content-wrapper .ant-tree-title:hover {
  padding-left: 0px;
  padding-right: 0px;
}

.sierra-sider-menu-tree .tree-node-add-interface-name .ant-tree-node-content-wrapper .ant-tree-title .aurora-add-interface-icon {
  position: static;
  visibility: visible;
  font-size: 11px;
  color: #ff610b;
  vertical-align: top;
  height: 20px;
  line-height: 22px;
}

.sierra-sider-menu-tree .tree-node-add-interface-name .ant-tree-node-content-wrapper {
  margin-left: -11px;
}

.sierra-sider-menu-tree .ant-tree-title:hover .aurora-interface-type-tag {
  display: none;
}

.sierra-sider-menu-tree .aurora-interface-type-tag {
  right: 4px;
}

.sierra-sider-menu-tree .ant-tree-title .sierra-pcb-setting-icon {
  visibility: hidden;
  height: 22px;
  line-height: 22px;
  font-size: 16px;
}

.sierra-sider-menu-tree .ant-tree-title:hover .sierra-pcb-setting-icon {
  visibility: visible;
  margin-right: 0px;
  position: absolute;
  right: 84px;
}

.tree-node .ant-tree-title>span.sierra-add-interface-content {
  width: 43%;
  margin-right: 17px;
  line-height: 20px;
  font-size: 12px;
  height: 22px;
}

.tree-node .ant-tree-title>span.sierra-search-interface-content {
  width: 43%;
  line-height: 20px;
  font-size: 12px;
  height: 22px;
}

.sierra-sider-menu-tree .ant-tree .tree-node-add-interface-name .ant-tree-node-content-wrapper:hover {
  background-color: #f9f9f9;
}

.aurora-tree-create-loading-icon {
  margin-right: 15px;
  line-height: 18px;
}

.sierra-sider-menu-tree .tree-node-add-interface-name .ant-tree-title {
  cursor: unset;
}

.sierra-sider-menu-tree .ant-tree-title .aurora-tree-multi-interface-setup-icon {
  visibility: visible;
  transition: right .2s linear;
  right: 88px;
  color: #a0a0a0;
}

.sierra-sider-menu-tree .ant-tree-title .aurora-tree-multi-interface-setup-open-icon {
  color: #1890ff;
}

.sierra-sider-menu-tree .ant-tree-title:hover .aurora-tree-multi-interface-setup-icon {
  right: 112px;
}

.sierra-sider-menu-tree .ant-tree-title .aurora-tree-import-export-loading-icon {
  right: 14px;
  visibility: visible;
  text-align: center;
  line-height: 30px;
  font-size: 15px;
  color: #1890ff;
  float: right;
}

.sierra-sider-menu-tree .ant-tree-title .aurora-tree-import-export-library-loading,
.sierra-sider-menu-tree .ant-tree-title .aurora-tree-import-export-project-loading {
  position: absolute;
  right: 14px;
  margin-top: calc(5% - 9px);
}

.sierra-sider-menu-tree .ant-tree-title:hover .aurora-tree-import-export-project-loading {
  right: 42px;
}

.sierra-sider-menu-tree .ant-tree-title .aurora-tree-set-icon {
  right: 60px;
}

.sierra-sider-menu-tree .sierra-library-default-tag {
  position: absolute;
  top: 50%;
  right: 4px;
  margin-top: -11px;
  border-radius: 4px;
  font-size: 12px;
  transform: scale(0.9, 0.9);
  border: 1px solid #a3a3a3;
  color: #a3a3a3;
}

.sierra-sider-menu-tree .ant-tree-title:hover .sierra-library-default-tag {
  display: none;
}

#sierra-content-main,
#sierra-top-bar,
.sierra-sider,
.my-sierra-layout,
.my-sierra-layout .ant-table,
.my-sierra-layout .ant-tree li .ant-tree-node-content-wrapper,
.my-sierra-layout .ant-tabs,
.my-sierra-layout .ant-dropdown-menu-item,
.sierra-panel .panel-ant-modal-body,
.sierra-panel .ant-input,
.my-sierra-layout .ant-input,
.my-sierra-layout .ant-select,
.sierra-panel .panel-ant-modal-body .ant-input,
.sierra-panel .panel-ant-modal-body .ant-select,
.sierra-panel .ant-modal-footer,
.sierra-panel .ant-tabs,
.sierra-panel .ant-dropdown-menu-item,
.sierra-panel .ant-table,
.sierra-panel .sierra-extraction-content-body,
.sierra-panel .sierra-extraction-panel-content,
.sierra-panel .ant-checkbox-wrapper,
.my-sierra-layout .ant-checkbox-wrapper,
.sierra-panel .ant-radio-wrapper,
.my-sierra-layout .ant-radio-wrapper,
.sierra-panel .sierra-debug-download-menu-btn,
.my-sierra-layout .ant-radio-group,
.sierra-panel .ant-radio-group,
.my-sierra-layout .ant-input-group-addon,
.sierra-panel .ant-input-group-addon,
.sierra-panel .ant-table-thead>tr>th,
.my-sierra-layout .ant-table-thead>tr>th,
.sierra-model-dialog .ant-input,
.sierra-model-dialog .ant-input-group-addon,
.sierra-model-dialog .ant-select,
.sierra-panel .ant-select-single.ant-select-sm .ant-select-selector,
.sierra-panel .ant-select .ant-select-selector {
  /*  font-weight: 600; */
  color: rgb(0, 0, 0, 0.85);
}

/* .my-sierra-layout .ant-btn-link,
.sierra-panel .ant-modal-body .ant-btn-link,
.my-sierra-layout .my-ccc-monitor {
  font-weight: 500;
} */

.my-sierra-layout .icon-Debug,
.my-sierra-layout .icon-icons8-circuit,
.my-sierra-layout .icon-setup,
.my-sierra-layout .pcb-icon,
.my-sierra-layout .icon-Monitor,
.my-sierra-layout .my-tree-switch-icon,
.my-sierra-layout .icon-cancel9 {
  font-weight: 500;
}

.my-sierra-layout .sierra-sider .my-tree>li>ul>li>span {
  color: rgb(0, 0, 0, 0.65);
}


.sierra-interface-type-input {
  width: 34%;
  height: 24px;
}

/* .sierra-interface-type-input .ant-select-selector {
  margin-left: 6px;
  margin-right: 16px;
} */

.sierra-interface-type-input .ant-select-arrow {
  right: 2px;
  font-size: 10px;
}

.sierra-interface-type-input .ant-select-arrow .anticon {
  visibility: visible;
  right: 3px;
}

.sierra-interface-type-input .ant-select-selector .ant-select-selection-item {
  font-size: 12px;
  padding-inline-end: 0px;
}

.sierra-sider-menu-tree .ant-tree-title .sierra-interface-create-input {
  width: 66%;
}

.sierra-sider-menu-tree .tree-node .ant-tree-title>.verification-name-input {
  top: 0px;
}

.sierra-panel.component-RLC-panel.component-PMIC-panel .classification-right .component-animation-classfication {
  height: 100%;
}