.top-bar-report-item {
  position: relative;
  padding: 6px 0px;
}

.top-bar-report-item::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  -webkit-animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  content: '';
}

.top-bar-report {
  line-height: 21px;
  height: 21px;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  margin: 6px 0;
  padding: 0 14px;
}

.top-bar-item:hover {
  background-color: #e0e0e0;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.top-bar-report-icon {
  margin: -2px 8px 0px 0px;
  font-size: 14px;
  display: inline-block;
  vertical-align: bottom;
}

.top-bar-icon-diff {
  padding-top: 2px;
}

.top-bar-report-progress {
  width: 0px;
  height: 33px;
  background-color: #82c2fd;
  float: left;
  position: absolute;
  overflow: visible;
  top: 0;
  z-index: 1;
}

.top-bar-item {
  margin: 6px 6px 6px 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: #005a9e;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 33px;
}

.report-message-main {
  margin-top: 10px;
  position: relative;
  min-height: 27px;
}

.report-download-message {
  line-height: 28px;
  text-indent: 10px;
  border-radius: 4px;
  font-size: 14px;
  max-width: calc(100% - 90px);
  padding: 0px 8px;
}

.report-download-button {
  position: absolute;
  right: 0px;
  bottom: 2px;
}

.report-download-btn {
  width: 80px;
  height: 26px;
  padding: 0px;
  font-size: 13px;
  line-height: 24px;
}

.report-progress {
  right: 10px;
  padding: 2px 0px;
  position: absolute;
  width: auto;
  left: 0px;
}

.report-progress .ant-progress-inner {
  background-color: #c7e0f4;
}

.report-progress .ant-progress-text {
  vertical-align: unset;
}

.report-progress .ant-progress-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  -webkit-animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  animation: ant-progress-active 2.0s cubic-bezier(.23, 1, .32, 1) infinite;
  content: '';
}

.download-error {
  border: 1px solid #ffa39e;
  background-color: #fff1f0;
}

.download-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}