.unit-select {
  float: right;
  width: 70px;
}

.unit-span {
  line-height: 32px;
}

.decap-panel,
.VRM-panel,
.SPIM-panel,
.library-data-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}


.decap-rlc-box,
.vrm-content-box,
.library-data-panel-content-box {
  padding: 16px;
}

.model-input {
  display: block;
}

.rlc-input {
  margin: 16px 0;
  display: block;
}

.rlc-input .ant-input-group-addon .ant-select-selection {
  width: 68px !important;
}

.model-name-error-msg {
  display: block;
  padding: 10px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
}

.decap-panel {
  margin: 0 auto;
}