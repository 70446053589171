.aurora-create-panel .aurora-create-advanced-content {
  background-color: #fff;
}

.aurora-create-panel .aurora-create-advanced-content>.ant-collapse-item {
  border: 0;
}

.aurora-create-panel .aurora-create-advanced-content>.ant-collapse-item>.ant-collapse-header {
  padding-left: 30px;
  padding-bottom: 0;
}

.aurora-create-panel .aurora-create-advanced-content>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  margin-top: 6px;
  left: 10px;
}

.aurora-create-advanced-content .ant-collapse-content-box {
  padding-bottom: 8px;
  padding-right: 0;
}

.aurora-create-advanced-content .aurora-create-advanced-input-item,
.aurora-create-advanced-content .aurora-create-input-item {
  margin: 8px;
  display: flex;
  line-height: 28px;
  justify-content: space-between;
}

.aurora-create-advanced-content .aurora-create-advanced-checkbox-item {
  justify-content: left;
}

.aurora-create-advanced-content .aurora-create-advanced-checkbox-item>.create-edit-input-label {
  width: 120px;
}

.aurora-create-advanced-content .aurora-create-advanced-input-item .create-content-input {
  width: calc(100% - 120px);
  cursor: pointer;
}

.aurora-create-advanced-input-item .create-advanced-switch {
  margin: 8px 0px;
}

.aurora-select-regular-input span.ant-input-group-addon:hover {
  cursor: pointer;
}

.aurora-select-regular-input span.ant-input-group-addon {
  background-color: #d6dadd;
}

.aurora-not-select-regular-input span.ant-input-group-addon {
  background-color: #fafafa;
}

.aurora-not-select-regular-input .regular-icon {
  display: inline-block;
  position: absolute;
  right: 0px;
  height: 20px;
  line-height: 20px;
  padding: 0px 6px;
  margin: 2px;
  border: 1px solid white;
  border-radius: 4px;
}

.aurora-select-regular-input.ant-input-affix-wrapper .ant-input-suffix {
  right: 4px;
  background-color: #bee1ff;
  border-radius: 4px;
  font-size: 14px;
}

.aurora-select-regular-input.ant-input-affix-wrapper .ant-input-suffix div {
  padding: 0 6px;
}

.aurora-select-regular-input .regular-icon-selected {
  display: inline-block;
  position: absolute;
  right: 0px;
  height: 20px;
  line-height: 20px;
  padding: 0px 6px;
  margin: 2px;
  border: 1px solid white;
  border-radius: 4px;
  background-color: #bee1ff;
}

.aurora-create-advanced-input-item label.create-input-radio.ant-radio-wrapper {
  margin-left: 10px;
  top: 4px;
}

.aurora-create-advanced-content .aurora-create-advanced-input-item .create-advanced-switch {
  margin-left: 10px;
}

.aurora-create-advanced-content .aurora-create-advanced-input-switch {
  justify-content: flex-start;
}

.aurora-create-advanced-input-item.aurora-create-advanced-input-checkout {
  margin-left: 30px;
}