.cascade-pre-layout-body {
  padding: 20px;
  min-width: 1350px;
}

.cascade-pre-layout-setup-border {
  border: 1px solid #a8c4e6;
  padding: 10px;
  border-radius: 3px;
}

.cascade-pre-layout-decap-group-border {
  max-width: 800px;
}

.cascade-prelayout-setup {
  position: relative;
  overflow-x: auto;
}

.cascade-prelayout-setup .setup-component {
  position: absolute;
  width: 100px;
  margin: 20px 0;
  border: 1px solid grey;
  border-radius: 10px;
  text-align: center;
  vertical-align: middle;
  font-weight: 700;
  background-color: #ffffff;
}

.cascade-prelayout-setup .setup-component-right {
  right: 20px
}

.cascade-prelayout-setup .setup-component-left {
  left: 20px
}

.cascade-prelayout-setup .setup-voltage {
  position: absolute;
  top: 55%;
  line-height: 28px;
  left: 0px;
  text-align: center;
  width: 100%;
  font-weight: normal;
}

.cascade-prelayout-setup .setup-voltage>input {
  width: 100px
}

.cascade-prelayout-setup .setup-decap-group {
  position: absolute;
  border: 1px solid grey;
  border-radius: 10px;
  text-align: center;
  font-size: 12px;
  background-color: #ffffff;
}

.cascade-prelayout-setup .setup-decap-group-select {
  box-shadow: 0px 0px 5px 1px #f4b083;
}


.cascade-prelayout-setup .setup-decap-group .decap-name {
  position: absolute;
  font-weight: bold;
}

.cascade-prelayout-setup .setup-decap-groups .decap-name {
  transform: rotate(90deg);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  top: 40px;
  right: -60px;
  text-align: center;
}

.cascade-prelayout-setup .setup-decap-group .decap-number {
  position: absolute;
  top: 100px;
  right: 0px;
}

.cascade-prelayout-setup .setup-decap-group .decap-l {
  position: absolute;
  top: 60px;
  width: calc(100% - 10px);
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 5px;
}

.cascade-prelayout-setup .setup-decap-group .decap-r {
  position: absolute;
  top: 20px;
  width: calc(100% - 10px);
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 5px;
}


.cascade-prelayout-setup .setup-decap-item .decap-l {
  top: 35px;
}


.cascade-prelayout-setup .setup-decap-item .decap-r {
  top: 10px;
}

.cascade-prelayout-setup .setup-decap-item .decap-name {
  top: 65%;
  max-width: 120px;
  word-wrap: break-word;
  word-break: break-all;
}

.cascade-prelayout-setup .setup-decap-group .group-decaps>span {
  display: block;
}

.cascade-prelayout-setup .add-decap-group {
  position: absolute;
  left: calc(50% - 75px);
  top: 10px;
  color: #0d87f7;
  cursor: pointer;
}

.cascade-prelayout-setup .add-decap-group>i {
  margin-right: 10px;
}

.cascade-prelayout-decap-group-content {
  margin: 20px;
}

.cascade-prelayout-decap-group-content .decap-group-item {
  width: 100%;
  margin: 10px;
}

.cascade-prelayout-part-select .decap-group-item {
  width: 100%;
  margin: 10px 0px;
}

.cascade-prelayout-decap-group-content .decap-group-item .decap-group-item-title,
.cascade-prelayout-part-select .decap-group-item .decap-group-item-title {
  display: inline-block;
  width: 100px;
  font-weight: bold;
}

.cascade-prelayout-decap-group-content .decap-group-item .decap-group-item-input,
.cascade-prelayout-part-select .decap-group-item .decap-group-item-input {
  width: calc(100% - 110px);
}

.cascade-prelayout-decap-group-content .decap-group-item .decap-group-item-input .ant-input-group-addon {
  width: 50px;
}

.cascade-prelayout-decap-table .ant-table-thead>tr>th .ant-table-header-column {
  display: block !important;
}

.cascade-prelayout-decap-table .ant-table-small>.ant-table-content>.aurora-table-body {
  margin: 0 20px;
}

.cascade-prelayout-decap-delete-icon {
  position: absolute;
  right: -30px;
  margin-top: -5px;
  top: 48%;
  color: #ccc;
  cursor: pointer;
}

.cascade-prelayout-decap-delete-icon:hover,
.cascade-prelayout-decap-group-buttons .close-icon:hover {
  color: #0d87f7;
}

.cascade-prelayout-decap-table .ant-table-small>.ant-table-content>.aurora-table-body .editable-cell-value-wrap {
  text-overflow: unset;
  overflow: visible;
  white-space: unset;
  line-height: auto;
  height: auto;
  min-height: 30px;
  position: relative;
}

.cascade-prelayout-decap-group-buttons {
  position: absolute;
  top: 10px;
  right: 15px;
}

.cascade-prelayout-decap-group-buttons .group-button {
  margin-left: 15px;
}

.cascade-prelayout-decap-group-buttons .hidden-button {
  visibility: hidden;
}

.cascade-prelayout-decap-group-buttons .close-icon {
  cursor: pointer;
}

.cascade-prelayout-part-select {
  margin: 20px;
}

.cascade-prelayout-setup .setup-ball .decap-l,
.cascade-prelayout-setup .setup-ball .decap-r,
.cascade-prelayout-setup .setup-ball .decap-c {
  position: absolute;
  top: 10px;
  width: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 5px;
  font-size: 11px;
  z-index: 50
}

.cascade-prelayout-setup .setup-ball .select-decap-l,
.cascade-prelayout-setup .setup-ball .select-decap-r,
.cascade-prelayout-setup .setup-ball .select-decap-c {
  width: 120px;
  z-index: 100;
}

.cascade-prelayout-setup .setup-ball .decap-c {
  top: 50px;
}


.cascade-prelayout-setup .setup-ball .decap-l>input,
.cascade-prelayout-setup .setup-ball .decap-r>input,
.cascade-prelayout-setup .setup-ball .decap-c>input {
  width: 100px;
  height: 22px;
}

.cascade-prelayout-decap-error-name {
  color: #cb2431;
}

.cascade-prelayout-decap-error-icon {
  padding-left: 5px;
}

.cascade-prelayout-svg {
  width: 100%;
  height: calc(100% - 10px);
  margin-top: 5px;
  vertical-align: middle;
}

.cascade-prelayout-setup-dc {
  border: 1px solid #e9e9e9
}

.cascade-dc-pre-grid .grid-line {
  stroke: #e9e9e9;
  stroke-width: 1;
}

.cascade-dc-pre-comps .dc-comp .dc-comp-rect {
  stroke: rgba(0, 0, 0, 0.8);
  stroke-width: 3;
  cursor: pointer;
}

.cascade-dc-pre-comps .dc-comp .dc-comp-rect:hover,
.cascade-dc-pre-nets .dc-net:hover .net-line,
.cascade-dc-pre-nets .dc-gnd-net:hover .net-line {
  stroke: red;
}

.cascade-dc-pre-comps .dc-comp-name {
  font-size: 16px;
  font-weight: bold;
}

.cascade-dc-pre-nets .dc-net .net-line {
  stroke-width: 10;
  stroke: #b0b0e1;
  cursor: pointer;
}

.cascade-dc-pre-nets .dc-gnd-net .net-line {
  stroke-width: 10;
  stroke: #898989;
  cursor: pointer;
}

.cascade-dc-prelayout-drawer {
  position: absolute !important;
}

.cascade-dc-prelayout-drawer-content {
  padding: 0 10px;
  margin-bottom: 10px;
}

.cascade-dc-prelayout-drawer-content-title {
  display: inline-block;
  width: 100px;
  font-size: 14px;
  vertical-align: middle;
  line-height: 28px;
  margin-bottom: 5px;
}

.cascade-dc-prelayout-drawer-content-input {
  width: calc(100% - 110px);
}

.cascade-dc-prelayout-drawer .ant-drawer-body {
  padding: 16px;
}

.cascade-dc-pre-drawer-error-msg {
  display: block;
  padding: 8px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 10px;
}

.cascade-dc-prelayout-drawer-box {
  margin: 20px;
}