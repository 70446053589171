.signal-port-edit-item,
.signal-port-edit-sub-item,
.signal-port-edit-reference-sub-item {
  display: flex;
  line-height: 28px;
  margin: 6px 0px;
}

.signal-port-edit-ball-item {
  display: block !important;
}

.signal-port-edit-item>label {
  width: 196px;
}

.signal-port-edit-sub-item {
  margin-left: 110px;
}

.signal-port-edit-sub-item>label {
  width: 61px;
}

.signal-port-edit-sub-item>.aurora-input {
  width: calc(100% - 61px);
}

.signal-port-edit-reference-sub-item {
  display: block;
}

.signal-port-edit-reference-sub-item .extraction-ports-radio {
  display: block;
  line-height: 28px;
}

.signal-port-comp-pin-content {
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 28px;
  line-height: 28px;
  padding: 0px 10px;
  width: calc(100% - 110px);
  font-size: 13px;
}

.signal-port-edit-reference-sub-item>.aurora-select {
  width: 100%;
  margin: 6px 0px;
  min-height: 28px;
  height: auto;
}

.signal-port-edit-reference-sub-item>ant-select-selector {
  height: auto;
  min-height: 28px;
  max-height: 120px;
  overflow-y: auto;
}

.signal-port-edit-reference-sub-item .signal-port-edit-sub-item {
  margin-left: 0px;
}

.signal-port-edit-reference-sub-item .signal-port-edit-sub-item>label {
  width: 140px;
}

.signal-port-edit-reference-sub-item .signal-port-edit-sub-item>.aurora-input {
  width: calc(100% - 140px);
}

.extraction-ports-auto-setup-port-radio {
  display: flex;
}

.extraction-circuit-ports-radio {
  margin-left: 80px;
}

.extraction-ports-auto-checkbox-main {
  margin: 0px;
}

/* reference component */
.signal-port-edit-reference-pins-content {
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 100px;
  min-height: 28px;
  line-height: 26px;
  padding: 0px 8px;
  width: calc(100% - 110px);
  overflow-y: auto;
  cursor: pointer;
  font-size: 13px;
}

.signal-port-edit-reference-pins-content:hover {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}

.signal-ports-edit-point .aurora-input {
  width: calc(50% - 98px);
}

.signal-ports-edit-point .aurora-select {
  width: 68px;
  margin-left: 4px;
}

.signal-ports-edit-point .signal-ports-edit-point-input {
  margin-right: 4px;
}

/* === Extraction ports panel === */
.extraction-ports-panel {
  margin-top: 100px;
  overflow: auto;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.extraction-ports-panel .panel-modal-header {
  padding-left: 14px !important;
}

.extraction-ports-title {
  font-size: 16px;
  font-weight: 500;
}

.extraction-ports-content {
  padding: 10px 16px;
}