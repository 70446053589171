.stackup-table .ant-table-footer {
  padding: 8px 0;
}

.stackup-table .aurora-table .ant-table-row:hover>td>.editable-cell-value-wrap {
  background-color: rgba(0, 0, 0, 0);
}

.aurora_stackup_fullscreen {
  margin-top: 70px;
}

.stackup-table .ant-table-container .ant-table-header>table>.ant-table-thead>tr>th {
  background: #fafafa;
  position: relative;
}

.stackup-table .ant-table-content table>.ant-table-thead>tr>th {
  position: relative;
}

.stackup-input-group {
  width: 160px;
  float: left;
}

.stackup-input-group .ant-select-selection-selected-value {
  padding-right: 0px;
}

.stackup-thickness-name {
  float: left;
  margin-left: 0px;
  margin-right: 8px;
}

.stackup-input-col,
.stackup-input-row {
  line-height: 32px;
}

.stackup-input-col {
  float: left;
}

.stackup-set-name {
  margin: 0px 8px 0 16px;
}

.stackup-footer-value-setting {
  height: 32px;
  line-height: 32px;
  width: 100%;
  text-align: left;
  margin: 0px;
}

.stackup-set-button {
  margin-left: 8px;
  margin-right: 8px;
}

.stackup-clear {
  zoom: 1
}

.stackup-clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.stackup-dropdown-button .ant-dropdown-menu-item button {
  width: 140px;
  padding-left: 15px;
  text-align: left;
  border: 0px;
}

.stackup-menu-button {
  padding: 0px;
  vertical-align: middle;
}

.stackup-subMenu {
  z-index: 2000;
}

.stackup-dropdown-button .ant-dropdown-menu-item {
  padding: 0px;
}

.stackup-dropdown-button .ant-dropdown-menu-item button:hover {
  background-color: #e6f7ff
}

.stackup-conductivity {
  width: 160px;
}

.stackup-unit-selection {
  height: 26px;
}

.stackup-unit-selection .ant-select-selector {
  line-height: 24px;
}

.stackup-unit-selection .ant-select-arrow {
  right: 6px;
}

.stackup-unit-option {
  z-index: 2000;
}

.stackup-table-edit div {
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}

.stackup-table-edit div+div {
  margin-top: 4px;
}

.stackup-table-edit .anticon-vertical-align-top,
.stackup-table-edit .anticon-vertical-align-bottom {
  font-size: 16px;
}

.stackup-table-edit .anticon-close {
  font-size: 13px;
}

.stackup-table tr td:first-child>div {
  cursor: pointer;
}

.stackup-table-row-light {
  background-color: #f3eecf;
}

.stackup-table .open-material-icon {
  margin-left: 10px;
  color: #1890ff;
  font-size: 17px;
  vertical-align: middle;
}

.stackup-total-thickness-content {
  display: flex;
  width: 100%;
  margin-left: 0px !important;
  justify-content: space-around;
  font-weight: bold;
}

.download-stackup-menu .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
  display: none;
}

.stackup-table .etch-help-icon {
  color: #1890ff;
  font-size: 16px;
  vertical-align: middle;
  position: absolute;
  right: 10px;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
}

.etch-factor-help-panel .help-panel-content,
.etch-factor-help-panel .help-panel-content img {
  width: 100%;
  height: 100%;
}

.etch-factor-box {
  margin-left: 18px;
}


.stackup-out-dielectric-color {
  background-color: #cfeb7e;
}

.stackup-all-metal-color {
  background-color: #f9c236;
}

.stackup-in-dielectric-color {
  background-color: #fffecf;
}

.stackup-out-dielectric-color .editable-cell-value-wrap {
  border-color: #9b9b9b;
}

.stackup-all-metal-color .editable-cell-value-wrap {
  border-color: #d1d1d1;
}

.stackup-in-dielectric-color .editable-cell-value-wrap {
  border-color: #d8d8d8;
}

.stackup-all-layer>td {
  border-right: 1px solid #d3d3d3 !important;
  border-bottom: 1px solid #d3d3d3 !important;
}

.stackup-out-dielectric-color:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background: #cce779 !important;
}

.stackup-all-metal-color:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background: #fdcb4c !important;
}

.stackup-in-dielectric-color:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background: #ffffbc !important;
}

.stackup-multi-zone-radio-group {
  padding: 8px 20px;
}

.multi-zone-stackup-checkbox-content {
  text-align: center;
}

/* .stackup-table .ant-table-small>.ant-table-content .aurora-table-body>tr>td.multi-zone-stackup-checkbox-content-PRIMARY-td,
.stackup-table .ant-table-small>.ant-table-content .ant-table-header>table>.ant-table-thead>tr>.multi-zone-stackup-checkbox-content-PRIMARY-td,
.stackup-table .stackup-out-dielectric-color:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td.multi-zone-stackup-checkbox-content-PRIMARY-td {
  background-color: #e2f3f9 !important;
}

.stackup-table .ant-table-small>.ant-table-content .aurora-table-body>tr>td.multi-zone-stackup-checkbox-content-FLEX-td,
.stackup-table .ant-table-small>.ant-table-content .ant-table-header>table>.ant-table-thead>tr>.multi-zone-stackup-checkbox-content-FLEX-td,
.stackup-table .stackup-out-dielectric-color:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td.multi-zone-stackup-checkbox-content-FLEX-td {
  background-color: #d9eddc !important;
}

.stackup-table .ant-table-small>.ant-table-content .aurora-table-body>tr>td.multi-zone-stackup-checkbox-content-RIGID-td,
.stackup-table .ant-table-small>.ant-table-content .ant-table-header>table>.ant-table-thead>tr>.multi-zone-stackup-checkbox-content-RIGID-td,
.stackup-table .stackup-out-dielectric-color:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td.multi-zone-stackup-checkbox-content-RIGID-td {
  background-color: #fcf0e5 !important;
} */
.stackup-table .ant-table-small>.ant-table-content .aurora-table-body>tr>td.multi-zone-stackup-checkbox-content-stackup-name-td,
.stackup-table .ant-table-small>.ant-table-content .ant-table-header>table>.ant-table-thead>tr>.multi-zone-stackup-checkbox-content-stackup-name-td,
.stackup-table .stackup-out-dielectric-color:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td.multi-zone-stackup-checkbox-content-stackup-name-td {
  background-color: #d9eddc !important;
}

.stackup-table .ant-table-small>.ant-table-content .aurora-table-body>tr>td.multi-zone-stackup-checkbox-content-stackup-name-td,
.stackup-table .ant-table-small>.ant-table-content .ant-table-header>table>.ant-table-thead>tr>.multi-zone-stackup-checkbox-content-stackup-name-td {
  border: 1px #ffffff solid !important;
  padding: 0px !important;
}

.aurora-multi-zone-stackup-icon {
  margin-right: 10px;
  font-size: 16px;
  color: #40a9ff;
  cursor: pointer;
  vertical-align: middle;
}

.stackup-name-td {
  width: calc(100% - 16px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}