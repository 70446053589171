.cascade-design-tree-content {
  padding: 20px;
  min-width: 1350px;
  height: 100%;
}

.cascade-design-tree-content .design-tree-spin {
  text-align: center;
  width: 100%;
  height: 100%;
}

.cascade-design-tree-content .design-tree-spin .ant-spin {
  margin-top: 10%;
}

.cascade-power-tree-apply-to {
  position: absolute;
  top: 0;
  right: 0;
}

.cascade-power-tree-main-loading-no-canvas .ant-spin-spinning{
  top: 100% !important;
}