.clip-design-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.clip-design-panel .panel-modal-header {
  padding-left: 14px !important;
}

.clip-design-panel .ant-divider-horizontal {
  margin: 10px 0px;
}

.clip-design-panel-content {
  height: 30px;
  line-height: 26px;
}

.clip-design-panel-content .clip-title {
  font-size: 13px;
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;
  padding-right: 10px;
  width: 150px;
}

.clip-design-panel-content .clip-design-input {
  width: calc(100% - 690px) !important;
  height: 28px;
}

.clip-design-panel-content .clip-design-input .ant-input-group-addon {
  width: 36px !important;
}

.clip-design-panel-content .radio-clip-group {
  margin-left: 28px;
  margin-right: 6px;
}

.clip-design-panel-content .radio-clip-type-group {
  margin-left: 40px;
}

.clip-design-panel-content .ant-row {
  line-height: 28px;
}

.clip-design-panel-content .clip-design-value {
  margin-top: 10px;
}

.preview-button,
.preview-button-focus {
  height: 28px;
  float: right;
  margin-right: 12px;
  width: 80px;
}

.preview-button-focus {
  color: #005a9e;
  background-color: #c7e0f4;
  border-color: #c7e0f4;
}

.preview-button-row {
  text-align: right;
  margin-top: 10px;
}

.clip-design-panel-content .aurora-switch-small.ant-switch-disabled {
  opacity: 1;
}