.virtual-table {
  margin: 0px;
  margin-right: 15px;
  max-height: 40px !important;
}

.virtual-table .ant-table-placeholder {
  display: none;
}

.virtual-table .ant-table-empty .aurora-table-body {
  overflow-x: auto;
}

.virtual-table-without-scroll-Y {
  margin-right: 0px !important;
}

/* .virtual-table-body {
  overflow: hidden;
  text-overflow: ellipsis;
} */

#virtual-table-body {
  overflow-anchor: none
}

.virtual-table .ant-table-row:hover>td {
  background-color: #e6f7ff;
}

.virtual-table .ant-table-row:hover .editable-cell-value-wrap {
  border-color: #d9d9d9
}

.virtual-table .ant-table-row>td>.editable-cell-value-wrap.ant-table-cell {
  padding: 0 8px;
}
