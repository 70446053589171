.decap-select-model-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.decap-select-model-title {
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.decap-select-model-content {
  width: 100%;
  padding: 0px 14px;
  font-size: 13px;
  margin-bottom: 6px;
}

.select-model-content-row {
  display: flex;
  justify-content: space-between;
}

.select-model-content-row .type-name {
  float: left;
  line-height: 24px;
  font-weight: 500;
  width: 100px;
}

.select-model-content-row .select-model-content-cal-custom .custom-span {
  width: 164px;
}

.select-model-content-row .ant-select {
  float: left;
  width: calc(100% - 100px);
  height: 24px;
  font-size: 13px;
  line-height: 24px;
}

.select-model-content-row .ant-input {
  float: left;
  width: calc(100% - 100px);
  height: 24px;
  font-size: 13px;
  line-height: 24px;
}

.select-model-content-row .select-model-content-cal-custom .ant-select,
.select-model-content-row .select-model-content-cal-custom .ant-input {
  width: calc(100% - 164px);
}

.select-model-content-row .voltageInput .ant-input {
  width: 100%;
}

.select-model-content-cal {
  width: 100%;
  padding: 5px 10px;
  line-height: 24px;

}

.select-model-select-dropdown {
  z-index: 5000;
}

.decap-select-model-content .select-model-name-box {
  width: 100%;
  padding: 6px;
  overflow: auto;
  background-color: #eee;
  border-radius: 6px;
  margin: 0px 10px 0px 10px;
}

.decap-select-model-content .select-model-ul {
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow: auto;
  width: 100%;
  overflow-anchor: none;
  position: relative;
}

.decap-select-model-content .select-model-li {
  white-space: nowrap;
  display: block;
  height: 22px;
  line-height: 22px;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
}

.decap-select-model-content .select-model-li-title {
  white-space: nowrap;
  display: block;
  height: 22px;
  font-weight: 800;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  background-color: #eee;
  width: 100%;
  z-index: 10000;
  padding-right: 18px;
}

.decap-select-model-content .select-model-li:hover {
  background-color: #d5ecff;
}

.decap-select-model-content .select-model-li-selected {
  background-color: #b9ecff;
  width: 100%;
  z-index: 10000;
  padding-right: 17px;
}

.decap-select-model-content .hidden {
  padding-right: 0px;
}

.decap-select-model-content .select-model-li-loading {
  cursor: not-allowed;
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.select-model-li .select-model-li-strong {
  font-weight: 600;
}

.select-model-li .ant-col>span,
.select-model-li-title .ant-col>span {
  font-size: 12px;
}


.decap-select-model-content .apply-model-title {
  width: 175px;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
}

.decap-select-model-content .ant-select {
  font-size: 13px;
}

/* .select-model-selection-dropdown {
  position: absolute;
  max-height: 200px;
  overflow: auto;
  margin-top: 3px;
  min-width: 100%;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0 2px 7px 2px rgba(0, 0, 0, 0.15);
  overflow-anchor: none;
} */

.select-model-selection-component {
  float: left;
  width: 100%;
}

.select-model-selection-dropdown .menu-list {
  margin: 2px;
  padding-inline-start: 0px
}

.select-model-selection-dropdown .menu-list li {
  padding: 3px 10px;
  width: 100%;
  white-space: nowrap;
}

.select-model-selection-dropdown .menu-list li:hover {
  background: #E6F7FF;
}

.select-model-selection-dropdown .menu-list li:hover .menu-item-check {
  visibility: visible;
}

.select-model-selection-dropdown .menu-list .menu-selected-item {
  background: #fafafa;
  font-weight: 800;
}

/* custom library list render */
.search-library-content-list {
  margin: 10px 16px;
  background: #eee;
  padding: 8px;
  border-radius: 6px;
  text-align: center;
  overflow: auto;
  width: calc(100% - 32px)
}

.search-library-content-no-data {
  font-size: 16px;
  color: #a3a3a3;
}

.search-library-list-title {
  font-weight: 600;
  height: 24px;
  line-height: 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}

.search-library-list-item {
  height: 24px;
  line-height: 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}

.search-library-item-name {
  font-weight: bold;
}

.search-library-list-item>span {
  text-align: left;
  padding: 0px 10px;
  text-align: center;
}

.search-library-list-item .search-library-item-path {
  white-space: nowrap;
  text-align: left;
}

.search-library-content-list-main {
  width: fit-content;
}

.decap-select-model-content .search-library-list-item {
  height: 22px;
  line-height: 22px;
  font-size: 12px;
}

.decap-select-model-content .search-library-list-item:hover {
  background-color: #d5ecff;
}

.decap-select-model-content .search-library-list-item-selected {
  background-color: #b9ecff;
}

.decap-select-model-content .search-library-content-list {
  margin: 10px 10px;
  width: calc(100% - 20px);
}

.select-model-input-component.ant-input-disabled {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  cursor: text;
}