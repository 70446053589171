.aurora-debug-download-content {
  margin: 10px;
  display: flex;
  justify-content: space-between;
}

.aurora-debug-download-content .aurora-debug-item-content {
  float: none;
  margin-top: 0px;
  border: 1px solid #e8e8e8;
  padding: 10px;
  position: relative;
}

.aurora-debug-download-menu-box .aurora-debug-download-menu-item {
  float: none;
  /* background: #f3f2f1; */
  text-align: left;
  padding-bottom: 4px;
  border-radius: 3px;
  text-align: center;
}

.aurora-debug-download-menu-box .aurora-debug-download-menu-item .aurora-debug-simulation-select {
  min-width: 100px;
  margin-left: 0px;
  width: 100%;
}

.debug-menu-item-title {
  text-align: center;
  font-size: 14px;
  color: #005a9e;
}

.aurora-debug-download-content .aurora-debug-list-content .debug-download-checkbox-group-box {
  margin-bottom: 70px;
  /* max-height: 250px; */
  overflow: auto;
  width: 100%;
}

.aurora-debug-download-content .aurora-debug-list-content .aurora-debug-progress,
.aurora-debug-download-content .aurora-debug-list-content .debug-download-button {
  position: absolute;
  bottom: 5px;
  right: 10px;
}

.aurora-debug-download-content .aurora-debug-list-content .aurora-debug-download-message {
  right: 20px;
  bottom: 50px;
  min-height: 30px;
  height: auto;
  padding: 0px 4px;
}

.aurora-debug-download-menu-item .aurora-debug-download-select {
  height: 28px;
}

.aurora-debug-download-menu-item .aurora-debug-download-select .ant-select-selector {
  line-height: 26px;
}

.aurora-debug-download-menu-item .aurora-multiple-select-box .aurora-debug-download-select {
  margin-top: 5px;
}

.aurora-debug-download-multi-select {
  font-size: 13px;
  min-height: 28px;
  height: auto;
  margin: 6px 0px;
  width: 100%;
}

.aurora-debug-download-content .aurora-debug-list-content .debug-download-checkbox-group-box .debug-download-checkbox-group {
  width: calc(100% - 12px);
}

.aurora-debug-download-content .aurora-debug-list-content .debug-download-checkbox-group-box .debug-download-checkbox-children-group {
  width: 100%;
}

.aurora-debug-download-content .aurora-debug-list-content .debug-download-checkbox-group-box .debug-download-checkbox-group>span:nth-child(2),
.aurora-debug-download-content .aurora-debug-list-content .debug-download-checkbox-group-box .debug-download-checkbox-children-group>span:nth-child(2) {
  width: calc(100% - 16px);
  display: inline-block;
}

.aurora-debug-download-content .aurora-debug-list-content .debug-download-checkbox-group-box .debug-download-checkbox-group .debug-download-checkbox-span {
  width: 100%;
}

.aurora-debug-download-content .aurora-debug-list-content .debug-download-checkbox-group-box .debug-download-checkbox-children-span {
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top;
}

.aurora-debug-download-content .aurora-debug-list-content .debug-download-checkbox-box {
  width: 100%;
}

.aurora-download-flex-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.aurora-download-flex-content-item-title {
  width: 100%;
  text-align: center;
  height: 28px;
  line-height: 28px;
  font-weight: 600;
}

.aurora-download-flex-content-item {
  border-right: 1px solid #cecece;
  width: calc(50% - 10px);
  min-width: calc(50% - 10px);
  margin-bottom: 10px;
}

.aurora-download-flex-content .aurora-download-flex-content-item .debug-download-checkbox-group-box {
  margin-bottom: 0px !important;
}