.library-upload-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  margin: 0 auto;
}

.library-upload-tag-content {
  margin: 10px 20px;
}

.library-upload-tag-title {
  color: #0b3b94;
  font-weight: bold;
  width: 45px;
  display: inline-block;
}

.library-upload-tag-select {
  width: calc(100% - 50px);
}