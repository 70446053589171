.spice-netlist-model-content .ant-input {
  height: 28px;
  font-size: 12px;
}

.spice-netlist-model-content {
  height: calc(100% - 50px);
}

.spice-connector-content {
  display: flex;
  font-weight: 500;
  padding: 6px 10px;
}

.spice-model-single-select .spice-netlist-model-select {
  width: calc(100% - 10px);
}

.spice-netlist-model-select {
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 5px 10px;
}

.spice-netlist-model-select>*:first-child {
  width: 100px;
  height: 24px;
  line-height: 24px;
  display: inline-block;
}

.spice-netlist-model-select>*:last-child {
  width: calc(100% - 100px);
  display: inline-block;
}

.spice-netlist-model-select .spice-netlist-model-selection,
.spice-netlist-model-select .spice-netlist-model-selection .ant-select-selection {
  height: 24px;
  line-height: 22px;
}

.spice-netlist-model-select .ant-select-selector,
.spice-netlist-model-select .spice-netlist-model-selection-item,
.spice-netlist-model-select .ant-select-selection {
  line-height: 22px;
  font-weight: 500;
  font-size: 13px;
}

.spice-netlist-model-dropdown,
.nodes-list-file-dropdown {
  z-index: 2002 !important;
}

.nodes-content {
  padding: 12px 12px;
  width: 100%;
  position: relative;
  padding-top: 2px;
  max-width: 600px;
}

.nodes-content-header {
  text-align: center;
  line-height: 28px;
  font-weight: 500;
}

.node-list-body-with-search {
  height: 100%;
}

.node-list-body-search-wrapper {
  width: 100%;
}

.node-list-body-search-wrapper .ant-input-affix-wrapper {
  height: 28px;
  line-height: 28px;
  font-size: 13px;
}

.node-list-body-search-wrapper .ant-input-affix-wrapper .ant-input {
  height: 20px;
  line-height: 20px;
  font-size: 13px;
}

.node-list-ul {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: auto;
  list-style: none;
  min-width: 20px;
  max-height: 230px;
  border: 1px solid #d9d9d9;
  border-top: none;
  border-radius: 0px 0px 4px 4px;
}

.node-list-ul>li {
  height: 24px;
  line-height: 24px;
  padding: 0px 10px;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
}

.node-list-ul>li:hover {
  background-color: #cfedff;
}


.node-list-ul>.current-pin-selected-node-li:hover {
  background-color: #93e6ff;
}

.spice-connect-content {
  display: flex;
  font-weight: 500;
  justify-content: center;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  min-height: 100px;
  margin: 10px 10px;
  margin-bottom: 0px;
  margin-top: 6px;
  min-width: 630px;
}

.spice-pin-list-ul {
  height: 100%;
  margin: 0;
  padding: 8px 10px;
  list-style: none;
  /* min-width: 630px; */
  width: fit-content;
}

.spice-pin-connect-node-item {
  display: flex;
  justify-content: left;
  height: 56px;
  width: 100%;
  /* min-width: 610px; */
  align-items: center;
  font-size: 12px;
  width: fit-content;
}

.spice-pin-list-ul .spice-pin-connect-node-item-center {
  /* justify-content: center; */
  padding: 0 10px;
  /* position: relative; */
}

.spice-pin-list-ul .spice-pin-connect-node-item-center-model {
  justify-content: center;
}

.spice-pin-node-item-center {
  justify-content: center;
}

.spice-box {
  flex: 0 0 auto;
  width: 200px;
  min-width: 160px;
  background-color: #eeeeee;
  height: 100%;
  border-bottom: 1px solid #d9d9d9;
  text-align: center;
  font-size: 12px;
  padding: 8px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: grid;
  align-content: center;
}

.spice-box>.spice-box-signal-text {
  display: block;
  line-height: 20px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px 4px;
}

.spice-box>.spice-box-net-text {
  display: block;
  line-height: 20px;
  color: #005a9e;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px 4px;
  max-width: 200px;
}

.spice-box-top {
  border-radius: 4px 4px 0px 0px;
}

.spice-box-bottom {
  border-radius: 0px 0px 4px 4px;
  border-bottom: none;
}

.spice-pin-die-box {
  margin: 0px 8px;
  flex: 0 0 auto;
  width: 80px;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.spice-pin-box {
  margin: 0px 8px;
  flex: 0 0 auto;
  width: 80px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.spice-pin-line {
  width: 20px;
  flex: 0 0 auto;
  height: 5px;
  background-color: #a6a6a6;
  transform: scaleY(0.2);
}

.spice-pin-half-line {
  width: 10px;
  flex: 0 0 auto;
  height: 5px;
  background-color: #a6a6a6;
  transform: scaleY(0.2);
}

.spice-pin-circle {
  width: 8px;
  height: 8px;
  flex: 0 0 auto;
  border-radius: 4px;
  border: 1px solid #a6a6a6;
  background-color: #ffffff;
}

.spice-pin-input,
.spice-pin-select {
  flex: 0 0 auto;
  text-align: center;
  max-width: 80px;
  width: 80px;
  min-width: 50px;
  display: inline-block;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  position: relative;
  vertical-align: top;
}

.spice-pin-input .ant-input:not(:last-child) {
  padding-right: 11px;
}

.spice-pin-input:hover>span:not(.anticon) {
  max-width: 65px;
}

.spice-pin-input>span:not(.anticon) {
  display: inline-block;
  max-width: 80px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* node select model */
.spice-node-select-Popover {
  z-index: 2001;
  display: flex;
}

.spice-port-select-Popover {
  z-index: 2001;
}

.spice-node-select-Popover .ant-popover-inner-content,
.spice-port-select-Popover .ant-popover-inner-content {
  padding: 0px 0px;
}

.spice-node-select-Popover .ant-popover-content,
.spice-port-select-Popover .ant-popover-content {
  box-shadow: 0px 0px 8px 2px #969696;
}

.node-list-body-search-wrapper .ant-input {
  border-radius: 4px 4px 0px 0px;
}

.node-content-close-icon {
  color: rgba(0, 0, 0, 0.45);
}

.node-content-close {
  position: absolute;
  right: 8px;
  top: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  line-height: 24px;
  transition: 0.3s;
}

.node-content-close:hover {
  background: #ececec;
  transition: 0.3s;
}

.node-list-ul>.node-li-selected,
.node-list-ul>.node-li-selected:hover {
  background-color: #e4e4e4;
  cursor: not-allowed;
}

.node-list-ul>.current-pin-selected-node-li {
  background-color: #93e6ff;
}

.spice-pin-die-value-box,
.spice-pin-value-box {
  height: 28px;
}

.spice-pin-node-delete-icon {
  position: absolute;
  right: 2px;
  top: 8px;
  display: none;
  color: rgba(0, 0, 0, 0.25);
}

.spice-pin-node-delete-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}

.spice-pin-input:hover .spice-pin-node-delete-icon {
  display: inline-block;
}

.nodes-list-file-select {
  width: 100%;
  font-size: 12px;
  margin-bottom: 6px;
  height: 28px;
}

.nodes-list-file-select {
  height: 28px;
}

.nodes-list-file-select .ant-select-selector {
  line-height: 26px;
}

.spice-model-add-file-icon {
  margin-left: 10px;
  color: #3da4fa;
  font-size: 14px;
  vertical-align: text-bottom;
}

.spice-model-multi-select {
  display: flex;
  position: relative;
  width: 100%;
}

.spice-netlist-model-select-content {
  width: calc(100% + 10px);
  ;
}

.spice-model-single-select {
  position: relative;
  width: 100%;
}

.spice-model-file-error-msg {
  display: block;
  margin: 5px 10px;
  padding: 6px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
}

.spice-model-single-select:hover .spice-model-delete-file-icon,
.spice-model-multi-select:hover .spice-model-delete-file-icon {
  visibility: visible;
}

.spice-model-delete-file-icon {
  color: #3da4fa;
  font-size: 13px;
  visibility: hidden;
  position: absolute;
  right: 2px;
  top: 10px;
}

.spice-netlist-model-select .Subckt {
  width: 0px;
}

.spice-netlist-model-select .Subckt_select {
  width: 100%;
}

.spice-model-multi-select .spice-netlist-model-select {
  padding-left: 5px;
}

.spice-model-multi-select>*:first-child {
  padding-right: 5px;
  padding-left: 10px;
}

.spice-netlist-model-dropdown .ant-select-dropdown-menu-item-group-title {
  font-size: 14px;
}

.spice-netlist-model-dropdown .ant-select-dropdown-menu-item-group-list {
  background: #f2f2f2;
}

/* multi rank */
.spice-netlist-model-select .ant-switch {
  width: unset;
  display: inline-block;
}

.spice-multi-rank-setting {
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 5px 10px;
}

.spice-multi-rank-setting .spice-multi-rank-span {
  width: 100px;
  height: 24px;
  line-height: 22px;
  text-align: right;
  padding-right: 10px;
}

.spice-multi-rank-setting .ant-input {
  height: 22px;
  width: calc(50% - 110px);
}

.spice-multi-rank-setting .ant-select {
  width: calc(50% - 100px);
}

.spice-multi-rank-setting .ant-select .ant-select-selection {
  height: 24px;
  line-height: 22px;
}

.spice-total-pin-box {
  display: inline-flex;
  align-items: center;
  height: 32px;
}

.spice-multi-rank-setting .ant-select .ant-select-selection .ant-select-selector {
  line-height: 22px;
  font-size: 13px;
}

.spice-netlist-model-title {
  display: flex;
  /*   justify-content: center; */
  align-items: flex-start;
}

.spice-netlist-model-title>.spice-netlist-model-title-div {
  width: calc(50% - 100px);
  color: #005a9e;
  line-height: 32px;
  height: 32px;
  text-align: center;
}

.spice-netlist-model-title>.spice-netlist-model-title-new {
  padding-left: 20px;
  width: 200px;
  position: relative;
}

.spice-netlist-model-title>.spice-netlist-model-title-net {
  /* width: 200px; */
  width: 33px;
}

.spice-netlist-model-title-div .spice-netlist-model-title-pcb-add-icon {
  color: #0d87f7;
  font-size: 19px;
  margin-left: 10px;
  cursor: pointer;
  vertical-align: text-bottom;
  position: absolute;
  right: -9px;
  top: 7px;
  z-index: 20;
}

.spice-netlist-model-title-div .spice-netlist-model-title-pcb-add-icon-disabled {
  color: rgba(0, 0, 0, 0.55);
  cursor: not-allowed;
}

.spice-netlist-model-title-pcb-delete-icon {
  color: rgba(0, 0, 0, 0.55);
  font-size: 13px;
  margin-left: 10px;
  cursor: pointer;
  vertical-align: text-top;
}

.spice-netlist-model-title-div:hover .spice-netlist-model-title-pcb-delete-icon {
  color: #0d87f7;
}

.spice-netlist-model-menu {
  z-index: 1000000000;
}

.spice-netlist-model-ports {
  display: flex;
  justify-content: center;
  height: 62px;
  align-items: center;
}

.spice-netlist-model-ports-background {
  background: #efefef;
}

.spice-netlist-last-model-ports {
  padding-right: 10px;
}

.spice-box-rename-input {
  margin: auto;
  max-width: 80px;
  /* width: 80px;
  min-width: 50px; */
}

/* .spice-box-display {
  min-width: 105px;
} */

.spice-search-node-icon {
  color: rgba(0, 0, 0, .25);
}

.node-list-ul>.node-list-ul {
  height: fit-content;
  width: 100%;
  list-style: none;
  min-width: 20px;
  max-height: 100px;
  border-radius: unset;
}

.node-list-ul>.node-list-ul>li {
  padding-left: 18px;
}

.node-list-ul>.node-list-group-title {
  height: 24px;
  line-height: 24px;
  padding: 0px 6px;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
  font-weight: 600;
  background-color: #f0f0f0;
}