.aurora-popover {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, .65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  font-weight: 400;
  white-space: normal;
  text-align: left;
  cursor: auto;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.aurora-popover-placement-bottom,
.aurora-popover-placement-bottomLeft,
.aurora-popover-placement-bottomRight {
  padding-top: 10px;
}

.aurora-popover-hidden {
  display: none;
}

.aurora-popover-arrow {
  position: absolute;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: transparent;
  border-style: solid;
  border-width: 4.24264069px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.aurora-popover-placement-bottom>.aurora-popover-content>.aurora-popover-arrow,
.aurora-popover-placement-bottomLeft>.aurora-popover-content>.aurora-popover-arrow,
.aurora-popover-placement-bottomRight>.aurora-popover-content>.aurora-popover-arrow {
  top: 6px;
  border-color: #fff transparent transparent #fff;
  -webkit-box-shadow: -2px -2px 5px rgba(0, 0, 0, .06);
  box-shadow: -2px -2px 5px rgba(0, 0, 0, .06);
}

.aurora-popover-placement-bottom>.aurora-popover-content>.aurora-popover-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg);
}

.aurora-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, .15) \9;
  box-shadow: 0 0 8px rgba(0, 0, 0, .15) \9;
}

.aurora-popover-inner-content {
  padding: 12px 16px;
  color: rgba(0, 0, 0, .65);
}

.context-menu {
  transform: translateX(-50%);
}

.context-menu .aurora-popover-inner {
  position: absolute;
  transform: translateX(-50%);
}

.context-menu .aurora-popover-inner-content {
  padding: 5px 15px;
}

.context-menu .aurora-popover-inner-content:hover {
  cursor: pointer;
  background-color: #eee;
  border-radius: 4px;
}