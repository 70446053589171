.connection-ports-list-file-dropdown {
  z-index: 1000000000;
}

.connection-ports-content {
  padding: 12px 12px;
  width: 100%;
  position: relative;
  padding-top: 2px;
  max-width: 600px;
}

.connection-ports-content-header {
  text-align: center;
  line-height: 28px;
  font-weight: 500;
}

.connection-port-list-body-with-search {
  height: 100%;
}

.connection-port-list-body-search-wrapper {
  width: 100%;
}

.connection-port-list-body-search-wrapper .ant-input-affix-wrapper,
.connection-port-list-body-search-wrapper .ant-input-affix-wrapper .ant-input {
  height: 28px;
  line-height: 28px;
  font-size: 13px;
}

.connection-port-list-ul {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: auto;
  list-style: none;
  min-width: 20px;
  max-height: 230px;
  border: 1px solid #d9d9d9;
  border-top: none;
  border-radius: 0px 0px 4px 4px;
}

.connection-port-list-ul>li {
  height: 24px;
  line-height: 24px;
  padding: 0px 10px;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
}

.connection-port-list-ul>li:hover {
  background-color: #cfedff;
}


.connection-port-list-ul>.current-pin-selected-port-li:hover {
  background-color: #93e6ff;
}

.connection-pin-port-content {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 10px;
  top: 64px;
}

.connection-pin-port-content .connection-pin-list-ul {
  height: 100%;
  margin: 0;
  /* padding: 8px 10px; */
  padding: 8px 0px;
  list-style: none;
}

.connection-pin-connect-port-item {
  width: 100%;
  min-width: 370px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.spice-pin-list-ul .connection-pin-connect-port-item-center {
  justify-content: center;
}

.connection-box {
  flex: 0 0 auto;
  width: 110px;
  background-color: #eeeeee;
  height: 100%;
  /* border-bottom: 1px solid #d9d9d9; */
  text-align: center;
  font-size: 12px;
  padding: 8px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.connection-box>.connection-box-net-text {
  display: block;
  line-height: 26px;
  color: #005a9e;
}

.connection-box-top {
  border-radius: 4px 4px 0px 0px;
}

.connection-box-bottom {
  border-radius: 0px 0px 4px 4px;
  border-bottom: none;
}

.connection-pin-l-box,
.connection-pin-net-box {
  padding: 0px 8px;
  flex: 0 0 auto;
  max-width: 180px;
  width: fit-content;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #ffffff;
  z-index: 10;
}

.connection-pin-net-box {
  max-width: 150px;
}

.connection-pin-r-box {
  padding: 0px 8px;
  flex: 0 0 auto;
  max-width: 180px;
  width: fit-content;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #ffffff;
  z-index: 10;
}

.connection-pin-line,
.connection-pin-long-line {
  width: 20px;
  flex: 0 0 auto;
  height: 5px;
  background-color: #a6a6a6;
  transform: scaleY(0.2);
}

.connection-pin-long-line {
  width: 100%;
  position: absolute;
  left: 0px;
  right: 0px;
  z-index: 0;
  top: 50%;
}

.connection-pin-circle {
  width: 8px;
  height: 8px;
  flex: 0 0 auto;
  border-radius: 4px;
  border: 1px solid #a6a6a6;
  background-color: #ffffff;
}

.connection-pin-input {
  flex: 0 0 auto;
  text-align: center;
  max-width: 80px;
  width: 50px;
  min-width: 50px;
  display: inline-block;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  position: relative;
  vertical-align: top;
  background: #ffffff;
}

.connection-pin-cable-value-box .connection-pin-input-value {
  background: #eee;
}

.spice-pin-input .ant-input:not(:last-child) {
  padding-right: 11px;
}

.spice-pin-input:hover>span:not(.anticon) {
  max-width: 65px;
}

.spice-pin-input>span:not(.anticon) {
  display: inline-block;
  max-width: 80px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* node select model */
.connection-port-select-Popover {
  z-index: 100000000;
  display: flex;
  padding: 0px;
}

.connection-port-select-Popover .ant-popover-inner-content {
  padding: 0px 0px;
}

.connection-port-select-Popover .ant-popover-content {
  box-shadow: 0px 0px 8px 2px #969696;
}

.node-list-body-search-wrapper .ant-input {
  border-radius: 4px 4px 0px 0px;
}

.connection-ports-content-close-icon {
  color: rgba(0, 0, 0, 0.45);
}

.connection-ports-content-close {
  position: absolute;
  right: 8px;
  top: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  line-height: 24px;
  transition: 0.3s;
}

.connection-ports-content-close:hover {
  background: #ececec;
  transition: 0.3s;
}

.connection-port-list-ul>.port-li-selected,
.connection-port-list-ul>.port-li-selected:hover {
  background-color: #e4e4e4;
  cursor: not-allowed;
}

.connection-port-list-ul>.current-pin-selected-port-li {
  background-color: #93e6ff;
}

.connection-pin-l-value-box,
.connection-pin-r-value-box,
.connection-pin-cable-value-box {
  height: 50px;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: space-between;
  width: 34%;
  position: relative;
  z-index: 20;
}

.connection-pin-cable-value-box {
  width: 32%;
  justify-content: space-around;
}

.connection-pin-node-delete-icon {
  position: absolute;
  right: 2px;
  top: 8px;
  display: none;
  color: rgba(0, 0, 0, 0.25);
}

.connection-pin-node-delete-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}

.connection-pin-input:hover .connection-pin-node-delete-icon {
  display: inline-block;
}

.connection-ports-list-file-select {
  width: 100%;
  font-size: 12px;
  margin-bottom: 6px;
  height: 28px;
}

.connection-ports-list-file-select {
  height: 28px;
}

.connection-ports-list-file-select .ant-select-selector {
  line-height: 26px;
}

.spice-model-add-file-icon {
  margin-left: 10px;
  color: #3da4fa;
  font-size: 14px;
  vertical-align: text-bottom;
}

.spice-model-multi-select {
  display: flex;
  position: relative;
  width: 100%;
}

.spice-netlist-model-select-content {
  width: calc(100% + 10px);
}

.pkg-new-panel .spice-netlist-model-select-content {
  width: 100%;
}

.spice-model-single-select {
  position: relative;
  width: 100%;
}

.spice-model-file-error-msg {
  display: block;
  margin: 5px 10px;
  padding: 6px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
}

.spice-model-single-select:hover .spice-model-delete-file-icon,
.spice-model-multi-select:hover .spice-model-delete-file-icon {
  visibility: visible;
}

.spice-model-delete-file-icon {
  color: #3da4fa;
  font-size: 13px;
  visibility: hidden;
  position: absolute;
  right: 2px;
  top: 10px;
}

.spice-netlist-model-select .Subckt {
  width: 0px;
}

.spice-netlist-model-select .Subckt_select {
  width: 100%;
}

.spice-model-multi-select .spice-netlist-model-select {
  padding-left: 5px;
}

.spice-model-multi-select>*:first-child {
  padding-right: 5px;
  padding-left: 10px;
}

.spice-netlist-model-dropdown .ant-select-dropdown-menu-item-group-title {
  font-size: 14px;
}

.spice-netlist-model-dropdown .ant-select-dropdown-menu-item-group-list {
  background: #f2f2f2;
}

.connection-pin-list-signal {
  padding: 6px 0px;
  position: relative;
}

.connection-pin-list-channel1-signal-title,
.connection-pin-list-channel2-signal-title,
.connection-pin-list-connection-signal-title {
  height: 100px;
  line-height: 100px;
  color: #2d82d7;
  margin: auto;
  position: absolute;
  top: 50px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  text-align: center;
  z-index: -1;
}

.connection-pin-input-pinport {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
}

.connection-pin-input {
  height: 26px;
  line-height: 26px;
}

.connection-net-name-box {
  position: relative;
}

.connection-pin-net-l-name {
  color: #2d82d7;
  position: absolute;
  top: -8px;
  z-index: 100;
  left: 0;
}

.connection-pin-net-r-name {
  color: #2d82d7;
  position: absolute;
  top: -8px;
  z-index: 100;
  right: 0px;
}

.connection-pin-connect-port-item .connection-pin-net-box {
  max-width: 31%;
}

.connection-pin-connect-port-item .connection-pin-net-l-name {
  max-width: 40%;
}

.connection-pin-map-edit-icon {
  margin-left: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #056fdb;
  vertical-align: middle;
}

.connection-pin-map-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.connection-pin-map-panel .connection-pin-map-content {
  padding: 8px 14px;
  min-width: 450px;
}

.connection-pin-map-panel .connection-pin-map-columns {
  width: 100%;
  height: 100%;
}

.connection-pin-map-columns-select-dropdown {
  z-index: 30000;
}