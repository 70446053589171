 .andes-v2-result-content .sparameter-span-file-name,
 .andes-v2-result-content .sparameter-file-expand-icon {
   vertical-align: middle;
 }

 .andes-v2-result-parameter-right {
   box-sizing: border-box;
   position: absolute;
   top: 0px;
   bottom: 20px;
   margin-left: 8px;
   right: 0px;
   width: 45px;
   height: 100px;
 }

 .andes-v2-result-parameter-right .reference-box {
   justify-content: center;
   align-items: center;
   width: 28px;
   height: 28px;
   background-color: #9191918c;
   position: absolute;
   top: 6px;
   right: 14px;
   cursor: pointer;
   border-radius: 50%;
   visibility: visible;
   box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.14), 1px 1px 3px 0 rgba(0, 0, 0, 0.21), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
   text-align: center;
   line-height: 27px;
   transition: all 0.3s;
 }

 .andes-v2-result-parameter-right .reference-box:hover {
   background-color: #6e6e6e8c;
   transition: all 0.3s;
 }

 .andes-v2-reference-table-icon {
   color: aliceblue
 }

 .andes-v2-reference-line-box {
   pointer-events: none;
 }

 .port-setting-panel-content {
   padding: 10px;
 }

 .port-setting-signal-name {
   font-size: 14x;
   font-weight: 500;
 }

 .port-setting-box {
   margin-left: 10px;
   display: flex;
   justify-content: space-between;
 }

 .port-setting-list.ant-list .ant-list-item {
   display: inline-block;
   width: 100%;
 }

 /* The child element is centered vertically */
 .port-setting-box>.port-box {
   display: flex;
   align-items: center;
 }

 .port-setting-box .port-name {
   display: inline-block;
   max-width: 220px;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
 }

 .port-setting-box .tag {
   margin-top: 1px;
   margin-left: 8px;
 }

 .port-setting-box .swap-icon {
   margin-right: 8px;
 }

 .port-setting-box .swap-icon:hover,
 .andes-result-port-icon-group>.port-view-text:hover {
   color: #1677ff;
 }

 .andes-result-port-icon-group {
   display: flex;
   justify-content: end;
 }

 .andes-result-port-icon-group>.port-view-text {
   cursor: pointer;
   margin-right: 10px;
   font-size: 12px;
 }

 .andes-result-port-icon-group>span:nth-child(2) {
   margin-top: 1px;
   margin-right: 16px;
   color: #1677ff;
   cursor: pointer;
 }

 .andes-result-port-icon-group .ant-switch.ant-switch-small {
   min-width: 24px;
   height: 14px;
   line-height: 14px;
   margin-left: 10px;
 }

 .andes-result-port-icon-group .ant-switch.ant-switch-small .ant-switch-handle {
   width: 10px;
   height: 10px;
 }

 .port-setting-panel-search .ant-input-group-addon {
   background-color: #1677ff;
   color: #fff;
 }