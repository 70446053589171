.custom-RLC-panel {
  width: 400px;
  box-shadow: 0 4px 12px 4px rgba(0, 0, 0, 0.15);
}

.custom-RLC-content {
  height: 100%;
  width: 100%;
  padding: 16px;
}

.custom-RLC-item {
  width: 100%;
  height: 33.33%;
  padding: 10px 0px;
  position: relative;
}

.custom-RLC-item-content {
  display: inline-block;
  width: 77%;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  vertical-align: middle;
  padding: 0px 11px;
  cursor: pointer;
}

.custom-RLC-title {
  font-size: 14px;
  display: inline-block;
  width: 23%;
  vertical-align: middle;
}

.custom-RLC-item-select {
  width: 100%;
}

.custom-RLC-item-input {
  border: none;
  height: 30px;
  /* float: left; */
  position: static;
  padding: 0;
  width: .75em;
  max-width: 100%;
  float: left;
}

.custom-RLC-item-tag {
  cursor: pointer;
  margin-top: 4px;
  float: left;
}

.custom-RLC-tags {
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.custom-RLC-item-input:hover,
.custom-RLC-item-input:focus {
  border-right-width: 0px !important;
  border-width: 0px;
  box-shadow: 0px 0px 0px #ffffff;
  border-color: #fff;
}

.custom-RLC-item-content .custom-RLC-tags .custom-RLC-item-tag .anticon-close {
  font-size: 10px;
}

.custom-RLC-item-input-box {
  width: auto;
  float: left;
  height: 30px;
}

.custom-RLC-sub-content {
  margin-left: 12px;
}

.custom-RLC-sub-title {
  font-weight: bold;
}

.custom-PMIC-panel {
  width: 700px;
}

.custom-PMIC-item-content {
  width: 77%;
  border-radius: 4px;
}

.custom-RLC-divider {
  margin: 10px 0px;
}

.custom-apply-title {
  font-size: 14px;
  display: inline-block;
  width: 265px;
  vertical-align: middle;
}

.custom-apply-checkbox {
  display: inline-block;
  vertical-align: middle;
}