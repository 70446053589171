.PDN-result-decap-content {
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  padding: 0 20px 30px;
}

.pdn-content-left .PDN-result-decap-content {
  overflow: auto;
}

.pdn-result-decap-span {
  display: inline-block;
  margin: 10px 0;
  font-weight: 700;
}

.pdn-table-unstuffed-span {
  margin-left: 10px;
}

.pdn-result-dc-content {
  height: 100%;
  position: absolute;
  top: 45px;
  width: 100%;
}

.dc-Result-Menu {
  height: 45px;
  position: absolute;
  top: 0px;
  background: #f3f2f1;
  z-index: 1000;
  width: 100%;
}

.PDN-dc-Result-Menu {
  margin-top: 44px;
  width: 100%;
}

.PDN-result-DC-content {
  padding: 15px 20px;
  /* overflow: auto; */
}

.PDN-result-DC-content .ant-spin-nested-loading .virtual-table-without-scroll-Y .ant-spin-container {
  padding-bottom: 20px;
}

.download-dc-icon {
  position: absolute;
  left: 160px;
  cursor: pointer;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
}

.download-dc-icon:hover {
  color: #1890ff;
}

.PDN-download-icon-vertical-line {
  height: 16px;
  background-color: #c7c7c7;
  width: 1px;
  margin-right: 28px;
}

.ant-btn.dc-export-icon {
  height: 26px;
  padding: 0 8px;
}

.pdn-decap-switch-icon {
  color: #3da4fa;
  margin-left: 10px;
  font-size: 15px;
  vertical-align: middle;
}
