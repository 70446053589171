.result-parameter-setting-box {
  width: 100%;
  height: auto;
  padding-bottom: 2px;
  background-color: #fafafa;
}

.result-parameter-setting-box .ant-row-flex-middle {
  margin: 2px 0;
}

.result-parameter-setting-box .ant-divider-inner-text {
  padding-left: 0px !important;
  padding-right: 10px !important;
}

.result-parameter-setting-box .ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 0%;
}

.result-parameter-setting-box .result-menu-title {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  padding-right: 1px;
}

.result-parameter-setting-box .setting-row {
  line-height: 28px;
}

.result-parameter-setting-box .setting-input-lable {
  font-weight: bold;
  color: rgb(0, 0, 0, 0.65)
}

.result-parameter-setting-box .padding-left-42 {
  padding-left: 42px;
}

.result-parameter-setting-box .result-sp-setting-select {
  height: 28px;
  line-height: 28px;
  width: 110px;
}

.result-parameter-setting-box .result-sp-setting-select div {
  height: 28px;
}

.result-parameter-setting-box .result-sp-setting-select .ant-select-selection-selected-value {
  line-height: 26px;
  height: 26px;
}

.result-parameter-setting-box .result-sp-setting-select .ant-select-selector {
  line-height: 26px;
  height: 26px;
}

.result-parameter-setting-box .result-sp-setting-select .ant-select-arrow {
  right: 6px;
}

.result-parameter-setting-box .sp-axis-title {
  cursor: pointer;
}

.result-parameter-setting-box .sp-axis-content>div {
  margin: 3px 0;
}

.result-parameter-setting-box .sp-axis-content>div>div {
  display: flex;
}

.result-parameter-setting-box .sp-axis-content>div>div .ant-select {
  width: 75px;
  height: 28px;
}

.result-parameter-setting-box .sp-axis-content>div>div .sp-setting-select {
  width: 120px;
}

.result-parameter-setting-box .result-setting-expand-icon {
  font-size: 10px;
  padding-right: 10px;
  padding-left: 22px;
}

.result-parameter-setting-box .result-sp-setting-input {
  width: 180px;
  margin-left: 0px;
  height: 28px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  vertical-align: top;
}

.result-parameter-setting-box .result-sp-setting-input input {
  height: 28px;
}

.result-parameter-setting-box .result-sp-unit-select {
  vertical-align: top;
  width: 75px;
  height: 28px;
}

.result-parameter-setting-box .result-sp-unit-select .ant-select-selection {
  position: relative;
  padding: 0 10px;
  padding-left: 6px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 28px;
  border-left: none;
}

.result-parameter-setting-box .result-sp-unit-select .ant-select-arrow {
  right: 4px;
}

.result-parameter-setting-box .result-sp-unit-select .ant-select-selector {
  margin-right: 0;
  margin-left: 0;
  line-height: 26px;
}

.result-parameter-setting-box .result-sp-unit-select .ant-select-selector .ant-select-selection-selected-value {
  padding-right: 0px;
}

.result-parameter-setting-box .result-sp-y-input {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}