/* .ssn-centric-file-select-panel-content .netlist-model-content {
  margin-bottom: 16px;
} */

.ssn-centric-file-select-panel-content {
  height: fit-content;
  max-height: 800px;
}

.ssn-die-radio-group {
  display: block;
  margin: 0 16px;
  height: 20px;
  margin-top: 16px;
}

.ssn-die-radio-value {
  float: left;
  line-height: 20px;
  width: 50%;
  margin: 0 !important;
}

.ROCKY-ssn-centric-file-select-panel-content .rocky-ssn-die-radio-group .ssn-die-radio-value,
.CASCADE-ssn-centric-file-select-panel-content .cascade-ssn-die-radio-group .ssn-die-radio-value {
  width: 33%;
}

.ssn-value-box-content {
  margin: 11px 16px;
}

.ssn-file-box-content {
  margin: 16px 6px;
}

.ssn-value-box-content .ssn-pdn-content-value-input-item {
  padding: 5px 0;
}

.ssn-value-box-content .ssn-pdn-content-value-input-item .ssn-pdn-content-value-input-item-title {
  width: 60px;
  display: inline-block;
  line-height: 24px;
}

.ssn-value-box-content .ssn-pdn-content-value-input-item .ssn-pdn-content-value-input-item-input {
  width: calc(100% - 60px);
}

.CASCADE-ssn-value-box-content .ssn-pdn-content-value-input-item .ssn-pdn-content-value-input-item-title {
  width: 100px;
}

.CASCADE-ssn-value-box-content .ssn-pdn-content-value-input-item .ssn-pdn-content-value-input-item-input {
  width: calc(100% - 100px);
}

.ssn-file-box-content .netlist-model-row .ant-select-selector,
.ssn-file-box-content .netlist-model-row .netlist-model-selection,
.ssn-file-box-content .netlist-model-row .ant-select-selection,
.ssn-file-box-content .netlist-model-table-rows .editable-cell-value-wrap {
  height: 28px;
  line-height: 24px;
}

.ssn-file-box-content .netlist-model-table-rows {
  max-height: unset;
}

.ssn-file-box-content .aurora-select .ant-select-arrow {
  right: 11px;
}

.ssn-pdn-content-file-select-item {
  padding: 0px 10px 5px 10px;
}

.ssn-pdn-content-file-select-item .ssn-pdn-content-file-select-item-title {
  width: 100px;
  display: inline-block;
  line-height: 24px;
}

.ssn-pdn-content-file-select-item .aurora-select {
  width: calc(100% - 100px);
}

.model-apply {
  text-align: left;
}

.model-apply>label {
  margin: 0px 10px;
  vertical-align: middle;
}

.ssn-die-per-port {
  margin: 12px 16px;
}

.ssn-die-per-port-type {
  width: 100px;
  display: inline-block;
  line-height: 24px;
}

.ssn-die-per-port .ssn-die-radio-value {
  width: 50%;
}

.ssn-die-per-port-radio-group {
  width: 300px;
  vertical-align: middle;
}

.ssn-die-divider {
  margin: 10px 0;
}

.ssn-die-icon {
  color: #0d87f7;
  font-size: 16px;
  vertical-align: middle;
  cursor: pointer;
  margin-left: 6px;
}