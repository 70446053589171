.cpm-connection-edit-main {
  margin: 12px 0px;
  padding: 0px 14px;
  width: 100%;
  height: 100%;
}

.cpm-connection-canvas-content {
  height: 60%;
}

.cpm-connection-edit-table-content {
  padding-top: 10px;
  height: 40%;
}

.cpm-connection-edit-title {
  font-size: 16px;
  font-weight: 500;
}

.cpm-connection-edit-table {
  height: 100%;
}

/* canvas */
.cpm-setup-canvas {
  height: 100%;
  padding-bottom: 0px;
}

.cpm-setup-canvas-pin-name {
  position: absolute;
  z-index: 9999;
  padding: 10px;
  display: inline-block;
  vertical-align: middle;
  background-color: transparent;
  width: fit-content;
  bottom: 0px;
  right: 0px;
}

.cpm-setup-canvas-pin-title {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
}

.cpm-setup-canvas-pin-switch {
  margin-left: 10px;
  margin-top: -5px;
}

.cpm-setup-canvas-pin-name .unshow-switch {
  background-color: #999999;
}

.cpm-setup-canvas-node-radius-main {
  position: absolute;
  z-index: 9999;
  padding: 10px;
  background-color: transparent;
  width: fit-content;
  bottom: 0px;
  right: 140px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.cpm-setup-canvas-node-radius-main>.cpm-setup-canvas-pin-title {
  font-weight: 500;
  width: 86px;
}

.cpm-setup-canvas-node-radius-main>.aurora-input {
  width: 110px;
}

.cpm-setup-canvas-node-radius-main .ant-input-group-addon {
  background-color: #ffffff;
}

#cpm-setup-design-canvas polygon:hover,
#cpm-setup-model-canvas polygon:hover,
#cpm-setup-design-canvas circle:hover,
#cpm-setup-model-canvas circle:hover,
#cpm-setup-design-canvas path:hover,
#cpm-setup-model-canvas path:hover,
#cpm-setup-design-canvas polyline:hover,
#cpm-setup-model-canvas polyline:hover {
  stroke: unset
}

#cpm-setup-design-canvas .lay_geom:hover,
#cpm-setup-model-canvas .lay_geom:hover {
  stroke: yellow;
}

#cpm-setup-canvas-main {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

#cpm-setup-design-canvas-main,
#cpm-setup-model-canvas-main {
  width: calc(50% - 1px);
  height: 100%;
  position: relative;
  /*  background-color: #000000; */
}

.node-layout-canvas-coord {
  position: absolute;
  left: 5px;
  bottom: 10px;
  color: #fff;
  float: right;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.node-canvas-btn-group-vertical {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 1;
}

.node-canvas-layBtn {
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 1px 0;
  width: 100%;
  margin: 8px 4px;
  display: block;
  position: relative;
  font-size: 22px;
  color: #aaa;
}

.node-canvas-span-layBtn {
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 1px 0;
  width: 100%;
  margin: 8px 4px;
  display: block;
  position: relative;
  color: #aaa;
}

.node-canvas-layBtn:hover,
.node-canvas-span-layBtn:hover {
  background-color: #dddddd;
  color: #333;
  cursor: pointer;
}

.node-canvas-span-layBtn .iconfont {
  font-size: 26px;
}

.cpm-connection-edit-table-title {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 8px;
}

.cpm-connection-edit-table-delete-icon {
  color: #1890ff;
  margin-left: 10px;
}

/* #cpm-setup-canvas-main .cpm-edit-tri-mapping-panel .panel-modal-header {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
 */
#cpm-setup-canvas-main .cpm-edit-tri-mapping-panel {
  z-index: 10000 !important;
  top: 55px;
}

#cpm-setup-canvas-main .panel-modal-header {
  padding: 6px 10px !important;
}

#cpm-setup-canvas-main .panel-modal-close-x {
  width: 35px !important;
  height: 35px !important;
  line-height: 35px !important;
}

.cpm-edit-tri-mapping-table {
  margin: 6px 10px;
}

.cpm-tri-mapping-add-icon {
  color: #1890ff;
  /*  float: right; */
  margin-left: 10px;
}

.cpm-connection-match-button-content {
  width: 100%;
  text-align: center;
  margin-top: 4px;
  margin-bottom: 10px;
}

.cpm-connection-match-button {
  height: 24px;
  font-size: 14px;
  color: #000000d9;
}

/* .aurora-select-dropdown {
  z-index: 100000000;
} */

.cpm-edit-tri-mapping-panel-clean-icon {
  color: #1890ff;
  float: right;
  margin-right: 0px;
  margin-top: 3px;
}

.cpm-edit-tri-mapping-panel-eye-icon {
  color: #1890ff;
  margin-left: 8px;
  margin-top: 3px;
}

.cpm-edit-tri-mapping-table .ant-table-small>.ant-table-content>.aurora-table-body>table>.ant-table-thead>tr>th,
.cpm-edit-tri-mapping-table .ant-table-small>.ant-table-content>.aurora-table-body>table>.aurora-table-body>tr>td {
  padding: 6px 6px;
}

.cpm-connection-spice-download-content {
  margin: 6px 10px;
  margin-bottom: 0px;
}

.cpm-connection-spice-download-item>.cpm-connection-spice-download-label {
  width: 100px;
  display: block;
  height: 28px;
  line-height: 28px;
}

.cpm-connection-spice-download-item>.aurora-input {
  width: calc(100% - 100px);
}

.cpm-connection-spice-download-item>.aurora-select {
  height: auto;
  width: calc(100% - 100px);
  min-height: 28px;
  max-height: 120px;
}

.cpm-connection-spice-download-item>.aurora-select .ant-select-selector {
  max-height: 120px;
  overflow: auto;
}

.cpm-connection-spice-download-content .cpm-connection-spice-download-item {
  margin-bottom: 6px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.cpm-connection-spice-download-item>div {
  width: calc(100% - 100px);
  height: 28px;
  line-height: 28px;
}

.cpm-connection-edit-main .aurora-success-msg,
.cpm-connection-edit-main .aurora-model-name-error-msg {
  margin-top: 4px;
}

.cpm-connection-spice-download-select-dropdown {
  max-height: 400px !important;
}

.node-canvas-layBtn .icon-sanjiaoxing3 {
  font-size: 22px;
  font-weight: 600;
}

.match-error-log-panel {
  top: 200px;
  left: calc(50% - 400px) !important;
}

.match-error-log-content {
  height: 100%;
  padding: 5px 10px;
  /* position: relative; */
}

.match-error-log-content .match-error-log-box {
  overflow: auto;
  height: calc(100% - 85px);
  background-color: #f2f2f2;
  padding: 5px;
  width: 100%;
  margin-top: 10px;
}

.match-error-log-button-content {
  margin-top: 8px;
  text-align: center;
}

.match-error-log-button-content .match-error-log-button {
  height: 28px;
  padding: 0 10px;
}

.cpm-connection-edit-table-content .cpm-connection-edit-table .ant-table-content .ant-table-hide-scrollbar {
  min-width: 0 !important;
}

.cpm-connection-edit-table-content .cpm-connection-edit-table .ant-table-content .ant-table-hide-scrollbar>table {
  /* height: 40px; */
}

.cpm-connection-auto-match-pair-button {
  display: inline-block;
  margin: 5px 10px;
  color: #1890ff;
  cursor: pointer;
}