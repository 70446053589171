.probe-pins-panel {
  box-shadow: 0px 0px 8px 2px #969696;
}

.probe-pins-main {
  margin: 10px;
  min-height: 80px;
  border: 1px solid #eee;
  border-radius: 4px;
  height: calc(100% - 20px);
  overflow: auto;
}

.probe-pins-main>.probe-pins-main-div {
  min-width: 700px;
}

.probe-pins-component-box {
  position: relative;
  width: 100%;
  height: 100%;
}

.probe-pins-component-box .probe-pins-close-icon {
  position: absolute;
  right: -24px;
  top: 50%;
  margin-top: -7px;
  font-size: 14px;
  color: #ccc;
  cursor: pointer;
}

.probe-pins-main-no-data {
  text-align: center;
  height: 80px;
  line-height: 80px;
  color: rgb(0, 0, 0, 0.45);
}

.probe-pins-select-components-box {
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: 16px;
  overflow: auto;
  max-height: 800px;
}

.probe-pins-select-components-close-icon {
  position: absolute;
  right: 8px;
  top: 6px;
  font-size: 14px;
  color: #939393;
  cursor: pointer;
}

/* tree */
.checkable-tree .tree-node .ant-tree-title>span:not(.anticon) {
  display: inline-block;
  width: 100%;
  line-height: 24px;
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* component box */
.probe-pins-content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 10px 0px;
}

.probe-pins-content .probe-pins-component {
  background-color: #fcfbfb;
  border-radius: 4px;
  min-height: 32px;
  width: 220px;
  text-align: center;
  line-height: 28px;
  height: 100%;
  box-sizing: border-box;
  font-size: 14px;
  border: 2px solid #bdbdbd;
}

.probe-pins-content .probe-pins-component-pin {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 0px;
  position: relative;
  font-size: 13px;
}

.probe-pins-content .probe-pins-component-pin-left .probe-pins-component-pin {
  margin-right: -90px;
  text-align: left;
}

.probe-pins-content .probe-pins-component-pin-right .probe-pins-component-pin {
  margin-left: -90px;
  text-align: right;
}

.probe-pins-content .probe-pins-component-pin .probe-pins-pin {
  width: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.probe-pins-content .probe-pins-component-pin .probe-pins-pin>span {
  cursor: pointer;
  min-width: 26px;
  display: inline-block;
}

.probe-pins-content .probe-pins-component-pin-left .probe-pins-component-pin .probe-pins-pin>span {
  padding-left: 6px;
}

.probe-pins-content .probe-pins-component-pin-right .probe-pins-component-pin .probe-pins-pin>span {
  padding-right: 6px;
}

.probe-pins-content .probe-pins-component-pin .probe-pins-net {
  min-width: 130px;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #005a9e;
}

.probe-pins-content .probe-pins-component-pin-left .probe-pins-component-pin .probe-pins-net {
  padding-right: 6px;
  text-align: left;
}

.probe-pins-content .probe-pins-component-pin-right .probe-pins-component-pin .probe-pins-net {
  text-align: right;
  padding-left: 6px;
}

.probe-pins-component-pin-left,
.probe-pins-component-pin-right {
  display: block;
  height: 100%;
  min-width: 150px;
}

.probe-pins-content .probe-pins-line {
  width: 22px;
  flex: 0 0 auto;
  height: 5px;
  background-color: #a6a6a6;
  color: rgb(0, 0, 0, 0.65);
  transform: scaleY(0.2);
}

.probe-pins-content .probe-pins-circle {
  width: 28px;
  height: 15px;
  flex: 0 0 auto;
  border-radius: 4px;
  border: 1px solid #a6a6a6;
  background-color: #ffffff;
  cursor: pointer;
}

.probe-pins-content .probe-pins-component-pin-selected .probe-pins-circle {
  background-color: rgb(0, 0, 0, 0.45);
  border: 1px solid rgb(0, 0, 0, 0);
}

.probe-pins-content .probe-pins-component-pin .probe-pins-pin-not-allowed>span,
.probe-pins-content .probe-pins-circle-not-allowed {
  cursor: not-allowed;
}