.himalayas-report-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.himalayas-report-panel .panel-modal-header {
  padding-left: 14px !important;
}

.himalayas-report-panel .report-panel-title {
  font-size: 16px;
  font-weight: 500;
}

.himalayas-report-panel .report-content {
  padding: 10px 16px;
  border-radius: 6px 6px 0px 0px;
  height: 100%;
  min-width: 500px;
}

.himalayas-report-panel .report-content .report-item-title {
  font-weight: 500;
  line-height: 28px;
  width: 84px;
  display: inline-block;
  font-size: 13px;
  position: relative;
}

.himalayas-report-panel .report-content .report-select {
  width: calc(100% - 84px);
  font-size: 13px;
  min-height: 28px;
  height: auto;
  margin: 6px 0px;
}

.himalayas-report-select-dropdown {
  z-index: 10000000;
  max-height: 500px;
}

.himalayas-report-select-dropdown .ant-select-dropdown-menu-item {
  padding: 4px 12px;
  font-size: 13px;
}