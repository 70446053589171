.aurora-sider-footer {
  width: 100%;
  position: absolute;
  box-sizing: border-box;
  bottom: 0;
  height: 42px;
  border-top: 1px solid #e2dfdf;
}

.aurora-sider-footer .view-icon-disabled {
  color: #a0a0a0;
  cursor: not-allowed;
}

.aurora-sider-footer .layout-icon-disabled .aurora-layout-button-right,
.aurora-sider-footer .layout-icon-disabled .aurora-layout-button-top {
  background: #c5c5c5;
}

.aurora-sider-footer .layout-icon-disabled {
  border: 1px #c5c5c5 solid;
  color: #a0a0a0;
  cursor: not-allowed;
}

.aurora-sider-footer .sider-switch-icon {
  display: inline-block;
  vertical-align: middle;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 6px;
  color: #737373;
}

.aurora-sider-footer .sider-switch-vertical-icon {
  padding: 0 17px;
  padding-bottom: 4px;
}

.aurora-sider-footer .pcb-icon {
  font-size: 25px;
  line-height: 32px;
  cursor: pointer;
}

.aurora-sider-footer .sider-switch-vertical-icon .pcb-icon {
  margin-left: -3px;
}

.aurora-sider-footer .sider-layout-div {
  display: inline-block;
}

.aurora-sider-footer .sider-layout-vertical-div {
  padding: 0 10px;
  padding-bottom: 4px;
}

.aurora-sider-footer .sider-layout-vertical-div .aurora-layout-button-1 {
  margin-bottom: 9px;
  margin-top: 3px;
}

.aurora-sider-footer .sider-layout-vertical-div .aurora-layout-button-2 {
  margin-bottom: 4px;
}

.aurora-switch-icon-selected {
  color: #0d87f7 !important;
}

.pcb-split-icon-box .aurora-layout-button-1 {
  width: 30px;
  height: 30px;
  line-height: 28px;
  margin-left: 3px;
  margin-right: 5px;
}

.pcb-split-icon-box .aurora-layout-button-2 {
  height: auto;
  width: 30px;
  margin-left: 3px;
  margin-right: 5px;
}

.pcb-split-icon-box .aurora-layout-button-left,
.pcb-split-icon-box .aurora-layout-button-right {
  line-height: 28px;
  color: #444444;
}

.pcb-split-icon-box .aurora-layout-button-bottom,
.pcb-split-icon-box .aurora-layout-button-top {
  line-height: inherit;
  color: #444444;
}

.pcb-split-icon-box .aurora-layout-button-3 {
  width: 30px;
  height: 30px;
  border: 1px #a5a5a5 solid;
  border-radius: 2px;
  line-height: 28px;
  text-align: center;
  margin-left: 3px;
  color: #444444;
  font-size: 10px;
}

.pcb-split-icon-box .aurora-layout-button-3.aurora-layout-selected {
  border-color: #63aef3;
  border-style: solid;
  background: #85bcff;
}