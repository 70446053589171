.himalayas-sider-menu-tree .ant-tree .ant-tree-title .aurora-project-name-input .aurora-tree-create-icon {
  visibility: visible;
  position: static;
  line-height: 24px;
  height: 22px;
}

.himalayas-sider-menu-tree .ant-tree-title:hover .himalayas-library-title {
  width: calc(100% - 26px);
}

.himalayas-sider-menu-tree .himalayas-rename-icon {
  margin-right: 22px;
}

.himalayas-sider-menu-tree .aurora-tree-project-edit-icon {
  margin-right: 22px;
}

.himalayas-sider-menu-tree .ant-tree-title:hover .himalayas-library-edit-title {
  margin-right: 22px;
  width: calc(100% - 46px);
}