.header {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 54px;
  line-height: 54px;
  background: #001529;
  padding: 0 50px
}

.header .header-menu {
  float: right;
  padding: 0px;
  margin: 0px;
}

.header .header-menu li {
  float: left;
  font-size: 16px;
  margin-right: 40px;
}


.header .header-menu li .dld_login {
  display: none;
  color: rgba(255, 255, 255);
}

@media only screen and (max-width: 1050px) {
  .dld_login {
    display: inline-block !important;
    color: #ffffff !important;
    cursor: pointer;
    line-height: 60px;
    height: 54px;
  }

  .dld_login:hover {
    opacity: 1;
  }

  .dld {
    display: none;
  }

  .header-has-download-menu .aurora-menu-icon {
    display: inline-block;
    color: #ffffff;
    cursor: pointer;
    opacity: 0.7;
    line-height: 60px;
    height: 54px;
  }

  .header-has-download-menu .aurora-menu-icon:hover {
    opacity: 1;
  }

  .header-has-download-menu .aurora-menu-a {
    display: none;
  }
}


.header .header-menu li .dld {
  color: rgba(255, 255, 255);
}

@media (max-width: 1024px) {
  .header .header-menu li {
    margin-right: 20px;
  }
}

.header .header-menu li span {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
}

.header .header-menu li span:hover {
  color: #fff;
}

.logo {
  width: 142px;
  height: 54px;
  float: left;
  line-height: 54px;
  opacity: 0.8;
}

.logo img {
  height: 40px;
  margin-right: 10px;
  /* margin-bottom: 14px; */
  margin-bottom: 8px;
}

.logo h1 {
  color: #fff;
  margin-bottom: 0px;
  display: inline-block;
  font-size: 21px;
  /*  font-size: 30px; */
  /* font-weight: bold;
  line-height: 60px;
  background-image: linear-gradient(to right, yellow, #f070ff, #00ffa1);
  background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.logo:hover {
  opacity: 1;
}

.account {
  float: right;
  color: #fff;
  opacity: 0.7;
  cursor: pointer;
}

.account:hover {
  opacity: 1;
}

.account .avatar {
  margin-right: 10px;
  background-color: #ccc;
}

img.header-lcfc-icon {
  height: 54px;
  padding: 13px 10px 13px 0;
  float: left;
}

.lcfc-account .avatar {
  background: white;
}

.lcfc-account .ant-avatar>img {
  width: 80%;
  height: 80%;
}

.header-lcfc-Separator {
  height: 18px;
  margin: 18px 10px 0px 0;
  float: left;
  border-right: 1px solid #767676;
}

.account .download {

  margin-top: 4px;
  margin-right: 43px;
  color: #fff;

}

.ant-avatar-download-Icon {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, .65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}

@media only screen and (max-width:530px) {
  .account .download {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0px;
    margin-right: 117px;
  }

  .account .download-Icon {
    box-sizing: border-box;
    position: absolute;
    display: inline-block;
    overflow: hidden;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    background: #ccc;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 50%;
    top: 0;
    right: 0;
    margin-top: 13px;
    margin-right: 186px;
  }
}

.account a {
  color: #fff;
}

.account a:active {
  color: #fff;
}

.account a:link {
  text-decoration: none;
}

.AccountMenuItem .ant-dropdown-menu-item {
  height: 32px;
  line-height: 32px;
  padding: 0px;
}

.AccountMenuItem span {
  display: inline-block;
}

.log-in-btn {
  display: inline-block;
}

.ant-layout .develop-panel {
  transform: translate3d(75%, 120px, 0px);
}

.ant-layout .account-panel {
  transform: translate3d(75%, 120px, 0px);
}

.development-name {
  font-size: 16px;
  font-weight: bold;
}

.develop-content {
  margin: 20px;
}

.develop-content-item,
.debug-content-item {
  width: 100%;
  margin-bottom: 12px;
}

.debug-content-title {
  font-size: 14px;
  font-weight: bold;
  vertical-align: top;
}

.debug-content-body {
  font-size: 14px;
  padding-left: 10px;
  vertical-align: middle;
}

.develop-content-radio {
  font-size: 14px;
}

.develop-content-render {
  margin: 10px;
}

.account-password .account-password-item-1 {
  margin: 6px 0px 0px;
}

.account-password-title {
  font-size: 14px;
  font-weight: bold;
}

.debug-switch {
  margin-left: 8px;
}

.debug-content {
  display: inline-block;
  margin: 8px 10px 10px 0;
}

.setting-version {
  float: left;
  height: 32px;
  line-height: 32px;
}

.header-product-version {
  float: left;
  color: #fff;
  opacity: 0.7;
  line-height: 54px;
  font-size: 14px;
  height: 54px;
}

.translation-switch,
.develop-switch {
  display: inline-block;
  margin: 0px 10px;
}

.translation-content-title,
.develop-content-title,
.user-default-setting-title {
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
}

.user-setup-couplingMinFreqValue {
  margin-left: 20px;
}

.translation-content-title-cursor {
  cursor: pointer;
}

.translation-content-input {
  width: 200px;
  margin-left: 20px;
}

.translation-content-input input {
  height: 28px;
}

.translation-error-msg {
  display: block;
  padding: 8px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 10px;
}


/* .logo-name-aurora {
  font-family: 'IndieFlower';
} */

.header-product-name {
  font-family: 'IndieFlower';
  font-weight: bold;
  font-size: 22px;
  margin: 0px 20px 0 110px;
  display: block;
  line-height: 54px;
  height: 54px;
  padding-top: 3px;
  float: left;
}

@media only screen and (max-width: 890px) {
  .header-product-name {
    margin: 0px 20px 0 20px;
  }
}


@media only screen and (max-width: 930px) {
  .header-version {
    display: none;
  }
}

@media only screen and (max-width: 560px) {
  .ant-layout-header {
    padding: 0 30px;
  }
}

.aurora-menu {
  display: inline-block;
}

.aurora-menu-icon {
  display: none;
}

@media only screen and (max-width: 610px) {
  .aurora-menu-icon {
    display: inline-block;
    color: #ffffff;
    cursor: pointer;
    opacity: 0.7;
    line-height: 60px;
    height: 54px;
  }

  .aurora-menu-icon:hover {
    opacity: 1;
  }

  .aurora-menu-a {
    display: none;
  }
}

.user-setup-panel-advanced {
  font-weight: normal;
  margin-left: 6px;
}

.user-setup-panel-advanced-icon {
  font-size: 12px;
}

.user-setup-panel-advanced-box {
  display: flex;
  line-height: 28px;
}

/* email setting */
.email-setting-content {
  margin-bottom: 12px;
  width: 100%;
}

.email-setting-content .email-setting-item {
  margin: 6px 0px;
  display: flex;
  margin-left: 20px;
}

.email-setting-item .email-setting-label {
  display: inline-block;
  width: 70px;
  line-height: 28px;
}

.email-setting-item .aurora-tags-input-content {
  padding: 0px 5px;
}

.email-setting-input,
.email-setting-select {
  width: calc(100% - 80px);
  font-size: 12px;
}

.email-setting-content .email-setting-notification {
  margin-left: 0px;
}

.email-setting-content .email-setting-notification .email-setting-label {
  width: 130px;
  font-weight: bold;
}

.email-setting-content .email-setting-notification>div {
  line-height: 26px;
}

.user-default-setting-title {
  margin-left: 5px;
  cursor: pointer;
}

.user-default-setting-item {
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
  width: 190px;
}

.user-default-setting-content .user-default-setting-title-item {
  display: inline-block;
  width: 280px;
}

.user-default-setting-content {
  padding-left: 20px;
}

.license-time {
  font-weight: normal;
  color: #767676;
  font-size: 12px;
  display: inline-block;
  padding-left: 10px;
}

.license-time-icon {
  margin-left: 5px;
  color: #1890ff;
}

.user-default-setting-div {
  margin: 10px 0px;
}

.level-setting-input {
  width: 200px;
}

.user-default-setting-ports-content {
  margin-left: 12px;
}

.user-default-setting-ports-content .signal-port-edit-item>label {
  width: 180px;
}