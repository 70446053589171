li {
  list-style: none;
}

.clear {
  zoom: 1;
}

.clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.sierra-footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: #f9f9f9;
  border-top: 1px solid #e2dfdf;
  width: 100%;
  z-index: 20;
  box-sizing: border-box;
}

/* my sierra monitor */

.my-sierra-monitor {
  overflow: auto;
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  top: 44px;
  left: 102px;
  right: 14px;
  bottom: 14px;
  font-weight: 500;
}

.my-sierra-monitor-ccc {
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  top: 44px;
  left: 102px;
  right: 14px;
  bottom: 14px;
  font-weight: 500;
}

.my-sierra-monitor-title {
  font-weight: bold;
  line-height: 44px;
  padding-left: 20px;
  float: left;
}

.my-sierra-middle {
  position: relative;
  height: 44px;
  overflow: hidden;
  padding-right: 20px;
}

.my-sierra-monitor-progress {
  position: absolute;
  top: 0px;
  height: 44px;
  line-height: 44px;
  width: 100%;
}

.monitor-upload-progress-bar {
  width: 100%;
}

.monitor-upload-progress-bar .ant-progress-text {
  font-weight: bold;
  vertical-align: unset;
}

.my-sierra-download-btn {
  position: relative;
  z-index: 20;
}

.my-sierra-debug-btn {
  position: relative;
  z-index: 20;
}

.design-msg-style {
  color: #f5222d;
  margin: 0;
  text-align: center;
}

.sierra-simulation-holygrail {
  position: absolute;
  right: 40px;
  left: 0;
  box-sizing: border-box;
}

.sierra-simulation-title {
  width: 100%;
  position: relative;
  height: 44px;
}

.sierra-simulation-btn {
  position: relative;
  float: right;
  height: 44px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  width: 40px;
  margin-right: 12px;
}

.sierra-simulation-msg {
  position: relative;
  float: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  min-width: 160px;
  text-align: center;
  margin-left: 16px;
  height: 44px;
}

.sierra-simulation-span {
  position: relative;
  float: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  min-width: 80px;
}

.sierra-workflow-msg {
  position: relative;
  float: left;
  height: 44px;
  line-height: 42px;
  text-align: center;
  min-width: 80px;
  margin-left: 20px;
}

.workflow-msg {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 28px;
  font-size: 16px;
}

.sierra-simulation-project {
  margin-right: 10px;
}

.span-msg,
.span-msg-project {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 40px;
}

.sierra-simulation-button,
.sierra-upload-button,
.sierra-cancel-button,
.sierra-kill-button {
  float: right;
  line-height: 44px;
  height: 44px;
  font-size: 18px;
  color: #1890ff;
  position: relative;
  z-index: 20;
  cursor: pointer;
  margin-left: 16px;
  box-sizing: border-box;
}

.sierra-cancel-button {
  line-height: 43px;
}

.sierra-kill-button {
  line-height: 45px;
  font-size: 19px;
  margin-left: 18px;
}

.sierra-simulation-progress .ant-progress-outer {
  width: 90%;
}

.sierra-simulation-progress .ant-progress-outer .ant-progress-inner {
  background-color: #fff;
}

.sierra-simulation-progress .ant-progress-text {
  margin-left: 4px;
}

.icon-tooltip .ant-tooltip-inner {
  color: #1890ff;
  background-color: #fff;
}

.icon-tooltip .ant-tooltip-arrow::before {
  background-color: #fffdfd;
}

.span-msg {
  text-indent: 8px;
}

.sierra-monitor-ul {
  margin: 0;
  padding: 0;
}

.sierra-button {
  font-size: 18px;
}

.sierra-button-cancel {
  transform: rotate(90deg);
}

.debug-mes-span {
  display: block;
}

.closed-message {
  display: block;
}

.sierra-simulation-btn .icon-cancel9 {
  font-size: 20px;
  line-height: 44px;
  color: #1890ff;
}

.sierra-button-icon {
  font-size: 22px;
}

.sierra-simulation-title .span-msg-icon {
  font-size: 18px;
  color: #1890ff;
}

.sierra-simulation-title .span-msg-icon-div {
  padding-left: 12px;
  margin-left: 4px;
  width: 100%;
  margin-top: 12px;
}

.sierra-span-msg-tooltip {
  display: block;
  text-indent: 8px;
}

.sierra-update-pcb-box {
  padding: 10px 20px;
  font-size: 14px;
  line-height: 22px;
}

.sierra-update-pcb-span {
  padding-left: 10px;
  display: block;
}

.sierra-update-library-box {
  padding: 0px 20px;
  font-size: 14px;
  line-height: 22px;
  margin: 0px;
}

.sierra-update-library-box .sierra-warning-dialog {
  padding: 0px;
  margin: 0px;
  color: #303030;
  padding-top: 10px;
}

.sierra-simulation-middle .simulation-waiting-title {
  line-height: 42px;
  font-weight: bold;
  float: right;
}

.sierra-simulation-middle .simulation-waiting-title .waiting-time-span {
  display: inline-block;
  margin-left: 10px;
  font-weight: 500;
}

.sierra-monitor-backbottom {
  position: absolute;
  font-size: 18px;
  right: 40px;
  bottom: 40px;
  opacity: 0.6;
  background-color: #1890ff;
  color: #fff;
  transform: rotate(90deg);
  padding: 4px;
  border-radius: 4px;
}

.sierra-monitor-backbottom:hover {
  opacity: 0.8;
  font-weight: bold;
}

.sierra-stackup-error-dialog {
  padding-top: 10px;
  padding-left: 20px;
  color: #ff4848;
}

.macro-modeling-error-span {
  display: block;
  color: #ff4848;
  line-height: 22px;
  padding: 0px 30px;
}

.sierra-stackup-error-check-main {
  padding: 10px 20px;
  color: #ff4848;
}

.sierra-stackup-error-check-main .aurora-stackup-error-monitor-content {
  margin-left: 10px;
}

/* multi setup monitor  */
.multi-setup-monitor-title {
  font-weight: 600;
}

.multi-setup-monitor-logs,
.multi-setup-monitor-sub-logs {
  padding: 0px;
  padding-bottom: 2px;
}

.multi-setup-monitor-content {
  padding-left: 12px;
  padding-bottom: 10px;
}

.multi-setup-monitor {
  padding: 10px 12px;
}

.multi-setup-monitor-content .sierra-warning-dialog {
  padding: 0px;
  padding-bottom: 2px;
  margin-bottom: 0px;
}

.sierra-monitor-ul .sierra-monitor-pre-log,
.sierra-monitor-ul .sierra-monitor-li>p {
  margin: 0;
}

.sierra-monitor-ul .sierra-monitor-li {
  list-style: none;
  width: 100%;
}

.sierra-monitor-ul .sierra-monitor-li>.error {
  color: #cb2431;
  font-weight: 600;
}

.sierra-monitor-ul .sierra-monitor-li>.warning {
  color: #fa8c16;
}

.sierra-monitor-ul .sierra-monitor-li>.bold {
  font-weight: 600;
}