.additional-nets-radio {
  display: inline-block;
  margin-left: 20px;
}

.additional-nets-title-color {
  color: #0b3694;
}

.additional-nets-select {
  max-width: calc(100% - 440px);
  min-width: 300px;
}