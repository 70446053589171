.pdn-setting {
  height: 100%;
  min-width: 1100px;
}

.pdn-setting>.ant-spin-nested-loading>.ant-spin-container {
  padding: 20px;
}

.space-10 {
  margin-top: 10px;
}

.space-16 {
  margin-top: 16px;
}

.voltage-input-box {
  margin-top: 10px;
  width: 50%;
  margin-left: 20px;
}

.pdn-components-table .aurora-table-body .ant-table-row>td>.editable-cell-value-wrap,
.pdn-powerdomain-table .aurora-table-body .ant-table-row>td>.editable-cell-value-wrap,
.pdn-vrm-tables .aurora-table-body .ant-table-row>td>.editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  white-space: normal;
}

.pdn-vrm-tables .aurora-table-body .ant-table-row>td>.editable-cell-value-wrap {
  overflow: visible !important;
}

.pdn-components-table .ant-table-column-title {
  padding-left: 4px;
}

.pdn-components-part-name {
  margin-left: -4px;
}

.pdn-components-table .select-width .ant-select-selection,
.pdn-powerdomain-table .select-width .ant-select-selection,
.pdn-vrm-tables .select-width .ant-select-selection {
  margin: -4px 0px;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.pdn-components-table .editable-row:hover .editable-cell-value-wrap,
.pdn-powerdomain-table .editable-row:hover .editable-cell-value-wrap,
.pdn-vrm-tables .editable-row:hover .editable-cell-value-wrap {
  border-color: #d9d9d9;
  background: #e6f7ff;
}

.pdn-components-table tbody>tr>td>span,
.pdn-components-table tbody>tr>td>.pdn-component-td>span,
.pdn-powerdomain-table tbody>tr>td>span,
.pdn-vrm-tables tbody>tr>td>span {
  display: block;
  max-height: 80px;
  overflow: auto;
}

.pdn-components-table tbody>tr>td .ant-select-selection--multiple,
.pdn-powerdomain-table tbody>tr>td .ant-select-selection--multiple,
.pdn-vrm-tables tbody>tr>td .ant-select-selection--multiple {
  padding-bottom: 3px;
}

.pdn-powerdomain-table tbody>tr>td .ant-select-selection--multiple .ant-select-selector>ul>li {
  height: 24px;
  line-height: 22px;
}

.clear {
  zoom: 1;
}

.clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.components-pkg-name {
  color: #909090;
  padding-left: 5px;
}

.components-pkg-name:hover {
  cursor: not-allowed;
}

.pdn-components-ignore-name {
  color: #e65b5b;
  padding-left: 5px;
}

.pdn-components-table .editable-cell-value-wrap .pdn-components-ignore-name {
  padding-left: 0px;
}

.pdn-components-unused-name {
  color: #e65b5b;
}

.pdn-select-dropdown-menu .ant-select-dropdown-menu-item {
  position: relative;
  padding-left: 30px;
}

.pdn-select-dropdown-menu .ant-select-dropdown-menu-item .ant-select-selected-icon {
  text-align: left !important;
  left: 10px !important;
  font-weight: bold;
  /* color: #1890ff; */
}

.vrm-default-name {
  color: #939593;
}

.power-domain-name {
  color: rgba(0, 0, 0, 0.85);
}

.pdn-components-name {
  padding-left: 5px;
}

.decap-dialog-close {
  position: absolute;
  right: 12px;
  top: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  line-height: 24px;
  transition: 0.3s;
}

.decap-dialog-close:hover {
  background: #ececec;
  transition: 0.3s;
}

.decap-close-icon {
  color: rgba(0, 0, 0, 0.45);
}

.VRM-add-icon {
  color: #0d87f7;
  font-size: 16px;
  margin-left: 14px;
  cursor: pointer;
  vertical-align: middle;
}

.VRM-setting-icon {
  color: #f39300;
  font-size: 17px;
  margin-left: 12px;
  cursor: pointer;
  vertical-align: middle;
  margin-top: -2px;
}

.pdn-component-prefix-prompt {
  display: inline-block;
  transform: translate(2px, -25px);
  font-size: 26px;
  vertical-align: middle;
  position: absolute;
  color: #f39300;
  background-color: #ffffff;
  cursor: pointer;
}

.pdn-components-table .ant-table>.ant-table-content>.aurora-table-body>table>.aurora-table-body>tr>td {
  word-break: break-word;
}

.pdn-components-table td {
  position: relative;
}

.pdn-component-arrow {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 8px solid #969696;
  width: 0;
  height: 0;
  position: absolute;
  right: 26px;
  bottom: 6px;
}

.extraction-main {
  margin-left: 20px;
}

.advanced-main {
  margin: 10px 20px;
}

.extraction-content {
  line-height: 28px;
  margin-bottom: 4px;
}

.extraction-content .extraction-input {
  display: inline-block;
  width: 44%;
  vertical-align: middle;
  height: 28px;
}

.extraction-input .ant-input-group,
.extraction-input .ant-input-group .ant-input,
.extraction-input .ant-input-group .ant-input-group-addon,
.extraction-vrm-input .ant-input-group,
.extraction-vrm-input .ant-input-group .ant-input,
.extraction-vrm-input .ant-input-group .ant-input-group-addon {
  height: 28px;
}

.extraction-vrm-input {
  width: 40%;
}

.extraction-content-body {
  font-size: 14px;
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;
  width: 55%;
  padding-right: 10px;
}

.advanced-vrm-content-body {
  font-size: 14px;
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;
  width: 55%;
  padding-right: 10px;
}

.simulation-content-body,
.vrm-content-body {
  font-size: 14px;
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;
  width: 78%;
}

.sub-extraction-content-body {
  font-size: 14px;
  padding-left: 10px;
  vertical-align: middle;
  display: inline-block;
  width: 55%;
  text-align: 20px;
  font-style: italic;
}

.extraction-name {
  font-size: 16px;
  font-weight: bold;
}

.advanced-name {
  font-size: 14px;
  font-weight: bold;
  margin-top: 6px;
}

.advanced-name span {
  cursor: pointer;
}

.extraction-option-error-msg {
  display: block;
  padding: 10px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin-top: 10px;
}

.extraction-tooltip {
  z-index: 10000;
}

.sub-extraction-box {
  display: inline-block;
  margin-left: 20px;
  margin-right: 6px;
  width: 8px;
  height: 8px;
  background: cornflowerblue;
  border-radius: 4px;
}

.pdn-component-split-panel {
  margin-top: 40px;
}

.model-color {
  color: #a5a5a5;
}

.pdn-component-advance {
  font-size: 17px;
  color: #0d87f7;
  vertical-align: middle;
  margin-left: 20px;
}

.pdn-component-split-part-select,
.pdn-component-split-table {
  margin: 8px;
}

.pdn-component-split-select-dropdown {
  z-index: 1000000;
}

.pdn-component-split-select {
  margin-left: 10px;
  margin-right: 20px;
  width: 200px;
}

.pdn-component-split-row,
.pdn-component-split-row>td {
  background-color: #e6ffed !important;
}

.pdn-component-split-row:hover>td,
.pdn-component-split-row:hover .editable-cell-value-wrap {
  background-color: #e6ffed !important;
}

.pdn-component-split-row:hover .editable-cell-value-wrap {
  border-color: #fff !important;
}

.pdn-component-select-row,
.pdn-component-select-row>td {
  background-color: #adddfd !important;
}

.pdn-component-select-row:hover>td,
.pdn-component-select-row:hover .editable-cell-value-wrap {
  background-color: #adddfd !important;
}

.pdn-component-select-row:hover .editable-cell-value-wrap {
  border-color: #fff !important;
}

.pdn-component-merge-row,
.pdn-component-merge-row>td {
  background-color: #fffbdd !important;
}

.pdn-component-merge-row:hover>td,
.pdn-component-merge-row:hover .editable-cell-value-wrap {
  background-color: #fffbdd !important;
}

.pdn-component-merge-row:hover .editable-cell-value-wrap {
  border-color: #fff !important;
}

.pdn-component-advance-disable {
  color: #b8b8b8;
  cursor: not-allowed;
}

.pdn-setup-title-color {
  color: #0b3694;
}

.pdn-setup-border {
  border: 1px solid #a8c4e6;
  /* border: 1px solid #c0d3eb;
  background: #f1f8ff; */
  padding: 10px;
  border-radius: 3px;
}

.pdn-setting .ant-table-small {
  border: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}

.pdn-setting .ant-table-small.ant-table-bordered .ant-table-content {
  border-right: 0;
}

/* pdn top bar */
#pdn-top-bar-block {
  height: 45px;
  padding-left: 10px;
  background-color: #f3f2f1;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.pdn-top-bar-item {
  padding: 6px 14px;
  transition: all 0.3s;
  color: #005a9e;
  cursor: pointer;
}

.pdn-top-bar-item span {
  display: flex;
  align-items: center;
}

.pdn-top-bar-item:hover {
  background-color: #edebe9;
}

.pdn-top-bar-item .iconfont,
.pdn-top-bar-item .anticon-play-circle,
.pdn-top-bar-item .anticon-setting,
.pdn-top-bar-item .anticon-loading {
  margin-right: 8px;
  font-size: 16px;
}

.pdn-top-bar-item .icon-Debug {
  font-size: 19px;
  line-height: 20px;
}

.pdn-top-bar-item+.pdn-top-bar-item {
  margin-left: 20px;
}

.pdn-top-bar-item-disabled {
  color: #797979 !important;
  cursor: not-allowed !important;
}

.pdn-top-bar-item-hide {
  height: 20px;
  line-height: 20px;
}

.pdn-top-bar-tip .ant-tooltip-inner {
  color: #00aaff;
  background-color: #fff;
}

.pdn-top-bar-tip .ant-tooltip-arrow::before {
  background-color: #fff;
}

#pdn-top-bar-block.hide .pdn-top-bar-item .iconfont,
#pdn-top-bar-block.hide .pdn-top-bar-item .anticon-play-circle,
#pdn-top-bar-block.hide .pdn-top-bar-item .anticon-setting,
#pdn-top-bar-block.hide .pdn-top-bar-item .anticon-loading {
  margin-right: 0px;
}

#pdn-top-bar-block.hide .pdn-top-bar-item {
  margin-left: 10px;
}

#pdn-top-bar-block.hide .pdn-top-bar-item .pdn-top-bar-item-hide {
  display: none;
}

.pdn-include-extended-content {
  display: inline-block;
  margin-left: 30px;
}

.pdn-include-extended-content .pdn-include-extended-title {
  vertical-align: middle;
  font-weight: 600;
}

.pdn-include-extended-content .ant-switch-small {
  margin-left: 10px;
  vertical-align: middle;
}

.pdn-powerdomain-error-tip {
  padding: 4px 8px;
}

.pdn-powerdomain-error-tip>span:first-child {
  font-weight: 500;
}

.pdn-powerdomain-error-icon {
  color: #FF0000;
  font-size: 14px;
  margin: 0 0 0 14px;
  cursor: pointer;
}

.pdn-powerdomain-error-msg {
  color: #f39300;
  font-size: 16px;
  margin-left: 14px;
  cursor: pointer;
}

.pdn-powerdomain-error-list,
.pdn-powerdomain-error-list li {
  margin: 0;
  padding: 0;
}

.pdn-powerdomain-error-list {
  margin-top: 8px;
}

.pdn-powerdomain-error-net {
  font-weight: 500;
}

.pdn-vrm-title {
  vertical-align: middle;
}

.pdn-part-name {
  padding-left: 4px;
}

.pdn-file-check-span {
  color: #f30518;
  width: 90%;
}

.pdn-file-check-icon {
  position: absolute;
  right: 14px;
  top: 50%;
  margin-top: -9px;
  color: #f13140;
  font-size: 18px;
}

.pdn-vrm-setting-panel {
  width: 400px;
  box-shadow: 0 4px 12px 4px rgba(0, 0, 0, 0.15);
}

.pdn-vrm-setting-content {
  height: 100%;
  width: 100%;
  padding: 16px;
}

.pdn-vrm-setting-item {
  width: 100%;
  padding: 5px;
  position: relative;
}

.pdn-vrm-setting-select {
  width: 65%;
  border-radius: 4px;
  vertical-align: middle;
}

.pdn-vrm-setting-title {
  font-size: 14px;
  display: inline-block;
  width: 15%;
  vertical-align: middle;
}

.pdn-vrm-setting-button {
  margin-left: 10px;
  vertical-align: middle;
}