/*TODO*/

.simulation-progress .ant-progress-inner {
  background: #ddd;
}

.simulation-progress .ant-progress-text {
  color: rgba(0, 0, 0, 0.6);
}

.setting-signal-expand {
  margin: 0px !important;
}

.setting-signal-expand .ant-table-small > .ant-table-content > .aurora-table-body {
  padding: 0px;
  margin: 0px;
}

.setting-signal-expand .ant-table-small,
.setting-signal-expand .ant-table-small.ant-table-bordered .ant-table-content {
  border: 0px !important;
}

.component-pin-tabs .ant-tabs-bar {
  margin: 0px;
}

.pintopin-signal-row {
  margin-top: 20px;
}

.component-pin-tabs .ant-tabs-tab {
  font-weight: bold;
}

.pintopin-warning-dialog {
  padding: 10px;
  border: 2px solid #ff9898;
  border-radius: 4px;
  background: #f9f9f9;
  position: absolute;
  top: 45px;
  bottom: 6px;
  width: 98%;
}

.pintopin-warning-title {
  color: #ff4848;
  margin-top: 10px;
  display: inline-block;
}

.footer-monitor {
  width: 99%;
  background: #f4f4f4;
  min-width: 980px;
}

.log-box {
  float: right;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}

.monitor-log {
  overflow: auto;
  border: 2px solid rgb(182, 182, 182);
  margin-top: 10px;
  background: #fafafa;
  border-radius: 4px;
  position: absolute;
  bottom: 20px;
  top: 35px;
  left: -2px;
  right: -10px;
}

.error-box {
  width: 49%;
  height: 100%;
  position: absolute;
}

.closed-message {
  display: inline-block;
  width: 100%;
}

.debugButton {
  position: relative;
  z-index: 2;
}

.logButton {
  position: relative;
  z-index: 2;
}

.button-content {
  text-indent: 4px;
}

.button-icon {
  font-size: 18px;
}

.span-tooltip {
  display: inline-block;
  width: 100%;
}

.verify-footer-name {
  font-weight: bold;
  line-height: 45px;
  display: block;
  float: left;
}

.icon-explores {
  color: #0055a2;
  font-size: 20px;
  font-weight: bolder;
  font-family: serif;
  display: block;
  float: right;
  line-height: 20px;
}

.andes-component-table .ant-table-thead > tr > th .ant-table-header-column,
.andes-pin-table .ant-table-thead > tr > th .ant-table-header-column {
  display: block !important;
}

.pintopin-pin-corner-box {
  margin: -4px;
}

.pintopin-pin-corner {
  padding: 0 5px;
  line-height: 36px;
}

.pintopin-pin-corner-explor {
  cursor: pointer;
  position: absolute;
  top: 11px;
  right: 6px;
  transition: 0.5s all;
  height: 14px;
  line-height: 16px;
}

.andes-pin-table tbody > tr > td:nth-child(6),
.andes-pin-table tbody > tr > td:nth-child(5),
.andes-component-table tbody > tr > td:nth-child(4) {
  position: relative;
}

.pintopin-pin-corner-cell,
.pintopin-pin-stimulus-cell {
  margin-right: 26px !important;
  width: auto !important;
}

.component-pin-tabs .ant-tabs-tab,
.component-pin-tabs .ant-tabs-tab-active {
  font-weight: bold !important;
}

.log-button {
  font-size: 14px;
  color: #1890ff;
  cursor: pointer;
}

.download-button {
  text-indent: 24px;
  font-size: 18px;
  color: #1890ff;
  cursor: pointer;
}

.info-size {
  font-size: 15px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  float: left;
}

.title-size {
  font-size: 15px;
  display: block;
  overflow: hidden;
  float: left;
}

.pcb-size {
  font-size: 15px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  float: left;
}

.verify-content-footer {
  overflow: auto;
}

.verify-footer-button {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.progress-footer-span {
  display: inline-block;
  width: 100%;
  padding: 0 20px;
}

.debug-footer-col {
  padding-left: 30px !important;
  text-align: center;
}

.log-footer-col {
  padding-left: 5px !important;
  text-align: center;
}

.result-footer-col {
  padding-left: 0 !important;
  text-align: center;
}

.verify-footer-col {
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
}

.btn-width {
  width: 20%;
  max-width: 80px;
  margin-left: 10px;
  display: block;
  float: left;
}

.btn-width button {
  width: 100%;
}

.btn-width .btn-width-tooltip {
  width: 100%;
}

.monitor-button {
  min-width: 629px;
}

.monitor-top {
  margin: 0px;
  padding: 0px;
  line-height: 45px;
}

.monitor-top-col {
  padding: 0px;
}

.btn-width-12 {
  width: 12%;
}

.btn-width-max-120 {
  max-width: 110px;
}

.verify-cancel-btn {
  background: #797979;
  border: 0px;
  color: #f4f4f4;
}

.verify-cancel-btn:hover,
.verify-cancel-btn:focus {
  background: #969696;
  color: #ffffff;
}

.signal-expand-icon {
  position: absolute;
  right: 20px;
}

/* ============== PinToPin setting =================*/

.andes-content-setting {
  padding: 20px;
  min-width: 900px;
}

.simulation-config-item {
  display: inline-block;
  line-height: 32px;
}

.width-33 {
  width: 33%;
}

.width-25 {
  width: 25%;
}

.simulation-option-select {
  width: 50%;
}

.simulation-config-name {
  font-weight: bold;
  margin-right: 10px;
}

.verify-setup-input-select {
  width: 60%;
}

.verify-setup-input {
  width: 30%;
}

.andes-signal-nets-table {
  margin-top: 10px;
}

.andes-select-dropdown-menu .ant-select-dropdown-menu-item {
  position: relative;
  padding-left: 30px;
}

.andes-select-dropdown-menu
  .ant-select-dropdown-menu-item
  .ant-select-selected-icon {
  text-align: left !important;
  left: 10px !important;
  font-weight: bold;
}

.andes-signal-nets-table .editable-cell-value-wrap,
.andes-component-table .editable-cell-value-wrap,
.andes-PG-table .editable-cell-value-wrap,
.andes-power-comps-table .editable-cell-value-wrap,
.andes-pin-table .editable-cell-value-wrap,
.andes-connector-table .editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.andes-signal-nets-table .select-width .ant-select-selection {
  margin: -4px 0px;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.andes-signal-nets-table tbody > tr > td > span {
  display: block;
  max-height: 80px;
  overflow: auto;
}

.andes-signal-nets-table tbody > tr > td .ant-select-selection--multiple {
  padding-bottom: 3px;
}

.andes-signal-nets-table
  tbody
  > tr
  > td
  .ant-select-selection--multiple
  .ant-select-selector
  > ul
  > li {
  height: 24px;
  line-height: 24px;
}

.margin-top-20 {
  margin-top: 20px;
}

.andes-pin-table .usage-repeater {
  padding: 0 5px;
}

.andes-decap-close-icon {
  color: rgba(0, 0, 0, 0.45);
}

.andes-decap-close {
  position: absolute;
  right: 1px;
  top: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  line-height: 24px;
  transition: 0.3s;
}

.andes-decap-close:hover {
  background: #ececec;
  transition: 0.3s;
}

.andes-model {
  padding: 5px 10px 0px;
  width: 100%;
  line-height: 24px;
}

.andes-model-last {
  padding-bottom: 5px;
}

.andes-model-selection {
  float: left;
  width: 100%;
}

.andes-model-input {
  margin-top: 5px;
}

.andes-pcb-content {
  background: #f2f7fa;
  padding: 20px;
  padding-top: 0;
  position: relative;
  margin-top: 20px;
}

.andes-pcb-menu {
  background: #f2f7fa;
  margin-left: 48px;
}

.andes-component-table,
.andes-pin-table,
.andes-power-comps-table {
  background: #ffffff;
}

.andes-setup-pcb-title {
  position: absolute;
  left: 20px;
  top: 13px;
}

.padding-top-10 {
  padding-top: 10px;
}
