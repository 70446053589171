/* download debug file */
.download-debug-ul-title {
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  background: #e0e0e0;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  padding-right: 30px;
}

.download-debug-ul-title:hover {
  background: #c5c5c5;
  transition: all 0.3s;
}

.download-debug-box {
  padding: 10px;
}

.download-debug-box ul {
  margin: 0px;
  padding: 0px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
}

.download-debug-box li {
  padding-left: 20px;
  height: 24px;
  line-height: 24px;
  padding-right: 10px;
  position: relative;
  background: #f9f9f9;
  transition: all 0.3s;
}

.download-debug-box li:hover {
  background: #eaeaea;
  transition: all 0.3s;
}

.debug-download-title-icon,
.debug-download-file-icon {
  position: absolute;
  right: 10px;
  top: 6px;
}

.download-debug-panel {
  top: 54px;
  margin-left: 0px;
  left: -9999px;
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  border: 1px solid #cdcfd2;
  box-sizing: content-box;
}

.download-debug-panel .panel-modal-header {
  padding: 4px 20px !important;
}

.download-debug-panel .panel-modal-close-x {
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
  margin-right: 10px;
}

.download-debug-panel .panel-body {
  max-height: 400px;
}

.pd-10 {
  padding: 0 10px;
}

.debug-download-file-name {
  min-width: 100px;
  width: 70%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: nowrap;
}

.debug-download-size-name {
  width: 30%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: nowrap;
}

.download-debug-box .anticon-check {
  color: rgb(77, 189, 22);
}

.download-debug-box .anticon-close {
  color: #ff4848;
}