#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.icon-tooltip .ant-tooltip-inner {
  color: #1890ff;
  background-color: #fff;
  width: fit-content;
}

.icon-tooltip .ant-tooltip-arrow::before {
  background-color: #fffdfd;
}


/* Margin */
.margin-top-14 {
  margin-top: 14px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-6 {
  margin-top: 6px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-8 {
  margin-left: 8px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-top-0 {
  margin-top: 0px;
}

.display-inline-block {
  display: inline-block;
}

.font-bold {
  font-weight: bold;
}

.space-10 {
  margin-top: 10px;
}

.width-25 {
  width: 25%;
}

.mg-r-8 {
  margin-right: 8px;
}

/* Select */
.aurora-select {
  height: 28px;
}

.aurora-select .ant-select-selector,
.aurora-tree-select .ant-select-selector {
  min-height: 24px;
  height: fit-content;
  line-height: 26px;
  height: auto;
}

.aurora-select .ant-select-selector>ul>li,
.aurora-select .ant-select-selector .ant-select-selector>ul>li {
  height: 20px;
  margin-top: 3px;
  line-height: 18px;
}

.aurora-select .ant-select-arrow,
.aurora-select .ant-select-clear {
  right: 6px;
}

.aurora-select .ant-select-selection--multiple {
  height: auto;
}

.ant-select-selection--multiple {
  padding-bottom: 3px;
}

.aurora-select-top .ant-select-arrow,
.aurora-select-top .ant-select-clear {
  margin-top: 4px;
}

.padding-left-20 {
  padding-left: 20px;
}

/* result input color */
.color-select {
  padding: 0px;
  background-color: #fafafa;
  border: 0px;
  width: 19px;
  cursor: pointer;
  display: inline-block;
  outline: none;
}

.aurora-input {
  height: 28px;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.aurora-input .ant-input {
  font-size: 13px;
  height: 28px;
}

.aurora-input-disable[disabled] {
  color: rgba(0, 0, 0, 0.75);
}

.clear {
  zoom: 1;
}

.clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  overflow: hidden;
}

.icon-disabled {
  cursor: not-allowed !important;
  color: #909294 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.signal-add-icon {
  color: #0d87f7;
  font-size: 16px;
  margin-left: 14px;
  cursor: pointer;
}

.signal-delete-icon {
  position: absolute;
  right: 4px;
  top: 50%;
  margin-top: -7px;
  color: #ccc;
}

.vertical-align-middle {
  vertical-align: middle;
}

.out-title-color {
  color: #0b3b94;
}

/* box-shadow */
.aurora-box-shadow {
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.vector-help-icon {
  color: #1890ff;
  font-size: 16px;
  float: right;
  margin-right: 26px;
  margin-top: 2px;
}

/* switch */
.aurora-switch-small {
  min-width: 26px;
  height: 14px;
  line-height: 12px;
}

.aurora-switch-small .ant-switch-handle {
  width: 10px;
  height: 10px;
}

.aurora-error-msg-span {
  display: block;
  padding: 6px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
  margin: 5px 16px 4px 10px;
}

.row-disabled {
  display: inline-block;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: #f3f3f3;
  cursor: not-allowed;
}

.ant-divider-horizontal.ant-divider-with-text-left::after,
.ant-divider-horizontal.ant-divider-with-text-left::before {
  top: 0%;
}

.out-error-title-color {
  color: rgba(0, 0, 0, 0.65);
}

.aurora-column-select-row,
.aurora-column-select-row>td {
  background-color: #adddfd !important;
}

.aurora-column-select-row:hover>td,
.aurora-column-select-row:hover .editable-cell-value-wrap {
  background-color: #adddfd !important;
}

.aurora-column-select-row:hover .editable-cell-value-wrap {
  border-color: #fff !important;
}

.aurora-column-merge-row,
.aurora-column-merge-row>td {
  background-color: #fffbdd !important;
}

.aurora-column-merge-row:hover>td,
.aurora-column-merge-row:hover .editable-cell-value-wrap {
  background-color: #fffbdd !important;
}

.aurora-column-merge-row:hover .editable-cell-value-wrap {
  border-color: #fff !important;
}

.merge-part-popover .ant-popover-content,
.merge-path-popover .ant-popover-content {
  z-index: 1000;
}

.merge-part-popover .ant-popover-content .ant-popover-inner-content,
.merge-path-popover .ant-popover-content .ant-popover-inner-content {
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid#ffadd2;
  color: #eb2f96;
  background: #fff0f6;
}

.merge-part-popover .ant-popover-content .ant-popover-arrow,
.merge-path-popover .ant-popover-content .ant-popover-arrow {
  border-right-color: #fff0f6;
  border-bottom-color: #fff0f6;
}

.merge-part-popover .ant-popover-inner-content:hover .ant-popover-arrow,
.merge-path-popover .ant-popover-inner-content:hover .ant-popover-arrow {
  border-right-color: #fff0f6;
  border-bottom-color: #fff0f6;
  bottom: 6.2px;
}

.z-index-100000 {
  z-index: 100000;
}

.display-none {
  display: none !important;
}