.aurora-tree-pop-confirm .ant-popover-inner {
  box-shadow: 0px 2px 8px 5px rgba(0, 0, 0, 0.15);
}

.aurora-tree-pop-confirm .ant-popover-buttons {
  margin-top: 0px;
  position: relative;
  height: 18px;
}

.aurora-tree-pop-confirm .ant-popover-buttons .ant-btn-primary,
.aurora-tree-pop-confirm .ant-popover-buttons .ant-btn {
  height: 18px;
  font-size: 12px;
  padding: 0px 5px;
  line-height: 16px;
  color: #1890ff;
  background-color: #fff;
  border-color: #1890ff;
  position: absolute;
}


.aurora-tree-pop-confirm .ant-popover-buttons .ant-btn-primary {
  left: 20px;
  top: 0px;
}

.aurora-tree-pop-confirm .ant-popover-buttons .ant-btn {
  right: 20px;
  top: 0px;
}

.aurora-tree-pop-confirm .ant-popover-buttons .ant-btn-primary:hover,
.aurora-tree-pop-confirm .ant-popover-buttons .ant-btn:hover {
  color: #40a9ff;
  background-color: #fff;
  border-color: #40a9ff;
}

.aurora-tree-pop-confirm .ant-popover-inner-content {
  padding: 6px 8px;
  width: 300px;
}

.aurora-tree-pop-confirm .ant-popover-message {
  padding-bottom: 0;
}

.aurora-tree-pop-confirm .ant-popover-message-title {
  padding-left: 0;
  display: block;
  font-size: 14px;
  padding: 10px 0px;
}

.aurora-tree-confirm-title {
  text-align: center;
}

.aurora-tree-confirm-warning-png {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.aurora-tree-confirm-title-span {
  font-size: 14px;
  vertical-align: middle;
  font-weight: 500;
}

/* === confirm panel ===  */

.aurora-confirm-panel {
  top: 200px;
}

.aurora-confirm-mask-panel {
  z-index: 100000000 !important;
}

.aurora-confirm-panel .ant-modal-header,
.aurora-confirm-panel .ant-modal-close {
  display: none;
}

.aurora-confirm-panel .rightBorder,
.aurora-confirm-panel .bottomBorder,
.aurora-confirm-panel .lowerRightCorner {
  cursor: unset;
}

.aurora-confirm-main {
  padding: 10px;
}

.aurora-confirm-title {
  text-align: center;
}

.aurora-confirm-warning-png {
  width: 24px;
  height: 24px;
  /*  margin-right: 8px; */
}

.aurora-confirm-title-span {
  font-size: 16px;
  vertical-align: middle;
  font-weight: 500;
}

.aurora-confirm-title {
  margin: 10px 0px;
}

.aurora-confirm-content {
  padding: 10px 8px;
  text-align: center;
}

.aurora-confirm-button {
  padding: 0px 10px;
  height: 26px;
  margin: 10px 0px;
}

.aurora-confirm-ok-button,
.aurora-confirm-cancel-button {
  height: 26px;
  font-size: 14px;
  padding: 0px 8px;
  line-height: 26px;
  border: none;
  width: 50%;
  box-shadow: unset;
}

.aurora-confirm-button>.ant-btn.aurora-confirm-ok-button:hover,
.aurora-confirm-button>.ant-btn.aurora-confirm-cancel-button:hover {
  color: #6f6f6f;
  background-color: #eee;
}

.aurora-confirm-cancel-none {
  display: none;
}

.aurora-confirm-Tips-icon {
  font-size: 22px;
}

.aurora-confirm-close-icon {
  font-size: 15px;
  float: right;
  color: rgba(0, 0, 0, 0.45);
}