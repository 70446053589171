.upload-box {
  display: block;
  height: 100%;
  margin: 16px;
}

.upload-icon-add {
  font-size: 36px !important;
}

.upload-icon {
  margin-bottom: 8px !important;
}

.file-list-box {
  display: block;
  min-height: 120px;
  margin: 16px;
  background: #f9f9fa;
  border-radius: 4px;
}

.upload-file-list {
  display: block;
  width: calc(100% - 8px);
  height: 120%;
  max-height: 320px;
  min-height: 10px;
  margin: 4px;
  background: #f9f9fa;
  padding: 0;
  padding-top: 10px;
  overflow-y: auto;
}

.upload-file-item {
  width: calc(100% - 12px);
  height: 60px;
  background: #fff;
  border-radius: 4px;
  margin: 6px;
  box-shadow: 0 0 8px 0 rgba(12, 12, 13, 0.1);
  border: 0 solid #dae1e7;
  display: flex;
}

.file-name-span {
  display: inline-block;
  margin-left: 4px;
  line-height: 60px;
  font-size: 16px;
  font-weight: bold;
  opacity: 0.85;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 38px);
  color: #000000a6;
}

.upload-del-icon {
  float: right;
  font-size: 16px;
  font-weight: bold;
  line-height: 62px;
  margin-right: 8px;
  margin-left: 2px;
  cursor: pointer;
}

.upload-del-icon:hover {
  opacity: 0.75;
}

.upload-file-icon {
  float: left;
  margin-left: 10px;
  line-height: 60px;
  font-size: 24px;
  font-weight: bold;
  height: 60px;
}

.upload-text {
  font-size: 14px;
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.85);
}

.upload-button {
  width: 100%;
}

.upload-move-button {
  margin: 10px 0;
  float: left;
  height: 26px;
  line-height: 26px;
  cursor: pointer;
}

.import-file-icon {
  font-size: 21px;
  color: #40a9ff;
  vertical-align: middle;
}

.import-file-span {
  display: inline-block;
  padding: 0 4px;
  font-size: 14px;
  vertical-align: middle;
  line-height: 26px;
}

.upload-file-button {
  margin: 6px;
}

.upload-drag {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  list-style: none;
  outline: 0;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.upload-drag .upload-btn {
  display: table;
  height: 100%;
  padding: 26px 0;
  width: 100%;
  outline: none;
}

.upload-drag .upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}

.upload-drag .upload-drag-icon {
  color: #40a9ff;
  margin-bottom: 8px;
}

.file-name-input {
  height: 26px;
  width: 72%;
}

.file-name-content {
  cursor: pointer;
  color: #40a9ff;
}

.color-red {
  color: red;
}

.upload-file-error-msg {
  display: block;
  margin: 0 10px;
  padding: 10px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
}

.file-list-box .ant-spin-nested-loading {
  position: static;
}

.upload-file-select-div {
  display: inline-block;
  height: 26px;
  line-height: 26px;
  margin-left: 10px;
  width: 69px;
}

.upload-model-type-select-div {
  display: inline-block;
  height: 26px;
  line-height: 26px;
  width: 160px;
}

.upload-file-select-div {
  display: inline-block;
  height: 26px;
  line-height: 26px;
  width: 69px;
}

.upload-file-select-div .ant-select-selector,
.upload-model-type-select-div .ant-select-selector {
  padding-right: 15px;
  line-height: 24px;
  margin-left: 6px;
}

.upload-file-select-div .ant-select-arrow,
.upload-model-type-select-div .ant-select-arrow {
  right: 4px;
  margin-top: -5px;
  font-size: 10px;
}

.library-upload-progress-bar {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 14px;
}

.library-upload-progress-bar .ant-progress-text {
  height: 22px;
  line-height: 22px;
  font-weight: bold;
}

.upload-success-icon {
  color: #52c419;
  font-size: 20px !important;
}

.library-upload-msg-bar {
  width: 100% !important;
  margin-left: 20px;
  margin-top: 14px;
}

.import-folder-message {
  line-height: 28px;
  text-indent: 10px;
  border-radius: 4px;
  font-size: 14px;
  padding: 0 8px;
  max-width: calc(100% - 40px);
}

.upload-error {
  border: 1px solid #ffa39e;
  background-color: #fff1f0;
}

.file-name-main,
.upload-file-title {
  width: calc(100% - 26px);
}

.file-list-has-option-box .file-name-main,
.upload-file-name-title {
  width: calc(66% - 26px)
}

.file-list-has-option-box .upload-file-option-main {
  width: 34%;
  height: 60px;
  line-height: 60px;
}

.upload-file-title {
  display: flex;
  margin-left: 10px;
  height: 30px;
}

.upload-file-option-select {
  width: calc(100% - 12px);
  margin: 0px 6px;
}

.upload-file-option-title,
.upload-file-name-title {
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-weight: 600;
}

.upload-file-option-title {
  width: 34%;
}

.upload-file-option-dropdown {
  z-index: 9999;
}

.file-list-has-option-box .upload-file-list {
  padding-top: 0px;
}

.upload-file-check-msg {
  white-space: pre-wrap;
  max-height: 200px;
  overflow-y: auto;
}

.file-name-content.file-name-no-edit-content {
  color: #000000a6;
  cursor: auto;
}