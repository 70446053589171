.end-to-end-channel-connection-content {
  padding: 8px 14px;
}

.end-to-end-channel-connection {
  min-width: 970px;
  padding: 10px;
  max-height: 100%;
  background: #ffffff;
}

.andes-connection-signal-map-content {
  padding: 10px;
}

.andes-connection-table .ant-table-small>.ant-table-content .ant-table-header {
  padding-right: 22px;
}

.andes-connection-table .editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
}

.andes-connection-table .ant-table-small .ant-table-container .aurora-table-body {
  padding-right: 14px;
}

.andes-connection-table .ant-table-small .ant-table-container .aurora-table-body {
  padding-right: 22px;
}

.andes-connection-table tbody>tr>td:last-child {
  position: relative;
}

.andes-connection-table .ant-table-small>.ant-table-content .connection-delete {
  position: absolute;
  right: -19px;
  top: 50%;
  margin-top: -7px;
  color: #ccc;
}

.andes-connection-table .ant-table-small .ant-table-container .aurora-table-body .ant-table-row:hover .connection-delete{
  color: #1890ff;
}

.andes-connection-signal-map-content .signal-add-icon {
  vertical-align: middle;
}