.andes-create-channel-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.andes-create-channel-panel .create-channel-content {
  padding: 8px 14px;
  min-width: 450px;
}

.andes-create-channel-panel .channel-input-item,
.andes-create-channel-panel .channel-advanced-input-item {
  margin: 8px;
  display: flex;
  line-height: 28px;
  height: 28px;
}

.andes-create-channel-panel .channel-edit-input-label {
  width: 80px;
  height: 28px;
  line-height: 28px;
}

.andes-create-channel-panel .channel-input,
.andes-create-channel-panel .channel-select {
  width: calc(100% - 80px);
}

.channel-input-item .aurora-model-name-error-msg {
  width: 100%;
  padding: 0px 10px;
}

.andes-create-channel-panel .channel-advanced-input-item .channel-edit-input-label {
  width: 120px;
}

.andes-create-channel-panel .channel-advanced-input-item .channel-input {
  width: calc(100% - 120px);
}

.andes-create-channel-panel .channel-create-advanced-content {
  background-color: #fff;
}

.andes-create-channel-panel .channel-create-advanced-content>.ant-collapse-item>.ant-collapse-header {
  padding-left: 30px;
  padding-bottom: 0px;
}

.andes-create-channel-panel .channel-create-advanced-content>.ant-collapse-item {
  border: 0;
}

.andes-create-channel-panel .channel-create-advanced-content .ant-collapse-content-box {
  padding-bottom: 8px;
  padding-top: 0px;
  padding-right: 0px;
}

.andes-create-channel-panel .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  margin-top: 6px;
  left: 10px;
}

.create-channel-content .ant-select-disabled {
  color: rgba(0, 0, 0, 0.65);
  background-color: #f8f8f8;
}

.create-channel-signals-table-content {
  margin: 8px;
}

.create-channel-setting-icon {
  vertical-align: text-bottom;
}

.create-channel-component-part-span {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  font-size: 13px;
}

.channel-create-component-table .cmc-components-usage-columns {
  font-size: 13px;
}