.rocky-library-panel {
  box-shadow: rgba(76, 77, 78, 0.14) 2px 4px 7px 5px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
  margin: 0 auto;
}

.vector-value-box {
  margin: 16px;
}

.vector-input,
.vector-value-input {
  margin: 16px 0;
  display: block;
}

.vector-name-error-msg {
  display: block;
  padding: 10px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
}

.vector-input-tooltip {
  z-index: 3000;
}

.library-edit-upload-progress-bar {
  width: 100%;
}

.library-edit-upload-progress-bar .ant-progress-outer {
  padding-right: 40px;
}

.library-edit-upload-progress-bar .ant-progress-text {
  position: absolute;
  right: 0px;
  width: 30px;
  height: 22px;
  line-height: 22px;
  font-weight: bold;
}

.vector-value-input .ant-input-group-addon {
  width: 50px;
}

.rocky-vector-radio-upload {
  float: left;
  line-height: 20px;
  width: 50%;
  margin: 0 !important;
}

.rocky-vector-radio-edit {
  float: left;
  line-height: 20px;
  width: 50%;
  margin: 0 !important;
}

.rocky-vector-radio-group {
  display: block;
  margin: 0 16px;
  height: 20px;
  margin-top: 16px;
}

.eye-mask-content-box {
  padding: 16px;
}

.eyemask-help-icon {
  color: #1890ff;
  font-size: 16px;
  float: right;
  margin-right: 26px;
  margin-top: 2px;
}

.pattern-edit-value-span {
  display: inline-block;
  width: 80px;
  margin: 8px 0;
  vertical-align: middle;
}

.pattern-edit-input {
  margin: 8px 0;
  display: inline-block;
  width: calc(100% - 80px);
  vertical-align: middle;
}

.pattern-library-content {
  margin: 16px;
}

.rocky-pattern-library-table .ant-table-small>.ant-table-content>.aurora-table-body {
  padding-right: 14px;
}

.rocky-pattern-library-table tbody>tr>td:last-child {
  position: relative;
}

.rocky-pattern-library-table .pattern-nets-delete {
  position: absolute;
  right: -15px;
  top: 50%;
  margin-top: -7px;
  color: #ccc;
}

.pattern-library-select-dropdown {
  z-index: 10000000;
}

.rocky-pattern-library-table .pattern-add-icon {
  color: #0d87f7;
  font-size: 13px;
  margin-left: 10px;
  cursor: pointer;
}

.rocky-pattern-library-table .ant-table-small>.ant-table-content>.aurora-table-body .ant-table-row:hover .pattern-nets-delete {
  color: #1890ff;
}

.rocky-spec-help-icon {
  color: #1890ff;
  font-size: 16px;
  float: right;
  margin-right: 26px;
  margin-top: 2px;
}