.cascade-power-tree-canvas {
  min-height: 80px;
  width: 100%;
  border: 1px solid #e9e9e9;
  border-radius: 3px;
  position: relative;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.85)
}

.cascade-power-tree-canvas .power-tree-canvas {
  text-align: center;
  vertical-align: middle;
  z-index: 1;
}

.cascade-power-tree-main .power-tree-name-span .delete-tree-icon {
  right: -130px
}

.cascade-power-tree-main .power-tree-name-span .detail-tree-icon {
  right: -55px
}

.cascade-power-tree-main .power-tree-name-span .shrink-tree-icon {
  right: -30px
}

.cascade-power-tree-main .power-tree-name-span .camera-tree-icon {
  right: -105px
}

.cascade-power-tree-main .power-tree-name-span .experiment-tree-icon {
  right: -80px;
}

.cascade-power-tree-main .power-tree-name-span .experiment-tree-not-allowed-icon,
.cascade-power-tree-main .power-tree-name-span .experiment-tree-not-allowed-icon:hover {
  cursor: not-allowed;
  color: unset;
}

.cascade-power-tree-main .power-tree-name-span .name-icon {
  position: absolute;
  top: 1.5px;
  cursor: pointer;
}

.cascade-power-tree-main .power-tree-name-span .name-icon:hover {
  color: #1890ff;
}

.cascade-power-tree-name-edit {
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.cascade-power-tree-root-edit {
  margin: 10px 0px;
}

.cascade-power-tree-icon {
  color: #0d87f7;
  font-size: 16px;
  margin-left: 14px;
  cursor: pointer;
  vertical-align: middle;
}

.cascade-power-tree-edit-icon {
  color: #0d87f7;
  font-size: 16px;
  position: absolute;
  right: -28px;
  margin-left: 5px;
  margin-top: -1px;
}

.power-tree-name-span {
  display: inline-block;
  min-width: 20px;
  vertical-align: middle;
  position: relative;
  line-height: 18px;
  height: 18px;
}

.power-tree-name-span span:not(.anticon) {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  display: inline-block;
  font-size: 15px;
  cursor: pointer;
  font-weight: bold;
}

.cascade-power-tree-canvas-component {
  position: absolute;
  z-index: 3;
}

.cascade-power-tree-canvas-component .tree-component-content {
  border: 3px solid #000000;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  position: relative;
}

.cascade-power-tree-canvas-component .tree-component-content .tree-component-name {
  text-align: center;
  font-weight: bold;
}

.cascade-power-tree-canvas-component .tree-component-content .tree-component-value {
  text-align: center;
}

.cascade-power-tree-canvas-component .tree-component-content .tree-multi-component-value {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 100%
}

.cascade-power-tree-canvas-component .tree-component-content .root-value {
  margin-top: 5px;
}

.cascade-power-tree-value-title {
  display: inline-block;
  vertical-align: middle;
}

.cascade-power-tree-canvas-component .tree-component-content .root-value .root-title {
  width: 30px;
  text-align: left;
}

.cascade-power-tree-canvas-component .tree-component-content .comp-value .component-span,
.cascade-power-tree-canvas-component .tree-component-content .tree-component-v-table .component-span {
  display: inline-block;
  min-width: 20px;
  max-width: 55px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.cascade-power-tree-canvas-component .tree-component-content .comp-value .component-input,
.cascade-power-tree-canvas-component .tree-component-content .tree-component-v-table .component-input {
  display: inline-block;
  max-width: 50px;
  min-width: 10px;
  height: 20px;
}

.cascade-power-tree-canvas-component .tree-component-content .root-value .component-span {
  display: inline-block;
  min-width: 20px;
  max-width: 50px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.cascade-power-tree-canvas-component .tree-component-content .root-value .component-input {
  display: inline-block;
  max-width: 50px;
  min-width: 20px;
  height: 20px;
}

.cascade-power-tree-canvas-component .tree-component-content .tree-pin {
  position: absolute;
  border: 3px solid #9c9c9c;
  border-radius: 50%;
  padding: 3px;
  background-color: #ececec;
  top: calc(50% - 6px);
}

.cascade-power-tree-canvas-component .tree-component-content .tree-voltage {
  position: absolute;
  margin: 3px;
  font-size: 10px;
  top: calc(50% - 25px);
}

.cascade-power-tree-canvas-component .tree-component-content .tree-pin-left {
  left: 0px;
}

.cascade-power-tree-canvas-component .tree-component-content .tree-pin-right {
  right: 0px;
}

.cascade-power-tree-canvas-component .tree-component-content .tree-icon-box {
  position: absolute;
  right: 3px;
  top: 0px;
}

.cascade-power-tree-canvas-component .tree-component-content .tree-icon-box .tree-component-icon {
  color: #83f4ef;
  font-size: 14px;
  margin: 0 3px;
  cursor: pointer;
}

.cascade-power-tree-canvas-component .tree-component-content .tree-component-v-table {
  width: 100%;
  text-align: center;
  margin-top: 5px;
  font-weight: 600;
}

.cascade-power-tree-canvas-component .tree-component-content .tree-result-box {
  position: absolute;
  left: 5px;
  top: 0px;
}

.cascade-power-tree-canvas-component .tree-component-content .tree-node-select {
  position: absolute;
  font-size: 10px;
}

.cascade-power-tree-canvas-component .tree-component-content .tree-node-select .ant-select-selection {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #ffffff
}

.cascade-power-tree-canvas-component .tree-component-content .tree-node-select-has-value .ant-select-selection {
  border: 0px;
}

.cascade-power-tree-v-value-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
  text-align: left;
  overflow: hidden;
}

.cascade-power-tree-pair-result {
  position: absolute;
  font-size: 10px;
  z-index: 1000
}

.cascade-power-tree-pair-result span {
  display: inline-block;
  margin-right: 2px
}

.powertree-pair-result-detail-icon {
  position: absolute;
  cursor: pointer;
  z-index: 1000;
}

.powertree-pair-result-detail-tooltip {
  min-width: 100px;
}

.driver-model-content {
  padding: 20px;
  width: 100%;
}

.driver-model-select-file {
  margin-bottom: 10px;
}

.driver-model-select-file>span {
  display: inline-block;
  width: 80px;
}

.driver-model-select-file .aurora-select {
  width: calc(100% - 80px);
}

.driver-model-select-drowdown {
  z-index: 2000;
}

.cascade-power-tree-pcb-name-title {
  position: relative;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  width: 100%;
  margin-top: 10px;
}

.cascade-power-tree-pcb-name-title .tree-tip {
  display: inline-block;
  margin-right: 10px;
  /* cursor: pointer; */
}

.cascade-power-tree-pcb-name-title .tree-tip .tree-tip-color-select {
  padding: 0px;
  margin: 0px;
  background-color: #ffffff;
  border: 0px;
  cursor: pointer;
  outline: none;
}


.cascade-power-tree-pcb-name-title .tree-tip .tree-tip-color {
  width: 14px;
  height: 18px;
  margin: 0 5px;
}

.cascade-power-tree-pcb-name-title .tree-tip .tree-tip-forbidden-color {
  cursor: not-allowed;
  width: 10px;
  height: 10px;
  border: 1px solid black;
  display: inline-block;
}


.cascade-power-tree-pcb-name-title .tree-select-tip>span {
  /* color: #0d87f7; */
}

.cascade-power-tree-canvas-select-pcb-component {
  /* box-shadow: #0d87f7 0px 0px 4px 1px; */
}

.cascade-power-tree-pcb-name-title .tree-forbid-tip {
  cursor: not-allowed;
}


.cascade-power-tree-pcb-name-title .tree-tip .tree-tip-name {
  padding: 2px 4px;
}

.cascade-power-tree-details-box pre {
  overflow: hidden;
  display: contents;
  margin-bottom: 0;
}

.cascade-power-tree-details {
  margin: 10px;
}

.cascade-power-tree-result-screen-shot-temp {
  position: absolute;
  text-align: center;
  z-index: 1999;
}

.tree-component-pcb-name {
  position: absolute;
  font-size: 10px;
  white-space: nowrap;
}

.cascade-power-tree-experiment-not-modify {
  color: #1890ff;
}

.cascade-power-tree-experiment-down-modify {
  color: #0bb929;
}

.cascade-power-tree-experiment-up-modify {
  color: #f32008;
}

.cascade-power-tree-experiment-voltage {
  color: #2700ef
}

.cascad-power-tree-pair-input {
  width: 150px;
}

.cascade-power-tree-pair-result .ant-input-group {
  display: table;
}

.cascade-power-tree-pair-result .ant-input-group .ant-input-group-addon {
  display: table-cell;
}