.my-andes-layout {
  position: absolute;
  top: 54px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.aurora-content {
  background-color: #ffffff;
  position: absolute;
  top: 0px;
  left: 240px;
  right: 0px;
  bottom: 0px;
  /* overflow-x: auto !important; */
}

.my-andes-content {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
}

.my-andes-content>.ant-spin-nested-loading,
.my-andes-content>.ant-spin-nested-loading>.ant-spin-container {
  width: 100%;
  height: 100%;
}

.andes-sider {
  position: relative;
  width: 240px;
  background: #f9f9f9;
  height: 100%;
  border-right: 1px solid #e2dfdf;
}

.andes-sider-footer {
  width: 100%;
  position: absolute;
  box-sizing: border-box;
  bottom: 0;
  height: 42px;
  border-top: 1px solid #e2dfdf;
}

.andes-close-box {
  float: left;
  margin: 0;
  margin-right: 10px;
  padding: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  color: #0d87f7;
  text-align: center;
  transition: 0.3s;
  box-shadow: #fff 0px 0px 0px 0px;
}

.andes-sider-layout {
  line-height: 36px;
  font-size: 19px;
}

.andes-sider-footer .andes-close-box:hover {
  color: #379af5;
  box-shadow: rgba(168, 204, 241, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.18) 0px 0px 5px 2px;
  transition: 0.3s;
}

.andes-sider-footer .andes-sider-monitor {
  line-height: 34px;
  font-size: 22px;
}

.mg-r-8 {
  margin-right: 8px;
}

.project-name-input .ant-input-group {
  float: left;
  width: 100%;
}

.project-name .ant-input-group-addon {
  width: 56px;
  padding: 0 8px;
}

.andes-tooltip-span {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.andes-create-name {
  width: 100%;
}

.ant-tree-title .ant-checkbox-wrapper-box {
  /* float: left;
  margin-right: 6px; */
  position: absolute;
  /* left: 28px; */
  top: 0px
}

.result-style {
  padding-left: 12px;
  font-style: italic;
}

.andes-delete-content {
  width: 196px;
}

.andes-delete-title {
  display: block;
  font-size: 14px;
  color: #f12727;
}

.delete-confirm {
  color: #1890ff;
  cursor: pointer;
}

.delete-confirm:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.andes-delete-pop-confirm .ant-popover-buttons {
  display: none;
}

.andes-delete-pop-confirm .ant-popover-inner-content {
  padding: 8px 16px;
}

.andes-delete-pop-confirm .ant-popover-message {
  padding-bottom: 0;
}

.andes-delete-pop-confirm .ant-popover-message>.andes-confirm-icon {
  display: none;
}

.andes-delete-pop-confirm .ant-popover-message-title {
  padding-left: 0;
}

.andes-delete-pop-confirm .andes-delete-button {
  display: block;
  width: 30px;
  height: 20px;
  float: right;
  text-align: center;
  line-height: 16px;
  padding: 0;
  font-size: 12px;
  border-radius: 4px;
  box-sizing: border-box;
}

.andes-clean-pop-confirm .ant-popover-inner {
  box-shadow: 0px 2px 8px 5px rgba(0, 0, 0, 0.15);
}

.andes-clean-pop-confirm .ant-popover-buttons .ant-btn {
  display: none;
}

.andes-clean-pop-confirm .ant-popover-buttons .ant-btn-primary {
  display: inline-block;
}

.andes-clean-pop-confirm .ant-popover-inner-content {
  padding: 8px 16px;
  width: 204px;
}

.andes-clean-pop-confirm .ant-popover-message {
  padding-bottom: 0;
}

.andes-clean-pop-confirm .ant-popover-message>.andes-confirm-icon {
  display: none;
}

.andes-clean-pop-confirm .ant-popover-message-title {
  padding-left: 0;
  display: block;
  font-size: 14px;
  color: #f12727;
}

.andes-clean-pop-confirm .andes-delete-button {
  display: block;
  width: 30px;
  height: 20px;
  float: right;
  text-align: center;
  line-height: 16px;
  padding: 0;
  font-size: 12px;
  border-radius: 4px;
  box-sizing: border-box;
}

.andes-clean-pop-confirm .ant-popover-inner {
  box-shadow: 0px 2px 8px 5px rgba(0, 0, 0, 0.15);
}

.andes-sider-menu {
  margin: 12px;
  padding: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  line-height: 30px;
  border-radius: 5px;
  color: #0d87f7;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  transition: 0.3s;
  box-shadow: #fff 0px 0px 0px 0px;
}

.andes-sider-menu:hover {
  color: #379af5;
  box-shadow: rgba(168, 204, 241, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.18) 0px 0px 5px 2px;
  transition: 0.3s;
}

.andes-layout-msg {
  font-size: 12px;
  margin-top: 10px;
  vertical-align: middle;
}

.andes-layout-msg>span {
  display: inline-block;
}

.andes-layout-msg>span:nth-child(1) {
  margin-right: 6px;
}

.andes-sider-menu-tree .ant-tree-title i {
  position: absolute;
  right: 14px;
  visibility: hidden;
}

.andes-sider-menu-tree .ant-tree-title:hover i {
  visibility: visible;
}

.andes-sider-menu-tree .ant-tree-title:hover {
  padding-right: 32px;
}

.andes-sider-menu-tree .ant-tree-title .project-name-input .aurora-tree-create-icon {
  visibility: visible;
  position: static;
}

.andes-icon-color:hover {
  color: #1890ff;
}

.andes-view-popover .ant-popover-inner-content {
  padding: 0;
}

.andes-switch-icon {
  display: inline-block;
  font-weight: 500;
  padding: 0 6px;
  cursor: pointer;
  font-size: 18px;
  vertical-align: middle;
  height: 30px;
  line-height: 30px;
  color: #737373;
}

#andes-switch-icon-selected {
  color: #0d87f7;
}

.andes-sider-footer .view-icon-disabled {
  color: #a0a0a0;
  cursor: not-allowed;
}

.andes-sider-footer .layout-icon-disabled {
  border: 1px #c5c5c5 solid;
  color: #a0a0a0;
  cursor: not-allowed;
}

.andes-delete-confirm {
  color: #1890ff;
}

.andes-delete-confirm:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.andes-sider .tree-node .ant-tree-node-content-wrapper:hover .aurora-status-span {
  margin-right: 2px;
}

.andes-sider .tree-node .ant-tree-node-content-wrapper:hover .aurora-status-icon {
  margin-right: 2px;
}

.andes-sider .tree-node .ant-tree-node-content-wrapper:hover .aurora-replace-icon {
  margin-right: 2px;
}

.andes-sider-menu-tree .ant-tree-title .aurora-replace-icon {
  visibility: hidden;
}

.andes-sider-menu-tree .ant-tree-title:hover .aurora-replace-icon {
  visibility: visible;
}

.andes-sider-menu-tree .project-create-node .ant-tree-title:hover {
  padding-right: 0px;
}

.andes-verification-tooltip {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.andes-sider-menu-tree .tree-node-interface-name .ant-tree-title {
  padding-left: 22px;
  padding-right: 22px;
}

.andes-sider-menu-tree .tree-node-interface-name .ant-tree-title:hover {
  padding-right: 48px;
}

.andes-sider-menu-tree .tree-node-interface-name .tree-node-interface-result-name .ant-tree-title {
  padding-left: 2px;
}

.andes-sider-menu-tree .tree-node-interface-name .ant-tree-title:hover .aurora-status-span {
  right: 38px;
}

.andes-sider-menu-tree .verification-create-node .ant-tree-title:hover {
  padding-right: 0px;
}

.andes-sider-menu-tree .tree-node-pcb-name .tree-node-pcb-replace-icon {
  position: absolute;
  right: 36px;
}

.andes-sider-menu-tree .tree-node-pcb-name .ant-tree-title:hover {
  padding-right: 50px;
}

.andes-sider-menu-tree .ant-tree-title .aurora-tree-loading-icon {
  visibility: visible;
}

.andes-sider-menu-tree .ant-tree-title:hover .aurora-tree-loading-icon {
  visibility: visible;
  right: 36px;
}

.andes-sider-menu-tree .ant-tree-title:hover .andes-project-title {
  padding-right: 40px;
}

.andes-sider-menu-tree .ant-tree-title .andes-project-title {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.andes-PCB-replace {
  position: absolute;
  right: 35px;
  display: inline;
}

.andes-sider-menu-tree .ant-tree-title i.simulate-icon-disabled,
.andes-sider-menu-tree .ant-tree-title i.aurora-tree-simulate-icon {
  visibility: visible !important;
  line-height: 36px;
  font-size: 15px;
}

.andes-sider-menu-tree .ant-tree-title i.simulate-icon-disabled {
  color: #a0a0a0;
  cursor: not-allowed;
}

.andes-sider-menu-tree .ant-tree-title:hover i.simulate-icon-disabled,
.andes-sider-menu-tree .ant-tree-title:hover i.aurora-tree-simulate-icon {
  right: 38px;
}

.andes-sider-footer .pcb-icon {
  font-size: 25px;
  line-height: 32px;
  cursor: pointer;
}

.andes-sider-footer .icon-setup {
  font-size: 19px;
  cursor: pointer;
}

.andes-sider-footer .icon-Monitor {
  font-size: 20px;
  cursor: pointer;
}