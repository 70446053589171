.ads-select-model-item {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 0px 8px;
}

.ibis-select-model-pins-title,
.ibis-select-model-name-title {
  width: 60%;
  height: 56px;
  line-height: 56px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  justify-content: space-around;
  padding: 4px 0px;
}

.ibis-select-model-name-title {
  width: 40%;
  padding: 4px 0px;
}

.ibis-select-model-pins-title>.ibis-select-model-pin-item-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 28px;
}

.ibis-select-model-pins-title .ibis-select-model-signal-title,
.ibis-select-model-name-title .ibis-select-model-signal-title {
  width: 70%;
  height: 26px;
  line-height: 26px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ibis-select-model-pins-title .ibis-select-model-pin-title,
.ibis-select-model-name-title>.ibis-select-model-pin-title {
  width: 30%;
  min-width: 80px;
  height: 26px;
  line-height: 26px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ibis-select-model-name-title .ibis-select-model-name-select {
  width: 100%;
  min-width: 80px;
  height: 28px;
  line-height: 28px;
}

.ibis-select-model-name-title .ibis-select-model-name-select .aurora-select {
  font-size: 12px;
  height: 26px;
  line-height: 26px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.ibis-select-model-name-title .ibis-select-model-name-select .aurora-select {
  height: 26px;
}

.ibis-select-model-name-title .ibis-select-model-name-select .aurora-select .ant-select-selector {
  line-height: 24px;
}

.ibis-jitter-pdf-content {
  margin: 4px 0px;
}

.ibis-jitters-pdf-input-main {
  display: flex;
  justify-content: space-around;
}

.ibis-jitter-pdf-input-content>.ibis-jitter-pdf-input-title {
  display: inline-block;
  width: 50px;
  height: 28px;
  line-height: 28px;
  padding-right: 6px;
  text-align: right;
  font-size: 12px;
}

.ibis-jitter-pdf-input-content>.aurora-input {
  width: calc(100% - 50px);
}

.ibis-jitter-pdf-input-content>.aurora-input .ant-input-group-addon {
  font-size: 12px;
  padding: 0px 4px;
}

.ibis-jitter-pdf-random-input-content>.aurora-input .ant-input-group-addon {
  width: 70px;
}

.ami-model-collapse-content .ibis-advanced-select-item>span {
  width: 200px;
}

.ami-model-collapse-content .ibis-advanced-select-item>.aurora-input,
.ami-model-collapse-content .ibis-advanced-select-item>.aurora-select {
  width: calc(100% - 200px);
}

.ami-model-collapse-content .ibis-advanced-select-item .aurora-tags-input-content {
  width: calc(100% - 92px);
}

.ibis-advanced-select-item .ant-input[disabled] {
  color: rgba(0, 0, 0, 0.65);
  /* background-color: #ffffff; */
}

.ibis-pin-and-selector-flex-box {
  display: flex;
  justify-content: space-between;
  margin: 6px 0px;
}

.ibis-pin-and-selector-flex-box>.ibis-pin-and-selector-flex-box-pin {
  flex: 3;
}

.ibis-pin-and-selector-flex-box-pin>label {
  display: inline-block;
  width: 100px;
}

.ibis-pin-and-selector-flex-box-pin>.ant-select {
  width: calc(100% - 100px);
}

.ibis-pin-and-selector-flex-box>.ibis-pin-and-selector-flex-box-select {
  flex: 6;
}

.ibis-pin-and-selector-flex-box-select>label {
  display: inline-block;
  width: 120px;
  text-align: center;
}

.ibis-pin-and-selector-flex-box-select>.ant-select {
  width: calc(100% - 120px);
}

.ibis-jitters-switch-box>label {
  display: inline-block;
  width: 150px;
}

.ibis-switch-top-7 {
  margin-top: 7px;
}

.ibis-waveform-advanced-collapse>.ant-collapse-item {
  background-color: #f5f5f5;
}

.andes-ads-model-setup-main .ami-model-collapse-content.ibis-waveform-advanced-collapse .ant-collapse-content-box {
  background-color: #fff;
}

.ibis-waveform-setup-title {
  font-weight: 500;
}

.ibis-model-waveform-risetime {
  margin-right: 10px;
}

.ibis-model-ibis-file-treeSelect .ant-select-tree {
  margin: 4px 0px;
}

.ibis-model-ibis-file-treeSelect .ant-select-tree li span.ant-select-tree-switcher,
.ibis-model-ibis-file-treeSelect .ant-select-tree li span.ant-select-tree-iconEle {
  width: auto;
}

.ibis-model-ibis-file-treeSelect .ant-select-tree li {
  margin: 0 0;
}

.ibis-model-ibis-file-treeIcon {
  margin-right: 5px;
}

.ibis-model-ibis-file-treeSelect-title {
  display: inline-block;
  width: 100%;
}