.cascade-DCR-content {
  padding: 20px;
  min-width: 1350px;
}

.resistance-add-icon {
  color: #0d87f7;
  font-size: 16px;
  margin-left: 14px;
  cursor: pointer;
  vertical-align: middle;
}

.dcr-point-select-table-panel {
  overflow: unset !important;
  margin-top: 100px;
}

.dcr-point-select-table-panel .panel-ant-modal-body.panel-body {
  overflow: unset !important
}

.point-panel-content {
  padding: 20px;
}

.cascade-resistance-tables .ant-table-small>.ant-table-content>.aurora-table-body .ant-table-row:hover .resistance-delete-icon {
  color: #1890ff;
}

.cascade-resistance-tables .ant-table-small>.ant-table-content>.aurora-table-body .path-r-index-td {
  margin-right: 20px;
}

.cascade-resistance-tables .input-with-select .ant-select .ant-select-selector .ant-select-selection-item {
  padding-inline-end: 18px
}

.path-r-index-content {
  position: relative;
  margin-right: 18px;
}

.resistance-delete-icon {
  position: absolute;
  top: 50%;
  right: -20px;
  margin-top: -8px;
  color: #ccc;
}

.dcr-select-dropdown-menu {
  z-index: 1000000000;
}

.dcr-point-table .aurora-table-body table {
  table-layout: fixed;
  width: 100%;
}

.point-pin-span {
  color: #1890ff;
}

.resistance-table-error-span {
  color: red;
}

.resistance-table-error-span .point-pin-span {
  color: red;
}

.cascade-resistance-tables .aurora-table-body .ant-table-row>td>.editable-cell-value-wrap,
.dcr-point-table .aurora-table-body .ant-table-row>td>.editable-cell-value-wrap {
  margin: -4px 0px;
  display: block;
  min-height: 30px;
  max-height: 88px;
  overflow: auto;
  height: auto;
  white-space: normal;
  word-break: break-all;
}

.resistance-add-icon-disable {
  color: #b8b8b8;
  cursor: not-allowed;
}

.resistance-table-disable-span {
  cursor: not-allowed;
}

.path-r-point-net-selection-content {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.path-r-point-net-selection-content>span {
  height: 32px;
  line-height: 32px;
}

.path-r-point-net-selection.ant-select {
  line-height: 28px;
  width: calc(100% - 40px);
  height: auto;
}

.path-r-point-net-selection-dropdown {
  z-index: 1000000;
}

.tree-list-content-pin-copy-icon {
  color: #1890ff;
  margin-left: 10px;
  visibility: hidden;
}

.tree-list-content:hover .tree-list-content-pin-copy-icon,
.tree-list-content-net:hover .tree-list-content-pin-copy-icon {
  visibility: visible;
}

.path-r-index-checkbox {
  margin-right: 8px;
}

.cascade-resistance-tables .ant-table-small>.ant-table-content .aurora-table-body .path-r-row-dividing-line>td,
.cascade-resistance-tables .ant-table-small>.ant-table-content .aurora-table-body>tr>.path-r-row-dividing-line-td {
  /* border-bottom: 1px solid #a8c4e6; */
  border-bottom: 2px solid #bedbff;
}

.cascade-resistance-tables .path-r-pins-main {
  height: auto;
  max-height: 88px;
  overflow: auto;
}

.cascade-resistance-tables .path-r-pins-main:empty {
  height: 28px;
}

.cascade-dcr-point-add-button {
  width: 130px;
  height: 26px;
  padding: 0px;
  font-size: 13px;
  line-height: 24px;
  margin-top: 10px;
}

.dcr-point-table .ant-table-small>.ant-table-content>.aurora-table-body {
  padding-right: 14px;
}

.dcr-point-table tbody>tr>td:last-child {
  position: relative;
}

.dcr-point-delete-icon {
  position: absolute;
  right: -19px;
  top: 50%;
  margin-top: -7px;
  color: #ccc;
}

.dcr-point-table .ant-table-small>.ant-table-content>.aurora-table-body .ant-table-row:hover .dcr-point-delete-icon {
  color: #1890ff;
}

.dcr-warning-color {
  color: orange;
}