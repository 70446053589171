.layer-tab,
.layer-tab .ant-tabs-nav-container {
  line-height: 28px;
}

.layer-tab .ant-tabs-nav-container {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: -1px;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;
  zoom: 1;
}

.layer-tab .ant-tabs-nav-container-scrolling {
  padding-right: 32px;
  padding-left: 32px;
}

.layer-tab .ant-tabs-bar,
.ant-tabs-nav-container {
  -webkit-transition: padding .3s cubic-bezier(.645, .045, .355, 1);
  transition: padding .3s cubic-bezier(.645, .045, .355, 1);
}

.layer-tab .ant-tabs-nav-wrap {
  margin-bottom: -1px;
  overflow: hidden;
}

.layer-tab .ant-tabs-nav-scroll {
  overflow: hidden;
  white-space: nowrap;
}

.layer-tab .ant-tabs-nav {
  position: relative;
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding-left: 0;
  list-style: none;
  -webkit-transition: -webkit-transform .3s cubic-bezier(.645, .045, .355, 1);
  transition: -webkit-transform .3s cubic-bezier(.645, .045, .355, 1);
  transition: transform .3s cubic-bezier(.645, .045, .355, 1);
  transition: transform .3s cubic-bezier(.645, .045, .355, 1), -webkit-transform .3s cubic-bezier(.645, .045, .355, 1);
}

.layer-tab .ant-tabs-tab-next.ant-tabs-tab-arrow-show,
.ant-tabs-tab-prev.ant-tabs-tab-arrow-show {
  width: 32px;
  height: 100%;
  opacity: 1;
  pointer-events: auto;
}

.layer-tab .ant-tabs-tab-next {
  right: 2px;
}

.layer-tab .ant-tabs-tab-next,
.ant-tabs-tab-prev {
  position: absolute;
  z-index: 2;
  width: 0;
  height: 100%;
  color: rgba(0, 0, 0, .45);
  text-align: center;
  background-color: initial;
  border: 0;
  cursor: pointer;
  opacity: 0;
  -webkit-transition: width .3s cubic-bezier(.645, .045, .355, 1), opacity .3s cubic-bezier(.645, .045, .355, 1), color .3s cubic-bezier(.645, .045, .355, 1);
  transition: width .3s cubic-bezier(.645, .045, .355, 1), opacity .3s cubic-bezier(.645, .045, .355, 1), color .3s cubic-bezier(.645, .045, .355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.layer-tab .ant-tabs-tab-prev {
  left: 0;
}

.layer-tab .ant-tabs-tab-btn-disabled,
.ant-tabs-tab-btn-disabled:hover {
  color: rgba(0, 0, 0, .25);
}

.layer-tab .ant-tabs-tab-btn-disabled {
  cursor: not-allowed;
}

.layer-tab .ant-checkbox-wrapper {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, .65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}

.layer-tab .ant-checkbox {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, .65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  top: -.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}

.layer-tab .ant-checkbox+span {
  padding-right: 8px;
  padding-left: 8px;
}

.layer-tab .ant-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.layer-tab .ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: initial;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.layer-tab .ant-checkbox-checked:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect .36s ease-in-out;
  animation: antCheckboxEffect .36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  content: "";
}

.layer-tab .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}

.layer-tab .ant-checkbox-inner:after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
  -ms-transform: rotate(45deg) scale(0) translate(-50%, -50%);
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  -webkit-transition: all .1s cubic-bezier(.71, -.46, .88, .6), opacity .1s;
  transition: all .1s cubic-bezier(.71, -.46, .88, .6), opacity .1s;
  content: " ";
}

.layer-tab .ant-checkbox-checked .ant-checkbox-inner:after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
  -ms-transform: rotate(45deg) scale(1) translate(-50%, -50%);
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  -webkit-transition: all .2s cubic-bezier(.12, .4, .29, 1.46) .1s;
  transition: all .2s cubic-bezier(.12, .4, .29, 1.46) .1s;
  content: " ";
}