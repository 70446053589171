.connection-v1-channel-title>div {
  font-size: 14px;
  margin: 0px 16px;
  min-width: 100px;
}

.connection-v1-channel-title>div .connection-v1-pcb-name {
  color: #0d82d7;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  padding-right: 10px;
  line-height: 32px;
}

.connection-v1-channel-title .connection-v1-border {
  height: 20px;
  width: 1px;
  min-width: 1px;
  background: #ccc;
  margin-top: 26px;
}

.connection-v1-channel-title>div .connection-v1-channel-name {
  color: #67676f;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  line-height: 32px;
}

.connection-v1-channel-title {
  display: flex;
  justify-content: center;
  transition: 0.3s;
}

.connection-v1-model-content {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.connection-v1-model-content .change-connection-v1-icon {
  font-size: 16px;
  width: 26px;
  display: inline-block;
  cursor: not-allowed;
  padding-left: 7px !important;
  padding-right: 8px !important;
  line-height: 32px;
  height: 30px;
  margin-bottom: 2px;
}

.connection-v1-model-content .change-connection-v1-icon i {
  margin-left: -1.5px;
}

.connection-v1-model-content .change-connection-v1-icon:hover {
  background-color: #bbd6f0;
}

.connection-v1-model-list {
  width: fit-content;
  display: inline-block;
}

.connection-v1-title-pcb {
  text-align: center;
  font-weight: 600;
}

.connection-v1-model-main {
  transition: 0.3s;
  width: 100%;
}

.connection-v1-name-content {
  text-align: center;
}

#connector-left-models,
#connector-right-models {
  width: 100%;
}