.vector-edit-value-input .ant-input-group-addon {
  width: 50px;
}

.vector-edit-input,
.vector-edit-value-input {
  margin: 8px 0;
  display: inline-block;
  width: calc(100% - 80px);
  vertical-align: middle;
}

.vector-edit-name-error-msg {
  display: block;
  padding: 10px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
}

.vector-edit-input-tooltip {
  z-index: 3000;
}

.vector-edit-value-span {
  display: inline-block;
  width: 80px;
  margin: 8px 0;
  vertical-align: middle;
}

.vector-edit-value-input .ant-input-group-addon .ant-select .ant-select-selection .ant-select-arrow {
  right: 8px;
}

textarea.ant-input.vector-edit-value-input {
  vertical-align: middle;
  max-height: 100px !important;
}