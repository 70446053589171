.rlc-model-panel {
  margin-top: 100px;
  box-shadow: rgba(76, 77, 78, 0.14) 0px -2px 7px 8px, rgba(0, 0, 0, 0.1) 0px 0px 10px 3px;
}

.rlc-model-title {
  font-size: 14px;
  font-weight: 500;
}

.rlc-model-panel .rlc-model-content {
  margin: 10px;
  overflow: hidden;
  font-size: 13px;
}

.rlc-model-selection {
  width: 100%;
}

.rlc-model-row .rlc-model-selection .ant-select-selector,
.rlc-model-row .rlc-model-selection,
.rlc-model-row .rlc-model-selection .ant-select-selection {
  height: 24px;
  line-height: 22px;
  font-weight: 500;
  font-size: 13px;
}

.rlc-model-row .rlc-model-selection .ant-select-selection--multiple {
  min-height: 24px;
}

.rlc-model-row .ant-select-selection--multiple .ant-select-selection__choice__content {
  font-size: 12px;
}

.rlc-model-row .rlc-model-selection .ant-select-selection--multiple .ant-select-selector>ul>.ant-select-search--inline {
  margin-top: 0px;
  font-size: 14px;
}

.rlc-model-select-dropdown {
  z-index: 10000000;
}

.sierra-package-model-item-select {
  width: 50%;
}

.rlc-model-panel .ant-input {
  height: 24px;
}

.rlc-model-row {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  font-weight: 600;
}

.rlc-model-row>*:first-child {
  width: 100px;
  height: 24px;
  line-height: 24px;
}

.rlc-model-row>*:last-child {
  width: calc(100% - 100px);
}

.rlc-sweep-create {
  display: flex;
  gap: 10px;
  padding: 0 10px;
  margin-top: 10px;
  justify-content: flex-end;
}

.rlc-value-tagsInput {
  width: 100%;
}

.rlc-sweep-create-btn {
  height: 28px;
}