.cascade-result-impedance {
  height: 100%;
  position: relative;
}

.cascade-result-impedance-result-content {
  position: absolute;
  width: 100%;
  top: 45px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;
}

.impedace-result-effective-layout {
  overflow: auto;
}

.cascade-result-impedance .ant-spin-nested-loading,
.cascade-result-impedance .ant-spin-nested-loading .ant-spin-container {
  height: calc(100% - 5px);
}

.cascade-result-parameter-left {
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
}

.cascade-result-parameter-right {
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  bottom: 20px;
  margin-left: 8px;
  right: 0px;
  width: 45px;
  height: 78px;
}

.cascade-result-parameter-right .photo-box {
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background-color: #9191918c;
  position: absolute;
  top: 6px;
  right: 14px;
  cursor: pointer;
  border-radius: 50%;
  visibility: visible;
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); */
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.14), 1px 1px 3px 0 rgba(0, 0, 0, 0.21), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  text-align: center;
  line-height: 27px;
  transition: all 0.3s;
}

.cascade-result-parameter-right .photo-box:hover {
  background-color: #6e6e6e8c;
  transition: all 0.3s;
}

.cascade-photo {
  width: 19px;
  margin-left: 1px;
}

.cascade-result-parameter-right .curve-table-box {
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background-color: #9191918c;
  position: absolute;
  top: 44px;
  right: 14px;
  cursor: pointer;
  border-radius: 50%;
  visibility: visible;
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.14), 1px 1px 3px 0 rgba(0, 0, 0, 0.21), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  text-align: center;
  line-height: 30px;
  transition: all 0.3s;
}

.cascade-result-parameter-right .curve-table-box:hover {
  background-color: #6e6e6e8c;
  transition: all 0.3s;
}

.cascade-curve-table-icon {
  font-size: 17px;
  color: #fff;
}

.cascade-result-effect-title {
  display: inline-block;
  margin: 10px 0;
  font-weight: 700;
}

.cascade-result-effect-table {
  margin: 20px 0px;
  padding-top: 0px !important;
}

.cascade-result-effect .ant-spin-nested-loading {
  height: auto;
}

.cascade-result-effect-setting {
  margin: 10px 20px 0px 20px;
  text-align: left;
}

.cascade-result-effect-input {
  width: calc(100% - 200px);
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.cascade-customized-title {
  vertical-align: middle;
  height: 20px;
  display: inline-block;
  width: 100%;
}

.cascade-customized-title .design-name {
  color: rgba(0, 0, 0, 0.85);
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.cascade-customized-title .component-pin-name {
  color: #3b70a7;
  display: inline-block;
}

.cascade-customized-title .signal-name {
  color: #2f7b35;
}

.cascade-customized-title .port-mode {
  color: rgba(0, 0, 0, 0.65);
}

.cascade-result-impedance-result-content .sparameter-file-expand-icon,
.cascade-result-impedance-result-content .sparameter-span-file-name {
  vertical-align: middle;
}

.cascade-effective-result-menu-title {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-weight: bold;
  min-width: 120px;
  padding-right: 20px
}

.cascade-effective-result-menu-title>span {
  display: inline-block;
}

.cascade-effective-result-vertically-divider {
  margin-top: 10px !important;
  margin-bottom: 0px !important;
  padding-right: 30px !important;
  margin-left: 10px !important;
  position: relative;
}

.cascade-effective-result-vertically-divider::before {
  width: 0% !important;
}

.cascade-effective-result-vertically-divider::after {
  width: 95% !important;
}

.cascade-effective-result-title {
  width: fit-content;
}

.cascade-result-impedance-result-content .title-expand-icon {
  margin-top: 10px;
}

.cascade-effective-result-title .title-expand-icon {
  cursor: pointer;
  margin-left: 1px;
  margin-top: 10px;
}

.cascade-effective-result-checkout-box {
  margin-right: 5px;
}

.cascade-effective-result-next-divider {
  padding-right: 30px !important;
}

.cascade-effective-result-not-title-divider {
  margin-left: 10px !important;
  padding-right: 30px !important;
}

.cascade-effective-result-next-divider::after,
.cascade-effective-result-not-title-divider::after {
  border-top: 0px !important;
}

.cascade-result-effect-canvas {
  margin: 20px 0px;
  display: flex;
}

.cascade-result-effect-canvas .canvas-content {
  width: 49%;
}

.effect-bar-chart-xAxis .domain,
.effect-bar-chart-yAxis .domain {
  display: unset;
}

.cascade-result-effect-divider {
  height: 500px;
}

.cascade-result-effect-left {
  overflow: auto;
  height: 100%;
  min-width: 800px;
}

.cascade-result-effect-left-box {
  margin: 0px 20px;
  padding: 10px 0;
}

/* .cascade-result-effect-left-box:not(:last-child) {
  border-bottom: 1px solid #ccc;
} */

.cascade-effective-result-title .color-select {
  width: 16px;
  height: 21px;
  vertical-align: middle;
}

.cascade-effective-history-delete-icon {
  right: 40px;
  visibility: hidden;
  cursor: pointer;
}

.cascade-effective-result-vertically-divider:hover .cascade-effective-history-delete-icon {
  visibility: visible;
}

.cascade-effective-icon {
  font-size: 13px;
  color: #40a9ff;
  position: absolute;
  top: 6px;
}

.cascade-effective-map-icon {
  right: unset;
  margin-left: -10px;
  visibility: visible;
  z-index: 5;
}

.cascade-impedance-sparameter-current-result {
  overflow-y: hidden;
}

.cascade-result-impedance-result-content .sparameter-result-checkbox-group-name,
.cascade-result-impedance-result-content .sparameter-result-checkbox-group {
  display: flex;
  align-items: center;
}

.cascade-result-impedance-result-content .sparameter-result-checkbox-group .ant-checkbox {
  top: 0px;
}

.cascade-result-impedance-result-content .sparameter-result-checkbox-group-name>label .checkbox-group-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.cascade-result-impedance-result-content .sparameter-result-checkbox-group-name .checkbox-group-name {
  display: block;
}

.cascade-result-impedance-result-content .sparameter-result-checkbox-group-name>label {
  max-width: fit-content;
}

.cascade-result-impedance-result-content .sparameter-result-checkbox-group .ant-checkbox+span {
  width: calc(100% - 16px);
}

.cascade-result-impedance-result-content .sparameter-result-checkbox-group>.ant-checkbox {
  height: 16px;
}

.cascade-result-impedance-result-content .sparameter-result-checkbox-group-name>input {
  flex-shrink: 0;
}

.cascade-result-effective-compare-add-icon {
  margin-left: 5px;
  margin-top: 0px;
  color: #40a9ff;
  font-size: 15px;
  cursor: pointer;
  padding-top: 4px
}

.cascade-result-effective-compare-cascader {
  margin-left: 23px;
  margin-top: 5px;
  width: calc(100% - 50px);
}

.cascade-result-effective-compare-cascader-close-icon {
  margin-left: 10px;
  font-size: 13px;
  color: #40a9ff;
}

.cascade-result-effect-table-main .ant-table-content {
  overflow: auto;
}

.cascade-result-effect-table-main-pins {
  max-height: 200px;
  overflow: auto;
}

.cascade-effect-map-panel {
  margin-top: 60px;
  box-shadow: rgb(76 77 78 / 14%) 0px -2px 7px 8px, rgb(0 0 0 / 10%) 0px 0px 10px 3px;
}

.cascade-effect-map-name {
  font-size: 16px;
  font-weight: 500;
}

.cascade-effect-map-content {
  margin: 10px;
}

.cascade-effect-select-dropdown-menu {
  z-index: 1000000000;
}

.cascade-result-effective-color-tips {
  position: absolute;
  right: 0;
  top: 8px;
  text-align: right;
}

.cascade-result-effective-color-tips .tip-content {
  margin-right: 15px;
  display: inline-block;
}

.cascade-result-effective-color-tips .tip-content .tip-color {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px
}

.cascade-result-effect-title .camera-icon:hover {
  color: #1890ff;
}

.cascade-result-effect-title .camera-icon {
  margin-left: 8px;
}

.cascade-result-impedance-result-content .result-zoom-menu {
  top: 76px;
  width: 45px;
  right: -3px;
}

.cascade-result-impedance-result-content .result-zoom-menu.ant-menu-vertical .ant-menu-item {
  margin-bottom: 10px;
}

.cascade-waveform-setting-box {
  padding-left: 20px;
  padding-top: 10px;
  max-width: 450px;
  overflow: auto;
}

.cascade-waveform-setting-box .ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 5%;
}

.cascade-opt-result-panel-content-keep,
.cascade-opt-result-panel-content-opt {
  padding: 10px 10px
}

.cascade-opt-result-table {
  margin: 6px 5px;
  background-color: #fff;
}

.cascade-opt-sum-title {
  margin-left: 10px;
}