.selectionButton {
  margin-left: 6px;
  width: 75px;
}

.highlightobjects .ant-tooltip-inner {
  color: #1890ff;
  background-color: #fff;
}

.highlightobjects .ant-tooltip-arrow::before {
  background-color: #fff;
}

.selection-panel {
  z-index: 1010;
  background: #fff;
  position: absolute;
  width: 300px;
  bottom: 34px;
  right: 5px;
}

.result-selected {
  padding: 6px 16px;
}

.seleted-icon {
  display: inline-block;
  margin-left: 8px;
}

.selection-panel-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}

.button-tiltes {
  font-size: 8px;
  font-weight: bold;
}

.selection-panel .ant-modal-header {
  padding: 8px 12px;
}

.selection-panel .ant-modal-close-x {
  width: 30px;
  height: 30px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
}

.delete-selection {
  float: right;
}

.result-titles.resultBtn {
  display: block;
  margin: 0 auto
}

.dispaly-selection {
  font-size: 16px;
  transform: translateY(5%);
}

.dispaly-selection+.dispaly-selection {
  margin-left: 12px;
}

.clear-selection {
  font-size: 16px;
}

.result-title-nothing {
  padding: 60px;
  font-size: 14px;
  text-align: center;
}

.result-list {
  padding: 0px 16px 6px 16px;
  margin-bottom: 0;
  overflow: auto;
}

.result-list .anticon-close:hover svg {
  fill: #00aaff !important;
}

.result-list>div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.display-selection-switch-box {
  display: inline-block;
  margin-left: 12px;
}

.display-selection-switch-box .display-selection-switch {
  margin-left: 10px;
  margin-bottom: 2px;
}