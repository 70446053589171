.upload-dropdown-button .ant-dropdown-menu-item:hover {
  color: #1890ff;
}

.aurora-PCB-upload,
.aurora-PCB-replace {
  display: block;
  float: right;
  text-align: center;
  line-height: 36px;
  font-size: 16px;
  color: #1890ff;
  width: 16px;
}

.pcb-icon-tooltip .ant-tooltip-inner {
  color: #1890ff;
  background-color: #fff;
}

.pcb-icon-tooltip .ant-tooltip-arrow::before {
  background-color: #fffdfd;
}

.pcb-icon-tooltip {
  max-width: 300px;
}

.pcb-pre-layout {
  color: #1890ff !important;
}