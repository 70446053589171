body {
  margin: 0;
  color: rgba(0, 0, 0, .85);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-variant: tabular-nums;
  line-height: 1.5;
  background-color: #fff;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
}

.body {
  font-family: "Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
}

.aurora-layout {
  position: absolute;
  top: 54px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.aurora-sider {
  position: relative;
  width: 240px;
  background: #f9f9f9;
  height: 100%;
  border-right: 1px solid #e2dfdf;
}

.aurora-sider-menu {
  margin: 12px;
  padding: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  line-height: 30px;
  border-radius: 5px;
  color: #0d87f7;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  transition: 0.3s;
  box-shadow: #fff 0px 0px 0px 0px;
}

.aurora-sider-menu:hover {
  color: #379af5;
  box-shadow: rgba(168, 204, 241, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.18) 0px 0px 5px 2px;
  transition: 0.3s;
}

.aurora-select-dropdown {
  z-index: 10000000;
}

.aurora-select-dropdown .ant-select-dropdown-menu-item {
  /* padding: 4px 11px; */
  font-size: 13px;
  line-height: 24px;
}

.aurora-select-dropdown.ant-tree-select-dropdown .ant-select-tree .ant-select-tree-treenode {
  padding-bottom: 4px;
  margin-bottom: 0px;
}

.aurora-tooltip-span {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.aurora-project-create-name {
  width: 100%;
}

.aurora-project-create-name .ant-input-group {
  float: left;
  width: 100%;
}

.aurora-project-name-input {
  width: 100%;
  height: 24px;
}

.aurora-project-name-input .ant-input-group-addon {
  width: 50px;
  padding: 0 5px;
}

.aurora-project-name-input .ant-input {
  height: 24px;
}

.aurora-setup-border {
  border: 1px solid #a8c4e6;
  padding: 10px;
  border-radius: 3px;
}

.aurora-row-background {
  background-color: #edf7ff;
}

.aurora-record-background,
.aurora-record-background .editable-cell-value-wrap.ant-table-cell-fix-left,
.aurora-record-background .editable-cell-value-wrap.ant-table-cell-fix-right {
  background-color: #dcecff;
}

.aurora-record-background .editable-cell-value-wrap {
  border-color: #dcecff;
}

.aurora-model-name-error-msg {
  display: block;
  padding: 6px 10px;
  color: #cb2431;
  background: #ffdce0;
  border-radius: 8px;
}

.aurora-model-name-error-msg .aurora-model-error-item,
.aurora-model-name-warning-msg .aurora-model-warning-item {
  line-height: 26px;
  height: 26px;
  margin-left: 10px;
}

.aurora-model-name-warning-msg {
  display: block;
  padding: 6px 10px;
  color: #fa8c16;
  background: #fff7e6;
  border-radius: 8px;
  border: 1px solid #ffd591;
}

.aurora-success-msg {
  display: block;
  padding: 6px 10px;
  color: #1b8409;
  background: #f6ffed;
  border-radius: 8px;
  border: 1px solid #b7eb8f;
}

.aurora-table-comp {
  width: 100%;
}

.aurora-placeHolder {
  color: #bfbfbf;
}

.aurora-cursor {
  cursor: pointer;
}

.aurora-disabled-color {
  color: #949494 !important;
}

.aurora-cursor-disabled {
  cursor: not-allowed !important;
}

.panel-x-scroll-hidden {
  overflow-x: hidden !important;
}

.panel-x-scroll-hidden .rightBorder {
  right: 0px;
}

.panel-x-scroll-hidden .leftBorder {
  left: 0px;
}

.aurora-table-tr-light {
  border: 1px solid #40a9ff;
  box-shadow: 0px 0px 4px 1px #8fcafb;
  position: absolute;
  left: 2px;
  top: 2px;
  right: 2px;
  bottom: 2px;
  border-radius: 4px;
}

.aurora-file-check-div {
  color: #f30518;
  position: relative;
}

.aurora-file-text {
  color: rgba(0, 0, 0, 0.65);
}

.aurora-file-check-icon {
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -9px;
  color: #f13140;
  font-size: 18px;
}

.aurora-layout-split-screen {
  /* background-color: #c0c0c0; */
  z-index: 1000;
}

.aurora-layout-split-screen+.dividingLine-border {
  display: block;
}

.no-scroll .ant-table-container .aurora-table-body {
  overflow-x: hidden !important;
}

.aurora-table-row-disabled {
  display: inline-block;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: #f3f3f3;
  cursor: not-allowed;
}

.aurora-extraction-tabs.ant-tabs-card {
  margin-top: 10px;
}

.aurora-extraction-tabs.ant-tabs-card .ant-tabs-nav {
  height: 30px;
  margin-bottom: 0px;
}

.aurora-extraction-tabs.ant-tabs-card .ant-tabs-content {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-top: 0;
  padding: 10px;
  border-radius: 4px;
}

.aurora-extraction-tabs .ant-tabs-nav-list .ant-tabs-tab {
  margin-right: 4px;
  height: 32px;
  padding: 0 16px;
  line-height: 30px;
  background-color: #dedede;
}

.aurora-extraction-tabs .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
  color: #1890ff;
  font-weight: 500;
  height: 32px;
  line-height: 30px;
  border-radius: 5px 5px 0px 0px;
  background-color: #ffffff;
}

.aurora-extraction-tabs .ant-tabs-ink-bar-animated {
  display: none !important;
}

.aurora-top-bar-vertical-line {
  height: 16px;
  background-color: #c7c7c7;
  width: 1px;
  margin-right: 28px;
  display: inline-block;
}

.system-library-tooltip {
  width: 350px;
  max-width: 350px;
}

.text-popup {
  animation: textPopup 1s both;
  transform: translateY(-20px);
  color: #01cf97;
  user-select: none;
  white-space: nowrap;
  position: absolute;
  z-index: 999999999999999;
}

@keyframes textPopup {

  0%,
  100% {
    opacity: 0;
  }

  5% {
    opacity: 1;
  }

  100% {
    transform: translateY(-50px);
  }
}

.aurora-top-bar-stackup-icon {
  font-size: 14px;
  font-weight: 600;
  margin-top: 1px;
  margin-right: 8px;
}

.aurora-top-bar-advanced-icon {
  font-size: 20px;
  font-weight: 500;
  margin-right: 8px;
  vertical-align: middle;
}

.aurora-setup-title-color {
  color: #0b3694;
}

.layout-canvas-input,
.layout-canvas-select {
  transform: rotate(-180deg) scale(-1, 1);
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #ffffff;
  margin: 0px;
  padding: 0px;
}

.layout-canvas-select {
  padding-left: 6px;
}

.layout-canvas-select:focus,
.layout-canvas-input:focus {
  border-color: #40a9ff;
  box-shadow: 0 0 2px 2px rgba(24, 144, 255, 0.2);
  outline: 0;
}

.layout-canvas-include-unit-input {
  border-radius: 4px 0px 0px 4px;
}

.layout-canvas-include-unit-select {
  border-radius: 0px 4px 4px 0px;
  border-left: none;
}

.canvas-foreignObject {
  padding: 2px;
  position: relative;
}

/* .layout-canvas-selection-option {
  background-color: #ffffff !important;
  height: 30px;
  line-height: 30px;
}

.layout-canvas-selection-option:hover {
  background-color: #0d87f7;
} */

.ant-color-picker-trigger.aurora-color-picker-small {
  padding: 2px;
  min-width: 12px;
  min-height: 12px;
  margin-right: 5px;
  vertical-align: middle;
}

.ant-color-picker-trigger.aurora-color-picker-small .ant-color-picker-color-block {
  width: 12px;
  height: 12px;
}

.canvas-not-display-pin {
  display: none;
}