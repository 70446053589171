.designInfo .ant-tabs-bar {
  margin: 0px;
}

.properties-title {
  background: #1890ff;
  color: #ffffff;
}

.properties-table .ant-table>.ant-table-content>.aurora-table-body {
  margin: 0px;
}

.properties-table .aurora-table-body > tr:hover > td{
  background:rgba(230, 247, 255, 0.784) ! important;
}

.properties-table .aurora-table-body > tr:hover .properties-title{
  background:#1890ff ! important;
}

.component-name,
.component-part,
.net-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.component-name{
  width: 64px;
}

.component-part {
  width: 150px;
}

.net-name {
  width: 150px
}

.designInfo {
  height: 300px;
}

.detail-info {
  padding: 5px 10px
}

.detail-info div {
  line-height: 21px
}

/* .general-info-table {

} */