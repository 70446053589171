li {
  list-style: none;
}

.clear {
  zoom: 1;
}

.clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}

.pdn-footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: #f9f9f9;
  border-top: 1px solid #e2dfdf;
  width: 100%;
  z-index: 20;
  box-sizing: border-box;
}

/* my pdn monitor */

.my-pdn-monitor {
  /*  min-width: 340px; */
  overflow: auto;
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  top: 44px;
  left: 102px;
  right: 14px;
  bottom: 14px;
}

.my-pdn-monitor-title {
  font-weight: bold;
  line-height: 44px;
  padding-left: 20px;
  float: left;
}

.my-pdn-middle {
  position: relative;
  height: 44px;
  overflow: hidden;
  padding-right: 20px;
}

.my-pdn-btn {
  position: relative;
  float: right;
  height: 44px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  margin-right: 14px;
}

.my-pdn-monitor-progress {
  position: absolute;
  top: 0px;
  height: 44px;
  line-height: 44px;
  width: 100%;
}

.monitor-upload-progress-bar {
  width: 100%;
}

.monitor-upload-progress-bar .ant-progress-text {
  font-weight: bold;
}

.my-pdn-download-btn {
  position: relative;
  z-index: 20;
}

.my-pdn-debug-btn {
  position: relative;
  z-index: 20;
}

.design-msg-style {
  color: #f5222d;
  margin: 0;
  text-align: center;
}

.pdn-simulation-holygrail {
  position: absolute;
  right: 40px;
  left: 0;
  box-sizing: border-box;
  display: flex;
}

.pdn-simulation-title {
  width: 100%;
  position: relative;
  height: 44px;
}

.pdn-simulation-btn {
  position: relative;
  float: right;
  height: 44px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  width: 40px;
  margin-right: 12px;
}

.pdn-simulation-msg {
  position: relative;
  float: left;
  margin-top: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  min-width: 160px;
  text-align: center;
  margin-left: 16px;
  display: flex;
}

.pdn-simulation-span {
  position: relative;
  float: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  min-width: 80px;
}

.pdn-workflow-msg {
  position: relative;
  float: left;
  height: 44px;
  line-height: 42px;
  text-align: center;
  min-width: 80px;
  margin-left: 20px;
}

.workflow-msg {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 28px;
  font-size: 16px;
}

.pdn-simulation-project {
  margin-right: 10px;
}

.pdn-simulation-title .span-msg,
.pdn-simulation-title .span-msg-project {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 40px;
}

.pdn-span-msg-tooltip {
  display: block;
  text-indent: 8px;
}

.pdn-simulation-middle {
  position: relative;
  flex: auto;
  margin-left: 30px;
}

.pdn-simulation-progress {
  position: absolute;
  top: 2px;
  right: 0px;
  left: 0px;
  height: 40px;
  width: 100%;
  line-height: 44px;
  box-sizing: border-box;
}

.simulation-progress-bar {
  width: 100%;
}

.pdn-simulation-button,
.my-pdn-btn,
.pdn-upload-button {
  float: right;
  line-height: 44px;
  height: 44px;
  font-size: 18px;
  color: #1890ff;
  position: relative;
  z-index: 20;
  cursor: pointer;
  margin-left: 20px;
  box-sizing: border-box;
}

.pdn-simulation-progress .ant-progress-outer {
  width: 90%;
}

.pdn-simulation-progress .ant-progress-outer .ant-progress-inner {
  background-color: #fff;
}

.pdn-simulation-progress .ant-progress-text {
  margin-left: 4px;
}

.icon-tooltip .ant-tooltip-inner {
  color: #1890ff;
  background-color: #fff;
}

.icon-tooltip .ant-tooltip-arrow {
  border-top-color: #fffdfd;
}

.icon-tooltip {
  z-index: 1000000;
}

.span-msg {
  text-indent: 8px;
}

.pdn-monitor-ul {
  margin: 0;
  padding: 0;
}

.pdn-button {
  font-size: 18px;
}

.pdn-button-icon {
  font-size: 22px;
}

.pdn-simulation-debug-button {
  line-height: 46px;
}

.pdn-button-cancel {
  transform: rotate(90deg);
}

.debug-mes-span {
  display: block;
}

.closed-message {
  display: block;
}

.simulation-waiting-title {
  line-height: 42px;
  font-weight: bold;
  float: right;
}

.pdn-simulation-btn .icon-cancel9 {
  font-size: 20px;
  line-height: 45px;
  color: #1890ff;
}

.pdn-simulation-title .span-msg-icon {
  font-size: 18px;
  color: #1890ff;
}

.pdn-simulation-title .span-msg-icon-div {
  padding-left: 12px;
  margin-left: 4px;
  width: 100%;
  margin-top: 12px;
}

.pdn-simulation-title .waiting-time-span {
  display: inline-block;
  margin-left: 10px;
  font-weight: 500;
}

.debug-icon-font {
  font-size: 22px;
}

.pdn-simulation-button,
.pdn-cancel-button,
.pdn-kill-button {
  float: right;
  line-height: 44px;
  height: 44px;
  font-size: 18px;
  color: #1890ff;
  position: relative;
  z-index: 20;
  cursor: pointer;
  margin-left: 16px;
  box-sizing: border-box;
}

.pdn-cancel-button {
  line-height: 43px;
}

.pdn-kill-button {
  line-height: 45px;
  font-size: 19px;
  margin-left: 18px;
}

.pdn-stackup-error-dialog {
  padding-top: 10px;
  padding-left: 20px;
  color: #ff4848;
}

.pdn-monitor-backbottom {
  position: absolute;
  font-size: 18px;
  right: 40px;
  bottom: 40px;
  opacity: 0.6;
  background-color: #1890ff;
  color: #fff;
  transform: rotate(90deg);
  padding: 4px;
  border-radius: 4px;
}

.pdn-monitor-backbottom:hover {
  opacity: 0.8;
  font-weight: bold;
}